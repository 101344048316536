
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ErrorBoundary extends Vue {
  @Prop() stopPropagation!: boolean;

  err = false;
  vm: any = null;
  info: any = null;

  errorCaptured(err: any, vm: any, info: any) {
    this.err = err;
    this.vm = vm;
    this.info = info;

    return !this.stopPropagation;
  }
}
