
/**
 *  Préparation : Affectation des UE
 */

import { Component, Vue } from "vue-property-decorator";

import StockController from "../shared/services/controllers/stock/stockController";
import { apiUrls } from "@/environment/environment";

// eslint-disable-next-line no-unused-vars
import { PreparationNoteToAssign, User } from "../shared/store/Types";

const moduleName = "Stock";

@Component
export default class ShippingUnitToAssign extends Vue {
  // Chargement des controller
  private controller: StockController;
  constructor() {
    super();
    this.controller = new StockController(apiUrls.stock, apiUrls.user);
  }

  company: string = this.$store.state.userContext.currentCompany;
  warehouse: string = this.$store.state.userContext.currentWarehouse;

  assignmentDate: string = new Date().toISOString().substr(0, 10);
  eanNoteOrUE = "";
  eanEmployee = "";

  errorNotif = false;
  errorMessage = "errorMessage";
  successNotif = false;
  successMessage = "successMessage";
  forceAssignement = false;
  dialog = false;

  preparationNoteToAssign: PreparationNoteToAssign = {} as PreparationNoteToAssign;

  employee: User = {} as User;

  /**
   * action sur changement de date
   */
  dateChange() {
    Vue.$log.debug("dateChange");
  }

  /**
   * Flashage du CAB bon ou UE
   */
  async changeBarCodeNoteOrUE() {
    this.controller.Log.logDebug(`getInfoAffectedWithBarCode`, `Object query : ${JSON.stringify(this.eanNoteOrUE)}`);
    let sendResult: { data: PreparationNoteToAssign; status: number; errors: any };

    try {
      // appel API pour recuperer le bon et ses infos
      if (this.eanNoteOrUE) {
        sendResult = await this.controller.StockStore.getInfoAffectedWithBarCode(this.eanNoteOrUE);

        if (sendResult.status === 200) {
          this.preparationNoteToAssign = sendResult.data;
          (this.$refs.eanEmployee as any).focus();

          // Traite le cas d'une UE deja affecté
          // on affiche la boite de dialog pour confirmation

          if (this.preparationNoteToAssign.shippingUnit) {
            if (this.preparationNoteToAssign.shippingUnit.pickerId) {
              this.dialog = true;
            }
          } else {
            if (this.preparationNoteToAssign.numberOfPreparer >= 1) {
              this.dialog = true;
            }
          }
        } else if (sendResult.status === 204) {
          this.errorNotif = true;
          this.errorMessage = "Aucun bon n'a été trouvé.";
          (this.$refs.eanNoteOrUE as any).$refs.input.select();
        } else {
          // traitement de l'erreur et affichage du message d'erreur

          if (sendResult.data) {
            let listError = sendResult.errors;
            if (sendResult.errors) {
              listError = sendResult.errors;
            }
            this.errorMessage = "";
            Object.entries(listError).forEach(([key, value]) => {
              if (key == "barcode" || key == "barCode") key = "Code à barre";
              this.errorMessage += `${key}: ${value}\n`;
            });
            // Affichage de la notif d'erreur

            this.errorNotif = true;
            (this.$refs.eanNoteOrUE as any).$refs.input.select();
          }
        }
      } else {
        this.errorMessage = "";
        this.errorMessage = "Le bon ou l'unité d'expédition à assigner n'a pas été renseigné.\n";
        this.errorNotif = true;
      }
    } catch (error) {
      //Log

      await this.controller.Log.logError(`getInfoAffectedWithBarCode`, "", error, moduleName);
    }
  }

  /**
   * Flashage du code employé
   */
  async changeEmployeeBarCode() {
    Vue.$log.debug("changeEmployeeBarCode");
    let result: { data: User; status: number; errors: any };
    let response: { data: PreparationNoteToAssign; status: number; errors: any };

    if (this.eanEmployee && this.eanNoteOrUE) {
      try {
        result = await this.controller.StockStore.getUserByCode(this.eanEmployee);
        if (result.status === 200) {
          this.employee = result.data;
          if (this.preparationNoteToAssign.id) {
            // affecte uniquement l'unité d'expédition au picker
            if (this.preparationNoteToAssign.shippingUnit) {
              response = await this.controller.StockStore.setPickerForShippingUnit(
                this.employee.id,
                this.preparationNoteToAssign.shippingUnit.id,
                this.assignmentDate
              );
            }
            // affecte tous le bon de préparation de commande au picker
            else {
              response = await this.controller.StockStore.setPickerForPreparationNote(
                this.employee.id,
                this.preparationNoteToAssign.id,
                this.preparationNoteToAssign.recipientId,
                this.preparationNoteToAssign.recipientName,
                this.assignmentDate
              );
            }

            if (response.status === 200) {
              // vider le CAB employé
              this.eanEmployee = "";
              // mettre le focus sur le CAB UE
              (this.$refs.eanNoteOrUE as any).focus();
              this.successNotif = true;
              this.successMessage = "L'utilisateur a bien été affecté.";
              this.controller.Log.logDebug(`changeEmployeeBarCode`, `response 200`);
            } else {
              // traitement de l'erreur et affichage du message d'erreur
              if (response.data) {
                let listError = response.errors;
                if (response.errors) {
                  listError = response.errors;
                }
                this.errorMessage = "";
                Object.entries(listError).forEach(([key, value]) => {
                  this.errorMessage += `${key}: ${value}\n`;
                });
                // Affichage de la notif d'erreur
                this.errorNotif = true;
              }
            }
          } else {
            this.errorMessage = "Le bon ou l'unité d'expédition à assigner est incorrect";
            this.errorNotif = true;
          }
        } else if (result.status === 204) {
          this.errorMessage = "Aucun utilisateur n'a été trouvé.";
          this.errorNotif = true;
          (this.$refs.eanEmployee as any).$refs.input.select();
        } else {
          // traitement de l'erreur et affichage du message d'erreur
          if (result.data) {
            let listError = result.errors;
            if (result.errors) {
              listError = result.errors;
            }
            this.errorMessage = "";
            Object.entries(listError).forEach(([key, value]) => {
              this.errorMessage += `${key}: ${value}\n`;
            });
            // Affichage de la notif d'erreur
            this.errorNotif = true;
          }
          (this.$refs.eanEmployee as any).$refs.input.select();
        }
      } catch (error) {
        this.errorMessage = error as string;
        this.errorNotif = true;
      }
    } else {
      this.errorNotif = true;
      this.errorMessage = "";
      if (!this.eanNoteOrUE) this.errorMessage += "Le bon ou l'unité d'expédition à assigner n'a pas été renseigné.\n";
      if (!this.eanEmployee) this.errorMessage += "L'utilisateur à assigner n'a pas été renseigné.";
    }
  }

  /**
   * Appel API pour forcer l'affectation d'une UE deja affectée
   */
  async forceAffect() {
    this.dialog = false;
    //Variable permettant de forcer l'affectation de l'UE deja affecté
    this.forceAssignement = true;

    Vue.nextTick().then(() => {
      //Prise de focus sur l'element de saisie des nombre de composant
      (this.$refs.eanEmployee as any).focus();
    });
  }

  async returnOnField() {
    this.dialog = false;
    //Variable permettant de forcer l'affectation de l'UE deja affecté
    this.forceAssignement = false;
    this.eanNoteOrUE = "";

    Vue.nextTick().then(() => {
      //Prise de focus sur l'element de saisie des nombre de composant
      (this.$refs.eanNoteOrUE as any).focus();
    });
  }

  mounted() {
    // this.assignmentDate = new Date().toISOString().substr(0, 10);
  }

  created() {
    // this.retrieveController();
  }
}
