
import { Component, Vue } from "vue-property-decorator";
import ButtonComponent from "../../button/ButtonComponent.vue";

@Component({
  components: {
    ButtonComponent,
  },
})
export default class ButtonColumnRenderer extends Vue {}
