import { Module } from "vuex";
import { RootState } from "@/app/app-state";
import { FieldType, Filter, Operator as MathOperator } from "@/app/shared/components/generic/list/V3/Type";
import OptionalFilter from "@/app/stock/shared/types/OptionalFilter";
import UserFilter from "@/app/stock/shared/types/UserFilter";
import LocalizationWithQuantity from "@/app/stock/shared/types/LocalizationWithQuantity";
import OperatorWithQuantity from "@/app/stock/shared/types/OperatorWithQuantity";
import {
  ThirdWarehouse,
  MouvementDirection,
  NoteStatus,
  StreamType,
  StatisticCode,
  WeeklyDeliveryPlanning,
  Localization,
  Operator,
} from "@/app/stock/shared/store/Types";

export interface StateDefinition {
  optionalFilters: OptionalFilter;
  queryFilters: Filter[];
  usersFiltersSet: UserFilter;
  filterPalletNumber: string | null;
  selectedZonesFilter: LocalizationWithQuantity[];
  selectedOperatorsFilter: OperatorWithQuantity[];
}

const OperatorMissionPriorityModule: Module<StateDefinition, RootState> = {
  namespaced: true,
  state: {
    optionalFilters: {},
    queryFilters: [],
    usersFiltersSet: {} as UserFilter,
    filterPalletNumber: null,
    selectedZonesFilter: [],
    selectedOperatorsFilter: [],
  },

  getters: {
    getOptionalFilters(state: StateDefinition) {
      return state.optionalFilters;
    },
  },

  mutations: {
    emptyOptionalFilters(state: StateDefinition) {
      state.optionalFilters = {};
    },
    emptyQueryFilters(state: StateDefinition) {
      state.queryFilters = [];
    },
    setOptionalFilters(state: StateDefinition, payload: OptionalFilter) {
      state.optionalFilters = payload;
    },
    addQueryFilter(state: StateDefinition, payload: Filter) {
      state.queryFilters.push(payload);
    },
    setQueryFilters(state: StateDefinition, payload: Filter[]) {
      state.queryFilters = payload;
    },
    setOptionalFiltersFromUsersFiltersSet(state: StateDefinition) {
      if (state.usersFiltersSet.filter != undefined) state.optionalFilters = state.usersFiltersSet.filter;
      else state.optionalFilters = {};
    },
    setFilterPalletNumber(state: StateDefinition, payload: string | null) {
      state.filterPalletNumber = payload;
    },
    updateUsersFiltersSet(state: StateDefinition, payload: UserFilter) {
      state.usersFiltersSet = payload;
    },
    setSelectedZonesFilter(state: StateDefinition, payload: LocalizationWithQuantity[]) {
      state.selectedZonesFilter = payload;
    },
    setSelectedOperatorsFilter(state: StateDefinition, payload: OperatorWithQuantity[]) {
      state.selectedOperatorsFilter = payload;
    },
    extractQueryFilters(state: StateDefinition) {
      const query: Filter[] = [];
      // On vide la variable du parent
      state.filterPalletNumber = "";
      // on clean les cles de valeur null
      if (state.optionalFilters == undefined) return;
      const filterClean: OptionalFilter = Object.fromEntries(
        Object.entries(state.optionalFilters).filter(([, val]) => val != null && val != "")
      );
      Object.entries(filterClean).forEach((entry) => {
        const [key, value] = entry;
        if (value != null) {
          switch (key) {
            case "contractorWarehouse":
            case "partnerWarehouse":
            case "carrierWarehouse":
            case "associatedPartner":
              query.push({
                field: `${key}Id`,
                fieldType: FieldType.string,
                operator: MathOperator.equals,
                value: (value as ThirdWarehouse).id,
              });
              break;
            case "mouvementDirection":
              query.push({
                field: key,
                fieldType: FieldType.string,
                operator: MathOperator.equals,
                value: (value as MouvementDirection).code,
              });
              break;
            case "noteStatus":
              query.push({ field: key, fieldType: FieldType.string, operator: MathOperator.equals, value: (value as NoteStatus).code });
              break;
            case "streamType":
              query.push({
                field: key,
                fieldType: FieldType.string,
                operator: MathOperator.equals,
                value: `${(value as StreamType).code}`,
              });
              break;
            case "palettNumber":
              // On renseigne la variable du parent
              state.filterPalletNumber = value;
              break;
            case "statisticCode":
              query.push({
                field: `${key}Id`,
                fieldType: FieldType.string,
                operator: MathOperator.equals,
                value: (value as StatisticCode).id,
              });
              break;
            case "weeklyDeliveryPlanning":
              query.push({
                field: `${key}Id`,
                fieldType: FieldType.string,
                operator: MathOperator.equals,
                value: (value as WeeklyDeliveryPlanning).id,
              });
              break;
            case "localization":
              query.push({
                field: `${key}Id`,
                fieldType: FieldType.string,
                operator: MathOperator.equals,
                value: (value as Localization).id,
              });
              break;
            case "operator":
              query.push({ field: `${key}Id`, fieldType: FieldType.string, operator: MathOperator.equals, value: (value as Operator).id });
              break;
            case "receivedDate":
            case "pickupDate":
            case "deliveryDate":
              query.push({ field: `${key}`, fieldType: FieldType.date, operator: MathOperator.equals, value: value });
              break;
            default:
              query.push({ field: key, fieldType: FieldType.string, operator: MathOperator.equals, value: value });
              break;
          }
        }
      });
      state.queryFilters = query;
    },
  },

  actions: {
    clearOptionalFilters({ commit }) {
      commit("emptyOptionalFilters");
      commit("emptyQueryFilters");
    },
    resetSearchToLastFilter({ commit }) {
      commit("setQueryFilters", [] as Filter[]);
      commit("setOptionalFiltersFromUsersFiltersSet");
      commit("extractQueryFilters");
    },
  },
};

export default OperatorMissionPriorityModule;
