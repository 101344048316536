
import { apiUrls } from "@/environment/environment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import StockController from "../../shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { ThirdWarehouse } from "../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import { ShippingUnitForStorage, WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage } from "../../shared/types/ShippingUnit";

import CardExpander from "../../storageShippingUnits/components/CardExpander.vue";

const noteTypeItems: { id: number; libelle: string }[] = [
  { id: 1, libelle: "BPC" },
  { id: 2, libelle: "Bon de mouvement" },
];

const today: string = new Date().toISOString().substr(0, 10);
@Component({
  components: {
    CardExpander,
  },
})
export default class StorageShippingUnitsConsultationList extends Vue {
  private controller: StockController;

  @Prop({ default: null }) shippingUnitForStorage!: ShippingUnitForStorage | WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage;
  constructor() {
    super();
    this.controller = new StockController(apiUrls.stock, apiUrls.user);
  }

  panel: number[] = [0, 1, 2];
  loading = false;
  loadingFilter = false;

  filterShow = false;
  selectedCarriersWarehouse: ThirdWarehouse | null = null;
  carriersWarehouses: ThirdWarehouse[] = [];
  searchCarriersWarehouse = "";
  searchCarriersWarehouse2 = "";

  noteTypeDateFilter: { id: number; libelle: string } | undefined = noteTypeItems[0];
  noteTypeDateItems: { id: number; libelle: string }[] = noteTypeItems;
  searchFilterNoteType: null | string = null;

  filterDateStart: string = today;
  filterDateEnd: string = today;

  /**
   * indique si on active les boutons de modification
   */
  get isDisable(): boolean {
    return !(this.noteTypeDateFilter && this.filterDateStart && this.filterDateEnd);
  }

  valid = false;
  /**
   * Validation du formualaire de filtre
   */
  async valideFilter() {
    (this.$refs as any).form.validate();
    if (this.valid) {
      if (this.filterDateStart) {
        this.dateModel = this.filterDateStart;
        this.loadingFilter = true;
        this.getWeeklyDeliveryPlanningPlanNote().finally(() => {
          this.loadingFilter = false;
        });
      }
    }
  }

  async changeDateFilter() {
    (this.$refs as any).form.validate();
  }

  rules: any = {
    dateMustBeLower: (value: any) => {
      return !value || this.checkDate() || "Date de debut doit etre inferieur a date de fin";
    },
    dateMustBeUper: (value: any) => {
      return !value || this.checkDate() || "Date de fin doit etre superieur a date de debut";
    },
    dateRequired: (v: any) => !!v || "Date requise",
  };

  /**
   * Verification coherance des date de debut et fin
   */
  checkDate() {
    let isValide = false;

    if (this.filterDateStart && this.filterDateEnd) {
      isValide = new Date(this.filterDateStart) <= new Date(this.filterDateEnd);
    }
    return isValide;
  }

  /**
   * watch to autocomplete for search CarriersWarehouse
   * @param {string} value
   */
  @Watch("searchCarriersWarehouse2")
  @Watch("searchCarriersWarehouse")
  onSearchCarriersWarehouseChanged(value: string): void {
    if (value === null) {
      this.getCarriersWarehouse();
    } else if (value != this.selectedCarriersWarehouse?.codeName) {
      this.getCarriersWarehouse(value);
    }
  }

  /**
   * Watch sur le Shiiiping unit. si il est fourni, la date de la consultation est sur la date du bon
   */
  @Watch("shippingUnitForStorage")
  onShippingUnitForStorageChange(value: ShippingUnitForStorage | WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage): void {
    if (value?.notesDate) {
      this.dateModel = value.notesDate;
      this.getWeeklyDeliveryPlanningPlanNote();
    }
  }

  weeklyDeliveryPlanningPlanNoteForShippigUnitStorage: WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage[] = [];

  /**
   *  Retourne la liste des elements a faire
   */
  get todoWeeklyDeliveryPlanningPlanNoteForShippigUnitStorage(): WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage[] {
    return this.weeklyDeliveryPlanningPlanNoteForShippigUnitStorage.filter((w: WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage) => {
      return w.stockedShippingUnitCount === 0 && w.totalShippingUnit > 0;
    });
  }

  /**
   *  Retourne la liste des elements en cours
   */
  get doingWeeklyDeliveryPlanningPlanNoteForShippigUnitStorage(): WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage[] {
    return this.weeklyDeliveryPlanningPlanNoteForShippigUnitStorage.filter((w: WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage) => {
      return w.stockedShippingUnitCount > 0 && w.stockedShippingUnitCount < w.totalShippingUnit;
    });
  }

  /**
   * Retourne la liste des elements terminés
   */
  get doneWeeklyDeliveryPlanningPlanNoteForShippigUnitStorage(): WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage[] {
    return this.weeklyDeliveryPlanningPlanNoteForShippigUnitStorage.filter((w: WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage) => {
      return w.stockedShippingUnitCount === w.totalShippingUnit;
    });
  }

  /**
   * Filtre sur la date oblgatoire (setter a date du jours par default)
   */
  dateModel: string = today;

  /**
   * Appel a l'api pour recuperer liste des Cadencier/Plans/Bons pour la consultation d'avancement du stockage des U.E.
   */
  async getWeeklyDeliveryPlanningPlanNote() {
    await this.controller.Log.logDebug(`getWeeklyDeliveryPlanningPlanNote`, "");
    let sendResult: { data: WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage[]; status: number; errors: any };

    try {
      this.weeklyDeliveryPlanningPlanNoteForShippigUnitStorage = [] as WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage[];
      sendResult = await this.controller.StockStore.getWeeklyDeliveryPlanningPlanNote(
        this.dateModel,
        this.selectedCarriersWarehouse,
        this.noteTypeDateFilter,
        this.filterDateStart,
        this.filterDateEnd
      );
      if (sendResult.status === 200) {
        this.weeklyDeliveryPlanningPlanNoteForShippigUnitStorage = sendResult.data;
      }
    } catch (error) {
      await this.controller.Log.logError(`getWeeklyDeliveryPlanningPlanNote`, "", error, "StorageShippingUnitsConsultation");
    }
  }

  /**
   * Get sugestion des transporteurs
   */
  async getCarriersWarehouse(searchQuery?: string) {
    let response = await this.controller.StockStore.getCarriersWarehouse(searchQuery ? searchQuery : "");
    let carriersWarehouses: ThirdWarehouse[] = [];
    if (response.data) {
      carriersWarehouses = response.data;
      for (let c of carriersWarehouses) {
        c.codeName = `${c.code} - ${c.name}`;
      }
    }

    this.carriersWarehouses = carriersWarehouses;
  }

  /**
   * appel a l'api avec filtrage
   */
  refreshData() {
    this.filterShow = !this.filterShow;
    this.getWeeklyDeliveryPlanningPlanNote();
  }

  /**
   * Appel a la methode du parnet pour fermer le detail
   */
  goToParentBack() {
    Vue.nextTick().then(() => {
      this.$vuetify.goTo(0, { duration: 0, offset: 0 });
    });
    (this.$parent as any).goBackFromConsultationList();
  }

  /**
   * ouverture de l'expander du detail et scroll en auto jusqua l'element
   */
  openExpander() {
    Vue.nextTick().then(() => {
      const refCode = this.getRef(this.shippingUnitForStorage);

      if (refCode != undefined) {
        let el = (this.$refs[refCode] as any)[0];
        if (el) {
          el.expand = true;
          this.$vuetify.goTo((this.$refs[refCode] as any)[0], {
            duration: 100,
            offset: 0,
            easing: "linear",
          });
        }
      }
    });
  }

  /**
   * Retourne le nom formaté de la ref avec le shipping unit
   */
  getRef(
    weeklyDeliveryPlanningPlanNoteForShippigUnitStorage: ShippingUnitForStorage | WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage
  ): string | undefined {
    if (weeklyDeliveryPlanningPlanNoteForShippigUnitStorage.noteId) {
      return "noteId-" + weeklyDeliveryPlanningPlanNoteForShippigUnitStorage.noteId;
    }
    if (weeklyDeliveryPlanningPlanNoteForShippigUnitStorage.planId) {
      return "planId-" + weeklyDeliveryPlanningPlanNoteForShippigUnitStorage.planId;
    }
    if (weeklyDeliveryPlanningPlanNoteForShippigUnitStorage.weeklyDeliveryPlanningId) {
      return "weeklyDeliveryPlanningId-" + weeklyDeliveryPlanningPlanNoteForShippigUnitStorage.weeklyDeliveryPlanningId;
    }
  }

  /**
   * Action sur supression du filtre
   */
  removeFilterAndRefreshData() {
    this.selectedCarriersWarehouse = null;
    this.getWeeklyDeliveryPlanningPlanNote();
  }

  mounted() {
    this.getWeeklyDeliveryPlanningPlanNote();
  }
}
