// import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";

// Vue.use(Vuetify);

// export default new Vuetify({});
import Vue from "vue";
import { UserVuetifyPreset } from "vuetify";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const opts: UserVuetifyPreset = {
  icons: {
    iconfont: "mdi",
    values: {
      // Permet de definir des icons reutilisatble par variable
      product: "mdi-dropbox",
    },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        next: "#ec406f",
        darkNext: "#c13660",
        tesfriRaspberry: "#ec406f",
        anchor: "#ec406f",
      },
    },
  },
};

export default new Vuetify(opts);
