
import { Component, Prop } from "vue-property-decorator";
import ModalComponent from "@/app/shared/components/generic/modal/ModalComponent.vue";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
import formUpdate from "../form/FormOrderPreparationDetail.vue";
// eslint-disable-next-line no-unused-vars
import { AffectedPreparatedNoteInfo } from "@/app/stock/shared/store/Types";

@Component({
  name: "ModalOrderPreparationDetail",
  components: {},
})
export default class ModalOrderPreparationDetail extends ModalComponent {
  @Prop() declare controller: StockController;

  commandeNumber: number | null = null;
  commandeNumberFinalClient: any = null;
  status = "";
  tourNumber: any = null;
  cadencier = "";
  carrierName = "";
  dialog = false;

  get dynamicModal() {
    return formUpdate;
  }

  /**
   * ouverture de la modal avec les detail d'infos
   */
  // eslint-disable-next-line no-unused-vars
  async showModalDetailPrera(params: any) {
    if (params.id) {
      this.controller.Log.logDebug(`loadComponent`, `Object query : ${JSON.stringify(params)}`);
      let sendResult: AffectedPreparatedNoteInfo[] | null = [];

      try {
        sendResult = await this.controller.StockStore.getAffectedNoteInfo(params.id);

        // suppression des doublons pour la fonction reduce
        const reducer = (acc: any, valCourante: any) => {
          if (acc.indexOf(valCourante) === -1) {
            acc.push(valCourante);
          }
          return acc;
        };

        this.commandeNumber = sendResult
          .map((e) => e.orderNumber)
          .reduce(reducer, [])
          .sort()
          .join(", ");
        this.commandeNumberFinalClient = sendResult
          .map((e) => e.finalCustomerOrderNumber)
          .reduce(reducer, [])
          .join(", ");

        this.status = sendResult
          .map((e) => {
            let val;
            switch (e.status) {
              //EN = En cours / PA = en pause / IN = Transmis
              case "IN":
                val = "Transmis";
                break;
              case "EN":
                val = "En cours";
                break;
              case "PA":
                val = "En pause";
                break;
            }
            return val;
          })
          .reduce(reducer, [])
          .join(", ");

        this.tourNumber = sendResult
          .map((e) => e.deliveryRoundsLabel)
          .reduce(reducer, [])
          .join(", ");
        this.cadencier = sendResult
          .map((e) => e.deliveryRoundsCode)
          .reduce(reducer, [])
          .join(", ");
        this.carrierName = sendResult
          .map((e) => e.carrier.name)
          .reduce(reducer, [])
          .join(", ");
        this.dialog = true;
      } catch (error) {
        //Log
        await this.controller.Log.logError(`loadComponent`, "", error, "modalOrderPreparationDetail");
        sendResult = null;
      }
    }
  }

  async closeModalDetailPrera() {
    this.dialog = false;
  }

  async beforeMount() {
    this.controller.EventBus.$on("closeModalDetailPrera", await this.closeModalDetailPrera);
    this.controller.EventBus.$on("showModalDetailPrera", await this.showModalDetailPrera);
  }

  async beforeDestroy() {
    this.controller.EventBus.$off("closeModalDetailPrera", await this.closeModalDetailPrera);
    this.controller.EventBus.$off("showModalDetailPrera", await this.showModalDetailPrera);
  }
}
