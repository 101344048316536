import { render, staticRenderFns } from "./ErrorBoundary.vue?vue&type=template&id=2e671570&scoped=true&"
import script from "./ErrorBoundary.vue?vue&type=script&lang=ts&"
export * from "./ErrorBoundary.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e671570",
  null
  
)

export default component.exports