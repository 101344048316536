
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import "moment/locale/fr";
// eslint-disable-next-line no-unused-vars
import ControllerBase from "@/app/shared/services/controllers/controllerBase";

moment.locale("fr");

@Component
export default class FormOrderPreparationDetail extends Vue {
  @Prop() controller!: ControllerBase;

  commandeNumber: number | null = null;
  commandeNumberFinalClient: number | null = null;
  status = "";
  tourNumber: number | null = null;
  cadencier = "";
  carrierName = "";

  // /**
  //  * initialisation du composant
  //  * @param params
  //  */
  async loadComponent(params: any) {
    this.commandeNumber = params.orderNumber;
    this.commandeNumberFinalClient = params.finalCustumerOrderNumber;

    switch (params.status) {
      //EN = En cours / PA = en pause / IN = Transmis
      case "IN":
        this.status = "Transmis";
        break;
      case "EN":
        this.status = "En cours";
        break;
      case "PA":
        this.status = "En pause";
        break;
    }

    this.tourNumber = params.deliveryRoundsLabel;
    this.cadencier = params.deliveryRoundsCode;
    this.carrierName = params.carrier.name;
  }
  async beforeMount() {
    this.controller.EventBus.$on("loadUpdate", await this.loadComponent);
  }

  async beforeDestroy() {
    this.controller.EventBus.$off("loadUpdate", await this.loadComponent);
  }
}
