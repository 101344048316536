
import { Component, Vue } from "vue-property-decorator";
import ButtonComponent from "../../button/ButtonComponent.vue";

@Component({
  components: {
    ButtonComponent,
  },
})
export default class ButtonHideColumnRenderer extends Vue {
  private params: any;

  get show() {
    return this.params.value;
  }
}
