import router from "@/app/app-routes";
import store from "@/app/app-state";
import { Context } from "ag-grid-community";
import { licenseExpirationTime } from "@/environment/environment";

const refreshUserContext = class {
  //licenseExpirationTime
  async start() {
    setInterval(this.setUserRightsFromApi, 30000);

    // on se donne 20% seconde de delais entre l'appel et l'expiration reel de la licences
    let licenseExpirationrefresh: number = licenseExpirationTime;
    licenseExpirationrefresh = licenseExpirationrefresh - licenseExpirationrefresh * 0.2;
    setInterval(this.setRefreshUserLicenceAccess, licenseExpirationrefresh);
  }
  /**
   * Recuperation des droits de l'API
   */
  async setUserRightsFromApi() {
    store.dispatch("userContext/refreshUserRights");
  }

  /**
   * Recuperation des acces licences de l'API
   */
  async setRefreshUserLicenceAccess() {
    store.dispatch("userContext/refreshUserLicenceAccess").then(() => {
      if (!store.state.userContext.isLicenceAuthorize && !router.currentRoute.meta?.allowAnonymous) {
        // si on perd l'authorisation des licences, on retourne sur la page de login
        store.dispatch("userContext/logout");
        if (router.currentRoute.path != "/Login") {
          router.push({ path: "/Login" });
        }
      }
    });
  }
};

export default new refreshUserContext();
