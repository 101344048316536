
import { Component, Vue } from "vue-property-decorator";
import ButtonComponent from "../../button/ButtonComponent.vue";

@Component({
  components: {
    ButtonComponent,
  },
})
export default class ButtonDisablableColumnRenderer extends Vue {
  private params: any;

  get disabled() {
    switch (this.params.type) {
      case "int": {
        if (parseInt(this.params.value, 10) <= 0) {
          return true;
        } else {
          return false;
        }
      }
      default:
        return !this.params.value;
    }
  }
}
