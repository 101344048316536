
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import StockController from "../../shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { ThirdWarehouse } from "../../shared/store/Types";

@Component
export default class ContractorsWarehousefilter extends Vue {
  @Prop() controller!: StockController;
  @VModel() selectedContractorWarehouse!: ThirdWarehouse | null;

  filterShow = false;

  contractorsWarehouses: ThirdWarehouse[] = [];
  searchContractorsWarehouse = "";

  /**
   * watch to autocomplete for search ContractorsWarehouse
   * @param {string} value
   * @param {string} oldValue
   */
  @Watch("searchContractorsWarehouse")
  onSearchContractorsWarehouseChanged(value: string): void {
    if (value && (!this.selectedContractorWarehouse || this.selectedContractorWarehouse.codeName !== value)) {
      this.getContractorsWarehouse(value);
    } else {
      this.contractorsWarehouses = [];
    }
  }

  /**
   * Get sugestion des transporteurs
   */
  async getContractorsWarehouse(searchQuery: string) {
    let response = await this.controller.StockStore.getCustomerSuggests(searchQuery);

    if (response.data) {
      this.contractorsWarehouses = response.data;
      for (let c of this.contractorsWarehouses) {
        c.codeName = `${c.code} - ${c.name}`;
      }
    } else {
      this.contractorsWarehouses = [];
    }
  }

  refreshData() {
    this.filterShow = !this.filterShow;
  }

  removeFilterAndRefreshData() {
    this.selectedContractorWarehouse = null;
  }
}
