
import Component from "vue-class-component";
import { Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";

// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  Operator,
} from "../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import { DataTableHeader } from "vuetify";
// eslint-disable-next-line no-unused-vars
import OperatorWithQuantity from "../../shared/types/OperatorWithQuantity";
// eslint-disable-next-line no-unused-vars
import { FieldType, Filter, Operator as MathOperator } from "@/app/shared/components/generic/list/V3/Type";
// eslint-disable-next-line no-unused-vars
import OptionalFilter from "../../shared/types/OptionalFilter";

@Component({
  computed: mapState("OperatorMissionPriorityModule", { operatorsFilterSelected: "selectedOperatorFilter" }),
  methods: {
    ...mapActions("OperatorMissionPriorityModule", ["clearOptionalFilters", "resetSearchToLastFilter"]),
    ...mapMutations("OperatorMissionPriorityModule", ["addQueryFilter", "setOptionalFilters"]),
  },
  components: {},
})
export default class OperatorMissionPriorityOperatorFilters extends Vue {
  @Prop() controller!: StockController;
  @Prop() date!: string;
  @VModel() filters!: Filter[];

  operatorPalletCountItems: OperatorWithQuantity[] = [];

  // initialFilter: Filter[] = [];

  clearOptionalFilters!: () => any;
  addQueryFilter!: (filter: Filter) => any;
  setOptionalFilters!: (optionalFilter: OptionalFilter) => any;
  resetSearchToLastFilter!: () => any;

  get operatorsFilterSelected(): OperatorWithQuantity[] {
    return this.$store.state.OperatorMissionPriorityModule.selectedOperatorsFilter;
  }
  set operatorsFilterSelected(value: OperatorWithQuantity[]) {
    this.$store.commit("OperatorMissionPriorityModule/setSelectedOperatorsFilter", value);
  }

  headers: DataTableHeader[] = [
    {
      text: "Nom",
      align: "start",
      sortable: false,
      value: `code`,
      width: "55%",
    },
    { text: "Nombre palettes", align: "end", sortable: false, value: "palletCount" },
  ];

  /**
   * Selectionne une ligne au click de la ligne
   */
  rowClick(item: OperatorWithQuantity) {
    if (this.operatorsFilterSelected.includes(item)) {
      this.operatorsFilterSelected.splice(this.operatorsFilterSelected.indexOf(item), 1);
    } else {
      this.operatorsFilterSelected = [item];
    }

    if (this.operatorsFilterSelected.length == 1) {
      this.clearOptionalFilters();
      this.addQueryFilter({
        field: `operatorId`,
        fieldType: FieldType.string,
        operator: MathOperator.equals,
        value: item.id,
      });
      let operator: Operator = this.operatorsFilterSelected[0] as unknown as Operator;
      this.setOptionalFilters({ operator: operator });
    } else {
      this.resetSearchToLastFilter();
    }
  }

  @Watch("date")
  async getInfos() {
    if (this.date != null) {
      let result = await this.controller.StockStore.getOperatorListForOperatorMissionPriorisationAsync(this.date);
      if (result.status === 200) {
        this.operatorPalletCountItems = result.data;
      }
    }
  }

  mounted() {
    // this.initialFilter = JSON.parse(JSON.stringify(this.filters));
    this.getInfos();
  }

  async beforeMount() {
    this.controller.EventBus.$on("refreshDataGridPaletDetail", this.getInfos);
    this.controller.EventBus.$on("refreshDataGrid", this.getInfos);
  }
  async beforeDestroy() {
    this.controller.EventBus.$off("refreshDataGridPaletDetail", this.getInfos);
    this.controller.EventBus.$off("refreshDataGrid", this.getInfos);
  }
}
