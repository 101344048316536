
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class BadgeComponent extends Vue {
  @Prop() text!: string;
  @Prop({ default: "badge-success" }) badgeClass!: string;
}
