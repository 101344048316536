import Vue from "vue";
import store from "@/app/app-state";
import RestClient from "./restClient";
import { apiUrls } from "@/environment/environment";
import { Userinfo } from "@/app/shared/state/userContextState";
import UserStore from "@/app/stock/shared/store/userStore";
import { QueryUserPref } from "@/app/stock/shared/store/Types";

const authHelper = class {
  async getAuthTokenFromApi(userinfo: Userinfo) {
    const restClient = new RestClient(apiUrls.auth).Instance;

    await restClient
      .post(`/Users/Authenticate?companyCode=${userinfo.companyCode}&warehouseCode=${userinfo.warehouseCode}`, userinfo)
      .then((response) => {
        if (response?.status == 200) {
          const authToken = response.data;
          Vue.$cookies.set("authToken", authToken.accessToken, new Date(authToken.expiresOn * 1000));
          store.commit("userContext/authToken", authToken.accessToken);
          store.commit("userContext/currentCompany", userinfo.companyCode);
          store.commit("userContext/currentWarehouse", userinfo.warehouseCode);
        } else {
          let error = "Erreur lors de la connection";
          if (response) {
            //return Promise.reject(response.data);
            error = `Erreur ${response.status} - connexion au système impossible.`;
          }
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        Vue.$log.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * Auto login with key params
   * @param {*} userinfo
   */
  async getAuthTokenFromApiWithKey(userinfo: Userinfo) {
    const restClient = new RestClient(apiUrls.auth).Instance;
    await restClient
      .get(
        `/Users/AuthenticateWithKeyAsync?companyCode=${userinfo.companyCode}&warehouseCode=${userinfo.warehouseCode}&key=${userinfo.key}`
      )
      .then((response) => {
        if (response.status == 200) {
          const authToken = response.data;
          Vue.$cookies.set("authToken", authToken.accessToken, new Date(authToken.expiresOn * 1000));
          store.commit("userContext/authToken", authToken.accessToken);
          store.commit("userContext/currentCompany", userinfo.companyCode);
          store.commit("userContext/currentWarehouse", userinfo.warehouseCode);
        }
      })
      .catch((error) => {
        Vue.$log.error(error);
      });
  }

  /**
   * Recuperation des informations systeme
   */
  async getSystemInfo() {
    if (store.getters["userContext/isAuthenticated"]) {
      const restClient = new RestClient(apiUrls.auth).Instance;
      await restClient
        .get(`/SystemInfo`)
        .then((response) => {
          if (response.status == 200) {
            store.commit("userContext/systemInfo", response.data);
          }
        })
        .catch((error) => {
          Vue.$log.error(error);
        });
    }
  }

  /**
   * Recuperation des favoris menu via API
   * @param userinfo
   */
  async getFavoris(currentCompany: string, currentWarehouse: string) {
    const userStore = new UserStore(apiUrls.user);
    if (store.getters["userContext/isAuthenticated"]) {
      const query: QueryUserPref = {
        moduleName: "favoris",
        subModuleName: "favoris",
        labelDataType: "favoris",
        companyCode: currentCompany,
        warehouseCode: currentWarehouse,
        data: null,
      };

      try {
        await userStore.getUserPreferences(query).then((response: any) => {
          store.commit("userContext/favoris", JSON.parse(response.data));
        });
      } catch (error) {
        //Log
        console.log("getFavoris error", error);
      }
    }
  }

  /**
   * Enregistrement des favoris menu via API
   * @param currentCompany
   * @param currentWarehouse
   * @param favoris
   */

  async setFavoris(currentCompany: string, currentWarehouse: string, favoris: string[]) {
    const userStore = new UserStore(apiUrls.user);
    if (store.getters["userContext/isAuthenticated"]) {
      const query: QueryUserPref = {
        moduleName: "favoris",
        subModuleName: "favoris",
        labelDataType: "favoris",
        companyCode: currentCompany,
        warehouseCode: currentWarehouse,
        data: JSON.stringify(favoris),
      };
      try {
        await userStore.saveUserPreferences(query);
      } catch (error) {
        //Log
        console.log("setFavoris error", error);
      }
    }
  }
};

export default new authHelper();
