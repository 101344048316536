import Vue from "vue";
//import store from "@/app/app-state";
import RestClient from "./restClient";
import { apiUrls, tesfriConfigurationsecureKey, clientEnvironmentCode } from "@/environment/environment";
import { Userinfo } from "../state/userContextState";

const urlApiHelper = class {
  async setUrlAuthFromApi(userinfo: Userinfo) {
    // recuperation des droits de l'API
    const restClient = new RestClient(apiUrls.tesfriconfiguration).Instance;
    await restClient
      .get(
        `/Configurations/Anonymous/ByCodes?companyCode=${userinfo.companyCode}&warehouseCode=${userinfo.warehouseCode}&environmentCode=${clientEnvironmentCode}&configurationType=AuthApi&secureKey=${tesfriConfigurationsecureKey}`
      )
      .then((response) => {
        if (response.status == 200) {
          apiUrls.auth = response.data;
        }
      })
      .catch((error: string) => {
        Vue.$log.error(error);
      });
  }
};

export default new urlApiHelper();
