
import { Component, Prop, Vue } from "vue-property-decorator";
import ListComponent from "@/app/shared/components/generic/list/ListComponentV2.vue";
import moment from "moment";
import "moment/locale/fr";

// eslint-disable-next-line no-unused-vars
import { ColDef } from "ag-grid-community";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { ComponentsPallets, ManagementDate, PalletsQuantities, ThirdWarehouse, Query, QueryUserPref } from "@/app/stock/shared/store/Types";

moment.locale("fr");

const moduleName = "Stock";
const subModuleName = "componentsUpdate";
const labelDataType = "listStockComponent";

@Component
export default class ListStockComponent extends ListComponent {
  //props: ["controller", "icon", "customer", "queryFilterElementsUpdate", "searchDate", "noteHeader"]

  @Prop() declare controller: StockController;
  @Prop() icon!: string;
  @Prop() customer!: ThirdWarehouse;
  @Prop() queryFilterElementsUpdate!: any;
  @Prop() searchDate!: any;
  @Prop() noteHeader!: any;

  onRowClicked = "";
  onRowDoubleClicked = "";

  initialColumnDefs: ColDef[] = [];

  listInittialize = true;

  mainManagementDate = "Date principale";
  secondaryManagementDate = "Date secondaire";

  currentCompany: string = this.$store.state.userContext.currentCompany;
  currentWarehouse: string = this.$store.state.userContext.currentWarehouse;

  async onGridReady(params: any) {
    let gridApi = params.api;

    this.dataSource = {
      rowCount: null,
      getRows: async (params: any) => {
        let query = this.fillListWithFilterAndSort(params);
        // ajout des filtre obligatoire (DO et numero de bon)
        query = this.getRequiredFilter(query);

        this.controller.Log.logDebug(`listStockComponent`, `Object query : ${JSON.stringify(query)}`);
        let sendResult: { data: ComponentsPallets[]; itemsCount: number } | null = { data: [], itemsCount: 0 };

        try {
          if (this.customer && this.searchDate && this.noteHeader) {
            sendResult = await this.controller.StockStore.getAllComponents(this.customer, query);
            if (sendResult == null) return;
            //ajout des colonnes des infos perso
            for (let row of sendResult.data) {
              for (let customInformation of row.customInformations) {
                (row as any)[customInformation.nameInfoPerso] = customInformation.value;
              }
            }
          }

          params.successCallback(sendResult.data, sendResult.itemsCount);
        } catch (error) {
          //Log
          await this.controller.Log.logError(`listStockComponent onGridReady`, "", error, moduleName);
          sendResult = null;
        }
      },
    };
    gridApi.setDatasource(this.dataSource);
  }

  refreshListListener() {
    if (this.customer && this.searchDate && this.noteHeader) {
      Vue.nextTick().then(() => {
        this.setOptionnalColumns();
      });
      this.refreshData();
      this.gridApi.onFilterChanged();

      Vue.nextTick().then(() => {
        this.listInittialize = true;
        this.setUserPrefrences();
        this.listInittialize = false;
      });
    } else {
      this.gridApi.setRowData([]);
    }
  }
  //   methods: {

  //Event when column state change
  onColumnEvent() {
    if (!this.listInittialize) {
      this.savePrefrences();
    }
  }

  //Event when filter or sort change
  onModelUpdated() {
    if (!this.listInittialize) {
      this.savePrefrences();
    }
  }

  ///** Save user prefenreces when they changes */
  async savePrefrences() {
    //Format data
    let data = { columnState: this.gridColumnApi.getColumnState() };

    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: JSON.stringify(data),
    };

    this.controller.Log.logDebug(`saveUserPreferences`, `Object query : ${JSON.stringify(query)}`);

    try {
      await this.controller.UserStore.saveUserPreferences(query);
    } catch (error) {
      //Log
      await this.controller.Log.logError(`saveUserPreferences`, "", error, moduleName);
    }
  }

  /** Set user preference sur la grille */
  async setUserPrefrences() {
    if (this.listInittialize) {
      //refresh ag-grid with preference in database

      let query: QueryUserPref = {
        moduleName: moduleName,
        subModuleName: subModuleName,
        labelDataType: labelDataType,
        companyCode: this.currentCompany,
        warehouseCode: this.currentWarehouse,
        data: null,
      };

      this.controller.Log.logDebug(`getUserPreferences`, `Object query : ${JSON.stringify(query)}`);
      let sendResult = null;
      try {
        sendResult = await this.controller.UserStore.getUserPreferences(query);

        let data = JSON.parse(sendResult.data);

        //set grid columns, sort, filter
        if (data) {
          let data = JSON.parse(sendResult.data);
          if (data.columnState) {
            this.gridColumnApi.applyColumnState(data.columnState);
          }
        }
      } catch (error) {
        //Log
        await this.controller.Log.logError(`getUserPreferences`, "", error, moduleName);
        sendResult = null;
      }
    }
  }

  isFirstColumn(params: any) {
    let displayedColumns = params.columnApi.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  async onFilterChanged() {
    //

    await this.getTotalSelectedComponantsFromApi();

    /* recuperation des entetes de colonnes des dates de gestions */
    this.setNameManagementDate();
  }

  /**
   * Methode to catch total's ligne
   */
  async getTotalSelectedComponantsFromApi() {
    if (this.customer && this.searchDate && this.noteHeader) {
      let filterModel = this.gridApi.getFilterModel();
      let query = this.fillListWithFilter(filterModel);
      // ajout des filtre obligatoire (DO et numero de bon)
      query = this.getRequiredFilter(query);

      // recuperation de tous les Id de composants correspondant au filtre pour les inclure dans la liste des selection
      this.controller.Log.logDebug(`getTotauSelectedCompnantsFromApi`, `Object query : ${JSON.stringify(query)}`);
      let sendResult: { data: PalletsQuantities[] } | null = { data: [] };
      let total = [];

      try {
        if (this.customer) {
          sendResult = await this.controller.StockStore.getTotauSelectedComponants(this.customer, query);
          if (sendResult) {
            total.push(sendResult.data);

            var rows = total;
            this.gridApi.setPinnedBottomRowData(rows);
          }
        }
      } catch (error) {
        //Log
        await this.controller.Log.logError(`getTotauSelectedCompnantsFromApi`, "", error, moduleName);
        sendResult = null;
      }
    }
  }

  fillListWithFilter(filterModel: any) {
    let query: Query = {
      name: null,
      user: null,
      offset: 0,
      limit: 0,
      filters: [],
      hasFilters: null,
      sorts: [],
      hasSorts: null,
      results: {},
      isValid: null,
    };

    var re = /\./gi;
    let filterModelKey = Object.keys(filterModel);
    if (filterModelKey.length != 0) {
      query.filters = [];

      for (let key in filterModelKey) {
        let col = filterModelKey[key];
        let queryFilter = filterModel[col];
        let queryFilterType = "";
        let queryFilterValue = queryFilter.filter;
        if (col) {
          switch (queryFilter.type) {
            case "equals":
              queryFilterType = "EQ";
              break;
            case "notEqual":
              queryFilterType = "NEQ";
              break;
            case "lessThan":
              queryFilterType = "LT";
              break;
            case "greaterThan":
              queryFilterType = "GT";
              break;
            case "lessThanOrEqual":
              queryFilterType = "LTE";
              break;
            case "greaterThanOrEqual":
              queryFilterType = "GTE";
              break;
            case "contains":
              queryFilterValue = "%" + queryFilterValue + "%";
              queryFilterType = "LIKE";
              break;
            case "endsWith":
              queryFilterValue = "%" + queryFilterValue;
              queryFilterType = "LIKE";
              break;
            case "startsWith":
              queryFilterValue = queryFilterValue + "%";
              queryFilterType = "LIKE";
              break;
            case "notContains":
              queryFilterValue = "%" + queryFilterValue + "%";
              queryFilterType = "LIKE";
              break;
            case "inRange":
              queryFilterType = "IN";
              break;
            default:
              break;
          }

          var field = col.replace(re, "");
          //Pour les dates il faut les formater avant de les envoyer
          if (col == "date" || col.toLowerCase().includes("date")) {
            //Je considère que pour une date si on fait un "inRange" ça veut dire de telle date à telle date
            if (queryFilterType == "IN") {
              query.filters.push({ type: "GTE", field: field, value: moment(queryFilter.dateFrom).format("Y-MM-DD") + "T00:00:00" });
              query.filters.push({ type: "LT", field: field, value: moment(queryFilter.dateTo).format("Y-MM-DD") + "T00:00:00" });
            } else if (queryFilterType == "EQ") {
              query.filters.push({ type: "GTE", field: field, value: moment(queryFilter.dateFrom).format("Y-MM-DD") + "T00:00:00" });
              query.filters.push({
                type: "LT",
                field: field,
                value: moment(queryFilter.dateFrom).add(1, "days").format("Y-MM-DD") + "T00:00:00",
              });
            } else {
              query.filters.push({ type: queryFilterType, field: field, value: moment(queryFilter.dateFrom).format() });
            }
          } else {
            query.filters.push({ type: queryFilterType, field: field, value: queryFilterValue });
          }
        }
      }
    }
    return query;
  }

  onPaginationChanged() {
    this.getTotalSelectedComponantsFromApi();
  }

  /**
   * Modification des noms de colonnes de dates de gestions
   * */
  async setNameManagementDate() {
    if (this.customer && this.searchDate && this.noteHeader) {
      let filterModel = this.gridApi.getFilterModel();
      let query = this.fillListWithFilter(filterModel);
      // ajout des filtre obligatoire (DO et numero de bon)
      query = this.getRequiredFilter(query);

      // recuperation des noms de colonnes de date de gestion par appel API
      this.controller.Log.logDebug(`setNameManagementDate`, `Object query : ${JSON.stringify(query)}`);
      let managementDate: { mainManagementDate: ManagementDate; secondaryManagementDate: ManagementDate } | undefined;
      try {
        if (this.customer) {
          managementDate = await this.controller.StockStore.getManagementDateHeader(this.customer, query);
        }
      } catch (error) {
        //Log
        await this.controller.Log.logError(`setNameManagementDate`, "", error, moduleName);
        managementDate = undefined;
      }

      let columnDefs = this.gridOptions.columnDefs;

      // on set les columns initial de la grille
      for (let column of columnDefs) {
        var fieldName: string = column.field.split(".")[0]; // on split pour avoir que le premier element car les date de getsion sont prefixé de 'xxx.value'

        /* initialisation par defaut du header de la date de gestion principale */
        if (fieldName == "mainManagementDate") {
          column.headerName = this.mainManagementDate;
        }
        /* initialisation par defaut du header de la date de gestion secondaire */
        if (fieldName == "secondaryManagementDate") {
          column.headerName = this.secondaryManagementDate;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (managementDate?.hasOwnProperty(fieldName) && (managementDate as any)[fieldName].shortLabel) {
          column.headerName = (managementDate as any)[fieldName].shortLabel;
        }
      }
      this.gridOptions.api.setColumnDefs(columnDefs);

      /* On raffraichi les informations des configuration de colonnes */
      this.retrieveColumns();
    }
  }

  /**
   * Method who charge optional columns from API
   */
  async setOptionnalColumns() {
    // on get le columnDefs de la grille
    let columnDefs = this.gridOptions.columnDefs;

    // on vide la grille actuel
    columnDefs.splice(0, columnDefs.length);

    // on set les columns initial de la grille
    for (let column of this.initialColumnDefs) {
      columnDefs.push(column);
    }

    // on set les columns optionnel de la grille
    if (this.customer) {
      let attributes = await this.controller.StockStore.getInfoColumns(this.customer);

      for (let attribute of attributes.data) {
        switch (attribute.valuetype) {
          case 1: //entier
          case 2: //decimal
            columnDefs.push(this.numericColumn(attribute.nameInfoPerso, { headerName: attribute.label, hide: false, width: 50 }));
            break;
          case 3: //date
            columnDefs.push(
              this.dateColumn(attribute.nameInfoPerso, {
                headerName: attribute.label,
                suppressMenu: true,
                floatingFilterComponentParams: { suppressFilterButton: false },
              })
            );
            break;
          case 4: //caractere
          case 5: //Liste
            columnDefs.push(this.textColumn(attribute.nameInfoPerso, { headerName: attribute.label, hide: false, width: 50 }));
            break;
          default:
            columnDefs.push({ headerName: attribute.label, field: attribute.nameInfoPerso, filter: false, sortable: false });
        }
      }
      this.gridOptions.api.setColumnDefs(columnDefs);
    }
    /* On raffraichi les informations des configuration de colonnes */
    this.retrieveColumns();

    /* recuperation des entetes de colonnes des dates de gestions */
    this.setNameManagementDate();
  }

  /**
   * Init variable of initial columns
   * @param params
   */
  setInitialColumns(params: any) {
    for (let column of params) {
      this.initialColumnDefs.push(column);
    }
  }

  /**
   * Add filter on customer and note number
   * @param query
   */
  getRequiredFilter(query: any) {
    //New query if isn't exist
    if (query.filters == null) {
      query.filters = [];
    }

    if (this.searchDate != null && this.noteHeader.noteNumber != null) {
      //cast searchDate into searchDatetime for api
      var searchDateTime = moment(this.searchDate).format("Y-MM-DD") + "T00:00:00";
      query.filters.push({ type: "EQ", field: "receivedate", value: searchDateTime });
      query.filters.push({ type: "EQ", field: "receivenotenumber", value: this.noteHeader.noteNumber });
    }
    return query;
  }

  /**
   * Get Query to filter's grid
   */
  getQueryFilterElementsUpdate() {
    let filterModel = this.gridApi.getFilterModel();
    let query = this.fillListWithFilter(filterModel);
    // Add required filter (customer and note number)
    query = this.getRequiredFilter(query);

    return query;
  }

  /**
   * Formatage pour les columns avec 3 decimal
   * @param data
   */
  formatDecimal(data: any) {
    return data.value ? parseFloat(data.value).toFixed(3) : "";
  }

  async beforeMount() {
    this.columnDefs = [
      this.numericColumn("id", {
        headerName: "Id",
        hide: true,
        cellRenderer: this.gridOptions.components.loadingRenderer,
        suppressMenu: true,
      }),
      this.textColumn("itemDetail.item.code", {
        headerName: "Code article",
        field: "itemDetail.item.code",
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("itemDetail.item.label", {
        headerName: "Libellé produit",
        field: "itemDetail.item.label",
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("itemDetail.code", {
        headerName: "Code dossier",
        field: "itemDetail.code",
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("sscc", {
        headerName: "SSCC",
        field: "sscc",
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("itemDetail.label", {
        headerName: "Libellé dossier",
        field: "itemDetail.label",
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.numericColumn("parcelAmount", {
        headerName: "Nb colis",
        floatingFilterComponentParams: { suppressFilterButton: false },
        suppressMenu: true,
      }),
      this.numericColumn("palletnumber", {
        headerName: "N° Palette",
        floatingFilterComponentParams: { suppressFilterButton: false },
        suppressMenu: true,
      }),
      this.numericColumn("csuAmount", {
        headerName: "Nb UVC ",
        floatingFilterComponentParams: { suppressFilterButton: false },
        suppressMenu: true,
      }),
      this.numericColumn("netWeight", {
        headerName: "Poids net",
        floatingFilterComponentParams: { suppressFilterButton: false },
        suppressMenu: true,
        valueFormatter: this.formatDecimal,
      }),
      this.numericColumn("grossWeight", {
        headerName: "Poids brut",
        floatingFilterComponentParams: { suppressFilterButton: false },
        suppressMenu: true,
        valueFormatter: this.formatDecimal,
      }),
      this.textColumn("batchNumber", {
        headerName: "N° de lot",
        field: "batchNumber",
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.dateColumn("mainManagementDate.value", {
        headerName: this.mainManagementDate,
        headerTooltip: this.mainManagementDate,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.dateColumn("secondaryManagementDate.value", {
        headerName: this.secondaryManagementDate,
        headerTooltip: this.secondaryManagementDate,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.dateColumn("receiveDate", {
        headerName: "Date de reception",
        headerTooltip: "receiveDate",
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.numericColumn("receiveNoteNumber", {
        headerName: "N° reception",
        floatingFilterComponentParams: { suppressFilterButton: false },
        suppressMenu: true,
      }),
      this.textColumn("supplierCode", {
        headerName: "Code fournisseur",
        field: "supplierCode",
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("supplierName", {
        headerName: "Nom fournisseur",
        field: "supplierName",
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
    ];

    this.setInitialColumns(this.columnDefs);

    this.gridOptions.rowSelection = "multiple";
    this.gridOptions.rowMultiSelectWithClick = true;

    this.defaultColDef.floatingFilter = true;
    this.gridOptions.onFilterChanged = this.onFilterChanged;
    this.gridOptions.onPaginationChanged = this.onPaginationChanged;

    //modification pour permettre l'infiniteScrolling
    this.pagination = false;
    this.gridOptions.infiniteInitialRowCount = 1;
    this.gridOptions.maxBlocksInCache = 20;
    this.gridOptions.cacheOverflowSize = 1;
    this.gridOptions.maxConcurrentDatasourceRequests = 5;

    this.gridOptions.paginationPageSize = 20;
    this.gridOptions.cacheBlockSize = 20;

    this.controller.EventBus.$on("refreshList", this.refreshListListener);
  }

  async beforeDestroy() {
    this.controller.EventBus.$off("refreshList", this.refreshListListener);
  }
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridApi.setDomLayout("normal");
    const screenHeight = window.innerHeight;
    /* 
     - (screenHeight * 80) / 100 : hauteur de la fenetre (80%)
     - 48 de header
     - 24 de marge top et bottom
    */
    this.height = (screenHeight * 80) / 100 - 48 - 24 + "px";
  }
}
