
import { Component, Vue, Prop } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { DataTableHeader } from "node_modules/vuetify";
// eslint-disable-next-line no-unused-vars
import { Operator, Filter, FieldType } from "../Type";
// eslint-disable-next-line no-unused-vars
import { HeadersMap } from "../ListComponentV3.vue";
import moment from "moment";

@Component({
  components: {},
})
export default class FilterComponent extends Vue {
  @Prop() filters!: Filter[];
  @Prop() params!: DataTableHeader;

  menu = false;
  filterValue: string | number = "";
  filterOperator: Operator = Operator.equals;

  get operators(): { key: string; value: string }[] {
    // Recuperation de la liste des operateur existant a partir de l'enum
    const operatorsMap = Object.keys(Operator) as (keyof typeof Operator)[];
    let operatorsKeyValue = operatorsMap.map((x) => {
      return { key: x, value: Operator[x] };
    });

    return operatorsKeyValue;
  }

  /**
   * Type du champ de sasie
   */
  get fieldType(): string {
    let fieldType = (this.params as HeadersMap).fieldType;
    switch (fieldType) {
      case FieldType.number:
        return "number";
      case FieldType.string:
        return "text";
      case FieldType.date:
        return "date";
      default:
        return "text";
    }
  }

  /**
   * Couleur du bouton filtre si un filtre est actif
   */
  get filterActif() {
    return this.filters.find((filter: any) => {
      return filter.field == this.params.value;
    })
      ? "tesfriRaspberry"
      : "";
  }

  /**
   * Mise a jour du filtre
   */
  updateValue() {
    let filter = this.filters.find((f: any) => {
      return f.field == this.params.value;
    });
    if (filter) {
      filter.operator = this.filterOperator;

      if (!this.filterValue || this.filterValue == "") {
        // Suppression si valeur vide
        this.filters.splice(this.filters.indexOf(filter), 1);
      } else {
        // Modification de valeur
        filter.value = this.filterValue;
      }
    } else {
      if (this.filterValue || this.filterValue != "") {
        // Ajout si non existant
        this.filters.push({
          field: this.params.value,
          fieldType: (this.params as HeadersMap).fieldType,
          operator: this.filterOperator,
          value: this.filterValue,
        });
      }
    }
  }

  initFilter() {
    // valeur des input au montage
    let filter: Filter[] = this.filters.filter((f) => {
      return f.field == this.params.value;
    });
    if (filter.length > 0) {
      this.filterValue = filter[0].fieldType == FieldType.date ? moment(filter[0].value).format("Y-MM-DD") : filter[0].value;
      this.filterOperator = filter[0].operator;
    } else {
      this.filterValue = "";
      this.filterOperator = Operator.equals;
    }
  }

  mounted() {
    // this.initFilter();
  }
}
