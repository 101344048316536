
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import "moment/locale/fr";
// import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { ComponentsUpdate, ManagementDate, PersonalInfoUpdate, Query, QueryUserPref, ThirdWarehouse } from "@/app/stock/shared/store/Types";

moment.locale("fr");

const moduleName = "Stock";
const subModuleName = "componentsUpdate";
const labelDataType = "formUpdate";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class FormUpdate extends Vue {
  @Prop() controller!: StockController;

  isAdd = false;
  queryFilterElementsUpdate!: Query;
  isBatchNumber = false;
  batchNumber = "";
  isMainManagementDate = false;
  mainManagementDate: null | string = null;
  mainManagementDateLabel = "Date de gestion principale";
  isSecondManagementDate = false;
  secondManagementDate: null | string = null;
  secondManagementDateLabel = "Date de gestion secondaire";
  componentPersonalsInfo: PersonalInfoUpdate[] = [];
  isComponentComment = false;
  componentComment = "";
  customer!: ThirdWarehouse;
  //saveTimer: null;

  currentCompany: string = this.$store.state.userContext.currentCompany;
  currentWarehouse: string = this.$store.state.userContext.currentWarehouse;

  loading = false;

  toogleAllAvtiveInactive() {
    this.isAdd = !this.isAdd;
  }

  /**
   * bacule des ligne avec sauvegarde des preferences utilisateur
   * @param idRow
   */
  async toogleAvtiveInactiveAndSavePreference(idRow: string) {
    await Vue.nextTick();
    let row = document.getElementById(idRow);

    await this.toogleAvtiveInactive(row);
    await this.reorderElements();
    // enregistrement des preferences utilisateur du formulaire

    let validElements = document.getElementsByClassName("rowtrue");

    // construction de la structure des données des preferences utilisateur
    let data = Array.prototype.slice.call(validElements).map((obj) => {
      var rObj = { id: obj.id, visible: true };
      return rObj;
    });

    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: JSON.stringify(data),
    };

    this.controller.Log.logDebug(`saveUserPreferences`, `Object query : ${JSON.stringify(query)}`);

    try {
      await this.controller.UserStore.saveUserPreferences(query);
    } catch (error) {
      //Log
      await this.controller.Log.logError(`saveUserPreferences`, "", error, moduleName);
    }
  }

  /**
   * bascule des lignes a modifier
   * @param row
   */
  async toogleAvtiveInactive(row: any) {
    //Clear value when switch
    let vueInput = row.getElementsByClassName("input")[0];
    if (vueInput) {
      let realInput = vueInput.querySelector("input");
      realInput.value = "";
      // on ajoute un event pour vider l'input car sinon il ne se vide pas tout seul
      let event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      realInput.dispatchEvent(event);
    }
  }

  /** recuperation des preference utilisateur */
  async setUserPreferences() {
    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: null,
    };

    this.controller.Log.logDebug(`getUserPreferences`, `Object query : ${JSON.stringify(query)}`);
    let sendResult = null;
    try {
      sendResult = await this.controller.UserStore.getUserPreferences(query);

      if (sendResult.data) {
        let data = JSON.parse(sendResult.data);

        if (data.length > 0) {
          data = data.filter((a: any) => a.visible === true);

          let batchNumberPref = data.find((elt: any) => elt.id === "batchNumber");
          if (batchNumberPref) {
            this.isBatchNumber = batchNumberPref.visible;
          }

          let mainManagementDatePref = data.find((elt: any) => elt.id === "mainManagementDate");
          if (mainManagementDatePref) {
            this.isMainManagementDate = mainManagementDatePref.visible;
          }

          let secondManagementDatePref = data.find((elt: any) => elt.id === "secondManagementDate");
          if (secondManagementDatePref) {
            this.isSecondManagementDate = secondManagementDatePref.visible;
          }

          let componentCommentPref = data.find((elt: any) => elt.id === "componentComment");
          if (componentCommentPref) {
            this.isComponentComment = componentCommentPref.visible;
          }

          if (this.componentPersonalsInfo) {
            this.componentPersonalsInfo.forEach(function (a: any) {
              let infoPersoPref = data.find((elt: any) => elt.id === "componentPersonalsInfo" + a.orderNumber);
              if (infoPersoPref) {
                a.active = infoPersoPref.visible;
              }
            });
          }
        }
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getUserPreferences`, "", error, moduleName);
      sendResult = null;
    }
  }

  /** reorganisation des differentes row */
  async reorderElements() {
    // recuperation des containers distinct
    let formValid: HTMLElement | null = document.getElementById("formValid");
    let formInalid: HTMLElement | null = document.getElementById("form");

    // recuperation des row distinct
    let rowTrue = document.getElementsByClassName("rowtrue");
    let rowFalse = document.getElementsByClassName("rowfalse");

    // deplacement des row true dans le container des elements valide
    Array.prototype.slice
      .call(rowTrue)
      .sort(function (x, y) {
        return parseInt(x.dataset.order) > parseInt(y.dataset.order) ? 1 : -1;
      })
      .forEach(function (x) {
        if (formValid) {
          formValid.appendChild(x);
        }
      });
    // deplacement des row true dans le container des elements non valide
    Array.prototype.slice
      .call(rowFalse)
      .sort(function (x, y) {
        return parseInt(x.dataset.order) > parseInt(y.dataset.order) ? 1 : -1;
      })
      .forEach(function (x) {
        if (formInalid) {
          formInalid.appendChild(x);
        }
      });
  }

  /** methode de validation du formulaire */
  async submitForm() {
    (this.$refs.componentValidation as any).validate().then(async (result: any) => {
      if (result) {
        try {
          //On enregistre si le champ est actif et remplit dans un premier temps
          let componentPersonalsInfo: PersonalInfoUpdate[] | null = [];
          for (let n of this.componentPersonalsInfo) {
            var updateValue = n.value;
            if (n.valuetype == 3) {
              //change date format to cross software
              updateValue = moment(n.value).format("DD/MM/Y");
            } else if (n.valuetype == 5) {
              // For list types, we take the value of the object
              updateValue = n.value;
            }
            componentPersonalsInfo.push({ isUpdated: n.active, number: n.orderNumber, value: updateValue } as PersonalInfoUpdate);
          }
          let data: ComponentsUpdate = {
            noteId: null,
            isBatchNumber: this.isBatchNumber,
            batchNumber: this.batchNumber,
            isMainManagementDate: this.isMainManagementDate,
            mainManagementDate: this.mainManagementDate,
            isSecondManagementDate: this.isSecondManagementDate,
            secondManagementDate: this.secondManagementDate,
            isComponentComment: this.isComponentComment,
            componentComment: this.componentComment,
            componentPersonalsInfo: componentPersonalsInfo,
            query: this.queryFilterElementsUpdate,
          };

          this.controller.Log.logDebug(`listStockComponent`, `Object query : ${JSON.stringify(data)}`);
          let sendResult: any = { data: [], itemsCount: 0 };
          try {
            if (this.customer) {
              sendResult = await this.controller.StockStore.updateComponents(this.customer.id, data);
            }

            if (sendResult.status === 200) {
              this.controller.EventBus.$emit("refreshList");
              this.controller.EventBus.$emit("closeModalUpdate");
            }
          } catch (error) {
            //Log
            await this.controller.Log.logError(`listStockComponent onGridReady`, "", error, moduleName);
            sendResult = null;
          }
        } catch (error) {
          await this.controller.Log.logError(`formUpdateComponent validateUpdateComponents`, "", error, moduleName);
        }
      }
    });
  }

  /**
   * Get label management date
   * */
  async setNameManagementDate() {
    let query = this.queryFilterElementsUpdate;

    // recuperation des noms de colonnes de date de gestion par appel API
    this.controller.Log.logDebug(`setNameManagementDate`, `Object query : ${JSON.stringify(query)}`);
    let managementDate: null | { mainManagementDate: ManagementDate; secondaryManagementDate: ManagementDate } = null;

    try {
      if (this.customer) {
        managementDate = await this.controller.StockStore.getManagementDateHeader(this.customer, query);
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`setNameManagementDate`, "", error, moduleName);
      managementDate = null;
    }

    // on set le label de la date de gestion principale
    // eslint-disable-next-line no-prototype-builtins
    if (managementDate && managementDate.hasOwnProperty("mainManagementDate") && managementDate["mainManagementDate"].label) {
      this.mainManagementDateLabel = managementDate["mainManagementDate"].label;
    } else this.mainManagementDateLabel = "Date de gestion principale";

    // on set le label de la date de gestion secondaire
    // eslint-disable-next-line no-prototype-builtins
    if (managementDate && managementDate.hasOwnProperty("secondaryManagementDate") && managementDate["secondaryManagementDate"].label) {
      this.secondManagementDateLabel = managementDate["secondaryManagementDate"].label;
    } else this.secondManagementDateLabel = "Date de gestion secondaire";
  }

  /**
   * initialisation du composant
   * @param queryFilterElementsUpdate
   * @param customer
   */
  async loadComponent(queryFilterElementsUpdate: Query, customer: ThirdWarehouse) {
    this.isAdd = false;
    this.isBatchNumber = false;
    this.batchNumber = "";
    this.isMainManagementDate = false;
    this.mainManagementDate = null;
    this.isSecondManagementDate = false;
    this.secondManagementDate = null;
    this.componentPersonalsInfo = [];
    this.isComponentComment = false;
    this.componentComment = "";
    this.queryFilterElementsUpdate = queryFilterElementsUpdate;
    this.customer = customer;

    this.loading = true;

    // Call API to get label of management date
    this.setNameManagementDate();

    // Appel API pour recuperer les infos perso
    let infosComponent = await this.controller.StockStore.getInfoColumns(customer);

    for (let infoComponent of infosComponent.data) {
      this.componentPersonalsInfo.push({
        value: "",
        orderNumber: infoComponent.orderNumber,
        active: false,
        label: infoComponent.label,
        valuetype: infoComponent.valuetype,
        list: infoComponent.valuesListe ? infoComponent.valuesListe : null,
      } as PersonalInfoUpdate);
    }

    let rowsFalse: any = document.getElementsByClassName("rowfalse");
    for (let row of rowsFalse) {
      row.style.display = "flex";
    }

    // recuperation des preference utilisateur
    await this.setUserPreferences();
    // reorganisation des lignes
    await this.reorderElements();

    this.isAdd = false;
    if (document && document.getElementById("formValid")) {
      let elt = document.getElementById("formValid");
      if (elt) {
        this.isAdd = elt.childNodes.length <= 0;
      }
    }

    this.loading = false;
  }

  async beforeMount() {
    this.controller.EventBus.$on("loadUpdate", await this.loadComponent);
    this.controller.EventBus.$on("submitFormUpdate", await this.submitForm);
  }

  async beforeDestroy() {
    this.controller.EventBus.$off("loadUpdate", await this.loadComponent);
    this.controller.EventBus.$off("submitFormUpdate", await this.submitForm);
  }
}
