// déclare une variable globale qui sera affectée lors du chargement.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let config: any;

const {
  VUE_APP_API_BASE_URL,
  VUE_APP_CLIENT_ID,
  VUE_APP_LOGLEVEL,
  VUE_APP_LICENSE_EXPIRATION_TIME,
  VUE_APP_USER_API_URL,
  VUE_APP_STOCK_API_URL,
  VUE_APP_TESFRICONFIGURATION_API_URL,
  VUE_APP_TESFRICONFIGURATION_SECUREKEY,
  VUE_APP_CLIENT_ENVIRONMENT_CODE,
  VUE_APP_AUTH_API_URL,
  VUE_APP_MODULE_API_URL,
  NODE_ENV = "",
  VUE_APP_FEATURE_FLIPPING_KEYS,
} = process.env;

const environment = NODE_ENV.toLowerCase();
// const environment = config.NODE_ENV.toLowerCase() || NODE_ENV.toLowerCase();
const apiBaseUrl = (config.VUE_APP_API_BASE_URL as string) || (VUE_APP_API_BASE_URL as string);
const clientId = (config.VUE_APP_CLIENT_ID as string) || (VUE_APP_CLIENT_ID as string);
const clientEnvironmentCode = (config.VUE_APP_CLIENT_ENVIRONMENT_CODE as string) || (VUE_APP_CLIENT_ENVIRONMENT_CODE as string);
const logLevel = (config.VUE_APP_LOGLEVEL as string) || (VUE_APP_LOGLEVEL as string);
const licenseExpirationTime =
  (config.VUE_APP_LICENSE_EXPIRATION_TIME as unknown as number) || (VUE_APP_LICENSE_EXPIRATION_TIME as unknown as number);
const apiUrls = {
  user: (config.VUE_APP_USER_API_URL as string) || (VUE_APP_USER_API_URL as string),
  stock: (config.VUE_APP_STOCK_API_URL as string) || (VUE_APP_STOCK_API_URL as string),
  tesfriconfiguration: (config.VUE_APP_TESFRICONFIGURATION_API_URL as string) || (VUE_APP_TESFRICONFIGURATION_API_URL as string),
  auth: (config.VUE_APP_AUTH_API_URL as string) || (VUE_APP_AUTH_API_URL as string),
  module: (config.VUE_APP_MODULE_API_URL as string) || (VUE_APP_MODULE_API_URL as string),
};

const featureFlippingKeys = config.VUE_APP_FEATURE_FLIPPING_KEYS || VUE_APP_FEATURE_FLIPPING_KEYS;
const tesfriConfigurationsecureKey = VUE_APP_TESFRICONFIGURATION_SECUREKEY;

export {
  environment,
  apiBaseUrl,
  clientId,
  apiUrls,
  logLevel,
  licenseExpirationTime,
  tesfriConfigurationsecureKey,
  clientEnvironmentCode,
  featureFlippingKeys,
};
