
import { Component, Vue, Watch } from "vue-property-decorator";

import moduleController from "../shared/services/controllers/module/moduleController";
// eslint-disable-next-line no-unused-vars
import { module, functionality } from "../shared/ModuleInterface";

import { apiUrls } from "@/environment/environment";

@Component
export default class ModuleSettings extends Vue {
  private controller: moduleController;
  constructor() {
    super();
    this.controller = new moduleController(apiUrls.module);
  }

  company: string = this.$store.state.userContext.currentCompany;
  warehouse: string = this.$store.state.userContext.currentWarehouse;
  licenceNumber = 0;
  isActive = false;

  modules: module[] = [];

  selectedFunctionality: functionality[] = [];

  isSettingsDefaultPackage = false;
  switchSettingDialog = false;

  saveDefautlSettingDialog = false;
  activateWarehouseDialog = false;
  desactivateWarehouseDialog = false;

  search: string | null = null;
  moduleInformation: any = [];

  errorMessage = "";
  errorNotif = false;

  successMessage = "";
  successNotif = false;

  toggleExclusive = 0;

  @Watch("toggleExclusive")
  onPropertyChanged(value: number) {
    let treeview = this.$refs.tree as any;
    if (value == 1) {
      treeview.updateAll(true);
    } else {
      treeview.updateAll(false);
    }
  }

  /**
   *
   * @param item Filtrage des modules pour la recherche
   * @param search
   * @param textKey
   */
  filterModules(item: any, search: string, textKey: string) {
    this.toggleExclusive = 1;
    return item[textKey].toLowerCase().includes(search.toLowerCase());
  }

  /**
   * Recuperation du package du site
   */
  async getCompanyWarehousePackage() {
    this.modules = await this.controller.moduleStore.getCompanyWarehousePackagApi(false);

    this.setDisableModules();
    Vue.nextTick().then(() => {
      // Charge la liste des fonctionnalité selectionées
      this.modules
        .filter((m) => m.isWarehouseModule || m.isDefault)
        .forEach((m) => {
          this.selectedFunctionality = this.selectedFunctionality.concat(m.functionalities);
        });
    });
  }

  /**
   * Récuérations des informations sites
   */
  async getWarehouseInfo() {
    let warehouse = await this.controller.moduleStore.getWarehouseInfo();
    if (warehouse) {
      this.licenceNumber = warehouse.licenceNumber;
      this.isActive = warehouse.isActive;
    }
  }

  /**
   * recuperation du package de base
   */
  async getDefaultPackage() {
    this.modules = await this.controller.moduleStore.getDefaultPackageApi();

    Vue.nextTick().then(() => {
      // Charge la liste des fonctionnalité selectionées
      this.modules
        .filter((m) => m.isDefault)
        .forEach((m) => {
          this.selectedFunctionality = this.selectedFunctionality.concat(m.functionalities);
        });
    });
  }

  /**
   * set les modules a griser
   */
  setDisableModules() {
    this.modules.forEach((module) => {
      module.isDisabled = (module.isDefault && !this.isSettingsDefaultPackage) || !this.isActive;
      module.functionalities.forEach((functionality) => {
        functionality.isDisabled = module.isDisabled;
      });
    });
  }

  /**
   * Enregistrement Nombre de livences
   */
  async saveLicence() {
    try {
      // appel API pour recuperer le bon et ses infos
      this.controller.moduleStore
        .saveLicenceAPI(this.licenceNumber)
        .then((successMessage) => {
          this.successMessage = `${successMessage}`;
          this.successNotif = true;
          this.getWarehouseInfo();
        })
        .catch((errorMessage) => {
          this.errorMessage = errorMessage;
          this.errorNotif = true;
        });
    } catch (error) {
      //Log
      await this.controller.Log.logError(`saveLicence`, "", error, "moduleSettings");
    }
  }

  /**
   * Enregistrement package pour societe site
   */
  async savePackage() {
    let selectedModules = this.modules;
    selectedModules.forEach((m) => {
      m.isWarehouseModule = m.functionalities.some((f) => this.selectedFunctionality.includes(f));
    });
    try {
      // appel API pour recuperer le bon et ses infos
      this.controller.moduleStore
        .savePackageAPI(selectedModules)
        .then((successMessage) => {
          this.successMessage = `${successMessage}`;
          this.successNotif = true;
          this.getCompanyWarehousePackage(); // on rafraichi les information des package du site
        })
        .catch((errorMessage) => {
          this.errorMessage = errorMessage;
          this.errorNotif = true;
        });
    } catch (error) {
      //Log
      await this.controller.Log.logError(`savePackage`, "", error, "moduleSettings");
    }
  }

  /**
   * Enregistrement du package par default
   */
  async saveSettingsDefaultPackage() {
    let selectedModules = this.modules;

    // on set la valeur isDefault des modules dont au moins une des fonctionnalité est selectionnées
    selectedModules.forEach((m) => {
      m.isDefault = m.functionalities.some((f) => this.selectedFunctionality.includes(f));
    });

    try {
      // appel API pour recuperer le bon et ses infos
      this.controller.moduleStore
        .saveDefaultPackageAPI(selectedModules)
        .then((successMessage) => {
          this.successMessage = `${successMessage}`;
          this.successNotif = true;
          this.getDefaultPackage(); // on rafraichi les information des package du site
          this.saveDefautlSettingDialog = false;
        })
        .catch((errorMessage) => {
          this.errorMessage = errorMessage;
          this.errorNotif = true;
        });
    } catch (error) {
      //Log
      await this.controller.Log.logError(`saveDefaultPackage`, "", error, "moduleSettings");
    }
  }

  /**
   * Desactivation du site
   */
  async desactivateWarehouse() {
    /* demander confirmation */

    try {
      this.controller.moduleStore
        .desactivateWarehouseAPI(!this.isActive)
        .then(async (successMessage) => {
          this.successMessage = `${successMessage}`;
          this.successNotif = true;
          await this.getWarehouseInfo(); // on rafraichi les information du site
          await this.getCompanyWarehousePackage(); // on rafraichi les information des package du site
        })
        .catch((errorMessage) => {
          this.errorMessage = errorMessage;
          this.errorNotif = true;
        });
      this.activateWarehouseDialog = this.desactivateWarehouseDialog = false;
    } catch (error) {
      //Log
      await this.controller.Log.logError(`desactivateWarehouse`, "", error, "moduleSettings");
    }
  }

  getInformationFunctionality(item: module) {
    this.moduleInformation = item;
  }

  /**
   * initialisation du controler
   */
  async retrieveController() {
    this.controller = new moduleController(apiUrls.module);
    // Recuperation des modules de societe et site connecté
    await this.getWarehouseInfo();
    await this.getCompanyWarehousePackage();
  }

  /**
   * Switch l'affichage vers la configuration du package de base
   */
  async switchSettingDefautlPackage() {
    this.isSettingsDefaultPackage = true;
    this.modules = [];
    this.selectedFunctionality = [];
    await this.getDefaultPackage();
    this.switchSettingDialog = false;
  }

  /**
   * Switch l'affichage vers la configuration du package du site
   */
  async switchSettingWarehousePackage() {
    this.isSettingsDefaultPackage = false;
    this.modules = [];
    this.selectedFunctionality = [];
    await this.getCompanyWarehousePackage();
  }

  created() {
    this.retrieveController();
  }
}
