
import { Component, Vue, Watch } from "vue-property-decorator";
import StockController from "../shared/services/controllers/stock/stockController";
import ListStreamTypeMovementCode from "./components/list/ListStreamTypeMovementCode.vue";
import { apiUrls } from "@/environment/environment";
// eslint-disable-next-line no-unused-vars
import { StreamType, GoodsMvtCode, CodeLabel, StreamTypeMvtCode, StreamTypeMvtCodeLight } from "../shared/store/Types";
@Component({
  components: {
    ListStreamTypeMovementCode,
  },
})
export default class StreamTypeMovementCode extends Vue {
  private controller: StockController;
  constructor() {
    super();
    this.controller = new StockController(apiUrls.stock, apiUrls.user);
  }

  valid = false;
  storageTypeItems: CodeLabel[] = [];
  streamTypeItems: StreamType[] = [];
  goodsMovementCodeItems: GoodsMvtCode[] = [];
  storageType: null | CodeLabel = null;
  streamType: null | StreamType = null;
  goodsMovementCode: null | GoodsMvtCode = null;
  //storageTypeRules = [this.storageTypeRule()];
  storageTypeRules = [(v: any): boolean | string => !!v || "Type de stockage est requis"];
  streamTypeRules = [(v: any): boolean | string => !!v || "Type de flux est requis"];
  MovementCodeRules = [(v: any): boolean | string => !!v || "Code de movement est requis"];

  loading = false; // se servir de cette variable pour afficher le loader lors du chargement des combo
  dialog = false;

  errorMessage: null | string = null;
  errorNotif = false;

  @Watch("dialog")
  onDialogChanged(value: string): void {
    value || this.resetValues();
  }

  /**
   * Ajout du rattachement de type de flux
   */
  async createStreamTypeMvtCode(StreamTypeMvtCode: StreamTypeMvtCode) {
    this.$log.debug("creation");
    this.$log.debug(StreamTypeMvtCode);

    (this.$refs.form as any).validate(); //validation du formulaire
    if (this.valid) {
      this.controller.Log.logDebug(`createStreamTypeMvtCode`, ``);
      try {
        var streamTypeMvtCode: StreamTypeMvtCodeLight = {
          storageType: this.storageType,
          streamType: this.streamType,
          goodsMvtCode: this.goodsMovementCode,
        };
        let sendResult = await this.controller.StockStore.createStreamTypeMvtCode(streamTypeMvtCode);

        switch (sendResult.status) {
          // Switch en place pour traiter les differents cas d'erreur
          case 400:
          case 409:
          case 500:
            {
              // Affichage de le snckbar pour l'erreur
              this.errorMessage = sendResult.errors;
              this.errorNotif = true;
            }
            break;
          default:
            {
              this.$log.debug(sendResult);
              //TODO : mettre en place une notification pour valider la creation
              await (this.$refs.grid as any).refreshData();
              this.dialog = false; //fermeture de la dialog box
            }
            break;
        }
      } catch (error) {
        //Log
        this.controller.Log.logError(`createStreamTypeMvtCode`, "", error, "StreamTypeMovementCode");
      }
    }
  }

  /**
   * chargement des types de stockage
   */
  async getStorageTypeSelect(): Promise<{ valueRenderer: string; obj: CodeLabel }[]> {
    let result: { valueRenderer: string; obj: CodeLabel }[] = [];
    this.controller.Log.logDebug(`getStorageTypeCombobox`, ``);
    try {
      let sendResult = await this.controller.StockStore.getStorageTypeSuggests();

      sendResult.data.forEach(function (element) {
        result.push({ valueRenderer: `${element.code}/${element.label} `, obj: element });
      });
    } catch (error) {
      //Log
      this.controller.Log.logError(`getStorageTypeCombobox`, "", error, "StreamTypeMovementCode");
    }
    return result;
  }

  /**
   * chargement des types de flux
   */
  async getStreamTypeSelect(): Promise<{ valueRenderer: string; obj: StreamType }[]> {
    let result: { valueRenderer: string; obj: StreamType }[] = [];
    this.controller.Log.logDebug(`getListeComboboxValue`, ``);
    try {
      let sendResult = await this.controller.StockStore.getStreamSuggests("");

      sendResult.data.forEach(function (element) {
        result.push({ valueRenderer: `${element.code}/${element.label} (${element.direction})`, obj: element });
      });
    } catch (error) {
      //Log
      this.controller.Log.logError(`getListeComboboxValue`, "", error, "StreamTypeMovementCode");
    }
    return result;
  }

  /**
   * chargement des codes mouvement
   */
  async getGoodsMvtCodeSelect(): Promise<{ valueRenderer: string; obj: GoodsMvtCode }[]> {
    let result: { valueRenderer: string; obj: GoodsMvtCode }[] = [];
    this.controller.Log.logDebug(`getListeComboboxValue`, ``);
    try {
      let sendResult = await this.controller.StockStore.getGoodsMvtCodeSuggests(null, null, null);
      if (sendResult.data) {
        sendResult.data.forEach(function (element) {
          result.push({ valueRenderer: `${element.code}/${element.label} (${element.direction})`, obj: element });
        });
      }
    } catch (error) {
      //Log
      this.controller.Log.logError(`getListeComboboxValue`, "", error, "StreamTypeMovementCode");
    }

    return result;
  }

  /**
   * Methode d'ouverture de la dialogBox pour les enfants
   */
  openCreate() {
    this.dialog = true;
  }
  /**
   * Mise a zero du formulaire
   */
  resetValues() {
    (this.$refs.form as any).reset();
  }

  /**
   * Refresh des codes de mouvement dispo en fonction
   */
  async refreshGoodsMovementCode(): Promise<void> {
    let result: { valueRenderer: string; obj: GoodsMvtCode }[] = [];

    this.controller.Log.logDebug(`refreshGoodsMovementCode`, ``);
    try {
      let sendResult = await this.controller.StockStore.getGoodsMvtCodeSuggests(null, this.storageType, this.streamType);
      if (sendResult.data) {
        sendResult.data.forEach(function (element) {
          result.push({ valueRenderer: `${element.code}/${element.label} (${element.direction})`, obj: element });
        });
      }
      this.$data.goodsMovementCodeItems = result;
    } catch (error) {
      //Log
      this.controller.Log.logError(`refreshGoodsMovementCode`, "", error, "StreamTypeMovementCode");
    }
  }

  retrieveController() {
    let stockBaseUrl = apiUrls.stock;
    let userBaseUrl = apiUrls.user;

    this.controller = new StockController(stockBaseUrl, userBaseUrl);
  }

  async beforeMount() {
    this.$data.storageTypeItems = await this.getStorageTypeSelect();
    this.$data.streamTypeItems = await this.getStreamTypeSelect();
    this.$data.goodsMovementCodeItems = await this.getGoodsMvtCodeSelect();
  }

  created() {
    this.retrieveController();
  }
}
