
import { Component, Vue } from "vue-property-decorator";

@Component
export default class IconeColumnRenderer extends Vue {
  private params: any;

  // getter
  get style() {
    if (this.params.color) {
      return "color:" + this.params.color + ";";
    } else return "";
  }
  // setter
  set style(newValue) {
    this.params.value = newValue;
  }

  // getter
  get icon() {
    if (this.params.icon) {
      return this.params.icon;
    } else return null;
  }
  // setter
  set icon(newValue) {
    this.params.value = newValue;
  }
}
