import ControllerBase from "@/app/shared/services/controllers/controllerBase";
import ModuleStore from "../../../store/moduleStore";

export default class ModuleController extends ControllerBase {
  public moduleStore: ModuleStore;

  constructor(moduleUrl: string) {
    super();
    this.moduleStore = new ModuleStore(moduleUrl);
  }
}
