import RestClient from "@/app/shared/services/restClient"; // Roadmap to get api
import { AxiosInstance } from "axios";
import store from "@/app/app-state";

import Vue from "vue";
import { FilterItem, Query, TypeControle } from "@/app/stock/preparationControl/shared/store/Types";
import { ThirdWarehouse } from "@/app/stock/shared/store/Types";

export default class PreparationControlStore {
  private loading: boolean;
  private restClient: AxiosInstance;

  constructor(baseUrl: string) {
    this.loading = false;
    this.restClient = new RestClient(baseUrl).Instance;
  }

  /**
   * liste de suggestion pour le filtre des transporteurs
   * @param {*} dateControle
   * @param {*} filterItem
   * @param {*} query
   */
  async getCarrierSuggests(
    dateControle: string,
    typeControle: TypeControle,
    filterItem: FilterItem,
    query: Query
  ): Promise<{ data: ThirdWarehouse[]; itemsCount: number; status?: number }> {
    this.loading = true;

    let response: { data: ThirdWarehouse[]; itemsCount: number; status?: number } = { data: [], itemsCount: 0 };
    let url = "";
    // ajout des parametre de base
    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&`;
    switch (typeControle.id) {
      case 2:
        queryString += `planDate=${dateControle}`;
        url = `/LoadingControl/LoadingPlan/SuggestsCarrier`; //ThirdWarehouse[]
        break;
      case 3:
        queryString += `carrierDate=${dateControle}`;
        url = `/LoadingControl/LoadingCarrier/SuggestsCarrier`; //ThirdWarehouse[]
        break;
      default:
        break;
    }
    //ajout des parametre de filtre optionnel
    if (filterItem.searchCode) {
      queryString += `&${filterItem.searchCode}=${query}`;
    }
    url += `${queryString}`;

    response = await this.restClient.get(url);
    this.loading = false;

    return response;
  }

  /**
   * liste de suggestion pour le filtre des do
   * @param {*} dateControle
   * @param {*} filterItem
   * @param {*} query
   */
  async getContractorSuggests(
    dateControle: string,
    typeControle: TypeControle,
    filterItem: FilterItem,
    query: Query
  ): Promise<{ data: ThirdWarehouse[]; itemsCount: number; status?: number }> {
    this.loading = true;

    let queryString;
    let response: { data: ThirdWarehouse[]; itemsCount: number; status?: number } = { data: [], itemsCount: 0 };

    // ajout des parametre de base
    switch (typeControle.id) {
      case 0:
      case 1:
        queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&noteDate=${dateControle}`;
        break;
      case 2:
        queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&planDate=${dateControle}`;
        break;
    }

    //ajout des parametre de filtre optionnel
    if (filterItem.searchCode) {
      queryString += `&${filterItem.searchCode}=${query}`;
    }
    switch (typeControle.id) {
      case 0:
        response = await this.restClient.get(`/LoadingControl/LoadingNote/SuggestsContractor${queryString}`); //ThirdWarehouse[]
        break;
      case 1:
        response = await this.restClient.get(`/LoadingControl/PreparationNote/SuggestsContractor${queryString}`); //ThirdWarehouse[]
        break;
      case 2:
        response = await this.restClient.get(`/LoadingControl/LoadingPlan/SuggestsContractor${queryString}`); //ThirdWarehouse[]
        break;
      default:
        break;
    }
    this.loading = false;

    return response;
  }
  /**
   * Controle une palette dans le cadre d'un contrôle
   * @param {*} origine
   * @param {*} typeControle
   * @param {*} barCode
   */
  async controlFromBarCode(origine: any, typeControle: TypeControle, barCode: string) {
    this.loading = true;

    let response = { data: [], itemsCount: 0 };

    // On récupère la date et on la met au bon format
    const carrierDate = new Date(origine.date);
    const date = `${carrierDate.getFullYear()}-${carrierDate.getMonth() + 1}-${carrierDate.getDate()}`;

    // ajout des parametre de base
    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&barCode=${barCode}`;
    switch (typeControle.id) {
      case 0:
        response = await this.restClient.post(`/LoadingControl/LoadingNote/${origine.id}/ControlFromBarCode${queryString}`);
        break;
      case 1:
        response = await this.restClient.post(`/LoadingControl/PreparationNote/${origine.id}/ControlFromBarCode${queryString}`);
        break;
      case 2:
        response = await this.restClient.post(`/LoadingControl/LoadingPlan/${origine.id}/ControlFromBarCode${queryString}`);
        break;
      case 3:
        queryString += `&carrierName=${origine.carrierName}&carrierDate=${date}`;
        // Si pas de licence on ne met pas le paramètre
        if (origine.licensePlate != null && origine.licensePlate) {
          queryString += `&licensePlate=${origine.licensePlate}`;
        }
        response = await this.restClient.post(`/LoadingControl/LoadingCarrier/ControlFromBarCode${queryString}`);
        break;
      default:
        break;
    }
    this.loading = false;

    return response;
  }

  /**
   * Liste des chargements à contrôler
   * @param {*} dateControle
   * @param {*} typeControle
   * @param {*} query
   */
  async getLst(
    dateControle: string,
    typeControle: TypeControle,
    query: Query
  ): Promise<{ data: any[]; itemsCount: number; status: number }> {
    let apiEndPoint = null;
    let response: any = { data: [], itemsCount: 0 };

    switch (typeControle.id) {
      case 0: // controle par bon
        apiEndPoint = "/LoadingControl/LoadingNote/GetList"; //LoadingNote[]
        response = this.callResponse(apiEndPoint, typeControle, `contractor`, `noteDate`, dateControle, query, response);
        break;
      case 1: // controle par BPC
        apiEndPoint = "/LoadingControl/PreparationNote/GetList"; //PreparationNote[]
        response = this.callResponse(apiEndPoint, typeControle, `contractor`, `noteDate`, dateControle, query, response);
        break;
      case 2: // controle par plan
        apiEndPoint = "/LoadingControl/LoadingPlan/GetList"; //LoadingPlan[]
        response = this.callResponse(apiEndPoint, typeControle, `carrier`, `planDate`, dateControle, query, response);
        break;
      case 3: // controle par transporteur
        apiEndPoint = "/LoadingControl/LoadingCarrier/GetList"; //LoadingCarrier[]
        response = this.callResponse(apiEndPoint, typeControle, `carrier`, `carrierDate`, dateControle, query, response);

        break;
      default:
        Vue.$log.info(`Appel methode non implémenté : ${typeControle.label}`);
        break;
    }
    return response;
  }

  async callResponse(
    this: any,
    apiEndPoint: string,
    typeControle: TypeControle,
    filterText: string,
    dateControleCode: string,
    dateControle: string,
    query: Query,
    response: { data: any[]; itemsCount: number; status?: number; headers?: any }
  ): Promise<{ data: any[]; itemsCount: number; status?: number; headers?: any }> {
    this.loading = true;
    // ajout des parametre de base
    const limit = query.limit;
    const offset = query.offset;
    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&${dateControleCode}=${dateControle}&limit=${limit}&offset=${offset}`;
    // ajout des parametre de filtre optionnel
    typeControle.filterItems.forEach((filter) => {
      //iteration sur chaque filtre present
      if (filter.value) {
        if (typeof filter.value === "object") {
          // si on est sur un objet il faut traiter le filtre au cas par cas
          switch (filter.searchCode) {
            case "carrier":
              if (filter.value.code) {
                queryString += `&${filterText}Code=${filter.value.code}`;
              }
              if (filter.value.name) {
                queryString += `&${filterText}Name=${filter.value.name}`;
              }
              break;
            case "contractor":
              if (filter.value.code) {
                queryString += `&${filterText}Id=${filter.value.id}`;
              }
              break;
            default:
              break;
          }
        } else {
          queryString += `&${filter.code}=${filter.value}`;
        }
      }
    });

    response = await this.restClient.get(`${apiEndPoint}${queryString}`);
    // ajout de l'info pour la pagination
    response.itemsCount = response.headers["content-range"].split("/")[1];

    this.loading = false;

    return response;
  }

  /**
   * Charge la liste des palettes
   * @param {*} origine
   * @param {*} typeControle
   * @param {*} query
   */
  async getLstPallets(
    origine: any,
    typeControle: TypeControle,
    query: Query
  ): Promise<{ data: any[]; itemsCount: number; status?: number; headers?: any }> {
    //PalletToLoadControl[]
    this.loading = true;

    let response: { data: any[]; itemsCount: number; erreur: string[]; headers?: any } = { data: [], itemsCount: 0, erreur: [] };
    const limit = query.limit;
    const offset = query.offset;

    // On récupère la date et on la met au bon format
    const carrierDate = new Date(origine.date);
    const date = `${carrierDate.getFullYear()}-${carrierDate.getMonth() + 1}-${carrierDate.getDate()}`;

    if (origine) {
      // ajout des parametre de base
      let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&limit=${limit}&offset=${offset}`;
      switch (typeControle.id) {
        case 0:
          response = await this.restClient.get(`/LoadingControl/LoadingNote/${origine.id}/GetLstPallets${queryString}`);
          break;
        case 1: // BPC
          response = await this.restClient.get(`/LoadingControl/PreparationNote/${origine.id}/GetLstPallets${queryString}`);
          break;
        case 2:
          response = await this.restClient.get(`/LoadingControl/LoadingPlan/${origine.id}/GetLstPallets${queryString}`);
          break;
        case 3:
          queryString += `&carrierDate=${date}&carrierName=${origine.carrierName}`;
          // Si id = 0 on ne met pas le paramètre
          if (origine.id != null && origine.id > 0) {
            queryString += `&carrierId=${origine.id}`;
          }
          // Si pas de licence on ne met pas le paramètre
          if (origine.licensePlate != null && origine.licensePlate) {
            queryString += `&licensePlate=${origine.licensePlate}`;
          }
          response = await this.restClient.get(`/LoadingControl/LoadingCarrier/GetLstPallets${queryString}`);
          break;
        default:
          break;
      }
    }

    // ajout de l'info pour la pagination
    if (response.headers && response.headers["content-range"]) response.itemsCount = response.headers["content-range"].split("/")[1];

    this.loading = false;

    return response;
  }
}
