import Vue from "vue";
import Url from "./url";
import Moment from "moment";

export default class LogHelper {
  private hasLogDebug = false;
  private hasLogInfo = false;
  private hasLogWarning = false;
  private hasLogError = false;

  constructor() {
    const url = new Url();
    let loglevel = url.get("loglevel");

    if (loglevel) loglevel = loglevel.toUpperCase();

    this.log("INFO", "Log", `loglevel: ${loglevel}`);

    this.hasLogDebug = false;
    this.hasLogInfo = false;
    this.hasLogWarning = false;
    this.hasLogError = false;

    switch (loglevel) {
      case "DEBUG":
        this.hasLogDebug = true;
        break;
      case "INFO":
        this.hasLogInfo = true;
        break;
      case "WARNING":
        this.hasLogWarning = true;
        break;
      default:
        this.hasLogError = true;
        break;
    }
  }

  async logDebug(src: string, msg: string) {
    if (!this.hasLogDebug) {
      return;
    }
    await this.log("Debug", src, msg);
  }

  async logInfo(src: string, msg: string) {
    if (!this.hasLogInfo) {
      return;
    }
    await this.log("Info", src, msg);
  }

  async logWarning(src: string, msg: string) {
    if (!this.hasLogWarning) {
      return;
    }
    await this.log("Warning", src, msg);
  }

  async logError(src: string, msg: string, err: any, moduleName: string) {
    if (!this.hasLogError) {
      return;
    }
    await this.log("Error", src, `${msg}: ${err}`);

    const date = Moment().format();
    this.setLog("Next Log", moduleName, date, "Error", src, `${msg}: ${err}`);
  }

  async log(type: string, src: string, msg: string) {
    const date = Moment().format();
    Vue.$log.info(`${date}[${type}][${src}] ${msg}`);
  }

  /*Logs*/
  setLog(key: string, moduleName: string, date: string, type: string, src: string, msg: string) {
    try {
      if ("serviceWorker" in navigator) {
        if (navigator.serviceWorker.controller == null) {
          return Promise.reject("No service worker controller for logs.");
        }
        return new Promise(function (resolve, reject) {
          const messageChannel = new MessageChannel();
          messageChannel.port1.onmessage = function (event) {
            if (event.data.error) {
              reject(event.data.error);
            } else {
              resolve(event.data);
            }
          };

          const urlLog = new Url().url;
          const message = {
            key: key,
            msg: {
              Module: moduleName,
              Date: date,
              Level: type,
              Url: urlLog,
              SourceContext: src,
              Message: msg,
            },
          };
          if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage(message, [messageChannel.port2]);
          }
        });
      } else {
        return Promise.reject("No service worker for logs.");
      }
    } catch (error) {
      Vue.$log.error(`SetLog LocalStorage error ` + error);
    }
  }
}
