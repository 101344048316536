
import { Component, Vue } from "vue-property-decorator";
import BadgeComponent from "../../badge/BadgeComponent.vue";

@Component({
  components: {
    BadgeComponent,
  },
})
export default class BadgeColumnRenderer extends Vue {
  private params: any;

  get text() {
    if (this.params.text) {
      return this.params.text;
    } else return "None";
  }
  set text(newValue: string) {
    this.params.value = newValue;
  }

  // getter
  get badgeClass() {
    if (!this.params.value) {
      return "badge-danger";
    } else {
      switch (this.params.value) {
        case "20":
          return "badge-light";
        case "30":
          return "badge-warning";
        case "40":
        default:
          return "badge-success";
        case "60":
          return "badge-info";
        case "80":
          return "badge-secondary";
      }
    }
  }
  // setter
  set badgeClass(newValue) {
    this.params.value = newValue;
  }
}
