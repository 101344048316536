
import { Component, Prop } from "vue-property-decorator";
import ListComponent from "@/app/shared/components/generic/list/ListComponentV2.vue";
import moment from "moment";
import "moment/locale/fr";

// eslint-disable-next-line no-unused-vars
import preparationControlController from "../../../shared/services/controllers/preparationControl/preparationControlController";
// eslint-disable-next-line no-unused-vars
import { TypeControle } from "../../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import preparationControl from "../../../PreparationControl.vue";
// eslint-disable-next-line no-unused-vars
import { IDatasource } from "node_modules/ag-grid-community/main";

moment.locale("fr");

const moduleName = "preparationControl";

@Component({
  components: {},
})
export default class PalletsControl extends ListComponent {
  @Prop() declare controller: preparationControlController;
  @Prop() plan!: any;
  @Prop() typeControle!: TypeControle;

  onRowClicked = "";
  onRowDoubleClicked = "";

  async onGridReady(params: any) {
    let gridApi = params.api;

    this.dataSource = {
      rowCount: null,
      getRows: async (params: any) => {
        let query = this.fillListWithFilterAndSort(params);

        this.controller.Log.logDebug(`listPalletsControl`, `Object query : ${JSON.stringify(query)}`);
        let sendResult: { data: any[]; itemsCount: number } | null = { data: [], itemsCount: 0 };
        try {
          let response: { data: any; itemsCount: number; status?: number } = { data: [], itemsCount: 0 };
          if (
            this.typeControle &&
            ((this.typeControle.id == 3 && this.plan != undefined) ||
              (this.typeControle.id != 3 && this.plan.id > 0 && this.plan != undefined))
          ) {
            response = await this.controller.preparationControlStore.getLstPallets(this.plan, this.typeControle, query);
            if (response.status) {
              if (response.status >= 200 && response.status < 300) {
                sendResult = response;
              } else {
                // traitement de l'erreur et affichage du message d'erreur
                if (response.data) {
                  (this.$parent as preparationControl).errorMessage = "";
                  if (response.data.ERREUR.length > 0) {
                    (this.$parent as preparationControl).errorMessage += response.data.ERREUR[0];
                  }
                  // Affichage de la notif d'erreur
                  (this.$parent as preparationControl).errorNotif = true;
                }
              }
              (this.$parent as preparationControl).nbPallets = sendResult.itemsCount;
            }
          } else {
            sendResult = response;
          }
          params.successCallback(sendResult.data, sendResult.itemsCount);
        } catch (error) {
          //Log
          await this.controller.Log.logError(`listPalletsControl onGridReady`, "", error, moduleName);
          sendResult = null;
        }
      },
    };
    gridApi.setDatasource(this.dataSource);
  }
  /**
   * rafraichissement de la liste
   */
  refreshList() {
    this.refreshData();
  }

  emptyList() {
    this.gridApi.setDatasource({} as IDatasource);
  }

  async beforeMount() {
    this.columnDefs = [
      this.numericColumn("id", {
        headerName: "Id",
        hide: true,
        cellRenderer: this.gridOptions.components.loadingRenderer,
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
      }),
      this.textColumn("type", {
        headerName: "Type",
        hide: true,
        field: "type",
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("number", {
        headerName: "N° palette",
        field: "number",
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
    ];

    this.gridOptions.rowMultiSelectWithClick = true;

    //modification pour permettre l'infiniteScrolling
    this.pagination = true;
    this.gridOptions.infiniteInitialRowCount = 20;
    this.gridOptions.maxBlocksInCache = 20;
    this.gridOptions.cacheOverflowSize = 20;
    this.gridOptions.maxConcurrentDatasourceRequests = 5;

    // on conditionne le nombre de ligne affiché en fonction du device
    if (this.$vuetify.breakpoint.smAndDown) {
      this.gridOptions.paginationPageSize = 8;
      this.pagination = true;
    } else {
      this.gridOptions.paginationPageSize = 20;
    }

    this.gridOptions.cacheBlockSize = 20;

    // Suppression des boutons clear filtre
    this.clearFilterButtonVisibility = false;
    this.suppressPaginationPanel = true;
  }
}
