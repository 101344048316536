
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiUrls } from "@/environment/environment";

import ScanHelper from "@/app/shared/services/scanHelper";

import StockController from "../shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { ItemDetailInfo, ThirdWarehouse } from "../shared/store/Types";
import ContractorsWarehousefilter from "./components/ContractorsWarehousefilter.vue";

@Component({
  components: { ContractorsWarehousefilter },
})
export default class ArticleSearch extends Vue {
  private controller: StockController;
  constructor() {
    super();
    this.controller = new StockController(apiUrls.stock, apiUrls.user);
  }

  eanItem = "";
  ItemDetailInfo: ItemDetailInfo = {} as ItemDetailInfo;
  selectedContractorWarehouse: ThirdWarehouse | null = null;

  /**
   * watch to autocomplete for search ContractorsWarehouse
   * @param {string} value
   * @param {string} oldValue
   */
  @Watch("selectedContractorWarehouse")
  onSelectedContractorWarehouseChanged(): void {
    this.eanItem = "";
    this.ItemDetailInfo = {} as ItemDetailInfo;
  }

  errorMessage = "";
  errorNotif = false;

  /**
   * Concatenation de deux valeur separé par un '-'
   * @param val1
   * @param val2
   */
  concatCodeLabel(val1: null | string, val2: null | string) {
    let codeLabel: string[] = [];
    if (val1) {
      codeLabel.push(val1);
    }
    if (val2) {
      codeLabel.push(val2);
    }
    return codeLabel.join(" - ");
  }

  /**
   * Methode du scanne CAB
   */
  async changeBarCodeItem() {
    // On sort le focus du CAB
    (this.$refs.eanItem as any).$refs.input.blur();
    this.controller.Log.logDebug(`getInfoItemdWithBarCode`, `Object query : ${JSON.stringify(this.eanItem)}`);
    let sendResult: { data: ItemDetailInfo; status: number; error: string };

    try {
      // appel API pour recuperer les infos de l'article scanne
      if (this.eanItem) {
        this.ItemDetailInfo = {} as ItemDetailInfo;
        sendResult = await this.controller.StockStore.getInfoItemWithBarCode(this.eanItem, this.selectedContractorWarehouse);
        if (sendResult.status === 200) {
          this.ItemDetailInfo = sendResult.data;
        } else {
          if (sendResult.status === 204) {
            this.errorMessage = "Aucun article connue avec ce GTIN";
          } else {
            this.errorMessage = sendResult.error;
          }
          this.errorNotif = true;
        }
      }
    } catch (error) {
      await this.controller.Log.logError(`getInfoItemdWithBarCode`, "", error, "articleSearch");
    }
  }

  /**
   * Fermeture de la snackbar sur le click outside
   */
  onClickOutsideSnackbar() {
    if (this.errorNotif) {
      this.errorNotif = false;
    }
  }

  /**
   * Methode appelée a la lecture du CAB
   */
  barCodeRead(barCode: string) {
    this.eanItem = barCode;
    this.changeBarCodeItem();
  }

  mounted() {
    ScanHelper.listenForBarcode(this.barCodeRead);
  }

  beforeDestroy() {
    ScanHelper.stopListening();
  }
}
