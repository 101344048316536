
import { Component, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { MapValue, RangeValue } from "../Type";

@Component({
  components: {},
})
export default class BadgeRenderer extends Vue {
  @Prop() props!: any;

  get getColor(): string {
    let mapColor = this.getColorAndIconValue();
    return mapColor && mapColor.color ? mapColor.color : "undefined";
  }
  get getIcon(): string | null {
    let mapIcon = this.getColorAndIconValue();
    return mapIcon && mapIcon.icon ? mapIcon.icon : null;
  }

  get getRangeMap(): MapValue[] | RangeValue[] {
    return this.props.header.renderer.badge.rangeMap;
  }

  private getColorAndIconValue(): { color?: string; icon?: string } | undefined {
    let mapColorAndIcon: { color?: string; icon?: string } = {};
    if (this.instanceOfArrayMapValue(this.getRangeMap)) {
      return this.getColorOfValue(this.props.value);
    } else if (this.instanceOfArrayRangeValue(this.getRangeMap)) {
      return this.getColorOfRange(this.props.value);
    }
    return mapColorAndIcon;
  }

  private getColorOfValue(value: number | string): MapValue | undefined {
    let mapValue = (this.getRangeMap as MapValue[]).find((p: MapValue) => {
      return p.value == value;
    });

    return mapValue;
  }

  private getColorOfRange(value: number | string): RangeValue | undefined {
    let rangeValue = (this.getRangeMap as RangeValue[]).find((p: RangeValue) => {
      let res = false;
      if (p.startValue === null && p.endValue) {
        res = value <= p.endValue;
      } else if (p.startValue && p.endValue === null) {
        res = p.startValue < value;
      } else if (p.startValue && p.endValue) {
        res = p.startValue < value && value <= p.endValue;
      }

      return res;
    });
    return rangeValue;
  }

  private instanceOfArrayMapValue(object: any): boolean {
    if ("value" in object[0]) {
      return true;
    }
    return false;
  }

  private instanceOfArrayRangeValue(object: any): boolean {
    if ("startValue" in object[0]) {
      return true;
    }
    return false;
  }
}
