import frMessages from "devextreme/localization/messages/fr.json";
import { locale, loadMessages } from "devextreme/localization";
import config from "devextreme/core/config";
// import store from "@/store";

export default class DevExtreme {
  static init(): void {
    loadMessages(frMessages);
    locale("fr");
    config({
      defaultCurrency: "EUR",
      defaultUseCurrencyAccountingStyle: false /*Permet d'avoir les négatifs "-xxxx" à la place des parenthèses (xxxx)*/,
    });
  }
}
