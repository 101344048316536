
import { Component, Vue, Prop } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import listOperatorMissionPriority from "../ListOperatorMissionPriority.vue";

@Component
export default class OperatorMissionPriorityDataTableNoteNumberRenderer extends Vue {
  @Prop() props!: any;
  @Prop({ default: false }) isDetail!: boolean;

  get OnNote() {
    let listOperatorMissionPriorityProps = this.$parent?.$parent?.$parent?.$parent as listOperatorMissionPriority;
    if (this.isDetail) {
      listOperatorMissionPriorityProps = this.$parent?.$parent?.$parent?.$parent?.$parent as listOperatorMissionPriority;
    }
    return listOperatorMissionPriorityProps.noteSelected == this.props.item && listOperatorMissionPriorityProps.isDetailsOpened;
  }
}
