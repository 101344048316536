
import Component from "vue-class-component";
import { Prop, VModel, Vue, Watch } from "vue-property-decorator";
// import { mapState, mapMutations } from "vuex";

import FilterChipsRenderer from "./renderer/FilterChipsRenderer.vue";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// // eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  Localization,
  // eslint-disable-next-line no-unused-vars
  MouvementDirection,
  // eslint-disable-next-line no-unused-vars
  NoteStatus,
  // eslint-disable-next-line no-unused-vars
  StatisticCode,
  // eslint-disable-next-line no-unused-vars
  StreamType,
  // eslint-disable-next-line no-unused-vars
  ThirdWarehouse,
  // eslint-disable-next-line no-unused-vars
  WeeklyDeliveryPlanning,
} from "../../shared/store/Types";
// // eslint-disable-next-line no-unused-vars
// import { Filter } from "@/app/shared/components/generic/list/V3/Type";
// eslint-disable-next-line no-unused-vars
import UserFilter from "../../shared/types/UserFilter";

import _ from "lodash";

// eslint-disable-next-line no-unused-vars
import OptionalFilter from "../../shared/types/OptionalFilter";

@Component({
  components: { FilterChipsRenderer },
})
export default class OperatorMissionPriorityFiltersEdit extends Vue {
  @Prop() controller!: StockController;
  @Prop({ default: false }) isIcon!: boolean;
  @Prop() filterDate!: string;
  @Prop() filterName!: string;

  @Prop() userFilterItems!: UserFilter[];

  @VModel({ default: {} }) filter!: OptionalFilter;

  loading = false;
  private initialFilter: OptionalFilter = {};
  private userFilterItemsInitial: UserFilter[] = []; // liste d'items fitlre initial (prise des preference utilisateur)

  get isDefaultFilter(): boolean {
    return this.filterName.toLowerCase() === "default";
  }
  /**
   * Permet de savoir si le filtre est vide (pour afficher le bouton)
   */
  get filterIsEmpty(): boolean {
    const filterClean: OptionalFilter = Object.fromEntries(Object.entries(this.filter).filter(([, val]) => val != null && val != ""));
    return _.isEmpty(filterClean);
  }

  dialog = false;
  @Watch("dialog")
  onDialogChange(newVal: boolean): void {
    if (newVal) {
      this.initialFilter = JSON.parse(JSON.stringify(this.filter));
      this.userFilterItemsInitial = JSON.parse(JSON.stringify(this.userFilterItems));
    }
  }

  panel: number[] = [0];

  getfilterListValue(filters: OptionalFilter): string[] {
    var query: string[] = [];
    // on clean les cles de valeur null
    const filterClean: OptionalFilter = Object.fromEntries(Object.entries(filters).filter(([, val]) => val != null && val != ""));
    Object.entries(filterClean).forEach((entry) => {
      const [key, value] = entry;
      if (value != null) {
        switch (key) {
          case "contractorWarehouse":
          case "partnerWarehouse":
          case "carrierWarehouse":
          case "associatedPartner":
            query.push(`${key}Id=${(value as ThirdWarehouse).id}`);
            break;
          case "mouvementDirection":
            query.push(`${key}=${(value as MouvementDirection).code}`);
            break;
          case "noteStatus":
            query.push(`${key}=${(value as NoteStatus).code}`);
            break;
          case "streamType":
            query.push(`${key}=${(value as StreamType).code}`);
            break;
          // case "palettNumber":
          //   break;
          case "statisticCode":
            query.push(`${key}Id=${(value as StatisticCode).id}`);
            break;
          case "weeklyDeliveryPlanning":
            query.push(`${key}Id=${(value as WeeklyDeliveryPlanning).id}`);
            break;
          case "localization":
            query.push(`${key}Id=${(value as Localization).id}`);
            break;
          case "receivedDate":
          case "pickupDate":
          case "deliveryDate":
            query.push(`${key}=${value}`);
            break;
          default:
            query.push(`${key}=${value}`);
            break;
        }
      }
    });
    return query;
  }
  // Date   -> type Date, champs de saisie et calendrier (correspond a la date du bon ou date du plan Valeur obligatoire)
  //#region Client -> type Alphanumérique, drop down (items ci-dessous)
  // D.O.  -> type Alphanumérique, champs de saisie en autocomplétions. Ramène tous les DO -> code DO ou nom DO référencés sur le site.
  // Champ du filtre correspondant :filter.contractorWarehouse
  contractorWarehouseItems: ThirdWarehouse[] = [];
  async getContractorWarehouseItems() {
    let sendResult: { data: ThirdWarehouse[] } = { data: [] };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityContractorWarehouseSuggests();
    this.contractorWarehouseItems = sendResult.data;
  }
  //#endregion

  //#region Bon de mouvement -> type Alphanumérique, drop down (items ci-dessous)
  // N° de bon -> type Alphanumérique, champs de saisie
  // Champ du filtre correspondant :filter.noteNumber
  // Date de réception -> type Date, champs de saisie et calendrier
  // Champ du filtre correspondant :filter.receivedDate
  // Date d'enlèvement -> type Date, champs de saisie et calendrier
  // Champ du filtre correspondant :filter.pickupDate
  // Date de livraison -> type Date, champs de saisie et calendrier
  // Champ du filtre correspondant :filter.deliveryDate
  // N° de commande -> type Alphanumérique, autocomplétions champs de saisie
  // Champ du filtre correspondant :filter.orderNumber
  orderNumberItems: string[] = [];
  /**
   * recuperation des numeros de commande
   */
  async getOrderNumberItems() {
    let sendResult: { data: string[] } = { data: [] };
    // Ajout Filtre possible : Date, DO,  Partenaire, transporteur
    const filterTmp: OptionalFilter = {
      contractorWarehouse: this.filter.contractorWarehouse,
      partnerWarehouse: this.filter.partnerWarehouse,
      carrierWarehouse: this.filter.carrierWarehouse,
    };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityOrderNumberItemsSuggests(
      this.getfilterListValue(filterTmp),
      this.filterDate
    );
    this.orderNumberItems = sendResult.data;
  }
  // N° de commande D.O. final  -> type Alphanumérique, autocomplétions champs de saisie
  // Champ du filtre correspondant :filter.finalOrderNumber
  finalOrderNumberItems: string[] = [];
  /**
   * recuperation des numeros de commande final
   */
  async getFinalOrderNumberItems() {
    let sendResult: { data: string[] } = { data: [] };
    // Ajout Filtre possible : Date, DO,  Partenaire, transporteur
    const filterTmp: OptionalFilter = {
      contractorWarehouse: this.filter.contractorWarehouse,
      partnerWarehouse: this.filter.partnerWarehouse,
      carrierWarehouse: this.filter.carrierWarehouse,
    };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityFinalOrderNumberItemsSuggests(
      this.getfilterListValue(filterTmp),
      this.filterDate
    );
    this.finalOrderNumberItems = sendResult.data;
  }
  // Sens mouvement -> type liste déroulante (items ci-dessous)
  // Champ du filtre correspondant :filter.mouvementDirection
  mouvementDirectionItems: MouvementDirection[] = [];
  /**
   * recuperation des code de mouvement
   */
  async getMouvementDirectionItems() {
    let sendResult: { data: MouvementDirection[] } = { data: [] };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityMouvementDirectionItemsSuggests();
    this.mouvementDirectionItems = sendResult.data;
  }
  // Statut du bon -> type liste déroulante (items ci-dessous)
  // Champ du filtre correspondant :filter.noteStatus
  noteStatusItems: NoteStatus[] = [];
  /**
   * recuperation des status de bon
   */
  async getNoteStatusItems() {
    let sendResult: { data: NoteStatus[] } = { data: [] };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityNoteStatusItemsSuggests();
    this.noteStatusItems = sendResult.data;
  }
  // Type de flux -> type liste déroulante (items si dessous)
  // Champ du filtre correspondant :filter.streamType
  streamTypeItems: StreamType[] = [];
  /**
   * Recuperation des type de flux
   */
  async getStreamTypeItems() {
    let sendResult: { data: StreamType[] } = { data: [] };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityStreamTypeItemsSuggests();
    this.streamTypeItems = sendResult.data;
  }
  // N° BPC -> type numérique entier, champs de saisie
  //: number | null = null;
  // Champ du filtre correspondant :filter.preparedNote
  //#endregion

  //#region Palettes -> type Alphanumérique, drop down (items ci-dessous)
  // N°palettes -> type Alphanumérique , autocomplétions champs de saisie
  // Champ du filtre correspondant :filter.palettNumber
  //#endregion

  //#region Partenaire -> type Alphanumérique, drop down (items ci-dessous)
  // Partenaire -> type Alphanumérique (code et libellé) , autocomplétions  champs de saisie
  // Champ du filtre correspondant :filter.partnerWarehouse
  partnerWarehouseItems: ThirdWarehouse[] = [];
  /**
   * Recuperation des Partenaire
   */
  async getPartnerWarehouseItems() {
    let sendResult: { data: ThirdWarehouse[] } = { data: [] };
    this.loading = true;
    // Ajout Filtre possible : Date, DO,  Partenaire, transporteur
    const filterTmp: OptionalFilter = {
      contractorWarehouse: this.filter.contractorWarehouse,
    };
    this.partnerWarehouseItems = [];
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityPartnerWarehouseSuggests(this.getfilterListValue(filterTmp));
    this.loading = false;
    this.partnerWarehouseItems = sendResult.data;
  }
  // Code statistique  -> type Alphanumérique , liste déroulante correspondant au code disponible sur le site
  // Champ du filtre correspondant :filter.statisticCode
  statisticCodeItems: StatisticCode[] = [];
  /**
   * Recuperation des codes de statistique
   */
  async getStatisticCodeItems() {
    let sendResult: { data: StatisticCode[] } = { data: [] };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityStatisticCodeItemsSuggests();
    this.statisticCodeItems = sendResult.data;
  }
  // Code regroupement  -> type Alphanumérique ,  liste déroulante correspondant au code disponible sur le site
  // Champ du filtre correspondant :filter.associatedPartner
  associatedPartnerItems: ThirdWarehouse[] = [];
  /**
   * Recuperation des code de regroupement
   */
  async getAssociatedPartnerItems() {
    let sendResult: { data: ThirdWarehouse[] } = { data: [] };
    // Ajout Filtre possible : DO
    const filterTmp: OptionalFilter = {
      contractorWarehouse: this.filter.contractorWarehouse,
    };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityAssociatedPartnerItemsSuggests(
      this.getfilterListValue(filterTmp)
    );
    this.associatedPartnerItems = sendResult.data;
  }
  //#endregion

  //#region Plan de chargement -> type Alphanumérique, drop down (items si dessous)
  // N° de plan -> type numérique entier , champs de saisie
  // Champ du filtre correspondant :filter.planNumber
  // Cadencier  -> type Alphanumérique , autocomplétions champs de saisie
  // Champ du filtre correspondant :filter.weeklyDeliveryPlanning
  weeklyDeliveryPlanningitems: WeeklyDeliveryPlanning[] = [];
  /**
   * recuperation des numeros de cadencier
   */
  async getWeeklyDeliveryPlanningitems() {
    let sendResult: { data: WeeklyDeliveryPlanning[] } = { data: [] };
    // Ajout Filtre possible : Date, DO,  Partenaire, transporteur
    const filterTmp: OptionalFilter = {
      contractorWarehouse: this.filter.contractorWarehouse,
      partnerWarehouse: this.filter.partnerWarehouse,
      carrierWarehouse: this.filter.carrierWarehouse,
    };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityWeeklyDeliveryPlanningitemsSuggests(
      this.getfilterListValue(filterTmp)
    );
    this.weeklyDeliveryPlanningitems = sendResult.data;
  }
  // Immatriculation  -> type Alphanumérique , champs de saisie
  // Champ du filtre correspondant :filter.licensePlate
  //#endregion

  //#region Quai de (dé)chargement -> type Alphanumérique, drop down (items si dessous)
  // Quai  -> type Alphanumérique (code et libellée)  liste déroulante, ramène toutes les zones des chambres dont le mode de stockage est égal à « 6 » et toutes les chambres en autre lieu de transit.
  // Champ du filtre correspondant :filter.localization
  localizationItems: Localization[] = [];
  async getLocalizationItems() {
    let sendResult: { data: Localization[] } = { data: [] };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityLocalizationItemsSuggests();
    this.localizationItems = sendResult.data;
  }
  //#endregion

  //#region Transporteur -> type Alphanumérique, drop down (items ci-dessous)
  // Transporteur -> type Alphanumérique (code et nom) ,autocomplétions champs de saisie.
  // Champ du filtre correspondant :filter.carrierWarehouse
  carrierWarehouseItems: ThirdWarehouse[] = [];
  async getCarrierWarehouseItems() {
    let sendResult: { data: ThirdWarehouse[] } = { data: [] };
    sendResult = await this.controller.StockStore.getOperatorMissionPriorityCarriersWarehouseSuggests();
    this.carrierWarehouseItems = sendResult.data;
  }
  //#endregion

  /**
   * Formatage de l'affichage d'une localisation
   */
  formatLocalization(localization: Localization): string {
    const localizationCode = [
      localization.roomCode,
      localization.areaCode,
      localization.aisleCode,
      localization.levelCode,
      localization.locationCode,
    ]
      .filter((el) => {
        return el != null && el != "";
      })
      .join("-");
    return `${localizationCode} : ${localization.areaLabel}`;
  }

  get userPreference() {
    return this.userFilterItemsInitial;
  }

  /**
   * Enregistrement du filtre par default
   */
  updateFilterToUserPreferenceByName(userFilterName: string) {
    // on clean les cles de valeur null
    const filterClean: OptionalFilter = Object.fromEntries(Object.entries(this.filter).filter(([, val]) => val != null && val != ""));
    let userFilter = this.userFilterItemsInitial.find((userFilter) => {
      return userFilter.name === userFilterName;
    });
    // Si on a pas deja le nom du filtre dans la liste on l'ajoute
    if (!userFilter) {
      this.userFilterItemsInitial.push({ name: userFilterName, lastUsed: false, filter: filterClean });
    } else {
      userFilter.filter = filterClean;
    }
  }

  /**
   * Action appliquer les filtres
   */
  async saveChangeAndChange() {
    (this.$refs as any).form.validate();
    if (this.valid) {
      this.updateFilterToUserPreferenceByName(this.filterName);
      this.$emit("saveChangeAndChange", { filterName: this.filterName, userPreference: this.userPreference });
      this.dialog = false;
    }
  }

  /**
   * Action sauvegarder filtre en cours d'edition
   */
  async saveChange() {
    this.updateFilterToUserPreferenceByName(this.filterName);
    this.$emit("saveChange", { filterName: this.filterName, userPreference: this.userPreference });
    this.dialog = false;
  }
  /**
   * Annuler les modification en cours
   */
  cancelChange() {
    this.filter = JSON.parse(JSON.stringify(this.initialFilter));
    this.dialog = false;
    (this.$refs as any).form.resetValidation();
  }

  valid = false;

  rules: any = {
    unsigned: (value: any) => {
      return !value || value.length == 0 || value > 0 || "Numero invalide.";
    },
    maxlength20: (value: any) => {
      return !value || value.length <= 20 || "Valeur trop grande.";
    },
  };

  removeAllFilter() {
    this.filter = {};
    (this.$refs as any).form.resetValidation();
  }
}
