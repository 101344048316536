//#region BadgeRendererType
export interface MapValue {
  value: string | number;
  color: string;
  icon?: string;
}

export interface RangeValue {
  startValue: string | number | null;
  endValue: string | number | null;
  color: string;
  icon?: string;
}

export interface BadgeRenderer {
  rangeMap: MapValue[] | RangeValue[];
}

export interface ButtonRenderer {
  params?: any;
  action?: any;
}

//#endregion BadgeRendererType

export interface Filter {
  field: string;
  fieldType: FieldType;
  operator: Operator;
  value: string | number;
}

export enum Operator {
  equals = "eq",
  notEqual = "neq",
  lessThan = "lt",
  greaterThan = "gt",
  lessThanOrEqual = "lte",
  greaterThanOrEqual = "gte",
  contains = "like",
  notContains = "nlike",
  endsWith = "end",
  startsWith = "start",
  inRange = "in",
}
export enum FieldType {
  number,
  decimal,
  string,
  date,
  boolean,
  null,
  time,
}
