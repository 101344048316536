import Vue from "vue";
import LogHelper from "../logHelper";

export default class ControllerBase {
  public Log: LogHelper;
  public EventBus: Vue;
  constructor() {
    this.EventBus = new Vue();
    this.Log = new LogHelper();
  }
}
