import AppComponentsUpdate from "./componentsUpdate/ComponentsUpdate.vue";
import AppArticleSearch from "./articleSearch/ArticleSearch.vue";
import AppOrderPreparation from "./orderPreparation/OrderPreparation.vue";
import AppStreamTypeMovementCode from "./streamTypeMovementCode/StreamTypeMovementCode.vue";
import AppShippingUnitToAssign from "./shippingUnitToAssign/ShippingUnitToAssign.vue";
import AppPreparationControl from "./preparationControl/PreparationControl.vue";
import AppOperatorMissionPriority from "./operatorMissionPriority/OperatorMissionPriority.vue";
import AppStorageShippingUnits from "./storageShippingUnits/StorageShippingUnits.vue";
import AppStorageShippingUnitsConsultation from "./storageShippingUnitsConsultation/StorageShippingUnitsConsultation.vue";
import store from "@/app/app-state";
import PrivateLayout from "@/app/layouts/Private.vue";
import { Route } from "vue-router";

const stockRoutes = [
  {
    path: "/stock",
    name: "stock",
    meta: {
      type: "module",
      breadcrumb: [
        {
          text: "Réception",
        },
      ],
      iconClass: "mdi-truck-delivery",
    },
    component: PrivateLayout,

    children: [
      {
        path: "streamTypeMovementCode",
        name: "streamTypeMovementCode",
        component: AppStreamTypeMovementCode,
        meta: {
          breadcrumb: [
            {
              text: "Code mvt/flux",
              to: "/Stock/streamTypeMovementCode",
            },
          ],
          rightName: "_STOCK_STREAMTYPEMOVEMENTCODE", // droit créé sour Tesfri (rightManager)
          type: "subModule",
          iconClass: "mdi-transit-detour",
        },

        beforeEnter: (to: Route, _from: Route, next: any) => {
          if (
            store.state.userContext.isAdministrator ||
            (store.state.userContext.rights.includes(to.meta?.rightName) &&
              store.state.userContext.functionalities.includes(to.meta?.rightName))
          ) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
    ],
  },
  {
    path: "/stock",
    meta: {
      type: "module",
      breadcrumb: [
        {
          text: "Stockage",
        },
      ],
      iconClass: "mdi-warehouse",
    },
    component: PrivateLayout,

    children: [
      {
        path: "componentsUpdate",
        name: "componentsUpdate",
        component: AppComponentsUpdate,
        meta: {
          breadcrumb: [
            {
              text: "Modification composants",
              to: "/Stock/componentsUpdate",
            },
          ],
          rightName: "_STOCK_COMPONENTSUPDATE", // droit créé sour Tesfri (rightManager)
          type: "subModule",
          iconClass: "mdi-clipboard-edit-outline",
        },

        beforeEnter: (to: Route, _from: Route, next: any) => {
          if (
            store.state.userContext.isAdministrator ||
            (store.state.userContext.rights.includes(to.meta?.rightName) &&
              store.state.userContext.functionalities.includes(to.meta?.rightName))
          ) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
      {
        path: "articleSearch",
        name: "articleSearch",
        component: AppArticleSearch,
        meta: {
          breadcrumb: [
            {
              text: "Recherche article",
              to: "/Stock/articleSearch",
            },
          ],
          rightName: "_STOCK_ARTICLESEARCH", // droit créé sour Tesfri (rightManager)
          type: "subModule",
          iconClass: "mdi-card-search",
          featureFlipping: { key: "articleSearch" },
        },

        beforeEnter: (to: Route, _from: Route, next: any) => {
          if (
            store.state.userContext.isAdministrator ||
            (store.state.userContext.rights.includes(to.meta?.rightName) &&
              store.state.userContext.functionalities.includes(to.meta?.rightName))
          ) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
      {
        path: "storageShippingUnits",
        name: "storageShippingUnits",
        component: AppStorageShippingUnits,
        meta: {
          breadcrumb: [
            {
              text: "Stockage UE",
              to: "/Stock/storageShippingUnits",
            },
          ],
          rightName: "_STOCK_STORAGESHIPPINGUNITS", // droit créé sour Tesfri (rightManager)
          type: "subModule",
          iconClass: "mdi-dolly",
          featureFlipping: { key: "storageShippingUnits" },
        },

        beforeEnter: (to: Route, _from: Route, next: any) => {
          if (
            store.state.userContext.isAdministrator ||
            (store.state.userContext.rights.includes(to.meta?.rightName) &&
              store.state.userContext.functionalities.includes(to.meta?.rightName))
          ) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
      {
        path: "storageShippingUnitsConsultation",
        name: "storageShippingUnitsConsultation",
        component: AppStorageShippingUnitsConsultation,
        meta: {
          breadcrumb: [
            {
              text: "Consultation Stockage UE",
              to: "/Stock/storageShippingUnitsConsultation",
            },
          ],
          rightName: "_STOCK_STORAGESUCONSULTATION", // droit créé sour Tesfri (rightManager)
          type: "subModule",
          iconClass: "mdi-table-eye",
          featureFlipping: { key: "storageShippingUnits" },
        },

        beforeEnter: (to: Route, _from: Route, next: any) => {
          if (
            store.state.userContext.isAdministrator ||
            (store.state.userContext.rights.includes(to.meta?.rightName) &&
              store.state.userContext.functionalities.includes(to.meta?.rightName))
          ) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
    ],
  },
  {
    path: "/stock",
    meta: {
      type: "module",
      breadcrumb: [
        {
          text: "Préparation",
        },
      ],
      iconClass: "mdi-cart",
    },
    component: PrivateLayout,

    children: [
      {
        path: "orderPreparation",
        name: "orderPreparation",
        component: AppOrderPreparation,
        meta: {
          breadcrumb: [
            {
              text: "Commandes",
              to: "/Stock/orderPreparation",
            },
          ],
          rightName: "_STOCK_ORDERPREPARATION", // droit créé sour Tesfri (rightManager)
          type: "subModule",
          iconClass: "mdi-cart-arrow-down",
        },

        beforeEnter: (to: Route, _from: Route, next: any) => {
          if (
            store.state.userContext.isAdministrator ||
            (store.state.userContext.rights.includes(to.meta?.rightName) &&
              store.state.userContext.functionalities.includes(to.meta?.rightName))
          ) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
      {
        path: "shippingUnitToAssign",
        name: "shippingUnitToAssign",
        component: AppShippingUnitToAssign,
        meta: {
          breadcrumb: [
            {
              text: "Affectation UE",
              to: "/Stock/shippingUnitToAssign",
            },
          ],
          rightName: "_STOCK_SHIPPINGUNITTOASSIGN", // droit créé sour Tesfri (rightManager)
          type: "subModule",
          iconClass: "mdi-clipboard-account",
        },

        beforeEnter: (to: Route, _from: Route, next: any) => {
          if (
            store.state.userContext.isAdministrator ||
            (store.state.userContext.rights.includes(to.meta?.rightName) &&
              store.state.userContext.functionalities.includes(to.meta?.rightName))
          ) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
      {
        path: "preparationControl",
        name: "preparationControl",
        component: AppPreparationControl,
        meta: {
          breadcrumb: [
            {
              text: "Contrôle",
              to: "/Stock/preparationControl",
            },
          ],
          rightName: "_STOCK_SHIPPINGUNITCONTROL", // droit créé sour Tesfri (rightManager)
          type: "subModule",
          iconClass: "mdi-clipboard-check",
        },

        beforeEnter: (to: Route, _from: Route, next: any) => {
          if (
            store.state.userContext.isAdministrator ||
            (store.state.userContext.rights.includes(to.meta?.rightName) &&
              store.state.userContext.functionalities.includes(to.meta?.rightName))
          ) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
    ],
  },
  {
    path: "/stock",
    meta: {
      type: "module",
      breadcrumb: [
        {
          text: "Missions Caristes",
        },
      ],
      iconClass: "mdi-forklift",
    },
    component: PrivateLayout,

    children: [
      {
        path: "operatorMissionPriority",
        name: "operatorMissionPriority",
        component: AppOperatorMissionPriority,
        meta: {
          breadcrumb: [
            {
              text: "Priorisation",
              to: "/Stock/operatorMissionPriority",
            },
          ],
          rightName: "_STOCK_OPERATORMISSIONPRIORITY", // droit créé sour Tesfri (rightManager)
          type: "subModule",
          iconClass: "mdi-priority-high",
          featureFlipping: { key: "operatorMissionPriority" },
        },

        beforeEnter: (to: Route, _from: Route, next: any) => {
          if (
            store.state.userContext.isAdministrator ||
            (store.state.userContext.rights.includes(to.meta?.rightName) &&
              store.state.userContext.functionalities.includes(to.meta?.rightName))
          ) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
    ],
  },
];

export default stockRoutes;
