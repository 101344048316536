import ControllerBase from "@/app/shared/services/controllers/controllerBase";
import UserStore from "../../../store/userStore";

export default class UserController extends ControllerBase {
  public UserStore: UserStore;

  constructor(userBaseUrl: string) {
    super();
    this.UserStore = new UserStore(userBaseUrl);
  }
}
