
import { Component, Prop } from "vue-property-decorator";
import ListComponent from "@/app/shared/components/generic/list/ListComponentV2.vue";
import moment from "moment";
import "moment/locale/fr";
// eslint-disable-next-line no-unused-vars
import preparationControlController from "../../../shared/services/controllers/preparationControl/preparationControlController";
// eslint-disable-next-line no-unused-vars
import { TypeControle } from "../../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import preparationControl from "../../../PreparationControl.vue";

moment.locale("fr");

const moduleName = "preparationControl";

@Component
export default class ByCarrier extends ListComponent {
  @Prop() declare controller: preparationControlController;
  @Prop() dateControle!: string;
  @Prop() typeControle!: TypeControle;

  onRowClicked = "";
  onRowDoubleClicked = "";

  async onGridReady(params: any) {
    let gridApi = params.api;

    this.dataSource = {
      rowCount: null,
      getRows: async (params: any) => {
        let query = this.fillListWithFilterAndSort(params);

        this.controller.Log.logDebug(`listPreparationControlByCarrier`, `Object query : ${JSON.stringify(query)}`);
        let sendResult: { data: any[]; itemsCount: number } | null = { data: [], itemsCount: 0 };

        try {
          if (this.dateControle && this.typeControle) {
            sendResult = await this.controller.preparationControlStore.getLst(this.dateControle, this.typeControle, query);
            // Affichage du transporteur = Code + Nom dans la grille
            sendResult?.data.forEach(function (element) {
              var code = element.carrierCode.length > 0 ? element.carrierCode + " - " : "";
              element.carrier = `${code}${element.carrierName} `;
            });
          }

          params.successCallback(sendResult?.data, sendResult?.itemsCount);
        } catch (error) {
          //Log
          await this.controller.Log.logError(`listPreparationControlByCarrier onGridReady`, "", error, moduleName);
          sendResult = null;
        }
      },
    };
    gridApi.setDatasource(this.dataSource);
  }

  /**
   * rafraichissement de la liste
   */
  refreshList() {
    this.refreshData();
  }

  onRowSelected(row: any) {
    if (row.node.isSelected()) {
      (this.$parent as preparationControl).current_step = 2;
      (this.$parent as preparationControl).plan = row.data;
    }
  }

  async beforeMount() {
    this.columnDefs = [
      // id transportier si renseigné
      this.numericColumn("Id", {
        headerName: "Id transporteur",
        field: "Id",
        hide: true,
        cellRenderer: this.gridOptions.components.loadingRenderer,
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
      }),
      this.textColumn("carrier", {
        headerName: "Transporteur",
        field: "carrier",
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("licensePlate", {
        headerName: "Immatriculation",
        field: "licensePlate",
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("deliveryDate", {
        headerName: "Date livraison",
        field: "deliveryDate",
        floatingFilter: false,
        sortable: false,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("collectionDate", {
        headerName: "Date enlèvement",
        field: "collectionDate",
        floatingFilter: false,
        sortable: false,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
    ];

    //this.gridOptions.rowSelection = "multiple";
    this.gridOptions.rowMultiSelectWithClick = true;

    //modification pour permettre l'infiniteScrolling
    this.pagination = true;
    this.gridOptions.infiniteInitialRowCount = 20;
    this.gridOptions.maxBlocksInCache = 20;
    this.gridOptions.cacheOverflowSize = 20;
    this.gridOptions.maxConcurrentDatasourceRequests = 5;

    // on conditionne le nombre de ligne affiché en fonction du device
    if (this.$vuetify.breakpoint.smAndDown) {
      this.gridOptions.paginationPageSize = 5;
      this.pagination = true;
    } else {
      this.gridOptions.paginationPageSize = 20;
    }

    this.gridOptions.cacheBlockSize = 20;

    // Suppression des boutons clear filtre
    this.clearFilterButtonVisibility = false;
  }

  mounted() {
    (this.$parent as preparationControl).clearCabAndPlan();
    (this.$parent as preparationControl).refreshListPallets();
  }
}
