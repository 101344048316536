
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ItemDeleteComponent extends Vue {
  @Prop() item!: any;
  dialogDelete = false;
  isMobile = false;

  deleteItem() {
    this.$emit("onDeleteItem", this.item);
    this.dialogDelete = false;
  }
}
