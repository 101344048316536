
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { Localization } from "@/app/stock/shared/store/Types";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class OperatorMissionPriorityPalletsDetailDataTableLocalizationRenderer extends Vue {
  @Prop() props!: any;
  @Prop() controller!: StockController;

  loading = false;
  localizations: Localization[] = [];
  localizationSelected: Localization | null = null;

  filterFindLocalization(item: any, queryText: string, itemText: string) {
    return (
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  /**
   * Chargement de la liste des zones par appel a l'API
   */
  async setLocalizationsList() {
    this.loading = true;
    try {
      this.controller.Log.logDebug(`zoneSelectRederer setLocalizationsList`, "");

      let sendResult: { data: Localization[]; itemsCount: number } = await this.controller.StockStore.getLocalizations();

      this.localizations = sendResult.data;
    } catch (error) {
      //Log
      await this.controller.Log.logError(`zoneSelectRederer setLocalizationsList`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }

  /**
   * Appel au set des items lors de la prise de focus sur la combobox
   */
  onFocus() {
    if (this.localizations.length === 0) {
      this.setLocalizationsList();
    }
  }
  /**
   * Mise a jours de la palette avec la zone selectionné
   */
  async updateLocalization() {
    this.controller.EventBus.$emit("operatorMissionPriorityUpdateLocalizationPallet", this.props.item, this.localizationSelected);
  }

  mounted() {
    this.localizationSelected = this.props.value;
  }
}
