import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import "devextreme/dist/css/dx.light.css";

import Vue from "vue";

// import de vue-cookiesw
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
// set default config
Vue.$cookies.config("7d");

// import de vue-feature-flipping
import FeatureFlipping, { setEnabledFeatures } from "vue-feature-flipping";
Vue.use(FeatureFlipping);

// Doc :
// https://www.npmjs.com/package/vue-feature-flipping

// liste des cles de feature flipping en fonction de l'environement
import { featureFlippingKeys, logLevel } from "@/environment/environment";
// TODO : process a ameliorer. genre aller chercher dans une API de feature flipping
setEnabledFeatures(featureFlippingKeys);

// CSS
import "vue-multiselect/dist/vue-multiselect.min.css";
import "ag-grid-autocomplete-editor/dist/main.css";

// Fontawesome
import "@fortawesome/fontawesome-pro/css/all.css";
import "@fortawesome/fontawesome-pro/js/all.js";

//Vuetify
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

import "@/assets/animate.scss";

import "@/main.scss";

// Vue de base
import App from "./app/App.vue";

import "@/app/shared/services/registerServiceWorker";
import router from "@/app/app-routes";
import store from "@/app/app-state";
import services from "@/app/app-services";

// JS
import "vue-multiselect/dist/vue-multiselect.min.js";

// VeeValidate
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

// Add the required rule
extend("required", {
  ...required,
  message: "{_field_} est requis",
});

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

import test from "./directives/test";
Vue.directive("test", test);

Vue.config.productionTip = false;

import DevExtreme from "./plugins/devExtreme";

import VueLogger from "vuejs-logger";
const options = {
  isEnabled: true,
  logLevel: logLevel,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Vue.use(VueLogger as any, options);

store
  .dispatch("userContext/initContextStateWithCookies")
  .then()
  .catch((err: string) => {
    Vue.$log.error(err);
  })
  .finally(() => {
    services.start();
    new Vue({
      router,
      store,
      created() {
        // Init devExtreme
        DevExtreme.init();
      },
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  });
