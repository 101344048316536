
import { Component, Vue, Prop } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  ShippingUnitForStorage,
  // eslint-disable-next-line no-unused-vars
  SUStorageArea,
  // eslint-disable-next-line no-unused-vars
  WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage,
} from "../../shared/types/ShippingUnit";
import SliderCardPreparation from "./SliderCardPreparation.vue";
// eslint-disable-next-line no-unused-vars
import { isEqual } from "lodash";
@Component({
  components: { SliderCardPreparation },
})
export default class CardExpander extends Vue {
  @Prop() shippingUnitForStorage!: ShippingUnitForStorage | WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage;
  @Prop() loading!: boolean;
  @Prop({ default: true }) expanded!: boolean;
  @Prop() updatedStorageArea!: SUStorageArea;
  @Prop({ default: false }) swipeable!: boolean;

  private expand = true;

  get title() {
    let title: any;
    if (this.shippingUnitForStorage.weeklyDeliveryPlanningId != null)
      title = `Cadencier ${this.shippingUnitForStorage.weeklyDeliveryPlanningCode}`;
    else if (this.shippingUnitForStorage.planId != null) title = `Plan ${this.shippingUnitForStorage.planNumber}`;
    else if (this.shippingUnitForStorage.noteId != null) title = `Bon ${this.shippingUnitForStorage.noteNumber}`;
    else title = "";
    return title;
  }

  get isHighlighted(): boolean {
    let found = false;
    // let counter = 0;
    // if (SUStorageArea == typeof this.shippingUnitForStorage  && this.shippingUnitForStorage.suStorageAreas == null) return found;
    // while (!found && this.shippingUnitForStorage.suStorageAreas.length < counter) {
    //   found = this.shippingUnitForStorage.suStorageAreas[counter].aisleCode == this.updatedStorageArea.aisleCode;
    //   if (found) (this.$refs[`suStorageArea-${counter}`] as HTMLElement).classList.add("highlighted");
    //   counter++;
    // }
    return found;
  }

  getColor(recipient: any) {
    // si 0/X Ue faite alors en rouge
    // si 1à X-1 /X UE fait alors c'est en cour en bleu
    // si X/X UE fait alors en vert
    let color = "blue--text";

    if (recipient.stockedShippingUnitCount === 0) {
      color = "red--text";
    } else if (recipient.stockedShippingUnitCount === recipient.totalShippingUnit) {
      color = "green--text";
    }

    return color;
  }

  swipe() {
    if (this.swipeable) {
      (this.$parent?.$parent?.$parent as any).switchToConsultationList();
    }
  }

  beforeMount() {
    this.expand = this.expanded;
  }
}
