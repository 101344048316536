
import Component from "vue-class-component";
import { Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  Localization,
} from "../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import { DataTableHeader } from "vuetify";
// eslint-disable-next-line no-unused-vars
import LocalizationWithQuantity from "../../shared/types/LocalizationWithQuantity";
// eslint-disable-next-line no-unused-vars
import { FieldType, Filter, Operator } from "@/app/shared/components/generic/list/V3/Type";
// eslint-disable-next-line no-unused-vars
import OptionalFilter from "../../shared/types/OptionalFilter";

@Component({
  computed: mapState("OperatorMissionPriorityModule", { zoneFilterSelected: "selectedZonesFilter" }),
  methods: {
    ...mapActions("OperatorMissionPriorityModule", ["clearOptionalFilters", "resetSearchToLastFilter"]),
    ...mapMutations("OperatorMissionPriorityModule", ["addQueryFilter", "setOptionalFilters"]),
  },
  components: {},
})
export default class OperatorMissionPriorityZoneFilters extends Vue {
  @Prop() controller!: StockController;
  @Prop() date!: string;
  @VModel() filters!: Filter[];

  localizationPalletCountItems: LocalizationWithQuantity[] = [];

  clearOptionalFilters!: () => any;
  addQueryFilter!: (filter: Filter) => any;
  setOptionalFilters!: (optionalFilter: OptionalFilter) => any;
  resetSearchToLastFilter!: () => any;

  get zoneFilterSelected(): LocalizationWithQuantity[] {
    return this.$store.state.OperatorMissionPriorityModule.selectedZonesFilter;
  }
  set zoneFilterSelected(value: LocalizationWithQuantity[]) {
    this.$store.commit("OperatorMissionPriorityModule/setSelectedZonesFilter", value);
  }

  headers: DataTableHeader[] = [
    {
      text: "Quai",
      align: "start",
      sortable: false,
      value: `roomCode`,
      width: "55%",
    },
    { text: "Nombre palettes", align: "end", sortable: false, value: "palletCount" },
  ];

  /**
   * Selectionne une ligne au click de la ligne
   */
  rowClick(item: LocalizationWithQuantity) {
    this.zoneFilterSelected.includes(item)
      ? this.zoneFilterSelected.splice(this.zoneFilterSelected.indexOf(item), 1)
      : (this.zoneFilterSelected = [item]);
    if (this.zoneFilterSelected.length == 1) {
      this.clearOptionalFilters();
      this.addQueryFilter({
        field: `localizationId`,
        fieldType: FieldType.string,
        operator: Operator.equals,
        value: item.id,
      });
      let localization: Localization = this.zoneFilterSelected[0] as unknown as Localization;
      this.setOptionalFilters({ localization: localization });
    } else {
      this.resetSearchToLastFilter();
    }
  }

  /**
   * Récupère la liste des zones avec leur nombre de palettes respectif.
   * La récupération se déclenche lorsque la date du filtre des missions caristes change.
   */
  @Watch("date")
  async getInfos() {
    if (this.date != null) {
      let result = await this.controller.StockStore.getLocalisationListForOperatorMissionPriorisationAsync(this.date);
      if (result.status === 200) {
        this.localizationPalletCountItems = result.data;
      }
    }
  }

  mounted() {
    this.getInfos();
  }
}
