
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { Localization, PlanOrNote } from "@/app/stock/shared/store/Types";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ZoneSelectRederer extends Vue {
  @Prop() props!: any;
  @Prop() controller!: StockController;

  loading = false;
  localizations: Localization[] = [];
  localizationSelected: Localization | null = null;
  localizationSelectedInit: Localization | null = null;
  planOrNote: PlanOrNote | null = null;

  storageTypeRules = [(v: any): boolean | string => !!v || "Type de stockage est requis"];

  zoneMulti = { id: 0, roomCode: "multi", areaCode: "multi", areaLabel: "multi", aisleCode: "", locationCode: "", levelCode: "" };

  validateDialog = false;

  get defaultRendere() {
    if (this.props.value.length == 0) {
      return "";
    }
    let localisation: Localization = this.props.value[0] as Localization;

    return (this.props.item as PlanOrNote)?.isMultiLocalization
      ? "Multi"
      : [localisation.roomCode, localisation.areaCode, localisation.aisleCode].join(" - ");
  }

  /**
   * Methode de filtrage des localisation (algo defiltrage)
   */
  filterFindLocalization(item: any, queryText: string, itemText: string) {
    return (
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  /**
   * Stop la propagation de l'evenement
   * (Pour selectionner la zone dans la combo avec touche entrer sans valider la modification)
   */
  stopEventOnSelectByKeydown(event: any) {
    event.stopPropagation();
  }

  get saveDisabled() {
    // le bouton est esactivé si multi zone ou si aucune modification
    return (
      this.localizationSelected === this.zoneMulti || // on est sur Multi en selection
      this.localizationSelectedInit === this.localizationSelected || // zones initial (pas de changement de zones)
      (this.planOrNote?.planId != null && this.localizationSelected == null) || // on est sur un plan et la zones doit etre renseigné
      (this.planOrNote?.direction == 1 && this.localizationSelected == null) // cas du plan/bon en entréé et zones vide (plan obligatoire pour une entrée)
    );
  }

  saveLocalization() {
    // initialisation de la variable de confirmation
    let donePalletCount: number = this.planOrNote?.donePalletCount != undefined ? this.planOrNote.donePalletCount : 0;
    let physicalPalletCount: number = this.planOrNote?.physicalPalletCount != undefined ? this.planOrNote.physicalPalletCount : 0;
    let direction: number | undefined = this.planOrNote?.direction;

    //si c'est sur une entrée en statut « en stockage » et que l’utilisateur modifie le quai de déchargement,
    // et qu’aucune mission n’a été validée pour ce bon/plan :
    //il faut afficher un message d’avertissement pour savoir s’il faut modifier les emplacements de stockage (en face du quai)
    if (direction == 1 && physicalPalletCount != 0 && donePalletCount / physicalPalletCount > 0) {
      // ouverture de la dialog box de confirmation
      this.validateDialog = true;

      // prise de focus sur le bouton non par default
      setTimeout(() => {
        (this.$refs.validateSaveLocalisation as any).$el.focus();
      });
    } else {
      this.saveLocalizationWithConfirm();
    }
  }

  saveLocalizationWithConfirm() {
    this.controller.EventBus.$emit("operatorMissionPriorityUpdateLocalization", this.props.item, this.localizationSelected);
    // fermeture de edit dialog
    (this.$refs["dialog"] as any).isActive = false;
    this.validateDialog = false;
  }

  cancelLocalisationChange() {
    this.localizationSelected = null;
  }

  async open() {
    this.planOrNote = this.props.item as PlanOrNote;

    // chargement des zones disponible
    await this.setLocalizationsList();
    // set l'element selection seulement si il y en a qu'un

    if (this.planOrNote.isMultiLocalization) {
      this.localizationSelected = this.zoneMulti;
    } else if (this.props.value.length == 1) {
      this.localizationSelected = this.props.value[0];
    }

    // on enregistre la valeur init pour desactiver le bouton save
    this.localizationSelectedInit = this.localizationSelected;
  }

  close() {
    //vide
  }

  /**
   * Chargement de la liste des zones par appel a l'API
   */
  async setLocalizationsList() {
    this.loading = true;
    try {
      this.controller.Log.logDebug(`zoneSelectRederer setLocalizationsList`, "");

      let sendResult: { data: Localization[]; itemsCount: number } = await this.controller.StockStore.getLocalizations();

      this.localizations = sendResult.data;
    } catch (error) {
      //Log
      await this.controller.Log.logError(`zoneSelectRederer setLocalizationsList`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }
}
