
import { apiUrls } from "@/environment/environment";
import { Component, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import StockController from "../../shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { RecipientsForSUStorage, RecipientsForSUStorageWithSU, ShippingUnitForStorage } from "../../shared/types/ShippingUnit";
@Component({
  components: {},
})
export default class StorageShippingUnitsConsultationShippingUnitDetail extends Vue {
  private controller: StockController;

  constructor() {
    super();
    this.controller = new StockController(apiUrls.stock, apiUrls.user);
  }

  @Prop() shippingUnitForStorage!: ShippingUnitForStorage | null;
  @Prop() recipientsForSUStorage!: RecipientsForSUStorage | null;

  @Prop() loading!: boolean;

  recipientsForSUStorageWithSU: RecipientsForSUStorageWithSU | null = null;
  dialog = false;

  /**
   * Recuperation du detail
   */
  async getRecipientsForSUStorageWithSU() {
    await this.controller.Log.logDebug(`getWeeklyDeliveryPlanningPlanNote`, "");
    let sendResult: { data: RecipientsForSUStorageWithSU; status: number; errors: any };

    try {
      if (this.shippingUnitForStorage?.weeklyDeliveryPlanningId && this.recipientsForSUStorage) {
        sendResult = await this.controller.StockStore.getShippingUnitStorageDetailByWeeklyDeliveryPlanningId(
          this.shippingUnitForStorage.weeklyDeliveryPlanningId,
          this.shippingUnitForStorage.notesDate,
          this.recipientsForSUStorage.name
        );

        if (sendResult.status === 200) {
          this.recipientsForSUStorageWithSU = sendResult.data;
        }
      } else if (this.shippingUnitForStorage?.planId && this.recipientsForSUStorage) {
        sendResult = await this.controller.StockStore.getShippingUnitStorageDetailByPlanId(
          this.shippingUnitForStorage.planId,
          this.recipientsForSUStorage.name
        );

        if (sendResult.status === 200) {
          this.recipientsForSUStorageWithSU = sendResult.data;
        }
      } else if (this.shippingUnitForStorage?.noteId && this.recipientsForSUStorage) {
        sendResult = await this.controller.StockStore.getShippingUnitStorageDetailByNoteId(
          this.shippingUnitForStorage.noteId,
          this.recipientsForSUStorage.name
        );

        if (sendResult.status === 200) {
          this.recipientsForSUStorageWithSU = sendResult.data;
        }
      }
    } catch (error) {
      await this.controller.Log.logError(`getWeeklyDeliveryPlanningPlanNote`, "", error, "StorageShippingUnitsConsultation");
    }
  }

  getColor(recipientsForSUStorageWithSU: any) {
    // si 0/X Ue faite alors en rouge
    // si 1à X-1 /X UE fait alors c'est en cour en bleu
    // si X/X UE fait alors en vert
    let color = "blue--text";
    if (recipientsForSUStorageWithSU.stockedShippingUnitCount === 0) {
      color = "red--text";
    } else if (recipientsForSUStorageWithSU.stockedShippingUnitCount === recipientsForSUStorageWithSU.totalShippingUnit) {
      color = "green--text";
    }

    return color;
  }
  /**
   * ouverture de la dialogbox avec appel a l'api
   */
  openDetail() {
    this.getRecipientsForSUStorageWithSU();
    this.dialog = true;
  }
}
