import { ObjectDirective } from "vue";
import { Vue } from "vue-property-decorator";
const directive: ObjectDirective = {
  componentUpdated: (el, binding, vnode: any) => {
    Vue.nextTick().then(() => {
      const row: HTMLElement = el.getElementsByTagName("tr")[0],
        cols: any = row ? row.children : undefined;
      if (!cols) return;

      el.style.overflow = "hidden";

      const theadHeight = el.getElementsByTagName("thead")[0] ? el.getElementsByTagName("thead")[0].offsetHeight : 0;
      const tbodyHeight = el.getElementsByTagName("tbody")[0] ? el.getElementsByTagName("tbody")[0].offsetHeight : 0;
      const tfootHeight = el.getElementsByTagName("tfoot")[0] ? el.getElementsByTagName("tfoot")[0].offsetHeight : 0;
      const tableHeight = theadHeight + tbodyHeight + tfootHeight;

      for (let i = 0; i < cols.length; i++) {
        // suppression de div si deja existante
        Array.prototype.forEach.call(cols[i].getElementsByClassName("rc"), (a: HTMLElement) => {
          a.remove();
        });
        const div = createDiv(tableHeight);
        cols[i].appendChild(div);
        setListeners(div, row, vnode.context);
      }
    });
  },
};

export default directive;

function setListeners(div: any, row: any, context: any) {
  let pageX: any, curCol: any, nxtCol: any, curColWidth: any, nxtColWidth: any, moved: boolean;

  div.addEventListener("mousedown", function (e: any) {
    curCol = e.target.parentElement;
    curCol.style.removeProperty("min-width"); // suppression de la prop min with pour ne pas bloquer le resize colonne
    nxtCol = curCol.nextElementSibling;
    pageX = e.pageX;

    const padding = paddingDiff(curCol);
    curCol.oldDraggable = curCol.draggable;
    curCol.draggable = false;
    curColWidth = curCol.offsetWidth - padding;
    if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
  });

  // si on reclick après avoir agrandis une colonne -> on réactive les click des headers
  document.addEventListener("mousedown", function () {
    if (moved) {
      row.removeEventListener("click", handler, true);
      moved = false;
    }
  });

  div.addEventListener("mouseover", function (e: any) {
    e.target.style.borderRight = "2px solid #bcbcbc";
  });

  div.addEventListener("mouseout", function (e: any) {
    e.target.style.borderRight = "";
  });

  document.addEventListener("mousemove", function (e: any) {
    if (curCol) {
      const diffX = e.pageX - pageX;

      if (nxtCol) {
        nxtCol.style.width = nxtColWidth - diffX + "px";
      }
      curCol.style.width = curColWidth + diffX + "px";
      //Si on est en cours de resize d'une colonne on désactive toutes les actions click du header
      if (!moved) {
        row.addEventListener("click", handler, true);
        moved = true;
      }
    }
  });

  //Désactivation de l'event
  function handler(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  document.addEventListener("mouseup", function () {
    if (curCol) {
      context.selectedHeaders[curCol.cellIndex].width = curCol.offsetWidth;
      //saveheader
      context.saveHeader(curCol);
      curCol.draggable = curCol.oldDraggable;
      curCol = undefined;
    }
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  });
}
function createDiv(height: number) {
  const div: HTMLElement = document.createElement("div");
  div.classList.add("rc");
  div.style.top = "0";
  div.style.right = "-2px";
  div.style.width = "5px";
  div.style.position = "absolute";
  div.style.cursor = "col-resize";
  div.style.userSelect = "none";
  div.style.height = height + "px";
  return div;
}

function paddingDiff(col: any) {
  if (getStyleVal(col, "box-sizing") == "border-box") {
    return 0;
  }

  const padLeft = getStyleVal(col, "padding-left");
  const padRight = getStyleVal(col, "padding-right");
  return parseInt(padLeft) + parseInt(padRight);
}

function getStyleVal(elm: any, css: any) {
  return window.getComputedStyle(elm, null).getPropertyValue(css);
}
