
import { apiUrls } from "@/environment/environment";
import { Component, Vue } from "vue-property-decorator";
import StockController from "../shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { Localization, NoteHeader } from "../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import { ShippingUnitForStorage, SUStorageArea } from "../shared/types/ShippingUnit";
import StorageShippingUnitsDetail from "./components/StorageShippingUnitsDetail.vue";
import StorageShippingUnitsConsultationList from "../storageShippingUnitsConsultation/components/StorageShippingUnitsConsultationList.vue";
@Component({
  components: {
    StorageShippingUnitsDetail,
    StorageShippingUnitsConsultationList,
  },
})
export default class StorageShippingUnits extends Vue {
  private controller: StockController;
  constructor() {
    super();
    this.controller = new StockController(apiUrls.stock, apiUrls.user);
    this.success = false;
    this.error = false;
  }

  stepModel = 1;
  eanShippingUnit: string | null = null;
  eanLocalization: string | null = null;
  shippingUnitForStorage: ShippingUnitForStorage | null = null;
  storageArea: SUStorageArea | null = null;
  LocalizationFound: Localization | null = null;
  HeadersNote: NoteHeader | null = null;

  confirmChangeAddress = false; // model pour confirmation sur changement de quai
  confirmChangeStorageAddress = false; // model pour confirmation sur changement de lieu de stockage quand Ue deja stockée

  successMessage = "";
  errorMessage = "";
  success: boolean;
  error: boolean;

  shippingUnitConsultationList = false;

  /**
   * Methode du scanne de l'UE
   */
  async changeBarcodeShippingUnit() {
    await this.controller.Log.logDebug(`changeBarcodeShippingUnit`, `Object query : ${JSON.stringify(this.eanShippingUnit)}`);
    let sendResult: { data: ShippingUnitForStorage; status: number; errors: any };

    try {
      // appel API pour récupérer les infos de l'article scanne
      this.errorMessage = "";
      this.error = false;
      if (this.eanShippingUnit) {
        this.shippingUnitForStorage = null;
        sendResult = await this.controller.StockStore.getInfoShippingUnitForStorage(this.eanShippingUnit);
        if (sendResult.status === 200) {
          this.errorMessage = "";
          this.shippingUnitForStorage = sendResult.data;
          this.stepModel = 2;
        } else {
          if (sendResult.status === 404) {
            this.errorMessage = "Aucune Unité d'expedition connue avec cet identifiant";
            this.error = true;
          } else {
            this.errorMessage = sendResult.errors;
            this.error = true;
          }
          Vue.$log.debug(sendResult.errors);
          (this.$refs.eanShippingUnit as any).$refs.input.select();
        }
      }
    } catch (error) {
      await this.controller.Log.logError(`changeBarcodeShippingUnit`, "", error, "StorageShippingUnits");
    }
  }

  /**
   * Méthode appelée lors du scan d'emplacement
   */
  async changeBarcodeLocalization() {
    await this.controller.Log.logDebug(`changedEanLocalization`, `Object query : ${JSON.stringify(this.eanShippingUnit)}`);
    let resultStoring: { status: number; errors: any };
    let resultGetLocalization: { data: Localization; status: number; errors: any };
    let resultCreateHeader: { data: NoteHeader; status: number; errors: any };
    this.successMessage = "";
    this.errorMessage = "";
    this.error = false;
    this.success = false;

    try {
      if (this.eanLocalization) {
        resultGetLocalization = await this.controller.StockStore.getLocalizationFromBarcode(this.eanLocalization);
        if (resultGetLocalization.status === 200) {
          // date au format ISO et sans l'heure
          let today = new Date().toISOString().substring(0, 10) as string;
          this.LocalizationFound = resultGetLocalization.data;
          // pas de création de bon s'il est déjà créé.
          const date = new Date(this.HeadersNote?.noteDate as string);
          if (this.HeadersNote == null || (date.toISOString().substring(0, 10) as string) < today) {
            resultCreateHeader = await this.controller.StockStore.createHeader(today, 31);
            // Si le bon a bien été créé (201 - created)
            if (resultCreateHeader.status === 201) {
              this.HeadersNote = resultCreateHeader.data;
            } else {
              // traitement de l'erreur et affichage du message d'erreur
              this.errorMessage = resultCreateHeader.errors;
              this.error = true;
              return;
            }
          }
          if (this.shippingUnitForStorage != null) {
            if (
              this.shippingUnitForStorage.localization != null &&
              this.LocalizationFound.id != this.shippingUnitForStorage.localization.id
            ) {
              // Si stockage a une autre adresse que celle deja stocké : demande de confirmation
              this.confirmChangeStorageAddress = true;
            } else {
              // Si l'adresse de destination n'est pas renseigné on affecte directememnt
              if (
                this.shippingUnitForStorage.destinationLocalization === null ||
                this.LocalizationFound.id == this.shippingUnitForStorage.destinationLocalization.id
              ) {
                resultStoring = await this.controller.StockStore.stockShippingUnitForStorageOnLocalization(
                  this.shippingUnitForStorage.id,
                  this.LocalizationFound.id,
                  this.HeadersNote.id
                );
                if (resultStoring.status === 200) {
                  this.successMessage = "L'unité d'expédition a été stockée.";
                  this.success = true;
                  await this.changeBarcodeShippingUnit();
                  this.goStep1();
                } else {
                  this.errorMessage = resultStoring?.errors;
                  this.error = true;
                  Vue.$log.debug(resultStoring.errors);
                }
              } else {
                this.confirmChangeAddress = true;
              }
            }
          }
        } else {
          if (resultGetLocalization.data) {
            let listError = resultGetLocalization.errors;
            if (resultGetLocalization.errors) {
              listError = resultGetLocalization.errors;
            }
            this.errorMessage = "";
            Object.entries(listError).forEach(([key, value]) => {
              if (key == "barCode" || key == "barCode") key = "Code à barre";
              this.errorMessage += `${key}: ${value}\n`;
            });
            // Affichage de la notif d'erreur
            this.error = true;
            (this.$refs.eanLocalization as any).$refs.input.select();
          }
          Vue.$log.debug(resultGetLocalization.errors);
        }
      }
    } catch (error) {
      await this.controller.Log.logError(`changeBarcodeShippingUnit`, "", error, "StorageShippingUnits");
    }
  }

  /**
   * Remplace l'adresse de l'unité d'expédition.
   */
  private async ReplaceAddressToShippingUnit(confirm: boolean) {
    let sendResult;
    this.error = false;
    this.success = false;
    this.successMessage = "";
    this.errorMessage = "";
    try {
      if (confirm) {
        if (this.shippingUnitForStorage != null && this.HeadersNote != null && this.LocalizationFound != null) {
          sendResult = await this.controller.StockStore.stockShippingUnitForStorageOnLocalization(
            this.shippingUnitForStorage.id,
            this.LocalizationFound.id,
            this.HeadersNote.id
          );
        }
        if (sendResult?.status === 200) {
          this.successMessage = "L'unité d'expédition est affecté à la nouvelle zone.";
          this.success = true;
          await this.changeBarcodeShippingUnit();
          this.goStep1();
        } else {
          this.errorMessage = sendResult?.errors;
          this.error = true;
        }
      } else {
        (this.$refs.eanLocalization as any).focus();
      }
    } catch (error) {
      this.errorMessage = sendResult?.errors;
      this.error = true;
      await this.controller.Log.logError(`ReplaceAddressToShippingUnit`, "", error, "StorageShippingUnits");
    }
    this.confirmChangeAddress = false;
    this.confirmChangeStorageAddress = false;
  }

  goStep1() {
    if (this.stepModel == 1) {
      this.shippingUnitForStorage = null;
      this.LocalizationFound = null;
    }

    this.eanShippingUnit = null;
    this.eanLocalization = null;

    this.stepModel = 1;
    (this.$refs.eanShippingUnit as any).focus();
  }

  /**
   * Ouverture de la liste de consultation des unités de stockage
   */
  switchToConsultationList() {
    this.shippingUnitConsultationList = true;
    (this.$refs.consultList as StorageShippingUnitsConsultationList).openExpander();
  }

  /**
   * Fermeture de la liste de consultation des unités de stockage
   */
  goBackFromConsultationList() {
    this.shippingUnitConsultationList = false;
    switch (this.stepModel) {
      case 1:
        (this.$refs.eanShippingUnit as any).focus();
        break;
      case 2:
        (this.$refs.eanLocalization as any).focus();
        break;

      default:
        break;
    }
  }
}
