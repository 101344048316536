import Vue from "vue";
import VueRouter from "vue-router";
import { loginRoutes } from "@/app/login";
import { homeRoutes } from "@/app/home";
import { testRoutes } from "@/app/test";
import { stockRoutes } from "@/app/stock";
import { settingsRoutes } from "@/app/settings";
import { helpRoutes } from "@/app/help";
import { PageNotFound } from "@/app/shared/components";
import { PageUnauthorized } from "@/app/shared/components";

import { isEnabled } from "vue-feature-flipping";

import store from "@/app/app-state";

Vue.use(VueRouter);

const appRoutes = [
  {
    path: "/",
    name: "home",
    redirect: "/Home",
  },
  {
    path: "*",
    name: "page-not-found",
    component: PageNotFound,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/Unauthorized",
    name: "page-unauthorized",
    component: PageUnauthorized,
    meta: {
      allowAnonymous: true,
    },
  },
];

// const routes = [...loginRoutes, ...homeRoutes, ...appRoutes, ...stockRoutes, ...settingsRoutes, ...helpRoutes, ...testRoutes];
const routes = [...loginRoutes, ...homeRoutes, ...appRoutes, ...stockRoutes, ...settingsRoutes, ...helpRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  // verification de la feature active
  if (to.matched.some((record) => record.meta.featureFlipping) && !isEnabled(to.meta?.featureFlipping.key)) {
    next({ path: "/" }); // redirection vers home si feature non active
  } else {
    // appel de la mise a jour du token et des droits dans le state
    const isAuthenticated = store.getters["userContext/isAuthenticated"];

    if (to.matched.some((record) => record.meta.allowAnonymous)) {
      if (to.name == "login" && isAuthenticated) {
        let urlredirect = "/";
        if (to.query.redirectUri != undefined) {
          urlredirect = to.query.redirectUri as string;
        }
        next({ path: urlredirect });
      } else {
        next();
      }
    } else {
      if (isAuthenticated) {
        //TODO : voir si ca a du sens
        // if (isAuthorized) {
        next();
        // } else {
        //   next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        // }
      } else {
        // redirection vers la page de login
        const redirectUri = to.fullPath;
        next({ name: "login", query: { redirectUri: redirectUri } });
      }
    }
  }
});

export default router;
