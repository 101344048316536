
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ButtonBadgeRenderer extends Vue {
  private params: any;
  // getter
  get badgeVariant() {
    if (!this.params.value) {
      return "light";
    } else {
      switch (this.params.value) {
        case "IN":
          return "white";
        case "EN":
          return "orange";
        case "PA":
          return "red";
        case "CO":
          return "green";
        default:
          return "";
      }
    }
  }

  // getter
  get icoClass() {
    if (!this.params.value) {
      return "";
    } else {
      switch (this.params.value) {
        case "IN":
          return "fa-light fa-play";
        case "EN":
          return "fa-light fa-forward";
        case "PA":
          return "fa-light fa-pause";
        case "CO":
          return "fa-light fa-check";
        default:
          return "fa-light fa-question";
      }
    }
  }
}
