import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=36cf2d64&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=ts&"
export * from "./Home.vue?vue&type=script&lang=ts&"
import style0 from "./home.scss?vue&type=style&index=0&id=36cf2d64&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36cf2d64",
  null
  
)

export default component.exports