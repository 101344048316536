
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class OrderEditRenderer extends Vue {
  @Prop() props!: any;
  @Prop() controller!: any;

  valueSelect: number | string = "";
  streamTypeRules = [(v: number): boolean | string => !!v || "Type de flux est requis"];

  get saveDisabled() {
    return !this.fielValid;
  }
  get fielValid() {
    return this.valueSelect != null && this.valueSelect !== "" && this.valueSelect > 0;
  }

  /**
   * Stop la propagation de l'evenement
   */
  stopEventOnSelectByKeydown(event: any) {
    event.stopPropagation();
  }

  saveOrderer() {
    if (this.fielValid) {
      this.controller.EventBus.$emit("operatorMissionPriorityChangeOrderByEditValue", this.props.item, this.valueSelect);
      (this.$refs["dialog"] as any).isActive = false; // fermeture de edit dialog
    }
  }
  cancelOrderer() {
    // On fait rien
  }
  open() {
    this.valueSelect = this.props.value;
    // On fait rien
  }
  close() {
    // On fait rien
  }

  greet() {
    Vue.$log.debug();
  }
}
