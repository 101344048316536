
import { Component, Vue } from "vue-property-decorator";

import HomeFavoris from "./components/HomeFavoris.vue";

@Component({
  components: {
    HomeFavoris,
  },
})
export default class Home extends Vue {
  get name() {
    return this.$store.state.userContext.name;
  }

  get surname() {
    return this.$store.state.userContext.surname;
  }
}
