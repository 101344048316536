import Vue from "vue";
import Vuex from "vuex";
import { StateType } from "./shared/state/userContextState";
import userContext from "./shared/state/userContextState";
import OperatorMissionPriorityModule, { StateDefinition } from "./shared/state/OperatorMissionPriority";

import { Module } from "vuex";
Vue.use(Vuex);

export interface RootState {
  userContext: StateType;
  drawerContext: DrawerType;
  OperatorMissionPriorityModule: StateDefinition;
}

export interface DrawerType {
  leftDrawer: boolean;
  rightDrawer: boolean;
}

const drawerContext: Module<DrawerType, RootState> = {
  namespaced: true,
  getters: {
    rightDrawerState: (state) => state.rightDrawer,
    leftDrawerState: (state) => state.leftDrawer,
  },
  state: {
    leftDrawer: true,
    rightDrawer: false,
  },
  mutations: {
    CHANGE_LEFT_DRAWER(state, data) {
      state.leftDrawer = data;
    },
    CHANGE_RIGHT_DRAWER(state, data) {
      state.rightDrawer = data;
    },
  },
};

export default new Vuex.Store<RootState>({
  modules: {
    userContext,
    drawerContext,
    OperatorMissionPriorityModule,
  },
});
