import Home from "./Home.vue";
import Privacy from "./privacy/Privacy.vue";
import Profile from "./profile/Profile.vue";
import PrivateLayout from "@/app/layouts/Private.vue";

const HomeRoutes = [
  {
    path: "/Home",
    name: "home",
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: "/Home",
        },
      ],
    },
    component: PrivateLayout,

    children: [
      {
        path: "",
        name: "accueil",
        component: Home,
        meta: {
          allowAnonymous: false,
          breadcrumb: [
            {
              text: "Accueil",
              to: "/Home",
              active: true,
            },
          ],
        },
      },
      {
        path: "Privacy",
        name: "privacy",
        component: Privacy,
        meta: {
          allowAnonymous: false,
          breadcrumb: [
            {
              text: "Politique de confidentialité",
              to: "/Home/Privacy",
            },
          ],
        },
      },
    ],
  },
  {
    path: "/Profile",
    name: "profile",
    meta: {
      breadcrumb: [
        {
          text: "Tesfri",
        },
      ],
    },
    component: PrivateLayout,

    children: [
      {
        path: "",
        component: Profile,
        meta: {
          allowAnonymous: false,
          breadcrumb: [
            {
              text: "",
            },
          ],
        },
      },
    ],
  },
];

export default HomeRoutes;
