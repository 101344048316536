import Login from "./Login.vue";
import PublicLayout from "@/app/layouts/Public.vue";
import store from "@/app/app-state";
import { Route } from "vue-router";

import Vue from "vue";

const LoginRoutes = [
  {
    path: "/Login",
    meta: {
      allowAnonymous: true,
      breadcrumb: [
        {
          text: "Login",
          to: "/Login",
          active: true,
        },
      ],
    },

    component: PublicLayout,

    children: [
      {
        name: "login",
        path: "",
        component: Login,
      },
    ],
  },

  {
    path: "/Auto",
    meta: {
      allowAnonymous: true,
      breadcrumb: [
        {
          text: "auto",
          to: "/Auto",
          active: true,
        },
      ],
    },

    component: PublicLayout,

    children: [
      {
        name: "auto",
        path: "",
        component: Login,

        beforeEnter: (to: Route, _from: Route, next: any) => {
          let missingParameters = false; // variable pour teste des parametres manquant

          // Recuperation de la route de redirection
          let urlredirect: string | (string | null)[] = "/";
          if (to.query && to.query.redirectUri != undefined) {
            urlredirect = to.query.redirectUri;
          }

          interface QueryInfo {
            key: string | null;
            companyCode: string | null;
            warehouseCode: string | null;
          }
          // Recuperation des parametres de la queryString
          const queryInfo: QueryInfo = {
            key: null,
            companyCode: null,
            warehouseCode: null,
          };

          if (to.query && to.query.key != undefined) {
            queryInfo.key = to.query.key.toString();
          } else {
            missingParameters = true;
          }
          if (to.query && to.query.companyCode != undefined) {
            queryInfo.companyCode = to.query.companyCode.toString();
          } else {
            missingParameters = true;
          }
          if (to.query && to.query.warehouseCode != undefined) {
            queryInfo.warehouseCode = to.query.warehouseCode.toString();
          } else {
            missingParameters = true;
          }

          if (!missingParameters) {
            store
              .dispatch("userContext/autoLogin", queryInfo)
              .then(() => {
                Vue.$log.debug("queryInfo", queryInfo);
                next({ path: urlredirect });
              })
              .catch(() => {
                //TODO : ajouter un message d'erreur sur la page
                Vue.$log.error("authentificationAuto failed");
              });
          } else {
            // add missing parameters message to debug
            Vue.$log.error("missing parameters message");
            // redirect to login page
            next({ path: urlredirect });
          }
        },
      },
    ],
  },
];

export default LoginRoutes;
