
import { Component, Prop, Watch } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import ListVuetify, { HeadersMap } from "@/app/shared/components/generic/list/V3/ListComponentV3.vue";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";

// eslint-disable-next-line no-unused-vars
import { FieldType, Filter } from "@/app/shared/components/generic/list/V3/Type";
import { Notification, NotificationType } from "@/app/shared/components/generic/list/V3/components/Notification.vue";

import OperatorSelectRederer from "./renderer/OperatorSelectRederer.vue";
import ZoneSelectRederer from "./renderer/ZoneSelectRederer.vue";
import OrderEditRenderer from "./renderer/OrderEditRenderer.vue";
import AdvancementRederer from "./renderer/AdvancementRederer.vue";
import OperatorMissionPriorityDatatableNoteNumberRenderer from "./renderer/OperatorMissionPriorityDatatableNoteNumberRenderer.vue";
import SensRederer from "./renderer/SensRederer.vue";

// eslint-disable-next-line no-unused-vars
import { Localization, Operator, PlanOrNote, QueryUserPref } from "../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import { UserPreference } from "@/app/shared/components/generic/list/V3/ListComponentV3.vue";

import { isEnabled } from "vue-feature-flipping";

import Vue from "vue";

moment.locale("fr");

const moduleName = "Stock";
const subModuleName = "OperatorMisionPriority";
const labelDataType = "ListOperatorMissionPriority";

@Component({
  components: {
    OperatorSelectRederer,
    OrderEditRenderer,
    ZoneSelectRederer,
    AdvancementRederer,
    OperatorMissionPriorityDatatableNoteNumberRenderer,
    SensRederer,
  },
})
export default class ListOperatorMissionPriority extends ListVuetify {
  @Prop() controller!: StockController;
  @Prop() filterDate!: string;
  @Prop() filterPalletNumber!: string | null;
  @Prop() optionalFilters!: Filter[];

  @Prop() noteSelected!: PlanOrNote | null;
  @Prop({ default: false }) isDetailsOpened!: boolean;

  currentCompany: string = this.$store.state.userContext.currentCompany;
  currentWarehouse: string = this.$store.state.userContext.currentWarehouse;

  @Watch("filterDate")
  // eslint-disable-next-line no-unused-vars
  onPropertyChanged(value: number, oldValue: number) {
    this.expandDetail = null;
    this.expanded = [];
  }

  //#region initialisation des variables heritées
  // header du tableau
  headersMap: HeadersMap[] = this.getcolumns();
  // option du tableau
  expandable = true;
  defaultExpandable = false;
  itemKey = "orderNum";
  paginate = false;
  dragableRow = true;
  //#endregion initialisation des variables heritées

  // plan/bon detail deja ouvert
  expandedPlanOrNote: PlanOrNote | null = null;
  /**
   * OverRide de la methode getDataFromApi
   */

  getcolumns(): HeadersMap[] {
    Vue.$log.debug("featureFlag Cariste ? ", isEnabled("operatorMissionPriorityOperatorSelect"));

    return isEnabled("operatorMissionPriorityOperatorSelect") === true
      ? [
          {
            text: "Ordre", //--> Rowcount
            value: "orderNum",
            fieldType: FieldType.number,
            align: "center",
            sortable: false,
            groupable: false,
            filterable: false,
            hideable: false,
            renderer: { custom: { component: OrderEditRenderer } },
            class: "fixed-column",
          },
          {
            text: "Ordre global", //--> OrderNum
            value: "orderGlobal",
            fieldType: FieldType.number,
            align: "center",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "Id plan",
            value: "planId",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "N° plan",
            value: "data-table-expand",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Id bon",
            value: "noteId",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "N° bon",
            value: "noteNumber",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            renderer: {
              custom: { component: OperatorMissionPriorityDatatableNoteNumberRenderer, action: this.setNoteNumber },
            },
          },
          {
            text: "Date de bon",
            value: "planNoteDate",
            fieldType: FieldType.date,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Sens",
            value: "direction",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            renderer: { custom: { component: SensRederer } },
          },
          {
            text: "D.O.",
            value: "contractorwarehouse.code",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Nom du D.O.",
            value: "contractorwarehouse.name",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "N° commande",
            value: "orderNumber",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Statut du bon",
            value: "noteStatus",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Transporteur",
            value: "carrierName",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Date d'arrivée",
            value: "estimatedArrivalDate",
            fieldType: FieldType.date,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "Heure d'arrivée",
            value: "estimatedArrivalTime",
            fieldType: FieldType.time,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "Palettes",
            value: "palletCount",
            fieldType: FieldType.number,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Colis",
            value: "parcelCount",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Kg net",
            value: "netWeight",
            fieldType: FieldType.decimal,
            decimalPrecision: 3,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Kg brut",
            value: "grossWeight",
            fieldType: FieldType.decimal,
            decimalPrecision: 3,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Quai",
            value: "localizations",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
            hideable: false,
            renderer: { custom: { component: ZoneSelectRederer } },
          },
          {
            text: "Cariste",
            value: "operators",
            fieldType: FieldType.number,
            align: "center",
            sortable: false,
            groupable: false,
            filterable: false,
            hideable: false,
            renderer: { custom: { component: OperatorSelectRederer } },
          },
          {
            text: "Avancement",
            value: "progress",
            fieldType: FieldType.number,
            align: "center",
            sortable: false,
            groupable: false,
            filterable: false,
            hideable: false,
            renderer: { custom: { component: AdvancementRederer } },
          },
        ]
      : [
          {
            text: "Ordre", //--> Rowcount
            value: "orderNum",
            fieldType: FieldType.number,
            align: "center",
            sortable: false,
            groupable: false,
            filterable: false,
            hideable: false,
            renderer: { custom: { component: OrderEditRenderer } },
            class: "fixed-column",
          },
          {
            text: "Ordre global", //--> OrderNum
            value: "orderGlobal",
            fieldType: FieldType.number,
            align: "center",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "Id plan",
            value: "planId",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "N° plan",
            value: "data-table-expand",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Id bon",
            value: "noteId",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "N° bon",
            value: "noteNumber",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            renderer: {
              custom: { component: OperatorMissionPriorityDatatableNoteNumberRenderer, action: this.setNoteNumber },
            },
          },
          {
            text: "Date de bon",
            value: "planNoteDate",
            fieldType: FieldType.date,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Sens",
            value: "direction",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            renderer: { custom: { component: SensRederer } },
          },
          {
            text: "D.O.",
            value: "contractorwarehouse.code",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Nom du D.O.",
            value: "contractorwarehouse.name",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "N° commande",
            value: "orderNumber",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Statut du bon",
            value: "noteStatus",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Transporteur",
            value: "carrierName",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Date d'arrivée",
            value: "estimatedArrivalDate",
            fieldType: FieldType.date,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "Heure d'arrivée",
            value: "estimatedArrivalTime",
            fieldType: FieldType.time,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
            hide: true,
          },
          {
            text: "Palettes",
            value: "palletCount",
            fieldType: FieldType.number,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Colis",
            value: "parcelCount",
            fieldType: FieldType.string,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Kg net",
            value: "netWeight",
            fieldType: FieldType.decimal,
            decimalPrecision: 3,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Kg brut",
            value: "grossWeight",
            fieldType: FieldType.decimal,
            decimalPrecision: 3,
            align: "end",
            sortable: false,
            groupable: false,
            filterable: false,
          },
          {
            text: "Quai",
            value: "localizations",
            fieldType: FieldType.string,
            align: "start",
            sortable: false,
            groupable: false,
            filterable: false,
            hideable: false,
            renderer: { custom: { component: ZoneSelectRederer } },
          },
          {
            text: "Avancement",
            value: "progress",
            fieldType: FieldType.number,
            align: "center",
            sortable: false,
            groupable: false,
            filterable: false,
            hideable: false,
            renderer: { custom: { component: AdvancementRederer } },
          },
        ];
  }

  setNoteNumber(planOrNote: PlanOrNote) {
    if (planOrNote.noteId != null) {
      this.$emit("click-on-note", planOrNote);
    }
  }

  @Watch("filterDate")
  filterDateChanged(newVal: string, oldVal: string) {
    if (newVal != oldVal) {
      this.getDataFromApi();
    }
  }

  /**
   * On raffraishi le tableau lorque le filtre change
   */
  @Watch("optionalFilters")
  optionalFiltersChanged(newVal: string[], oldVal: string[]) {
    if (newVal != oldVal) {
      this.filters = this.optionalFilters; // Todo : fusionner le filtre optionnel avec le filtre du tableau
      this.getDataFromApi();
    }
  }

  async getDataFromApi() {
    this.loading = true;
    try {
      let queryString = this.getQueryStringFilterAndSort();
      this.controller.Log.logDebug(`listOperatorMissionPriority`, `Object query : ${JSON.stringify(queryString)}`);

      let sendResult: { data: PlanOrNote[]; itemsCount: number } = await this.controller.StockStore.getOperatorMissionPriority(
        queryString,
        this.filterDate,
        this.filterPalletNumber
      );
      this.items = sendResult.data;
      this.totalItems = +sendResult.itemsCount;
    } catch (error) {
      //Log
      await this.controller.Log.logError(`listOperatorMissionPriority onGridReady`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }

  /**
   * OverRide de la methode pour afficher l'expander en fonction de la presence de prop item.notes
   */
  itemCanExpand(item: PlanOrNote) {
    return item.planId ? true : false;
  }

  /**
   * Methode pour setter le detail a afficher dans l'expander
   */
  async loadDetails(item: any) {
    let planOrNote = item.item as PlanOrNote;
    await this.getDetailOfPlanOrNote(planOrNote);
    this.expandedPlanOrNote = planOrNote;
  }

  async getDetailOfPlanOrNote(planOrNote: PlanOrNote) {
    if (planOrNote.planId) {
      this.loading = true;
      try {
        let queryString = this.getQueryStringFilterAndSort();
        this.controller.Log.logDebug(`listOperatorMissionPriority`, `Object query : ${JSON.stringify(queryString)}`);

        let sendResult: { data: PlanOrNote[]; itemsCount: number } = await this.controller.StockStore.GetListNoteOfPlan(
          planOrNote.planId,
          queryString
        );

        this.expandDetail = sendResult.data;
        this.totalItems = +sendResult.itemsCount;
      } catch (error) {
        //Log
        await this.controller.Log.logError(`listOperatorMissionPriority onGridReady`, "", error, "Stock");
      } finally {
        this.loading = false;
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  dragRowOnMove(event: any) {
    if (this.filterDate == undefined) {
      event.preventDefault();
      return false;
    }
  }

  /**
   * Fin du deplacement de ligne
   */
  async dragRowEnd(event: any) {
    let itemMoved: PlanOrNote = this.items[event.oldDraggableIndex];
    let itemsReplaced: PlanOrNote = this.items[event.newDraggableIndex];
    let newOrder = itemsReplaced.orderGlobal;
    this.items = [];
    this.operatorMissionPriorityChangeOrder(itemMoved, newOrder);
  }

  /**
   * Appel a la methode de reorganisation en passant d'un orderNum a un orderGlobal
   */
  async operatorMissionPriorityChangeOrderByEditValue(itemMoved: PlanOrNote, orderNum: number) {
    let planOrNote: PlanOrNote = this.items.find((e: PlanOrNote) => {
      return e.orderNum == orderNum;
    });
    if (planOrNote) {
      let newOrder: number = planOrNote.orderGlobal;
      await this.operatorMissionPriorityChangeOrder(itemMoved, newOrder);
    }
  }

  /**
   * Methode d'appel APi pour la reorganisation de la mission cariste
   */
  async operatorMissionPriorityChangeOrder(itemMoved: PlanOrNote, newOrder: number) {
    // on vide l'expander ouvert pour eviter que celui ci soit mal placé dans la grille
    this.expanded = [];
    let filterDate = this.filterDate;
    if (filterDate) {
      this.loading = true;
      try {
        let queryString = this.getQueryStringFilterAndSort();
        this.controller.Log.logDebug(`operatorMissionPriorityChangeOrder`, `Object query : ${JSON.stringify(queryString)}`);
        await this.controller.StockStore.operatorMissionPriorityChangeOrder(itemMoved, newOrder, filterDate);
        this.getDataFromApi();
      } catch (error) {
        //Log
        await this.controller.Log.logError(`operatorMissionPriorityChangeOrder onGridReady`, "", error, "Stock");
      }
    }
  }

  /**
   * Mise a jours de zone sur les plans/bons et bons
   */
  async operatorMissionPriorityUpdateLocalization(itemUpdate: PlanOrNote, newLocalization: Localization | null) {
    this.loading = true;
    try {
      let queryString = this.getQueryStringFilterAndSort();
      this.controller.Log.logDebug(`operatorMissionPriorityUpdateLocalization`, `Object query : ${JSON.stringify(queryString)}`);

      if (itemUpdate.planId != null && newLocalization == null) {
        let message = "Zone obligatoire pour une ligne de plan";
        this.notification = new Notification(message, NotificationType.error);
        this.notification.show();
        throw new Error(message);
      }

      await this.controller.StockStore.operatorMissionPriorityUpdateLocalization(itemUpdate, newLocalization).then(
        (r: { status: number; errors: any }) => {
          if (r.status === 200) {
            // rafraichissement de la grille
            this.getDataFromApi();
            // rafraichissement de l'expander si deja ouvert
            if (this.expandedPlanOrNote) {
              this.getDetailOfPlanOrNote(this.expandedPlanOrNote);
            }
            // refraichissement de la grille detail palette
            this.controller.EventBus.$emit("refreshDataGridPaletDetail");
          } else {
            let msgError = "";
            Object.entries(r.errors).forEach(([key, value]) => {
              msgError += `${key}: ${value}\n`;
            });
            this.notification = new Notification(`${msgError}`, NotificationType.error, 5000);
            this.notification.show();
          }
        }
      );
    } catch (error) {
      //Log
      await this.controller.Log.logError(`operatorMissionPriorityUpdateLocalization`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }

  /**
   * Methode de rafraichissement des grille principal et secondaire (expander)
   */
  refreshDataGrid() {
    this.getDataFromApi();
    // rafraichissement de l'expander si deja ouvert
    if (this.expandedPlanOrNote) {
      this.getDetailOfPlanOrNote(this.expandedPlanOrNote);
    }
  }

  /**
   * Mise a jours de caristes sur les plans/bons et bons
   */
  async operatorMissionPriorityUpdateOperators(itemUpdate: PlanOrNote, newOperators: Operator[]) {
    this.loading = true;
    try {
      let queryString = this.getQueryStringFilterAndSort();
      this.controller.Log.logDebug(`operatorMissionPriorityUpdateOperators`, `Object query : ${JSON.stringify(queryString)}`);
      itemUpdate.operators = newOperators;
      await this.controller.StockStore.operatorMissionPriorityUpdateOperators(itemUpdate).then((r: { status: number; errors: any }) => {
        if (r.status === 200) {
          // rafraichissement de la grille
          this.getDataFromApi();
          // rafraichissement de l'expander si deja ouvert
          if (this.expandedPlanOrNote) {
            this.getDetailOfPlanOrNote(this.expandedPlanOrNote);
          }
          // refraichissement de la grille detail palette
          this.controller.EventBus.$emit("refreshDataGridPaletDetail");
        } else {
          let msgError = "";
          Object.entries(r.errors).forEach(([key, value]) => {
            msgError += `${key}: ${value}\n`;
          });
          this.notification = new Notification(`${msgError}`, NotificationType.error);
          this.notification.show();
        }
      });
    } catch (error) {
      //Log
      await this.controller.Log.logError(`operatorMissionPriorityUpdateOperators`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }

  /**
   * Save user prefenreces when they changes
   */
  async saveUserPreference() {
    //Format data
    let data = { savedUserPreference: this.savedUserPreference };

    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: JSON.stringify(data),
    };

    this.controller.Log.logDebug(`saveUserPreferences`, `Object query : ${JSON.stringify(query)}`);

    try {
      await this.controller.UserStore.saveUserPreferences(query);
    } catch (error) {
      //Log
      await this.controller.Log.logError(`saveUserPreferences`, "", error, moduleName);
    }
  }

  /**
   * Set user preference sur la grille
   */
  async setUserPreferences(): Promise<UserPreference | null> {
    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: null,
    };

    let data: UserPreference | null = null;

    this.controller.Log.logDebug(`getUserPreferences`, `Object query : ${JSON.stringify(query)}`);
    let sendResult = null;
    try {
      sendResult = await this.controller.UserStore.getUserPreferences(query);

      if (sendResult?.data) {
        let userPreference = JSON.parse(sendResult.data);
        if (userPreference.savedUserPreference) {
          data = userPreference.savedUserPreference as UserPreference;
        }
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getUserPreferences`, "", error, moduleName);
      sendResult = null;
    }
    return data;
  }

  async beforeMount() {
    this.controller.EventBus.$on("operatorMissionPriorityChangeOrderByEditValue", this.operatorMissionPriorityChangeOrderByEditValue);
    this.controller.EventBus.$on("operatorMissionPriorityUpdateLocalization", this.operatorMissionPriorityUpdateLocalization);
    this.controller.EventBus.$on("operatorMissionPriorityUpdateOperators", this.operatorMissionPriorityUpdateOperators);
    this.controller.EventBus.$on("refreshDataGrid", this.refreshDataGrid);
  }
  async beforeDestroy() {
    this.controller.EventBus.$off("operatorMissionPriorityChangeOrderByEditValue", this.operatorMissionPriorityChangeOrderByEditValue);
    this.controller.EventBus.$off("operatorMissionPriorityUpdateLocalization", this.operatorMissionPriorityUpdateLocalization);
    this.controller.EventBus.$off("operatorMissionPriorityUpdateOperators", this.operatorMissionPriorityUpdateOperators);
    this.controller.EventBus.$off("refreshDataGrid", this.refreshDataGrid);
  }
}
