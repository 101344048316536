import ControllerBase from "@/app/shared/services/controllers/controllerBase";
import preparationControlStore from "../../../store/preparationControlStore";

export default class PreparationControlController extends ControllerBase {
  public preparationControlStore: preparationControlStore;

  constructor(baseUrl: string) {
    super();
    this.preparationControlStore = new preparationControlStore(baseUrl);
  }
}
