
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ItemCreateComponent extends Vue {
  dialogCreate = false;

  createItemConfirm() {
    this.$emit("onCreateItem");
    this.dialogCreate = false;
  }
}
