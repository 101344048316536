import Vue from "vue";
import RestClient from "@/app/shared/services/restClient"; // Roadmap to get api
import { AxiosInstance, AxiosResponse } from "axios";
import store from "@/app/app-state";
import { module, warehouse } from "@/app/settings/shared/ModuleInterface";
import Vuex from "vuex";

Vue.use(Vuex);

export default class ModuleStore {
  private loading: boolean;
  private restClient: AxiosInstance;

  constructor(baseUrl: string) {
    this.loading = false;
    this.restClient = new RestClient(baseUrl).Instance;
  }

  /**
   * Recuperation des modules d'un site
   * @param {boolean} onlyAuthorised
   */
  async getCompanyWarehousePackagApi(onlyAuthorised: boolean): Promise<module[]> {
    let data: module[] = [];

    await this.restClient
      .get(
        `Modules/GetModulesWarehouse?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&onlyAuthorised=${onlyAuthorised}`
      )
      .then((response: AxiosResponse) => {
        if (response.status == 200 || response.status == 204) {
          data = response.data;
        }
      });

    return data;
  }

  /**
   * Apelle methode API pour récupérer les infos du site.
   * @param currentCompany
   * @param warehouseCode
   */
  async getWarehouseInfo(): Promise<null | warehouse> {
    let data: null | warehouse = null;

    await this.restClient
      .get(
        `/Warehouses/GetWarehouse?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`
      )
      .then((response: AxiosResponse) => {
        if (response.status == 200 || response.status == 204) {
          data = response.data;
        }
      });

    return data;
  }

  /**
   * Appel methode API pour mettre a jours la liste des module du site
   * @param currentCompany
   * @param warehouseCode
   * @param selectedModules
   */
  async savePackageAPI(selectedModules: module[]): Promise<string> {
    // on revoi la liste des identifiant seulement a l'API
    const selectedModulesId = selectedModules.filter((x) => x.isWarehouseModule).map((x) => x.id);

    return new Promise((resolve, reject) => {
      this.restClient
        .put(
          `Modules/UpdateModulesWarehouse?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`,
          selectedModulesId
        )
        .then((response: AxiosResponse) => {
          if (response.status == 200 || response.status == 204) {
            resolve("Installation des modules effectué.");
          } else {
            reject(`Error ${response.status} from Module API: ${response.statusText}`);
          }
        })
        .catch((error) => {
          Vue.$log.error(error);
          reject(`Error savePackageAPI from Module API: ${error}`);
        });
    });
  }

  /**
   * Recuperation des module du package de base
   * @param currentCompany
   * @param warehouseCode
   */
  async getDefaultPackageApi(): Promise<module[]> {
    let data: module[] = [];

    await this.restClient
      .get(
        `Modules/GetStandardModules?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`
      )
      .then((response: AxiosResponse) => {
        if (response.status == 200 || response.status == 204) {
          data = response.data;
        }
      });

    return data;
  }

  /**
   * Appel methode API pour mettre a jours la liste des module du package de base
   * @param selectedModules
   */
  async saveDefaultPackageAPI(selectedModules: module[]): Promise<string> {
    return new Promise((resolve, reject) => {
      this.restClient
        .post(
          `Modules/SaveStandardModules?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`,
          selectedModules
        )
        .then((response: AxiosResponse) => {
          if (response.status == 200 || response.status == 204) {
            resolve("Installation des modules effectué.");
          } else {
            reject(`Error ${response.status} from Module API: ${response.statusText}`);
          }
        })
        .catch((error) => {
          Vue.$log.error(error);
          reject(`Error savePackageAPI from Module API: ${error}`);
        });
    });
  }

  /**
   * Enregistement des nombres de licences
   * @param currentCompany
   * @param warehouseCode
   * @param licenceNumber
   */
  async saveLicenceAPI(licenceNumber: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this.restClient
        .put(
          `Warehouses/UpdateLicences?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&licencesNumber=${licenceNumber}`
        )
        .then((response: AxiosResponse) => {
          if (response.status == 200 || response.status == 204) {
            resolve("Mise a jours du nombre de licences effectué.");
          } else {
            reject(`Error ${response.status} from Module API: ${response.statusText}`);
          }
        })
        .catch((error) => {
          Vue.$log.error(error);
          reject(`Error saveLicenceAPI from Module API: ${error}`);
        });
    });
  }

  /**
   * Activation et desactivation de site
   * @param Activate
   */
  async desactivateWarehouseAPI(Activate: boolean): Promise<string> {
    return new Promise((resolve, reject) => {
      this.restClient
        .put(
          `Warehouses/UpdateDisabledWarehouse?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&isActive=${Activate}`
        )
        .then((response: AxiosResponse) => {
          if (response.status == 200 || response.status == 204) {
            resolve("Activation/Desactivation du site effectué.");
          } else {
            reject(`Error ${response.status} from Module API: ${response.statusText}`);
          }
        })
        .catch((error) => {
          Vue.$log.error(error);
          reject(`Error desactivateWarehouseAPI from Module API: ${error}`);
        });
    });
  }
}
