
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ButtonComponent extends Vue {
  @Prop() icon!: string;
  @Prop() action!: string;
  @Prop() actionParams!: any;
  @Prop() title!: string;
  @Prop() btnClass!: string;

  id = "";

  mounted() {
    if (this.actionParams) {
      this.id = this.title + "-" + this.actionParams.id;
      //Permet de griser un bouton
      Vue.nextTick().then(() => {
        if (this.actionParams.disableButton) {
          for (let disableButton of this.actionParams.disableButton) {
            if (disableButton && disableButton.disabled) {
              document.getElementById(`#${disableButton.button}-${this.actionParams.id}`)?.setAttribute("disabled", "disabled");
            }
          }
        }
      });
    }
  }
}
