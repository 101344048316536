
import { Component, Vue } from "vue-property-decorator";
import ModalComponent from "@/app/shared/components/generic/modal/ModalComponent.vue";
import formUpdate from "../form/FormUpdate.vue";
// eslint-disable-next-line no-unused-vars
import { ThirdWarehouse } from "../../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import ControllerBase from "@/app/shared/services/controllers/controllerBase";

@Component({
  name: "modalFormUpdate",
  components: {},
})
export default class ModalFormUpdate extends ModalComponent {
  blockingType: string | null = null;
  title = "";
  get dynamicModal() {
    return formUpdate;
  }

  async showModalUpdate(queryFilterElementsUpdate: null, customer: null | ThirdWarehouse) {
    this.showModal();

    await Vue.nextTick().then(() => {
      this.controller.EventBus.$emit("loadUpdate", queryFilterElementsUpdate, customer);
    });
  }
  async closeModalUpdate() {
    this.closeModal();
  }
  validateModal() {
    this.controller.EventBus.$emit("submitFormUpdate");
  }
  async beforeMount() {
    this.controller.EventBus.$on("closeModalUpdate", this.closeModalUpdate);
    this.controller.EventBus.$on("showModalUpdate", this.showModalUpdate);
  }
  async beforeDestroy() {
    this.controller.EventBus.$off("closeModalUpdate", this.closeModalUpdate);
    this.controller.EventBus.$off("showModalUpdate", this.showModalUpdate);
  }
}
