
import Component from "vue-class-component";
import { Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { mapState, mapMutations } from "vuex";

import FilterChipsRenderer from "./renderer/FilterChipsRenderer.vue";
import OperatorMissionPriorityFiltersEdit from "./OperatorMissionPriorityFiltersEdit.vue";

// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  Localization,
  // eslint-disable-next-line no-unused-vars
  MouvementDirection,
  // eslint-disable-next-line no-unused-vars
  NoteStatus,
  // eslint-disable-next-line no-unused-vars
  QueryUserPref,
  // eslint-disable-next-line no-unused-vars
  StatisticCode,
  // eslint-disable-next-line no-unused-vars
  StreamType,
  // eslint-disable-next-line no-unused-vars
  ThirdWarehouse,
  // eslint-disable-next-line no-unused-vars
  WeeklyDeliveryPlanning,
} from "../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import { FieldType, Filter, Operator } from "@/app/shared/components/generic/list/V3/Type";
import _ from "lodash";

// eslint-disable-next-line no-unused-vars
import OptionalFilter from "../../shared/types/OptionalFilter";

// eslint-disable-next-line no-unused-vars
import UserFilter from "../../shared/types/UserFilter";

const moduleName = "Stock";
const subModuleName = "OperatorMisionPriority";
const labelDataType = "OperatorMissionPriorityFilters";

@Component({
  computed: mapState("OperatorMissionPriorityModule", { filter: "optionalFilters" }),
  methods: {
    ...mapMutations("OperatorMissionPriorityModule", [
      "setOptionalFilters",
      "setQueryFilters",
      "setFilterPalletNumber",
      "setSelectedZonesFilter",
      "extractQueryFilters",
    ]),
  },
  components: { FilterChipsRenderer, OperatorMissionPriorityFiltersEdit },
})
export default class OperatorMissionPriorityFilters extends Vue {
  @Prop() controller!: StockController;
  @Prop() filterDate!: string;
  @VModel() filters!: Filter[];

  loading = false;
  private initialFilter: OptionalFilter = {};

  /**
   * Permet de savoir si le filtre est vide (pour afficher le bouton)
   */
  get filterIsEmpty(): boolean {
    return _.isEmpty(this.filter);
  }

  /**
   * Permet de savoir si le filtre a changer d'etat
   */
  get needRefresh(): boolean {
    return !_.isEqual(this.filter, this.initialFilter);
  }

  get filter(): OptionalFilter {
    return this.$store.state.OperatorMissionPriorityModule.optionalFilters;
  }
  set filter(value: OptionalFilter) {
    this.setOptionalFilters(value);
  }

  dialog = false;
  filterDetail = false;
  panel: number[] = [0];

  setOptionalFilters!: (payload: OptionalFilter) => any;
  setQueryFilters!: (payload: Filter[]) => any;
  setSelectedZonesFilter!: (payload: Filter[]) => any;
  extractQueryFilters!: () => any;
  setFilterPalletNumber!: (payload: string | null) => any;

  get queryFilterList(): string[] {
    return this.getfilterListValue(this.filter);
  }

  get userFilterItemsWithoutDefault() {
    return this.userFilterItemsInitial.filter((elt) => {
      return elt.name.toLowerCase() != "default";
    });
  }

  get userFilterSelected(): UserFilter {
    return this.$store.state.OperatorMissionPriorityModule.usersFiltersSet;
  }

  set userFilterSelected(value: UserFilter) {
    if (typeof value === "object") {
      this.$store.commit("OperatorMissionPriorityModule/updateUsersFiltersSet", value);
    }
  }

  get isNewFilter() {
    return (
      this.userFilterSelected == null ||
      (this.userFilterSelected && this.userFilterSelected.name && this.userFilterSelected.name.toLowerCase() === "default")
    );
  }

  getfilterListValue(filters: OptionalFilter): string[] {
    var query: string[] = [];
    // on clean les cles de valeur null
    const filterClean: OptionalFilter = Object.fromEntries(Object.entries(filters).filter(([, val]) => val != null && val != ""));
    Object.entries(filterClean).forEach((entry) => {
      const [key, value] = entry;
      if (value != null) {
        switch (key) {
          case "contractorWarehouse":
          case "partnerWarehouse":
          case "carrierWarehouse":
          case "associatedPartner":
            query.push(`${key}Id=${(value as ThirdWarehouse).id}`);
            break;
          case "mouvementDirection":
            query.push(`${key}=${(value as MouvementDirection).code}`);
            break;
          case "noteStatus":
            query.push(`${key}=${(value as NoteStatus).code}`);
            break;
          case "streamType":
            query.push(`${key}=${(value as StreamType).code}`);
            break;
          // case "palettNumber":
          //   break;
          case "statisticCode":
            query.push(`${key}Id=${(value as StatisticCode).id}`);
            break;
          case "weeklyDeliveryPlanning":
            query.push(`${key}Id=${(value as WeeklyDeliveryPlanning).id}`);
            break;
          case "localization":
            query.push(`${key}Id=${(value as Localization).id}`);
            break;
          case "receivedDate":
          case "pickupDate":
          case "deliveryDate":
            query.push(`${key}=${value}`);
            break;
          default:
            query.push(`${key}=${value}`);
            break;
        }
      }
    });
    return query;
  }

  /**
   * Action appliquer les filtres
   */
  async ValideFilters() {
    this.extractQueryFilters();
    this.dialog = false;
    await this.saveFilterToUserPreferenceByName("Default");
  }

  userFilterName: string | null = null;

  @Watch("userFilterSelected")
  onChange(newVal: UserFilter | null): void {
    if (newVal) {
      this.setOptionalFilters(newVal.filter);
    } else {
      this.setOptionalFilters({});
    }
    if (this.filter != undefined) {
      this.initialFilter = JSON.parse(JSON.stringify(this.filter));
    }
    this.setSelectedZonesFilter([]);
    this.extractQueryFilters();

    // on place le nouveau filtre en tant que dernier utilisé
    this.userFilterItemsInitial.forEach((element) => {
      element.lastUsed = element.name === newVal?.name;
    });
    this.savePreference();
  }

  userFilterItems: UserFilter[] = [];
  userFilterItemsInitial: UserFilter[] = []; // liste d'items fitlre initial (prise des preference utilisateur)

  get userPreference() {
    return this.userFilterItemsInitial;
  }

  userFilterNameRules = [(v: any): boolean | string => !!v || "Nom du filtre requis"];

  /**
   * Sauvegarde des preferences utilisateur
   */
  async savePreference() {
    this.userFilterItems = JSON.parse(JSON.stringify(this.userFilterItemsInitial));
    //Format data
    let data = { savedUserPreference: this.userPreference };

    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.$store.state.userContext.currentCompany,
      warehouseCode: this.$store.state.userContext.currentWarehouse,
      data: JSON.stringify(data),
    };

    this.controller.Log.logDebug(`saveUserPreferences`, `Object query : ${JSON.stringify(query)}`);

    try {
      await this.controller.UserStore.saveUserPreferencesWithoutTimer(query);
    } catch (error) {
      //Log
      await this.controller.Log.logError(`saveUserPreferences`, "", error, moduleName);
    }
  }

  /**
   * Enregistrement du filtre par default
   */
  async saveFilterToUserPreferenceByName(userFilterName: string) {
    // on clean les cles de valeur null
    const filterClean: OptionalFilter = Object.fromEntries(Object.entries(this.filter).filter(([, val]) => val != null && val != ""));
    let userFilter = this.userFilterItemsInitial.find((userFilter) => {
      return userFilter.name === userFilterName;
    });
    // Si on a pas deja le nom du filtre dans la liste on l'ajoute
    if (!userFilter) {
      this.userFilterItemsInitial.push({ name: userFilterName, lastUsed: true, filter: filterClean });
    } else {
      userFilter.filter = filterClean;
    }

    // on place le nouveau filtre en tant que dernier utilisé
    this.userFilterItemsInitial.forEach((element) => {
      element.lastUsed = element.name === userFilterName;
    });

    await this.savePreference();
    this.setLastFilterUsed();
  }

  /**
   * Lecture des preferences utilisateur pour recuperer les filtres enregistrés
   */
  async loadFilterFromUserPreference() {
    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.$store.state.userContext.currentCompany,
      warehouseCode: this.$store.state.userContext.currentWarehouse,
      data: null,
    };

    this.controller.Log.logDebug(`getUserPreferences`, `Object query : ${JSON.stringify(query)}`);
    let sendResult = null;
    try {
      sendResult = await this.controller.UserStore.getUserPreferences(query);
      if (sendResult?.data) {
        let userPreference = JSON.parse(sendResult.data);
        if (userPreference.savedUserPreference) {
          this.userFilterItems = userPreference.savedUserPreference as UserFilter[];
          // clone l'obet pour ne pas y toucher sur le binding du select filter
          this.userFilterItemsInitial = JSON.parse(JSON.stringify(userPreference.savedUserPreference));
          this.setLastFilterUsed();
        }
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getUserPreferences`, "", error, moduleName);
      sendResult = null;
    }
  }

  /**
   * Place le filtre selectionner au dernier utilisé
   */
  setLastFilterUsed() {
    let lastFilterUsed: UserFilter | undefined = this.userFilterItems.find((elt: UserFilter) => {
      return elt.lastUsed;
    });
    let defaultFilter: UserFilter | undefined = this.userFilterItems.find((elt: UserFilter) => {
      return elt.name.toLowerCase() === "default";
    });
    if (lastFilterUsed != undefined) {
      this.userFilterSelected = lastFilterUsed;
    } else if (defaultFilter != undefined) {
      this.userFilterSelected = defaultFilter;
    }
  }

  //#region CRUD
  /**
   * Pour rendre le bouton de creation en disabled
   */
  get canCreateFilter(): boolean {
    return this.userFilterName != null && this.userFilterName != "";
  }

  /**
   * Pour rendre le bouton d'enregistrement en disabled
   */
  get canSaveFilter(): boolean {
    return !_.isEqual(this.filter, this.initialFilter) && this.userFilterSelected != null;
  }
  /**
   * Creation d'un nouveau filtre
   */
  async createNewFilter() {
    if (this.userFilterName) {
      await this.saveFilterToUserPreferenceByName(this.userFilterName);
    }
    this.userFilterName = null;
  }

  /**
   * Enregistrement du filtre courant
   */
  async saveCurrentFilter() {
    if (this.userFilterSelected) {
      await this.saveFilterToUserPreferenceByName(this.userFilterSelected.name);
    }
  }

  /**
   * Suppression d'un filtre
   */
  async removeFilter(item: any) {
    this.userFilterItems.splice(item, 1);
    await this.savePreference();
    this.userFilterItemsInitial.splice(this.userFilterItemsInitial.indexOf(item), 1);
    await this.savePreference();
    this.setLastFilterUsed();
  }
  //#endregion CRUD

  /**
   * Enregistrement de filtre avec positionnement de celui ci comme utilisé
   */
  async saveChangeAndChange(payload: { filterName: string; userPreference: UserFilter[] }) {
    await this.saveChange(payload);
    let namedFilter: UserFilter | undefined = this.userFilterItems.find((elt: UserFilter) => {
      return elt.name.toLowerCase() === payload.filterName;
    });
    if (namedFilter === undefined) {
      namedFilter = this.userFilterItems.find((elt: UserFilter) => {
        return elt.name.toLowerCase() === "default";
      });
    }
    if (namedFilter) {
      this.userFilterSelected = namedFilter;
    }
  }

  /**
   * Enregistrement de filtre
   */
  async saveChange(payload: { filterName: string; userPreference: UserFilter[] }) {
    this.userFilterItemsInitial = payload.userPreference;
    await this.savePreference();
  }

  mounted() {
    this.loadFilterFromUserPreference();
  }
}
