import Vue from "vue";
import axios, { AxiosAdapter, AxiosInstance, AxiosResponse } from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import { clientEnvironmentCode } from "@/environment/environment";

import store from "@/app/app-state";

const baseUrlOrigin = window.location.origin.toString();

export default class RestClient {
  RestClientLoadingCount: number;
  handleError: boolean;
  Instance: AxiosInstance;

  constructor(baseUrl: string, anonymous = false) {
    this.RestClientLoadingCount = 0;

    this.handleError = true;
    const notimeout = true;
    const timeout = notimeout ? 0 : 2500;

    this.Instance = axios.create({
      timeout: timeout,
      baseURL: baseUrl,
      //crossDomain: true, // crossDomain n'est pas dans AxiosRequestConfig voir si l'ajout des header regle le pb
      headers: {
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
      // disable the default cache
      adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { enabledByDefault: false }),
    });

    this.Instance.interceptors.request.use(
      (config: any) => {
        //on donne le token courant lors de l'appel
        if (!anonymous) {
          const authToken = store.getters["userContext/getToken"];

          config.headers = {
            Authorization: "Bearer " + authToken,
            "X-Tesfri-EnvironmentCode": clientEnvironmentCode,
            "X-Tesfri-CompanyCode": store.state.userContext.currentCompany,
            "X-Tesfri-WarehouseCode": store.state.userContext.currentWarehouse,
          };
        }
        this.RestClientLoadingCount += 1;
        window.postMessage({ type: "loading", value: this.RestClientLoadingCount }, baseUrlOrigin);

        return config;
      },
      (error: any) => {
        // Pull config, status and data from the error
        const { config } = error;

        if (this.handleError) {
          Vue.$log.error("Remote Connection " + `${config.url}: ${error.message}`);
          return Promise.reject(error);
        }
        return config;
      }
    );

    this.Instance.interceptors.response.use(
      (response: AxiosResponse) => {
        this.RestClientLoadingCount -= 1;
        window.postMessage({ type: "loading", value: this.RestClientLoadingCount }, baseUrlOrigin);
        return response;
      },
      (error: any) => {
        this.RestClientLoadingCount -= 1;
        window.postMessage({ type: "loading", value: this.RestClientLoadingCount }, baseUrlOrigin);
        // Pull config, status and data from the error
        const { config, response } = error;
        //Si on est en 409, on ne veux pas de message, on le gère par une modale Oui/Non
        if (this.handleError === true && response?.status !== 409) {
          Vue.$log.error("Remote Connection " + `${config.url}: ${error.message}`);
          return Promise.reject(error).then(null, function () {
            return response;
          });
        }
        return response;
      }
    );
  }
}
