import AppSystemInformation from "./systemInformation/SystemInformation.vue";
import store from "@/app/app-state";
import PrivateLayout from "@/app/layouts/Private.vue";
import { Route } from "vue-router";

const settingsRoutes = [
  {
    path: "/help",
    meta: {
      type: "module",
      breadcrumb: [
        {
          text: "Aide",
        },
      ],
      iconClass: "mdi-help-circle",
    },
    component: PrivateLayout,

    children: [
      {
        path: "systemInformation",
        component: AppSystemInformation,
        meta: {
          breadcrumb: [
            {
              text: "Informations système",
              to: "/help/systemInformation",
            },
          ],
          //rightName: "", // Aucun droit pour le menu aide
          type: "subModule",
          iconClass: "mdi-information",
        },
        beforeEnter: (to: Route, _from: Route, next: any): void => {
          next();
        },
      },
    ],
  },
];

export default settingsRoutes;
