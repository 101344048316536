
import { Component, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { SystemInfo } from "@/app/shared/state/userContextState";
import store from "@/app/app-state";

@Component
export default class AppFooter extends Vue {
  get year(): number {
    return new Date().getFullYear();
  }

  get systemInfo(): null | SystemInfo {
    return store.state.userContext.systemInfo;
  }
}
