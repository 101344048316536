import Vue from "vue";
import RestClient from "@/app/shared/services/restClient"; // Roadmap to get api
import { AxiosInstance } from "axios";
import store from "@/app/app-state";
import {
  Query,
  ThirdWarehouse,
  ShippingUnit,
  ComponentToPrepare,
  CodeLabel,
  StreamType,
  StreamTypeMvtCode,
  ComponentsPallets,
  PalletsQuantities,
  NoteHeader,
  CustomInformation,
  ComponentsUpdate,
  ManagementDate,
  AffectedPreparedNote,
  AffectedNoteDetail,
  AffectedPreparatedNoteInfo,
  GoodsMvtCode,
  ShippingUnitToPrepare,
  PreparationMode,
  PreparationNoteToAssign,
  User,
  ItemDetailInfo,
  StreamTypeMvtCodeLight,
  PlanOrNote,
  Localization,
  PalletPrioritization,
  Operator,
  MouvementDirection,
  NoteStatus,
  StatisticCode,
  WeeklyDeliveryPlanning,
} from "./Types";
import {
  RecipientsForSUStorageWithSU,
  ShippingUnitForStorage,
  WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage,
} from "../types/ShippingUnit";
import LocalizationWithQuantity from "../types/LocalizationWithQuantity";
import OperatorWithQuantity from "../types/OperatorWithQuantity";

export default class StockStore {
  private loading: boolean;
  private restClient: AxiosInstance;

  constructor(baseUrl: string) {
    this.loading = false;
    this.restClient = new RestClient(baseUrl).Instance;
  }

  //#region Module update components

  /**
   * Get all Component to a customers
   * @param {ThirdWarehouse} customer
   * @param {Query} query
   */
  async getAllComponents(customer: ThirdWarehouse, query: Query): Promise<{ data: ComponentsPallets[]; itemsCount: number }> {
    this.loading = true;

    const response = await this.restClient.post("/ComponentsUpdate/" + customer.id + "/Query", query);

    const data = response.data.results;

    const itemsCount = parseInt(response.headers["content-range"]?.split("/")[1]);

    this.loading = false;
    return { data, itemsCount };
  }

  /**
   * Get list customer to suggest autocompletion
   * @param {string} search
   */
  async getCustomerSuggests(search: string): Promise<{ data: ThirdWarehouse[] }> {
    this.loading = true;

    const query = "?q=" + search;

    const response = await this.restClient.get("/ContractorsWarehouse/Suggests" + query);
    const data = response.data;

    this.loading = false;

    return { data };
  }

  /**
   * Get list Note Header to suggest autocompletion
   * @param {string} searchCustomer
   * @param {string} searchDate
   * @param {string} searchNoteheader
   */
  async getNoteHeaderSuggests(
    searchNoteheader: string,
    searchDate: null | string,
    searchCustomer: number
  ): Promise<{ data: NoteHeader[] }> {
    this.loading = true;

    const query = "?q=" + searchNoteheader + "&d=" + searchDate + "&c=" + searchCustomer;
    const response = await this.restClient.get("/Headers/Suggests" + query);
    const data = response.data;

    // cast noteNumber:number into noteNumber:String for v-autocomplete who accept online text
    for (const ob in data) {
      data[ob].noteNumber = data[ob].noteNumber.toString();
    }

    this.loading = false;
    return { data };
  }

  /**
   * Get total of component filter customer, date and note header
   * @param {ThirdWarehouse} customer
   * @param {Query} query
   */
  async getTotauSelectedComponants(customer: ThirdWarehouse, query: Query): Promise<{ data: PalletsQuantities[] }> {
    this.loading = true;

    const response = await this.restClient.post(
      `/ComponentsUpdate/QueryTotalComponents?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&contractorWarehouseId=${customer.id}`,
      query
    );
    const data = response.data;

    this.loading = false;
    return { data };
  }

  /**
   * Get information to optionnal columns
   * @param {ThirdWarehouse} customer
   */
  async getInfoColumns(customer: ThirdWarehouse): Promise<{ data: CustomInformation[] }> {
    this.loading = true;

    /* 6 -> composant */
    const querystring = `?contractorWarehouseId=${customer.id}&tableType=6&companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response = await this.restClient.get(`/Customisation${querystring}`);
    const data = response.data;

    this.loading = false;
    return { data };
  }

  /**
   * Update components
   * @param {number} customerId
   * @param {ComponentsUpdate} query
   */
  async updateComponents(customerId: number, query: ComponentsUpdate): Promise<ComponentsUpdate> {
    this.loading = true;
    const querystring = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response: ComponentsUpdate = await this.restClient.post(`/ComponentsUpdate/${customerId}/UpdateComponents${querystring}`, query);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * Update components
   * @param {ShippingUnit} shippingUnitToPrepare
   * @param {ComponentToPrepare} componentToPrepare
   * @param {boolean} withMissing
   */
  async getNextComponent(
    shippingUnitToPrepare: ShippingUnit,
    componentToPrepare: ComponentToPrepare,
    withMissing: boolean
  ): Promise<ComponentToPrepare> {
    this.loading = true;
    const querystring = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&withMissing=${withMissing}`;

    const response = await this.restClient.post(
      `/ShippingUnit/${shippingUnitToPrepare.id}/NextComponent${querystring}`,
      componentToPrepare
    );
    const data = response.data;
    this.loading = false;
    return data;
  }

  /**
   * Appel API pour recuperer les nom d'entete de colonnes des dates de gestion
   * @param {ThirdWarehouse} customer
   * @param {Query} query
   */
  async getManagementDateHeader(
    customer: ThirdWarehouse,
    query: Query
  ): Promise<{ mainManagementDate: ManagementDate; secondaryManagementDate: ManagementDate }> {
    this.loading = true;

    const response: { mainManagementDate: ManagementDate; secondaryManagementDate: ManagementDate } = await this.restClient.post(
      `/ComponentsUpdate/ManagementDateName?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&contractorWarehouseId=${customer.id}`,
      query
    );
    const data = response;

    this.loading = false;
    return data;
  }
  //#endregion Module update components

  //#region Module préparation

  /**
   * Get all preparation
   * @param {Query} query
   */
  async getAllOrderPreparation(query: Query): Promise<{ data: AffectedPreparedNote[]; itemsCount: number }> {
    this.loading = true;

    const querystring = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&limit=${query.limit}&offset=${query.offset}`;

    const response = await this.restClient.get(`/Preparation/AffectedNoteToPrepare${querystring}`);
    const data: AffectedPreparedNote[] = response.data;
    const itemsCount = response.headers["content-range"]?.split("/")[1] as unknown as number;
    this.loading = false;
    return { data, itemsCount };
  }

  /**
   * Call API to start preparation
   * @param {number} AffectedNoteid
   */
  async startPrepareFromApi(affectedNoteId: number): Promise<boolean> {
    this.loading = true;
    const querystring = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response: boolean = await this.restClient.post(`/Preparation/${affectedNoteId}/StartPrepare${querystring}`);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * call API to stop preparation
   * @param {*} AffectedNoteIdentifier
   */
  async stopPreparation(AffectedNoteId: number): Promise<boolean> {
    this.loading = true;
    const querystring = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response: boolean = await this.restClient.post(`/Preparation/${AffectedNoteId}/StopPrepare${querystring}`);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * Call API to start preparation
   * @param {ShippingUnit} shippingUnit
   * @param {boolean} isRecovery
   */
  async startShippingUnitPrepareFromApi(shippingUnit: ShippingUnit, isRecovery: boolean): Promise<boolean> {
    this.loading = true;
    const querystring = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&orderPrepaId=${shippingUnit.preparationID}&isRecovery=${isRecovery}`;

    const response: boolean = await this.restClient.post(`/ShippingUnit/${shippingUnit.id}/StartPrepare${querystring}`, shippingUnit);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * call API to stop shipping unit
   * @param {ShippingUnit} shippingUnit
   * @param {boolean} isRecovery
   * @param {boolean} forceClose
   */
  async stopShippingUnit(shippingUnit: ShippingUnit, isRecovery: boolean, forceClose: boolean): Promise<boolean> {
    this.loading = true;
    const querystring = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&orderPrepaId=${shippingUnit.preparationID}&isRecovery=${isRecovery}&forceClose=${forceClose}`;

    const response: boolean = await this.restClient.post(`/ShippingUnit/${shippingUnit.id}/StopPrepare${querystring}`);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * Appel API pour recuperer la liste des UE d'un bon en preparation
   * @param {number} AffectedNoteid
   */
  async getAllOrderPreparationDetail(AffectedNoteid: number): Promise<AffectedNoteDetail> {
    this.loading = true;

    const querystring = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response = await this.restClient.get(`/Preparation/${AffectedNoteid}/AffectedNoteDetail${querystring}`);
    const data: AffectedNoteDetail = response.data;

    this.loading = false;
    return data;
  }

  /**
   * Appel API pour recuperer les informations detaillées
   * @param {number} AffectedNoteid
   */
  async getAffectedNoteInfo(AffectedNoteid: number): Promise<AffectedPreparatedNoteInfo[]> {
    this.loading = true;

    const querystring = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response = await this.restClient.get(`/Preparation/${AffectedNoteid}/AffectedNoteInfo${querystring}`);
    const data: AffectedPreparatedNoteInfo[] = response.data;

    this.loading = false;
    return data;
  }

  //#endregion Module préparation

  //#region Module stream type movement code

  /**
   * Gets the list of storage types to suggest autocompletion
   */
  async getStorageTypeSuggests(): Promise<{ data: CodeLabel[] }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response = await this.restClient.get(`/StorageType${queryString}`);
    const data: CodeLabel[] = response.data;

    this.loading = false;

    return { data };
  }

  /**
   * Gets the list of stream types to suggest autocompletion
   * @param {string} search
   */
  async getStreamSuggests(search: string): Promise<{ data: StreamType[] }> {
    this.loading = true;

    const query = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&q=${search}&limit=100`;

    const response = await this.restClient.get(`/StreamType/Suggests` + query);
    const data: StreamType[] = response.data;

    this.loading = false;

    return { data };
  }

  /**
   * Gets the list of stream types to suggest autocompletion
   * @param {string} search
   * @param {StorageType} storageType
   * @param {StreamType} streamType
   */
  async getGoodsMvtCodeSuggests(
    search: null | string,
    storageType: null | CodeLabel,
    streamType: null | StreamType
  ): Promise<{ data: GoodsMvtCode[] }> {
    this.loading = true;

    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&limit=20`;
    if (search) {
      queryString += `&q=${search}`;
    }

    if (storageType) {
      queryString += `&storageTypeCode=${storageType.code}`;
    }
    if (streamType) {
      queryString += `&streamTypeCode=${streamType.code}`;
    }

    const response = await this.restClient.get(`/GoodsMvtCode/Suggests${queryString}`);
    const data: GoodsMvtCode[] = response.data;

    this.loading = false;

    return { data };
  }

  /**
   * Gets the list of movement code
   * @param {Query} query
   */
  async getStreamTypeMvtCode(query: Query): Promise<{ data: StreamTypeMvtCode[]; itemsCount: number }> {
    this.loading = true;

    const limit = query.limit;
    const offset = query.offset;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&limit=${limit}&offset=${offset}`;

    const response = await this.restClient.get(`/StreamTypeMvtCode${queryString}`);

    const data: StreamTypeMvtCode[] = response.data;
    const itemsCount = response.headers["content-range"]?.split("/")[1] as unknown as number;

    this.loading = false;
    return { data, itemsCount };
  }

  /**
   * Gets the list of movement code from Id
   * @param {number} Id
   */
  async getStreamTypeMvtCodeFromId(id: number): Promise<{ data: StreamTypeMvtCode }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response = await this.restClient.get(`/StreamTypeMvtCode/${id}${queryString}`);
    const data: StreamTypeMvtCode = response.data;

    this.loading = false;

    return { data };
  }

  /**
   * Create stream type mvt code
   * @param {StreamTypeMvtCode} streamTypeMvtCode
   */
  async createStreamTypeMvtCode(
    streamTypeMvtCode: StreamTypeMvtCodeLight
  ): Promise<{ data: StreamTypeMvtCode; status: number; errors: any }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response = await this.restClient.post(`/StreamTypeMvtCode${queryString}`, streamTypeMvtCode);
    let data: StreamTypeMvtCode = {} as StreamTypeMvtCode;
    const status: number = response.status;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }
    this.loading = false;
    return { data, status, errors };
  }

  /**
   * Update stream type mvt code
   * @param {StreamTypeMvtCode} streamTypeMvtCode
   */
  async updateStreamTypeMvtCode(streamTypeMvtCode: StreamTypeMvtCode) {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response = await this.restClient.put(`/StreamTypeMvtCode/${streamTypeMvtCode.id}${queryString}`, streamTypeMvtCode);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * Delete stream type mvt code (en attente API)
   * @param {StreamTypeMvtCode} streamTypeMvtCode
   */
  async deleteStreamTypeMvtCode(streamTypeMvtCode: StreamTypeMvtCode): Promise<boolean> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response: boolean = await this.restClient.delete(`/StreamTypeMvtCode/${streamTypeMvtCode.id}${queryString}`);
    const data = response;

    this.loading = false;
    return data;
  }

  //#endregion Module stream type movement code

  //#region Module Order preparation

  /**
   * Get shipping unit from cab
   * @param {string} barcode
   */
  async getShippingUnitFromBarCode(barcode: string): Promise<ShippingUnit> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&barcode=${barcode}`;

    const response: ShippingUnit = await this.restClient.get(`/ShippingUnit/IdentifyFromBarecode${queryString}`);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * Get preparation from shipping unit
   * @param {ShippingUnit} shippingUnit
   * @param {boolean} withMissing
   */
  async getComponantToPrepare(shippingUnit: ShippingUnit, withMissing: boolean): Promise<ShippingUnitToPrepare> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&orderPrepaId=${shippingUnit.preparationID}&shippingUnitId=${shippingUnit.id}&limit=1&withMissing=${withMissing}`;

    const response: ShippingUnitToPrepare = await this.restClient.get(`/ShippingUnit/GetLstComponantToPrepare${queryString}`);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * Get preparation mode items
   */
  async getPreparationModeItems(): Promise<{ data: PreparationMode[] }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response = await this.restClient.get(`/Preparation/preparationMode${queryString}`);

    const data: PreparationMode[] = response.data;

    this.loading = false;
    return { data };
  }

  /**
   * Get paused shipping units
   */
  async getPausedShippingUnits(): Promise<AffectedNoteDetail> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response: AffectedNoteDetail = await this.restClient.get(`/Preparation/AffectedNoteDetail${queryString}`);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * Declaration d'un manquant
   * @param {ShippingUnit} ShippingUnit
   * @param {ComponentToPrepare} componantToPrepare
   * @param {boolean} withMissing
   */
  async setMissingComponent(
    ShippingUnit: ShippingUnit,
    componantToPrepare: ComponentToPrepare,
    withMissing: boolean
  ): Promise<ShippingUnitToPrepare> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&withMissing=${withMissing}`;

    const response: ShippingUnitToPrepare = await this.restClient.post(
      `/ShippingUnit/${ShippingUnit.id}/MissingComponent${queryString}`,
      componantToPrepare
    );
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   * Prise de preparation d'UE par CAB
   * @param {ShippingUnit} ShippingUnit
   * @param {ComponentToPrepare} componantToPrepare
   * @param {string} eanCodePreparation
   * @param {boolean} withMissing
   */
  async prepareUEWithBarCode(
    ShippingUnit: ShippingUnit,
    componantToPrepare: ComponentToPrepare,
    eanCodePreparation: string,
    withMissing: boolean
  ): Promise<ShippingUnitToPrepare> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&shippingUnitId=${ShippingUnit.id}&barCode=${eanCodePreparation}&withMissing=${withMissing}`;

    const response: ShippingUnitToPrepare = await this.restClient.post(
      `/Preparation/PrepareUEWithBarCode${queryString}`,
      componantToPrepare
    );
    const data = response;

    this.loading = false;
    return data;
  }

  //#endregion Module Order preparation

  //#region Module shippingUnitToAssign

  /**
   * identifie un BPC à partir d'UN CAB.
   * @param {string} eanNoteOrUE
   */
  async getInfoAffectedWithBarCode(eanNoteOrUE: string): Promise<{ data: PreparationNoteToAssign; status: number; errors: any }> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&barCode=${eanNoteOrUE}`;

    const response = await this.restClient.get(`/Preparation/GetInfoAffectedWithBarCode${queryString}`);

    let data: PreparationNoteToAssign = {} as PreparationNoteToAssign;
    const status: number = response.status;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }
    this.loading = false;
    return { data, status, errors };
  }

  /**
   * Récupère les informations d'un utilisateur
   * @param {string} employeeCode
   */
  async getUserByCode(employeeCode: string): Promise<{ data: User; status: number; errors: any }> {
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&userBarcode=${employeeCode}`;
    const response = await this.restClient.get(`/Users${queryString}`);

    const status: number = response.status;
    let data: User = {} as User;
    let errors: any = {};

    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }

    return { data, status, errors };
  }

  /**
   * Affecte l'utilisateur à un bon
   * @param {number} employeeId
   * @param {number} preparationNoteId
   * @param {number} recipientId
   * @param {string} recipientName
   * @param {string} assignmentDate
   */
  async setPickerForPreparationNote(
    employeeId: number,
    preparationNoteId: number,
    recipientId: number,
    recipientName: string,
    assignmentDate: string
  ): Promise<{ data: PreparationNoteToAssign; status: number; errors: any }> {
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&pickerId=${employeeId}&preparationNoteId=${preparationNoteId}&recipientId=${recipientId}&recipientName=${recipientName}&assignmentDate=${assignmentDate}`;
    const response = await this.restClient.put(`/Preparation/PreparationNoteToAssign${queryString}`);

    const status: number = response.status;
    let data: PreparationNoteToAssign = {} as PreparationNoteToAssign;
    let errors: any = {};

    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }

    return { data, status, errors };
  }

  /**
   * Affecte l'utilisateur à une UE
   * @param {number} employeeId
   * @param {number} shippingUnitId
   * @param {string} assignmentDate
   */
  async setPickerForShippingUnit(
    employeeId: number,
    shippingUnitId: number,
    assignmentDate: string
  ): Promise<{ data: PreparationNoteToAssign; status: number; errors: any }> {
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&pickerId=${employeeId}&shippingUnitId=${shippingUnitId}&assignmentDate=${assignmentDate}`;
    const response = await this.restClient.put(`/Preparation/ShippingUnitToAssign${queryString}`);

    const status: number = response.status;
    let data: PreparationNoteToAssign = {} as PreparationNoteToAssign;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }

    return { data, status, errors };
  }

  //#endregion  Module shippingUnitToAssign

  //#region Module recherche d'article

  /**
   * Recherche d'information article
   * @param {string} eanArticle
   */
  async getInfoItemWithBarCode(
    eanArticle: string,
    selectedContractorWarehouse: ThirdWarehouse | null
  ): Promise<{ data: ItemDetailInfo; status: number; error: string }> {
    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&barcode=${eanArticle}`;
    queryString += selectedContractorWarehouse ? `&contractorWarehouseId=${selectedContractorWarehouse.id}` : "";

    let data = {} as ItemDetailInfo;
    let error = "";

    let status = 0;

    await this.restClient
      .get(`/Items/GetItemFromBarcode${queryString}`)
      .then((response) => {
        status = response.status;
        data = response.data;

        if (status != 200 && status != 204) {
          error = response.data;
        }
      })
      .catch((error) => {
        Vue.$log.error(error);
      });
    return { data, status, error };
  }

  //#endregion module recherche d'article

  //#region Module Priorisation mission cariste

  /**
   *
   * @returns Methode de recuperation des mission priorisation des missions cariste
   */
  async getOperatorMissionPriority(
    query: string,
    filterDate: string,
    filterPalettNumber: string | null
  ): Promise<{ data: PlanOrNote[]; itemsCount: number }> {
    this.loading = true;

    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&filterDate=${filterDate}&${query}`;
    queryString += filterPalettNumber ? `&palettnumber=${filterPalettNumber}` : "";

    let data: PlanOrNote[] = [] as PlanOrNote[];
    let itemsCount = 0;
    await this.restClient
      .get(`/LoadingPlansNotes/OperatorMission/GetList${queryString}`)
      .then((response) => {
        if (response.status == 200) {
          data = response.data;
          itemsCount = response.data.length;
        }
      })
      .catch((error) => {
        Vue.$log.error(error);
      });
    this.loading = false;
    return { data, itemsCount };
  }

  /**
   *
   * @returns Methode de recuperation du detail d'un plan des missions priorisation des missions cariste
   */
  async GetListNoteOfPlan(planId: number, query: string): Promise<{ data: PlanOrNote[]; itemsCount: number }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&${query}`;

    let data: PlanOrNote[] = [] as PlanOrNote[];
    let itemsCount = 0;
    await this.restClient
      .get(`/LoadingPlansNotes/OperatorMission/${planId}/GetListNoteOfPlan${queryString}`)
      .then((response) => {
        if (response.status == 200) {
          data = response.data;
          itemsCount = response.data.length;
        }
      })
      .catch((error) => {
        Vue.$log.error(error);
      });
    this.loading = false;
    return { data, itemsCount };
  }

  /**
   * Reorganisation d'une mission cariste
   * @param planOrNote
   * @param newOrder
   * @param filterDate
   * @returns
   */
  async operatorMissionPriorityChangeOrder(planOrNote: PlanOrNote, newOrder: number, filterDate: string) {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&newOrder=${newOrder}&filterDate=${filterDate}`;

    const response = await this.restClient.post(`/LoadingPlansNotes/OperatorMission/ChangeOrder${queryString}`, planOrNote);
    const data = response;

    this.loading = false;
    return data;
  }

  /**
   *
   * @returns Methode de recuperation du detail d'un plan des missions priorisation des missions cariste
   */
  async getLocalizations(): Promise<{ data: Localization[]; itemsCount: number }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&storageMode=6`;

    let data: Localization[] = [] as Localization[];
    let itemsCount = 0;
    await this.restClient
      .get(`/Localization${queryString}`)
      .then((response) => {
        if (response.status == 200) {
          data = response.data;
          itemsCount = response.data.length;
        }
      })
      .catch((error) => {
        Vue.$log.error(error);
      });
    this.loading = false;
    return { data, itemsCount };
  }

  /**
   *
   * @param barcode Code à barre d'emplacement
   * @returns emplacement, statut HTTP, erreurs
   */
  async getLocalizationFromBarcode(barcode: string): Promise<{ data: Localization; status: number; errors: any }> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&barCode=${barcode}`;
    const response = await this.restClient.get(`/Localization/GetLocalizationFromBarCode${queryString}`);
    const status: number = response.status;
    const data: Localization = response.data;
    let errors: any = {};
    if (status !== 200) {
      errors = response.data;
    }
    this.loading = false;
    return { data, status, errors };
  }
  /**
   * Mise a jours de zone de stockage d'un plan/bon
   * @param planOrNote Plan/Bon a mettre a jour
   * @param newLocalization Nouvelle zone de stockage
   * @returns
   */
  async operatorMissionPriorityUpdateLocalization(
    planOrNote: PlanOrNote,
    newLocalization: Localization | null
  ): Promise<{ status: number; errors: any }> {
    this.loading = true;
    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;
    if (newLocalization) {
      queryString = queryString.concat(`&loadingDockId=${newLocalization.id}`);
    }
    const response = await this.restClient.post(`/LoadingPlansNotes/OperatorMission/SetLoadingDock${queryString}`, planOrNote);

    const status: number = response.status;
    const errors = response.data;

    return { status, errors };
  }

  /**
   * Mise a jours de la liste des caiste pour un plan/bon
   */
  async operatorMissionPriorityUpdateOperators(planOrNote: PlanOrNote): Promise<{ status: number; errors: any }> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    const response = await this.restClient.post(`/LoadingPlansNotes/OperatorMission/SetOperators${queryString}`, planOrNote);

    const status = response.status;
    const errors = response.data;

    return { status, errors };
  }

  /**
   *
   * @returns Methode de recuperation des caristes disponilbe
   */
  async getOperators(): Promise<{ data: Operator[]; itemsCount: number }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`; // storageMode = 9 --> zone de transit

    let data: Operator[] = [] as Operator[];
    let itemsCount = 0;
    await this.restClient
      .get(`/Users/Operators${queryString}`)
      .then((response) => {
        if (response.status == 200) {
          data = response.data;
          itemsCount = response.data.length;
        }
      })
      .catch((error) => {
        Vue.$log.error(error);
      });
    this.loading = false;
    return { data, itemsCount };
  }

  async getOperatorMissionPriorityPalletsList(noteId: number): Promise<{ data: PalletPrioritization[]; itemsCount: number }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    let data: PalletPrioritization[] = [] as PalletPrioritization[];
    let itemsCount = 0;
    await this.restClient
      .get(`/LoadingPlansNotes/OperatorMission/${noteId}/GetListPallet${queryString}`)
      .then((response) => {
        data = response.data;
        itemsCount = response.data.length;
      })
      .catch((error) => {
        Vue.$log.error(error);
      });
    this.loading = false;

    return { data, itemsCount };
  }

  /**
   * Mise a jour de la zone d'une pallette
   * @param palletPrioritization
   * @param newLocalization
   * @returns
   */
  async operatorMissionPriorityUpdateLocalizationPallet(
    palletPrioritization: PalletPrioritization,
    newLocalization: Localization | null,
    note: PlanOrNote
  ): Promise<{ status: number; errors: any }> {
    this.loading = true;
    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&direction=${note.direction}&orderGlobal=${note.orderGlobal}`;
    if (newLocalization) {
      queryString = queryString.concat(`&loadingDockId=${newLocalization.id}`);
    }
    const response = await this.restClient.post(
      `/LoadingPlansNotes/OperatorMission/SetLoadingDockPallet${queryString}`,
      palletPrioritization
    );

    const status: number = response.status;
    const errors = response.data;

    return { status, errors };
  }

  /**
   * Mise a jour des caristes d'une pallette
   * @param palletPrioritization
   * @returns
   */
  async operatorMissionPriorityUpdateOperatorPallet(palletPrioritization: PalletPrioritization): Promise<{ status: number; errors: any }> {
    Vue.$log.debug(palletPrioritization);
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;
    const response = await this.restClient.post(`/LoadingPlansNotes/OperatorMission/SetOperatorPallet${queryString}`, palletPrioritization);

    const status: number = response.status;
    const errors = response.data;
    return { status, errors };
  }

  /**
   * Get list customer to suggest autocompletion
   * @returns
   */
  async getOperatorMissionPriorityContractorWarehouseSuggests(): Promise<{ data: ThirdWarehouse[] }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&q=`;

    let data = [];
    const response = await this.restClient.get(`/ContractorsWarehouse/SuggestsAll${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;
    return { data };
  }

  /**
   * Get list partner to suggest autocompletion
   * @param {string} search
   */
  async getOperatorMissionPriorityPartnerWarehouseSuggests(queryFilterList: string[]): Promise<{ data: ThirdWarehouse[] }> {
    this.loading = true;

    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&q=`;
    // ajout des filtre optionnel
    queryString += queryFilterList.length > 0 ? `&${queryFilterList.join("&")}` : "";

    let data = [];
    const response = await this.restClient.get(`/PartnersWarehouse/Suggests${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;

    return { data };
  }

  /**
   * Get list carrier to suggest autocompletion
   * @returns
   */
  async getOperatorMissionPriorityCarriersWarehouseSuggests(): Promise<{ data: ThirdWarehouse[] }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&q=`;
    let data = [];
    const response = await this.restClient.get(`/CarriersWarehouse/SuggestsAll${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }
    this.loading = false;
    return { data };
  }

  /**
   * Get list order number to suggest autocompletion
   * @param queryFilterList liste de filtre optionnel
   * @param noteDate date du bon
   * @returns
   */
  async getOperatorMissionPriorityOrderNumberItemsSuggests(queryFilterList: string[], noteDate: string): Promise<{ data: string[] }> {
    this.loading = true;

    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&notedate=${noteDate}`;
    // ajout des filtre optionnel
    queryString += queryFilterList.length > 0 ? `&${queryFilterList.join("&")}` : "";
    let data = [];
    const response = await this.restClient.get(`/GoodsNoteHeaders/OrderNumberSuggests${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;
    return { data };
  }

  /**
   * Get list final order number to suggest autocompletion
   * @param queryFilterList liste de filtre optionnel
   * @param noteDate date du bon
   * @returns
   */
  async getOperatorMissionPriorityFinalOrderNumberItemsSuggests(queryFilterList: string[], noteDate: string): Promise<{ data: string[] }> {
    this.loading = true;

    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&notedate=${noteDate}`; // ajout des filtre optionnel
    queryString += queryFilterList.length > 0 ? `&${queryFilterList.join("&")}` : "";
    let data = [];
    const response = await this.restClient.get(`/GoodsNoteHeaders/FinalOrderNumberSuggests${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;
    return { data };
  }

  /**
   * Get list mouvement direction to suggest autocompletion
   * @returns
   */
  async getOperatorMissionPriorityMouvementDirectionItemsSuggests(): Promise<{ data: MouvementDirection[] }> {
    this.loading = true;
    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    let data = [];
    const response = await this.restClient.get(`/GoodsNoteHeaders/MovementDirection${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;

    return { data };
  }

  /**
   * Get list note status to suggest autocompletion
   * @returns
   */
  async getOperatorMissionPriorityNoteStatusItemsSuggests(): Promise<{ data: NoteStatus[] }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;

    let data = [];
    const response = await this.restClient.get(`/GoodsNoteHeaders/NoteStatusBeforeControl${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;

    return { data };
  }

  /**
   * Get list stream type to suggest autocompletion
   * @returns
   */
  async getOperatorMissionPriorityStreamTypeItemsSuggests(): Promise<{ data: StreamType[] }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;
    let data = [];
    const response = await this.restClient.get(`/StreamType/ListForMisison${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;

    return { data };
  }

  /**
   * Get list statistic code to suggest autocompletion
   * @returns
   */
  async getOperatorMissionPriorityStatisticCodeItemsSuggests(): Promise<{ data: StatisticCode[] }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;
    let data: StatisticCode[] = [];
    const response = await this.restClient.get(`/StatisticCode/SuggestsForPartner${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;

    return { data };
  }

  /**
   * Get list grouping code to suggest autocompletion
   * @returns
   */
  async getOperatorMissionPriorityAssociatedPartnerItemsSuggests(queryFilterList: string[]): Promise<{ data: ThirdWarehouse[] }> {
    this.loading = true;

    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;
    // ajout des filtre optionnel
    queryString += queryFilterList.length > 0 ? `&${queryFilterList.join("&")}` : "";
    let data;
    const response = await this.restClient.get(`/PartnersWarehouse/ListPartnersGroup${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;

    return { data };
  }

  /**
   * Get list weekly delevery planning to suggest autocompletion
   * @returns
   */
  async getOperatorMissionPriorityWeeklyDeliveryPlanningitemsSuggests(
    queryFilterList: string[]
  ): Promise<{ data: WeeklyDeliveryPlanning[] }> {
    this.loading = true;
    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`;
    // ajout des filtre optionnel
    queryString += queryFilterList.length > 0 ? `&${queryFilterList.join("&")}` : "";

    let data: WeeklyDeliveryPlanning[] = [];
    const response = await this.restClient.get(`/WeeklyDeliveryPlanning/Suggests${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }
    this.loading = false;

    return { data };
  }

  /**
   * Get list localisation to suggest autocompletion
   * @returns
   */
  async getOperatorMissionPriorityLocalizationItemsSuggests(): Promise<{ data: Localization[] }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&storageModeList=6`;

    let data = [];
    const response = await this.restClient.get(`/Localization/GetLstLocalizationWithStorageModeList${queryString}`);
    if (response?.status === 200) {
      data = response.data;
    }

    this.loading = false;
    return { data };
  }

  /**
   * Liste des caristes avec le nombre de palette affecté
   * @param date
   * @returns
   */
  async getOperatorListForOperatorMissionPriorisationAsync(
    date: string
  ): Promise<{ data: OperatorWithQuantity[]; status: number; errors: any }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&filterDate=${date}`;

    let data: OperatorWithQuantity[] = {} as OperatorWithQuantity[];
    const response = await this.restClient.get(`Users/OperatorsForMissionPriority${queryString}`);
    const status: number = response.status;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }
    this.loading = false;
    return { data, status, errors };
  }

  //#endregion Module Priorisation mission cariste

  //#region Stockage des UE
  /**
   * Recuperation d'une UE a stocker
   * @param eanShippingUnit
   * @returns
   */
  async getInfoShippingUnitForStorage(eanShippingUnit: string): Promise<{ data: ShippingUnitForStorage; status: number; errors: any }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&barcode=${eanShippingUnit}`;

    let data: ShippingUnitForStorage = {} as ShippingUnitForStorage;
    const response = await this.restClient.get(`/ShippingUnit/Storage/IdentifyFromBarecode${queryString}`);
    const status: number = response.status;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }
    this.loading = false;
    return { data, status, errors };
  }
  /**
   * Stockage d'une unité d'expédition sur un nouvel emplacement
   * @param shippingUnitIdentifier identifiant d'unité d'expédition
   * @param localizationIdentifier identifiant d'emplacement
   * @param noteIdentifier identifiant de bon
   * @return statut HTTP, erreurs
   */
  async stockShippingUnitForStorageOnLocalization(
    shippingUnitIdentifier: number,
    localizationIdentifier: number,
    noteIdentifier: number
  ): Promise<{ status: number; errors: any }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&localizationId=${localizationIdentifier}&shippingUnitId=${shippingUnitIdentifier}&noteId=${noteIdentifier}`;
    const response = await this.restClient.post(`/ShippingUnit/Storage/StorageSU${queryString}`);
    const status: number = response.status;
    let errors: any = {};
    if (status !== 200) {
      errors = response.data;
    }
    this.loading = false;
    return { status, errors };
  }

  /**
   * Création d'un bon qui est retourné
   * @param date date du bon
   * @param noteType type du bon
   * @returns objet d'une en-tête de bon
   */
  async createHeader(date: string, noteType: number): Promise<{ data: NoteHeader; status: number; errors: any }> {
    this.loading = true;

    const response = await this.restClient.post(
      `/Headers/Create?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`,
      {
        noteDate: date,
        noteType: noteType,
      }
    );
    const status: number = response.status;
    let errors: any = {};
    let data: NoteHeader = {} as NoteHeader;
    // 201 - created
    if (status !== 201) {
      errors = response.data;
    } else {
      data = response.data;
    }
    this.loading = false;
    return { data, status, errors };
  }
  //#endregion Stockage des UE

  //#region Stockage des UE
  /**
   * Recuperation liste des Cadencier/Plans/Bons pour la consultation d'avancement du stockage des U.E.
   * @param date
   * @returns
   */
  async getWeeklyDeliveryPlanningPlanNote(
    date: string,
    thirdWarehouse: ThirdWarehouse | null,
    noteTypeDateFilter?: { id: number; libelle: string },
    dateStartFiler?: string,
    dateEndFiler?: string
  ): Promise<{ data: WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage[]; status: number; errors: any }> {
    this.loading = true;

    let queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&date=${date}`;
    if (thirdWarehouse) {
      queryString = queryString.concat(`&carrierId=${thirdWarehouse.id}`);
    }
    if (noteTypeDateFilter) {
      queryString = queryString.concat(`&noteTypeDate=${noteTypeDateFilter.id}`);
    }
    if (dateStartFiler && dateEndFiler) {
      queryString = queryString.concat(`&dateStart=${dateStartFiler}&dateEnd=${dateEndFiler}`);
    }

    let data: WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage[] = [] as WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage[];
    const response = await this.restClient.get(`/WeeklyDeliveryPlanningPlanNote/ShippingUnitStorage/GetList${queryString}`);
    const status: number = response.status;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }
    this.loading = false;
    return { data, status, errors };
  }

  /**
   *get suggestion de transporteur
   * @param search valeur de recherche
   * @returns
   */
  async getCarriersWarehouse(search: string): Promise<{ data: ThirdWarehouse[] }> {
    this.loading = true;

    const query = "?q=" + search;

    const response = await this.restClient.get(`/CarriersWarehouse/Suggests${query}`);

    const data = response.data;

    this.loading = false;

    return { data };
  }

  /**
   * charge les U.E. lié à un cadencier/destinataire pour le stockage des U.E.
   * @param weeklyDeliveryPlanningId Id du cadencier
   * @param date date du bon
   * @returns
   */
  async getShippingUnitStorageDetailByWeeklyDeliveryPlanningId(
    weeklyDeliveryPlanningId: number,
    date: string | null,
    recipientName: string | null
  ): Promise<{
    data: RecipientsForSUStorageWithSU;
    status: number;
    errors: any;
  }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&weeklyDeliveryPlanningId=${weeklyDeliveryPlanningId}&date=${date}&recipientName=${recipientName}`;

    let data: RecipientsForSUStorageWithSU = {} as RecipientsForSUStorageWithSU;
    const response = await this.restClient.get(`/ShippingUnit/Storage/GetListByWeeklyDeliveryPlanningId${queryString}`);
    const status: number = response.status;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }
    return { data, status, errors };
  }

  /**
   * Charge les U.E. lié à un plan/destinataire pour le stockage des U.E.
   * @param planId id du plan
   * @returns
   */
  async getShippingUnitStorageDetailByPlanId(
    planId: number,
    recipientName: string | null
  ): Promise<{
    data: RecipientsForSUStorageWithSU;
    status: number;
    errors: any;
  }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&planId=${planId}&recipientName=${recipientName}`;

    let data: RecipientsForSUStorageWithSU = {} as RecipientsForSUStorageWithSU;
    const response = await this.restClient.get(`/ShippingUnit/Storage/GetListByPlanId${queryString}`);
    const status: number = response.status;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }
    return { data, status, errors };
  }

  /**
   * Charge les U.E. lié à un bon pour le stockage des U.E.
   * @param noteId Id du bon
   * @returns
   */
  async getShippingUnitStorageDetailByNoteId(
    noteId: number,
    recipientName: string | null
  ): Promise<{
    data: RecipientsForSUStorageWithSU;
    status: number;
    errors: any;
  }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&noteId=${noteId}&recipientName=${recipientName}`;

    let data: RecipientsForSUStorageWithSU = {} as RecipientsForSUStorageWithSU;
    const response = await this.restClient.get(`/ShippingUnit/Storage/GetListByNoteId${queryString}`);
    const status: number = response.status;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }
    return { data, status, errors };
  }

  async getLocalisationListForOperatorMissionPriorisationAsync(
    date: string
  ): Promise<{ data: LocalizationWithQuantity[]; status: number; errors: any }> {
    this.loading = true;

    const queryString = `?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}&date=${date}`;

    let data: LocalizationWithQuantity[] = {} as LocalizationWithQuantity[];
    const response = await this.restClient.get(`Localization/GetLocalisationListForOperatorMissionPriorisation${queryString}`);
    const status: number = response.status;
    let errors: any = {};
    if (status === 200) {
      data = response.data;
    } else {
      errors = response.data;
    }
    this.loading = false;
    return { data, status, errors };
  }
  //#endregion Stockage des UE
}
