
import { Component, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { RouteConfig } from "vue-router";
import { isEnabled } from "vue-feature-flipping";
interface module {
  moduleName: string;
  modulePath: string;
  iconClass: string;
  subModules: Array<subModule>;
  featureFlipping: null | { key: string };
}

interface subModule {
  subModuleName: string;
  subModulepath: string;
  iconClass: string;
  rightName: string;
  featureFlipping: null | { key: string };
}

@Component
export default class AppMenu extends Vue {
  get systemInfo() {
    return this.$store.state.userContext.systemInfo;
  }
  get rights() {
    return this.$store.state.userContext.rights;
  }
  get functionalities() {
    return this.$store.state.userContext.functionalities;
  }

  get drawer() {
    return this.$store.state.drawerContext.leftDrawer;
  }
  set drawer(state) {
    this.$store.commit("drawerContext/CHANGE_LEFT_DRAWER", state);
  }

  /**
   * Recuperation des menu en fonction des droits utilisateur
   */
  get modulesSousModules(): Array<module> {
    //Liste des modules et sous modules disponible
    let routeList = this.$router.options.routes;
    let modules: Array<module> = [];
    if (routeList) {
      modules = routeList
        .filter(
          (element: RouteConfig) =>
            "meta" in element && element.meta != undefined && "type" in element.meta && element.meta.type === "module"
        )
        .map((mod) => {
          return {
            moduleName: mod.meta?.breadcrumb[0].text as string,
            subModules: mod.children
              ? mod.children.map((sub) => {
                  return {
                    subModuleName: sub.meta?.breadcrumb[0].text as string,
                    subModulepath: `${mod.path}/${sub.path}` as string,
                    iconClass: sub.meta?.iconClass as string,
                    rightName: sub.meta?.rightName as string,
                    featureFlipping: sub.meta?.featureFlipping ? sub.meta.featureFlipping : null,
                  } as subModule;
                })
              : [],
            modulePath: `${mod.path}`,
            iconClass: mod.meta?.iconClass,
            featureFlipping: mod.meta?.featureFlipping ? mod.meta.featureFlipping : null,
          };
        });
    }
    return modules
      .map((moduleName) => this.filterSubModules(moduleName)) //filtrage des sous modules avec les droits
      .filter((moduleName) => moduleName.subModules.length > 0) // filtrage des module vide
      .filter(this.filterFeatureFlippingModule); // filtrage featureFlipping des modules
  }

  filterFeatureFlippingModule(module: module) {
    let isEnabledModule = true;
    // filtrage des menus en fonction du feature flipping
    if (module.featureFlipping) {
      isEnabledModule = isEnabled(module.featureFlipping.key);
    }
    return isEnabledModule;
  }

  /**
   * largeur du menu en fonction du device
   */
  get sizeLeftSidebar(): string {
    if (this.$vuetify.breakpoint.mobile) {
      return "100vw";
    } else {
      return "280";
    }
  }

  // Filtrage des sous modules avec les droits utilisateur
  filterSubModules(element: module) {
    element.subModules = element.subModules.filter((subModule) => {
      let accepted = false;
      // filtrage des menus en fonction du droit ou du statu administrateur
      accepted =
        (this.rights.includes(subModule.rightName) && this.functionalities.includes(subModule.rightName)) ||
        this.$store.state.userContext.isAdministrator ||
        subModule.rightName === undefined;

      // filtrage des menus en fonction du feature flipping
      if (subModule.featureFlipping) {
        accepted = accepted && isEnabled(subModule.featureFlipping.key);
      }

      return accepted;
    });

    return element;
  }

  /**
   * Methode de fermeture du menu gauche sur un click de menu
   * (force le menu a se replier)
   */
  closeDrawer() {
    this.drawer = false;
  }
}
