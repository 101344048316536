import Vue from "vue";
import RestClient from "@/app/shared/services/restClient"; // Roadmap to get api
import { AxiosInstance, AxiosResponse } from "axios";
import store from "@/app/app-state";

import Vuex from "vuex";

export interface UserInfos {
  id: null | number;
  code: null | string;
  name: null | string;
  surname: null | string;
  initials: null | string;
  signature: null | string;
  mail: null | string;
  phone1: null | string;
  phone2: null | string;
  fax: null | string;
  fonction: null | string;
  isAdministrator: boolean;
}

Vue.use(Vuex);
export default class UserStore {
  private loading: boolean;
  private restClient: AxiosInstance;

  constructor(baseUrl: string) {
    this.loading = false;
    this.restClient = new RestClient(baseUrl).Instance;
  }

  /**
   * Recuperation des infos de l'utilisateur
   */
  async getComplementaryInfo(): Promise<UserInfos> {
    let data: UserInfos = {
      id: null,
      code: null,
      name: null,
      surname: null,
      initials: null,
      signature: null,
      mail: null,
      phone1: null,
      phone2: null,
      fax: null,
      fonction: null,
      isAdministrator: false,
    };

    await this.restClient
      .get(`UserInfos?companyCode=${store.state.userContext.currentCompany}&warehouseCode=${store.state.userContext.currentWarehouse}`)
      .then((response: AxiosResponse) => {
        if (response.status == 200 || response.status == 204) {
          data = response.data;
        }
      });

    return data;
  }
}
