
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class NotificationComponent extends Vue {
  @Prop() notification!: Notification;

  get getcolor() {
    switch (this.notification.type) {
      case NotificationType.success:
        return "success";
      case NotificationType.warning:
        return "warning";
      case NotificationType.error:
        return "error";
      default:
        return "";
    }
  }

  get getIcon() {
    switch (this.notification.type) {
      case NotificationType.success:
        return "mdi-checkbox-marked-circle";
      case NotificationType.warning:
        return "mdi-alert";
      case NotificationType.error:
        return "mdi-alert-octagon";
      default:
        return "";
    }
  }
}

export enum NotificationType {
  // eslint-disable-next-line no-unused-vars
  success,
  // eslint-disable-next-line no-unused-vars
  warning,
  // eslint-disable-next-line no-unused-vars
  error,
  // eslint-disable-next-line no-unused-vars
  null,
}

export class Notification {
  notif = false;
  message = "";
  type: NotificationType = NotificationType.null;
  timeout = 1000;

  //constructor
  constructor(message: string, type: NotificationType, timeout?: number) {
    this.message = message;
    this.type = type;
    this.timeout = timeout || 1000;
  }

  show() {
    this.notif = true;
  }
}
