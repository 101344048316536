
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ItemUpdateComponent extends Vue {
  @Prop() item!: any;
  dialogEdit = false;
  isMobile = false;

  editItemConfirm() {
    this.$emit("onUpdateItem", this.item);
    this.dialogEdit = false;
  }
}
