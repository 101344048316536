
import { Component, Vue } from "vue-property-decorator";
import userController from "../shared/services/controllers/user/userController";
import { apiUrls } from "@/environment/environment";
// eslint-disable-next-line no-unused-vars
import { UserInfos } from "../shared/store/userStore";

@Component
export default class Profile extends Vue {
  private controller: userController;
  constructor() {
    super();
    this.controller = new userController(apiUrls.user);
  }

  userInfos: UserInfos = {
    id: null,
    code: null,
    name: null,
    surname: null,
    initials: null,
    signature: null,
    mail: null,
    phone1: null,
    phone2: null,
    fax: null,
    fonction: null,
    isAdministrator: false,
  };

  /**
   * Deconnexion de l'utilisateur
   */
  logoutfn() {
    const redirectUri = this.$route.fullPath;
    this.$store
      .dispatch("userContext/logout")
      .then(() => this.$router.push({ name: "login", query: { redirectUri: redirectUri } }))
      .catch((error) => {
        //TODO : ajouter un message d'erreur sur la page
        Vue.$log.error(error);
      });
  }

  /**
   * Recuperation des information utilisateur
   */
  async getComplementaryInfo() {
    this.controller.Log.logDebug(`getComplementaryInfo from Profile`, "");
    try {
      let reponse = await this.controller.UserStore.getComplementaryInfo();
      this.userInfos = reponse;
    } catch (error) {
      await this.controller.Log.logError(`getComplementaryInfo`, "", error, "profile");
    }
  }

  created() {
    this.getComplementaryInfo();
  }
}
