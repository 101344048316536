
// eslint-disable-next-line no-unused-vars
import ControllerBase from "@/app/shared/services/controllers/controllerBase";
import { Component, Vue, Prop } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  RecipientsForSUStorage,
  // eslint-disable-next-line no-unused-vars
  ShippingUnitForStorage,
  // eslint-disable-next-line no-unused-vars
  WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage,
} from "../../shared/types/ShippingUnit";

import StorageShippingUnitsConsultationShippingUnitDetail from "../../storageShippingUnitsConsultation/components/StorageShippingUnitsConsultationShippingUnitDetail.vue";

@Component({
  components: { StorageShippingUnitsConsultationShippingUnitDetail },
})
export default class SliderCardPreparation extends Vue {
  @Prop() recipient!: RecipientsForSUStorage;
  @Prop() shippingUnitForStorage!: ShippingUnitForStorage | WeeklyDeliveryPlanningPlanNoteForShippigUnitStorage;
  @Prop() loading!: boolean;

  getColor(recipient: any) {
    // si 0/X Ue faite alors en rouge
    // si 1à X-1 /X UE fait alors c'est en cour en bleu
    // si X/X UE fait alors en vert
    let color = "blue--text";

    if (recipient.stockedShippingUnitCount === 0) {
      color = "red--text";
    } else if (recipient.stockedShippingUnitCount === recipient.totalShippingUnit) {
      color = "green--text";
    }

    return color;
  }

  /**
   * Appel a la methode du composant de detail pour ouvrir la dialogbox
   */
  openDetail() {
    (this.$refs.StorageShippingUnitsConsultationShippingUnitDetail as any).openDetail();
  }
}
