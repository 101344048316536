
import { Component, Prop, Vue } from "vue-property-decorator";

import moment from "moment";
import "moment/locale/fr";
// eslint-disable-next-line no-unused-vars
import ControllerBase from "@/app/shared/services/controllers/controllerBase";
moment.locale("fr");

@Component({
  name: "modalComponent",
  components: {},
})
export default class ModalComponent extends Vue {
  @Prop() controller!: ControllerBase;
  @Prop() modalId!: string;
  @Prop() modalTitle!: string;
  @Prop({ default: false }) modalHideFooter!: boolean;
  @Prop({ default: true }) modalStatic!: boolean;
  @Prop({
    default: "Ok",
    validator: function (value) {
      // La valeur passée doit être l'une de ces chaines de caractères
      return ["ValidateCancel", "YesNo", "Ok"].indexOf(value as string) !== -1;
    },
  })
  buttons!: string;
  @Prop({ default: true }) noCloseOnBackdrop!: boolean;

  id = this.modalId;
  title = this.modalTitle;
  loadingBtn = false;

  dialog = false;

  get dynamicModal(): any {
    return null;
  }

  showModal() {
    this.dialog = true;
  }

  closeModal() {
    this.dialog = false;
  }

  showLoadingBtn() {
    this.loadingBtn = true;
  }

  hideLoadingBtn() {
    this.loadingBtn = false;
  }

  async beforeMount() {
    this.controller.EventBus.$on("showLoadingBtn", await this.showLoadingBtn);
    this.controller.EventBus.$on("hideLoadingBtn", await this.hideLoadingBtn);
  }

  async beforeDestroy() {
    this.controller.EventBus.$off("showLoadingBtn", await this.showLoadingBtn);
    this.controller.EventBus.$off("hideLoadingBtn", await this.hideLoadingBtn);
  }
}
