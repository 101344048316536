
import { Component, Vue, Watch } from "vue-property-decorator";
import StockController from "../shared/services/controllers/stock/stockController";
import ListOperatorMissionPriority from "./components/ListOperatorMissionPriority.vue";
import ZoneSelectRederer from "./components/renderer/ZoneSelectRederer.vue";
import OperatorSelectRederer from "./components/renderer/OperatorSelectRederer.vue";
import AdvancementRederer from "./components/renderer/AdvancementRederer.vue";
import OperatorMissionPriorityDatatableNoteNumberRenderer from "./components/renderer/OperatorMissionPriorityDatatableNoteNumberRenderer.vue";
import OperatorMissionPriorityFilters from "./components/OperatorMissionPriorityFilters.vue";
import OperatorMissionPriorityZoneFilters from "./components/OperatorMissionPriorityZoneFilters.vue";
import OperatorMissionPriorityOperatorFilters from "./components/OperatorMissionPriorityOperatorFilters.vue";
import OperatorMissionPriorityPalletsDetailIterator from "./components/OperatorMissionPriorityPalletsDetailIterator.vue";

import { apiUrls } from "@/environment/environment";
// eslint-disable-next-line no-unused-vars
import { FieldType, Filter } from "@/app/shared/components/generic/list/V3/Type";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import { HeadersMap } from "@/app/shared/components/generic/list/V3/ListComponentV3.vue";
// eslint-disable-next-line no-unused-vars
import { PlanOrNote } from "../shared/store/Types";
import { mapState } from "vuex";

@Component({
  computed: mapState("OperatorMissionPriorityModule", { optionalFilters: "queryFilters", filterPalletNumber: "filterPalletNumber" }),
  components: {
    ListOperatorMissionPriority,
    ZoneSelectRederer,
    OperatorSelectRederer,
    AdvancementRederer,
    OperatorMissionPriorityDatatableNoteNumberRenderer,
    OperatorMissionPriorityFilters,
    OperatorMissionPriorityZoneFilters,
    OperatorMissionPriorityOperatorFilters,
    OperatorMissionPriorityPalletsDetailIterator,
  },
})
export default class OperatorMissionPriority extends Vue {
  private controller: StockController;
  private noteId: number | null = null;
  private note: PlanOrNote | null = null;
  isDetailsOpened = false;
  constructor() {
    super();
    this.controller = new StockController(apiUrls.stock, apiUrls.user);
  }

  dialog = false;
  tab: any | null = null;
  /**
   * Filtre sur la date oblgatoire (setter a date du jours par default)
   */
  filterDate = "";
  filterDateModel: string = new Date().toISOString().substr(0, 10);
  filterPalletNumber!: string | null;
  /**
   * Format de la chaine en fonction du type
   */

  /**
   * Tableau de filtre optionnel (les valeur sont de la forme ["key=value"])
   */
  optionalFilters!: Filter[];
  @Watch("optionalFilters")
  async onOptionalFiltersChanged() {
    this.note = null;
  }
  formatValue(value: string, header: HeadersMap): string {
    switch (header.fieldType) {
      case FieldType.date:
        value = moment(value).format("L");
        break;
      case FieldType.decimal:
        value = Number(value).toFixed(header.decimalPrecision ? header.decimalPrecision : 3);
        break;
      default:
        break;
    }
    return value;
  }

  retrieveController() {
    let stockBaseUrl = apiUrls.stock;
    let userBaseUrl = apiUrls.user;

    this.controller = new StockController(stockBaseUrl, userBaseUrl);
  }

  /**
   * On modifie le filterdate seulement au changement du model
   */
  filterDateChange() {
    this.note = null;
    this.filterDate = this.filterDateModel;
  }

  getClass(header: any) {
    if (header?.align) {
      return `text-${header.align}`;
    }
  }

  toogleDetail(note: PlanOrNote) {
    if (this.note?.noteId != note.noteId) {
      this.note = note;
      this.isDetailsOpened = true;
    } else {
      this.isDetailsOpened = !this.isDetailsOpened;
    }
  }

  created() {
    this.retrieveController();
  }

  getAlign(align: string) {
    return `justify-${align}`;
  }

  async beforeMount() {
    this.filterDate = this.filterDateModel;
  }
}
