
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { Operator, PlanOrNote } from "@/app/stock/shared/store/Types";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { isEnabled } from "vue-feature-flipping";

@Component({
  components: {},
})
export default class OperatorSelectRederer extends Vue {
  @Prop() props!: any;
  @Prop() controller!: StockController;

  get defaultRendere() {
    let planOrNote = this.props.item as PlanOrNote;
    if (this.props.value.length == 0 && !planOrNote.isMultiOperator) {
      return "";
    }
    return planOrNote.isMultiOperator || this.props.value.length > 1
      ? "Multi"
      : `${this.props.value[0].name} - ${this.props.value[0].forname}`;
  }

  planOrNote: PlanOrNote | null = null;

  loading = false;
  operators: Operator[] = [];
  operatorsSelected: Operator[] = [];
  // selection d'operator unique
  operatorSelected: Operator | null = null;
  /**
   * watch to autocomplete for search customers
   * @param {string} value
   * @param {string} oldValue
   */
  @Watch("operatorSelected")
  operatorSelectedChange(value: Operator): void {
    if (!this.isFeatureMultiOperator) {
      this.operatorsSelected = value ? [value] : [];
    }
  }

  operatorMulti: Operator = { objectId: 0, id: 0, code: "multi", name: "multi", forname: "multi" };

  /**
   * Methode de filtrage des caristes (algo defiltrage)
   */
  filterFindOperator(item: any, queryText: string, itemText: string) {
    return (
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  /**
   * Stop la propagation de l'evenement
   * (Pour selectionner le cariste dans la combo avec touche entrer sans valider la modification)
   */
  stopEventOnSelectByKeydown(event: any) {
    event.stopPropagation();
  }

  get isFeatureMultiOperator() {
    return isEnabled("operatorMissionPriorityMultiOperator");
  }

  get saveDisabled() {
    // le bouton est desactivé si multi cariste ou si aucune modification
    return this.operatorsSelected.includes(this.operatorMulti); // || // on est sur Multi en selection
  }

  saveOperators() {
    this.controller.EventBus.$emit("operatorMissionPriorityUpdateOperators", this.props.item, this.operatorsSelected);
    (this.$refs["dialog"] as any).isActive = false; // fermeture de edit dialog
  }

  cancelOperatorsChange() {
    this.operatorsSelected = [];
  }

  async open() {
    this.planOrNote = this.props.item as PlanOrNote;

    // chargement des zones disponible
    await this.setOperatorsList();
    // set l'element selection seulement si il y en a qu'un
    if (this.isFeatureMultiOperator) {
      this.operatorsSelected = this.props.value;
    } else {
      this.operatorSelected = this.props.value[0];
    }
  }

  close() {
    //vide
  }

  /**
   * Chargement de la liste des caristes par appel a l'API
   */
  async setOperatorsList() {
    this.loading = true;
    try {
      this.controller.Log.logDebug(`OperatorSelectRederer setOperatorsList`, "");

      let sendResult: { data: Operator[]; itemsCount: number } = await this.controller.StockStore.getOperators();

      this.operators = sendResult.data;
    } catch (error) {
      //Log
      await this.controller.Log.logError(`OperatorSelectRederer setOperatorsList`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }

  /**
   * Action sur le changement de selection
   */
  operatorsChange() {
    if (this.isFeatureMultiOperator) {
      // suppression de la selection multiple lors de selection ou clear
      let index = this.operatorsSelected.indexOf(this.operatorMulti);
      if (index >= 0) {
        this.operatorsSelected.splice(index, 1);
      }
    }
  }
}
