
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";

import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SensRederer extends Vue {
  @Prop() props!: any;
  @Prop() controller!: StockController;

  get sensMouvement() {
    let sens = "";
    switch (this.props.value) {
      case 1:
        sens = "Entrée";
        break;
      case -1:
        sens = "Sortie";
        break;
    }
    return sens;
  }
}
