
import { Component, Vue, Watch } from "vue-property-decorator";

import preparationControlController from "./shared/services/controllers/preparationControl/preparationControlController";
import ListPreparationControlByPlan from "./components/list/byPlan/ByPlan.vue";
import ListPreparationControlByNote from "./components/list/byNote/ByNote.vue";
import ListPreparationControlByBpc from "./components/list/byBpc/ByBpc.vue";
import ListPreparationControlByCarrier from "./components/list/byCarrier/ByCarrier.vue";

import ListPalletsControl from "./components/list/palletsControl/PalletsControl.vue";

import FilterAutoComplete from "./components/filter/FilterAutoComplete.vue";
import { apiUrls } from "@/environment/environment";
// eslint-disable-next-line no-unused-vars
import { TypeControle } from "./shared/store/Types";
const moduleName = "preparationControl";

@Component({
  components: {
    ListPreparationControlByPlan,
    ListPreparationControlByNote,
    ListPreparationControlByBpc,
    ListPreparationControlByCarrier,
    FilterAutoComplete,
    ListPalletsControl,
  },
})
export default class PreparationControl extends Vue {
  private controller: preparationControlController;
  constructor() {
    super();
    this.controller = new preparationControlController(apiUrls.stock);
  }
  dateControle: string | null = null;
  typeControle: TypeControle | null = null;
  typeControleItems: TypeControle[] = [
    {
      id: 0,
      label: "Bon",
      filterItems: [
        { label: "N° bon", code: "noteNumber", value: null },
        {
          label: "D.O",
          searchCode: "contractor",
          items: [],
          code: "id",
          value: null,
        },
      ],
    },
    {
      id: 1,
      label: "Bpc",
      filterItems: [
        { label: "N° BPC", code: "noteNumber", value: null },
        { label: "D.O", code: "contractorCode", searchCode: "contractor", items: [], value: null },
      ],
    },
    {
      id: 2,
      label: "Plan",
      filterItems: [
        { label: "N° Plan", code: "planNumber", type: "number", value: null },
        {
          label: "Transporteur",
          searchCode: "carrier",
          items: [],
          code: "carrierName",
          value: null,
        },
      ],
    },
    {
      id: 3,
      label: "Transporteur",
      filterItems: [
        { label: "Transporteur", searchCode: "carrier", items: [], code: "carrierName", value: null },
        { label: "Immatriculation", code: "licensePlate", value: null },
      ],
    },
  ];
  eanPallet: string | null = null;
  plan: any | null = null;

  errorMessage: string | null = null;
  errorNotif = false;

  successMessage: string | null = null;
  successNotif = false;

  current_step = 1;
  nbPallets = 0;

  get dynamicCol() {
    if (this.typeControle && this.typeControle.filterItems) {
      return Math.round(12 / this.typeControle.filterItems.length);
    } else return 12;
  }

  @Watch("plan")
  onPlan(): void {
    // rafraichissement de la list de palette
    this.refreshListPallets();
  }

  @Watch("successNotif")
  onSuccessNotif(newVal: string): void {
    // Initialisation du message d'erreur a la fermeture de la notif
    if (!newVal) {
      this.successMessage = null;
    }
  }

  @Watch("errorNotif")
  onErrorNotif(newVal: string): void {
    // Initialisation du message d'erreur a la fermeture de la notif
    if (!newVal) {
      this.errorMessage = null;
    }
  }

  @Watch("current_step")
  onCurrentStep(): void {
    window.scrollTo(0, 0);
  }

  /**
   * chargement des controler
   */
  retrieveController() {
    this.controller = new preparationControlController(apiUrls.stock);
  }

  /**
   * action sur changement de date
   */
  dateChange() {
    Vue.$log.debug("dateChange");
  }
  /**
   * Vide le CAB
   */
  clearCabAndPlan() {
    this.eanPallet = null;
    this.plan = null;
  }
  /**
   * Vide le CAB
   */
  clearCabAndFocus() {
    (this.$refs as any).eanPallet.focus();
    this.eanPallet = null;
  }

  /**
   * rafraichissement de la liste
   */
  async refreshList() {
    if (this.dateControle && this.typeControle) {
      switch (this.typeControle.id) {
        case 0:
          await (this.$refs as any).listPalletsControl.emptyList();
          await (this.$refs as any).listPreparationControlByNote.refreshList();
          break;
        case 1:
          await (this.$refs as any).listPalletsControl.emptyList();
          await (this.$refs as any).listPreparationControlByBpc.refreshList();
          break;
        case 2:
          await (this.$refs as any).listPalletsControl.emptyList();
          await (this.$refs as any).listPreparationControlByPlan.refreshList();
          break;
        case 3:
          await (this.$refs as any).listPalletsControl.emptyList();
          await (this.$refs as any).listPreparationControlByCarrier.refreshList();
          break;

        default:
          break;
      }
      this.closeSnackBar();
    }
  }

  /**
   * rafraichissement de la liste de palette
   */
  async refreshListPallets() {
    await (this.$refs as any).listPalletsControl.refreshList();
    // on place le focus sur le CAB
    this.clearCabAndFocus();
    this.closeSnackBar();
  }

  /**
   * Flashage du CAB bon ou UE
   */
  async changeBarCodeNoteOrUE() {
    this.closeSnackBar();
    this.controller.Log.logDebug(`changeBarCodeNoteOrUE`, `Object query : ${JSON.stringify(this.eanPallet)}`);
    let sendResult: any = { data: [], itemsCount: 0 };
    try {
      // appel API pour recuperer le bon et ses infos
      if (this.plan && this.eanPallet && this.typeControle) {
        sendResult = await this.controller.preparationControlStore.controlFromBarCode(this.plan, this.typeControle, this.eanPallet);

        if (sendResult.status === 200) {
          this.successMessage = `Contrôle validé\n`;
          this.successNotif = true;
        } else {
          // traitement de l'erreur et affichage du message d'erreur
          if (sendResult.data) {
            let listError = sendResult.data;
            if (sendResult.data.errors) {
              listError = sendResult.data.errors;
            }
            this.errorMessage = "";
            Object.entries(listError).forEach(([key, value]) => {
              if (key == "barcode" || key == "barCode") key = "Code à barre";
              this.errorMessage += `${key}: ${value}\n`;
            });
            // Affichage de la notif d'erreur
            this.errorNotif = true;
          }
        }
      } else {
        this.errorMessage = "";
        this.errorMessage = "CAB non renseigné.\n";
        this.errorNotif = true;
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`changeBarCodeNoteOrUE`, "", error, moduleName);
      sendResult = null;
    }
    if (this.nbPallets > 1) {
      await (this.$refs as any).listPalletsControl.refreshList();
      this.clearCabAndFocus();
    } else if (this.nbPallets <= 1 && this.errorNotif == false) {
      this.successMessage = "Plus de palette à contrôler !";
      if (this.typeControle) {
        switch (this.typeControle.id) {
          case 0:
            await (this.$refs as any).listPreparationControlByNote.refreshList();
            break;
          case 1:
            await (this.$refs as any).listPreparationControlByBpc.refreshList();
            break;
          case 2:
            await (this.$refs as any).listPreparationControlByPlan.refreshList();
            break;
          case 3:
            await (this.$refs as any).listPreparationControlByCarrier.refreshList();
            break;

          default:
            break;
        }
      }
      this.clearCabAndPlan();
    } else {
      this.clearCabAndFocus();
    }
  }

  /**
   * Fermeture de la snackBar
   */
  closeSnackBar() {
    this.errorNotif = false;
    (this.$refs as any).eanPallet.focus();
  }

  mounted() {
    this.dateControle = new Date().toISOString().substr(0, 10);
  }

  created() {
    this.retrieveController();
  }
}
