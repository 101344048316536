
import { Component, Prop } from "vue-property-decorator";
import ListComponent from "@/app/shared/components/generic/list/ListComponentV2.vue";
import moment from "moment";
import "moment/locale/fr";

import BadgeRenderer from "./renderer/BadgeRenderer.vue";
import ButtonBadgeRenderer from "./renderer/ButtonBadgeRenderer.vue";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { ColDef } from "node_modules/ag-grid-community/main";
// eslint-disable-next-line no-unused-vars
import { AffectedNoteDetail } from "@/app/stock/shared/store/Types";

moment.locale("fr");

@Component({
  components: {
    BadgeRenderer,
    ButtonBadgeRenderer,
  },
})
export default class ListShippingUnit extends ListComponent {
  @Prop() declare controller: StockController;
  @Prop() icon!: string;
  @Prop() currentOrderPreparation!: AffectedNoteDetail;
  @Prop() isResumptionMode!: boolean;

  onRowClicked = "";
  onRowDoubleClicked = "";

  initialColumnDefs: ColDef[] = [];

  async onGridReady(params: any) {
    let gridApi = params.api;

    // Remplissage du datasource avec la liste des UE
    this.dataSource = {
      rowCount: null,
      getRows: async (params: any) => {
        // let sendResult = { data: [], itemsCount: 0 };
        let sendResult: { data: any | null; itemsCount: number } | null = { data: null, itemsCount: 0 };
        if (this.isResumptionMode) {
          let rawResult: any = await this.controller.StockStore.getPausedShippingUnits();
          sendResult.data = rawResult.data.shippingUnits;
          sendResult.itemsCount = rawResult.data.shippingUnits.length;
        } else {
          sendResult.itemsCount = this.currentOrderPreparation.shippingUnits.length;
          sendResult.data = this.currentOrderPreparation.shippingUnits;
        }
        params.successCallback(sendResult.data, sendResult.itemsCount);
      },
    };
    gridApi.setDatasource(this.dataSource);
  }

  isFirstColumn(params: any) {
    let displayedColumns = params.columnApi.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onFirstDataRendered(params: any) {
    //resize des colonnes pour remplir la grille
    params.api.sizeColumnsToFit();
  }

  getFirstShippingUnit() {
    // On prends par default la premiere ligne de la grille
    let firstRowNodeSelected = this.gridOptions.api.getDisplayedRowAtIndex(0);
    // si on a une ligne de selectionner on remonte la ligne selectionnee
    let rowNodeSelected = this.gridOptions.api.getSelectedNodes();
    if (rowNodeSelected.length > 0) {
      firstRowNodeSelected = rowNodeSelected[0];
    }

    if (firstRowNodeSelected) {
      return firstRowNodeSelected.data;
    } else {
      return null;
    }
  }

  getShippingUnit(event: any) {
    this.controller.EventBus.$emit("getShippingUnit", event.data);
  }

  refreshList() {
    this.refreshData();
  }

  async beforeMount() {
    this.columnDefs = [
      this.numericColumn("id", {
        headerName: "Id",
        hide: true,
        cellRenderer: this.gridOptions.components.loadingRenderer,
      }),
      this.textColumn("number", {
        headerName: "N° U.E.",
        field: "number",
      }),
      this.textColumn("externalNumber", {
        headerName: "N° Ext.",
        field: "externalNumber",
      }),
      this.textColumn("sscc", {
        headerName: "SSCC",
        field: "sscc",
      }),
      this.numericColumn("quantityPrepared", {
        headerName: "Préparé",
      }),
      this.numericColumn("quantityToPrepare", {
        headerName: "A préparer",
      }),

      this.textColumn("status", {
        headerName: "Statut",
        maxWidth: 45,
        editable: false,
        cellClass: "d-flex justify-content-center align-items-center",
        pinned: "right",
        field: "status",
        resizable: false,
        suppressSizeToFit: true,
        cellRendererFramework: ButtonBadgeRenderer,
        cellRendererParams: {
          status: "status", // Complementing the Cell Renderer parameters
          action: this.getShippingUnit,
        },
        cellStyle: { "text-align": "center" },
      }),
    ];

    this.defaultColDef.suppressMenu = true;
    this.defaultColDef.filter = false;
    this.defaultColDef.sortable = false;
    this.defaultColDef.resizable = true;
    this.defaultColDef.floatingFilterComponentParams = { suppressFilterButton: true };

    this.gridOptions.paginationPageSize = 10;

    this.gridOptions.pagination = false;
    this.gridOptions.suppressPaginationPanel = true;
    this.gridOptions.suppressScrollOnNewData = true;

    this.clearFilterButtonVisibility = false;
    this.toggleCheckboxIsVisibility = false;
  }
}
