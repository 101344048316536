
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { Operator, PalletPrioritization } from "@/app/stock/shared/store/Types";
import { Component, Vue, Prop } from "vue-property-decorator";

import { isEnabled } from "vue-feature-flipping";

@Component
export default class OperatorMissionPriorityPalletsDetailDataTableOperatorSelectRenderer extends Vue {
  @Prop() props!: any;
  @Prop() controller!: StockController;

  get defaultRendere() {
    if (this.props.value.length == 0) {
      return "";
    }
    return this.props.value.length > 1 ? "Multi" : `${this.props.value[0].name} - ${this.props.value[0].forname}`;
  }

  palletPrioritization: PalletPrioritization = this.props?.item as PalletPrioritization;

  loading = false;
  operators: Operator[] = [];
  operatorsSelected: Operator[] = [];
  // selection d'operator unique
  operatorSelected: Operator | null = null;

  operatorMulti: Operator = { objectId: 0, id: 0, code: "multi", name: "multi", forname: "multi" };

  filterFindOperator(item: any, queryText: string, itemText: string) {
    return (
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  /**
   * Stop la propagation de l'evenement
   * (Pour selectionner le cariste dans la combo avec touche entrer sans valider la modification)
   */
  stopEventOnSelectByKeydown(event: any) {
    event.stopPropagation();
  }

  get isFeatureMultiOperator() {
    return isEnabled("operatorMissionPriorityMultiOperator");
  }

  get saveDisabled() {
    // le bouton est desactivé si multi cariste ou si aucune modification
    return this.operatorsSelected.includes(this.operatorMulti); // || // on est sur Multi en selection
  }

  async saveOperators() {
    this.palletPrioritization.operators = this.operatorsSelected;
    this.updateOperator();
    (this.$refs["dialog"] as any).isActive = false; // fermeture de edit dialog
  }

  cancelOperatorsChange() {
    this.operatorsSelected = Object.assign([], this.props.value);
  }

  remove(item: Operator) {
    const index = this.operatorsSelected.indexOf(item);
    if (index >= 0) this.operatorsSelected.splice(index, 1);
  }

  async open() {
    // chargement des caristes disponible
    await this.setOperatorsList();

    this.setInitialValue();
  }

  close() {
    //vide
  }

  /**
   * Appel au set des items lors de la prise de focus sur la combobox en mono cariste
   */
  onFocus() {
    if (this.operators.length === 0) {
      this.setOperatorsList();
    }
  }

  /**
   * Chargement de la liste des caristes par appel a l'API
   */
  async setOperatorsList() {
    this.loading = true;
    try {
      this.controller.Log.logDebug(`zoneSelectRederer setOperatorsList`, "");

      let sendResult: { data: Operator[]; itemsCount: number } = await this.controller.StockStore.getOperators();

      this.operators = sendResult.data;
    } catch (error) {
      //Log
      await this.controller.Log.logError(`zoneSelectRederer setOperatorsList`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }

  /**
   * Initialisation des variables
   */
  setInitialValue() {
    this.palletPrioritization = this.props.item as PalletPrioritization;
    // set l'element selection seulement si il y en a qu'un
    if (this.isFeatureMultiOperator) {
      this.operatorsSelected = Object.assign([], this.props.value);
    } else {
      this.operatorSelected = this.props.value[0];
    }
  }

  /**
   * Action sur le changement de selection en multi cariste
   */
  operatorsChange() {
    if (this.isFeatureMultiOperator) {
      // suppression de la selection multiple lors de selection ou clear
      let index = this.operatorsSelected.indexOf(this.operatorMulti);
      if (index >= 0) {
        this.operatorsSelected.splice(index, 1);
      }
    }
  }

  /**
   * Action sur le changement de selection en mono cariste
   */
  operatorChange() {
    if (this.operatorSelected) {
      this.palletPrioritization.operators = [this.operatorSelected];
    } else {
      this.palletPrioritization.operators = [];
    }
    this.updateOperator();
  }

  /**
   * Mise a jours de la palette avec le cariste selectionné
   */
  async updateOperator() {
    this.controller.EventBus.$emit("operatorMissionPriorityUpdateOperatorPallet", this.palletPrioritization);
  }

  mounted() {
    this.setInitialValue();
  }
}
