import RestClient from "@/app/shared/services/restClient"; // Roadmap to get api
import { AxiosInstance } from "axios";
import { QueryUserPref } from "./Types";

export default class UserStore {
  private loading: boolean;
  private restClient: AxiosInstance;
  private saveTimer: number | undefined;

  constructor(baseUrl: string) {
    this.loading = false;
    this.restClient = new RestClient(baseUrl).Instance;
  }

  /**
   * Sauvegarde des parametres utilisateur
   * @param {Query} query
   */
  async saveUserPreferences(query: QueryUserPref) {
    let data: any;
    window.clearTimeout(this.saveTimer);
    this.saveTimer = window.setTimeout(async () => {
      const response = await this.restClient.post(`/Preferences/SetPreferences`, query);

      data = response;
    }, 2000);

    return data;
  }

  /**
   * Sauvegarde des parametres utilisateur sans timer
   * @param {Query} query
   */
  async saveUserPreferencesWithoutTimer(query: QueryUserPref) {
    const response = await this.restClient.post(`/Preferences/SetPreferences`, query);
    return response;
  }

  /**
   * Sauvegarde des parametres utilisateur
   * @param {Query} query
   */
  async getUserPreferences(query: QueryUserPref) {
    const response = await this.restClient.post(`/Preferences/GetPreferences`, query);
    return response.data;
  }
}
