
import { Component, Vue, Watch } from "vue-property-decorator";

import StockController from "../shared/services/controllers/stock/stockController";
import ListStockComponent from "./components/list/ListStockComponent.vue";
import ModalUpdate from "./components/modal/ModalFormUpdate.vue";
import { apiUrls } from "@/environment/environment";
// eslint-disable-next-line no-unused-vars
import { NoteHeader, ThirdWarehouse } from "../shared/store/Types";

@Component({
  components: {
    ListStockComponent,
    ModalUpdate,
  },
})
export default class ComponentsUpdate extends Vue {
  private controller: StockController;
  constructor() {
    super();
    this.controller = new StockController(apiUrls.stock, apiUrls.user);
  }
  customer: null | ThirdWarehouse = null;
  customers: ThirdWarehouse[] = [];
  searchCustomers: null | string = null;
  searchDate: null | string = null;
  noteHeader: null | NoteHeader = null;
  noteHeaders: NoteHeader[] = [];
  searchNoteHeaders: null | string = null;
  queryFilterElementsUpdate!: null;

  /**
   * watch to autocomplete for search customers
   * @param {string} value
   * @param {string} oldValue
   */
  @Watch("searchCustomers")
  onSearchCustomersChanged(value: string): void {
    if (value && (!this.customer || this.customer.codeName !== value)) {
      this.queryCustomers(value);
    }
  }

  /**
   * watch to autocomplete for search note number with customer and date
   * @param {string} value
   * @param {string} oldValue
   */
  @Watch("searchNoteHeaders")
  onSearchNoteHeadersChanged(value: string): void {
    if (value) {
      this.searchNoteHeadersOnFocus(value);
    }
  }

  /**
   * rafraichit la liste quand on change l'activation.
   * @param {boolean} value
   * @param {boolean} oldValue
   */
  @Watch("isDisable")
  onIsDisableChanged(value: boolean, oldValue: boolean): void {
    if (value !== oldValue) {
      if (value) {
        this.controller.EventBus.$emit("refreshList");
      }
    }
  }

  /**
   * indique si on active les boutons de modification
   */
  get isDisable(): boolean {
    return !(this.searchDate && this.customer && this.noteHeader);
  }

  /**
   * appeler en cas de changement de D.O.
   */
  changeCustomer(): void {
    this.searchDate = null;
  }

  /**
   * appeler au changement de date de bon.
   */
  dateChange(): void {
    this.noteHeader = null;
  }

  /**
   * Get data from stockStore to autocomplete customers
   * @param {string} searchQuery
   */
  async queryCustomers(searchQuery: string) {
    let response = await this.controller.StockStore.getCustomerSuggests(searchQuery);
    if (response.data) {
      this.customers = response.data;
      for (let c of this.customers) {
        c.codeName = `${c.code} - ${c.name}`;
      }
    } else {
      this.customers = [];
    }
  }

  /**
   * charge la liste de suggestion de n° de bon quand il prend le focus.
   * @param {string} query
   */
  searchNoteHeadersOnFocus(query: string) {
    if ((!this.noteHeader || this.noteHeader.noteNumber !== query) && this.customer && this.customer.id) {
      this.queryNoteHeaders(query, this.searchDate, this.customer.id);
    }
  }

  /**
   * Get data from stockStore to autocomplete note Number
   * @param searchNoteheader
   * @param searchDate
   * @param searchCustomer
   */
  async queryNoteHeaders(searchNoteheader: string, searchDate: null | string, searchCustomer: number) {
    let response = await this.controller.StockStore.getNoteHeaderSuggests(searchNoteheader, searchDate, searchCustomer);
    if (response.data) {
      this.noteHeaders = response.data;
    } else {
      this.noteHeaders = [];
    }
  }

  /**
   *  affiche la fenètre de modification.
   */
  showModalUpdate() {
    if (this.customer && this.searchDate && this.noteHeader) {
      let form = this.$refs.form as any;
      this.queryFilterElementsUpdate = form.getQueryFilterElementsUpdate();
      this.controller.EventBus.$emit("showModalUpdate", this.queryFilterElementsUpdate, this.customer);
    }
  }

  /**
   * appeler à la sélection d'un DO
   */
  async changeCustomerCode() {
    this.controller.EventBus.$emit("refreshList");
  }

  retrieveController() {
    let stockBaseUrl = apiUrls.stock;
    let userBaseUrl = apiUrls.user;

    this.controller = new StockController(stockBaseUrl, userBaseUrl);
  }

  created() {
    this.retrieveController();
  }
}
