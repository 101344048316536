
import { Component, Vue } from "vue-property-decorator";

import StockController from "../shared/services/controllers/stock/stockController";
import ListOrderPreparation from "./components/list/ListOrderPreparation.vue";
import ListShippingUnit from "./components/list/ListShippingUnit.vue";
import ModalDetail from "./components/modal/ModalOrderPreparationDetail.vue";
import ModalPreparationInstructions from "./components/modal/ModalPreparationInstructions.vue";

import { apiUrls } from "@/environment/environment";

import {
  // eslint-disable-next-line no-unused-vars
  AffectedNoteDetail,
  // eslint-disable-next-line no-unused-vars
  ComponentToPrepare,
  // eslint-disable-next-line no-unused-vars
  PreparationMode,
  // eslint-disable-next-line no-unused-vars
  QueryUserPref,
  // eslint-disable-next-line no-unused-vars
  ShippingUnit,
  // eslint-disable-next-line no-unused-vars
  ShippingUnitToPrepare,
} from "../shared/store/Types";

const moduleName = "Stock";
const subModuleName = "OrderPreparation";
@Component({
  components: {
    ListOrderPreparation,
    ListShippingUnit,
    ModalDetail,
    ModalPreparationInstructions,
  },
})
export default class OrderPreparation extends Vue {
  private controller: StockController;
  constructor() {
    super();
    this.controller = new StockController(apiUrls.stock, apiUrls.user);
  }
  currentCompany: string = this.$store.state.userContext.currentCompany;
  currentWarehouse: string = this.$store.state.userContext.currentWarehouse;

  current_step = 1;
  isResumptionMode = false;
  currentOrderPreparation: AffectedNoteDetail | null = null;
  currentShippingUnit: ShippingUnit | null = null;

  currentShippingUnitToPrepare: ShippingUnitToPrepare | null = null;
  currentComponantToPrepare: ComponentToPrepare | null = null;

  eanCodeUE: string | null = null;
  eanCodePreparation: string | null = null;
  eanCodeParcel: string | null = null;

  preparationMode: null | undefined;
  preparationModeItems: PreparationMode[] = [];

  withMissing = false;

  errorMessage: string | null = null;
  errorNotif = false;

  /**
   * Action sur la validation du nombre de colis a prendre (keyPress enter)
   */
  takeComponant(event: any) {
    Vue.$log.debug(event);
    //TODO : voir l'action eventuel a cette endroit
    Vue.$log.debug("currentComponantToPrepare");
    Vue.$log.debug(this.currentComponantToPrepare);
    // pour selectionner le text complet
    //event.target.select()

    // on place le focus sur le CAB Preparation
    (this.$refs as any).refEanCodePreparation.focus();
  }

  retrieveController() {
    this.controller = new StockController(apiUrls.stock, apiUrls.user);

    // set la select box des mode de preparation
    this.getPreparationModeItems();
  }

  /**
   * Lancement de la preparation de affichage du detail de la preparation
   */
  async showShippingUnitDetail(affectedNoteid: number) {
    if (await this.startPrepareFromApi(affectedNoteid)) {
      await this.getAllOrderPreparationDetail(affectedNoteid);
    }
  }

  /**
   * Affichage du detail de la preparation
   */
  async getAllOrderPreparationDetail(affectedNoteid: number) {
    this.controller.Log.logDebug(`showShippingUnitDetail`, `Object query : ${JSON.stringify(affectedNoteid)}`);
    let sendResult: AffectedNoteDetail | null;

    try {
      sendResult = await this.controller.StockStore.getAllOrderPreparationDetail(affectedNoteid);

      this.currentOrderPreparation = sendResult;

      this.current_step = 2;
    } catch (error) {
      //Log
      await this.controller.Log.logError(`showShippingUnitDetail`, "", error, moduleName);
      sendResult = null;
    }
  }

  /**
   * Lancement de la preparation
   */
  async startPrepareFromApi(affectedNoteid: number) {
    this.controller.Log.logDebug(`startPrepareFromApi`, `Object query : ${JSON.stringify(affectedNoteid)}`);
    let sendResult: any = { data: [], itemsCount: 0 };

    try {
      sendResult = await this.controller.StockStore.startPrepareFromApi(affectedNoteid);
      if (sendResult.status === 200) {
        return true;
      } else {
        // traitement de l'erreur et affichage du message d'erreur
        if (sendResult.data) {
          let listError = sendResult.data;
          if (sendResult.data.errors) {
            listError = sendResult.data.errors;
          }

          let errorMessage = "";
          Object.entries(listError).forEach(function ([clé, valeur]) {
            errorMessage += `${clé}: ${valeur}\n`;
          });
          //TODO : peut etre afficher une notif
          alert(`Erreur :\n ${errorMessage}`);
        }
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`startPrepareFromApi`, "", error, moduleName);
      sendResult = null;
    }

    return false;
  }

  async prevStep() {
    // Quand on revient sur l'étape précédente on vérifie sur quelle étape on est,
    // ça permet de savoir quelles variables doivent vidées, pour éviter des potentiels erreurs
    switch (this.current_step) {
      case 2:
        if (!this.isResumptionMode && this.currentOrderPreparation)
          this.controller.StockStore.stopPreparation(this.currentOrderPreparation.id);
        this.isResumptionMode = false;
        this.withMissing = false;
        this.currentOrderPreparation = null;
        this.currentShippingUnit = null;
        this.currentShippingUnitToPrepare = null;
        this.currentComponantToPrepare = null;

        this.current_step = 1;

        break;
      case 3:
        if (this.currentShippingUnit) {
          this.controller.StockStore.stopShippingUnit(this.currentShippingUnit, this.isResumptionMode, false);
        }

        if (!this.isResumptionMode && this.currentShippingUnit) {
          // rafraichissement de la liste des UE
          await this.getAllOrderPreparationDetail(this.currentShippingUnit.preparationID);
        }
        this.currentShippingUnitToPrepare = null;
        this.currentComponantToPrepare = null;

        this.current_step = 2;

        break;
    }
  }

  firstStep() {
    // on vide tous les variables comme ça on est sur que tout ira bien quand on revient à l'étape 1
    this.current_step = 1;
    this.currentOrderPreparation = null;
    this.currentShippingUnit = null;
    this.currentShippingUnitToPrepare = null;
    this.currentComponantToPrepare = null;
    this.isResumptionMode = false;
  }

  async nextComponent() {
    if (this.currentShippingUnit && this.currentComponantToPrepare) {
      this.currentComponantToPrepare = await this.controller.StockStore.getNextComponent(
        this.currentShippingUnit,
        this.currentComponantToPrepare,
        this.withMissing
      );
      if (this.currentShippingUnitToPrepare) {
        await this.setNextCurrentShippingUnitToPrepare();
      } else {
        this.errorMessage = "Erreur lors de la récupération des données. Réessayez plus tard.";
      }
    }
  }

  /**
   * Affichage des instruction de preparation
   */
  showModalPreparationInstructions() {
    this.controller.EventBus.$emit("showModalPreparationInstructions", this.currentOrderPreparation);
  }

  onFocus(event: any) {
    Vue.$log.debug(event);
    // on vide le eanCodeUE lors de la prise du focus
    this.eanCodeUE = null;
  }

  onFocusEanCodePreparation(event: any) {
    Vue.$log.debug(event);
    // on vide le eanCodePreparation lors de la prise du focus
    this.eanCodePreparation = null;
  }

  /**
   * Flashage du code bar de l'UE
   */
  async changeBarCode() {
    this.currentShippingUnit = null;
    if (this.eanCodeUE) {
      // Appel a l'API pour prendre l'UE flashé
      this.currentShippingUnit = await this.getShippingUnitFromApi(this.eanCodeUE);
    } else {
      // On prendre la premiere UE (ou plutot l'UE selectionné dans la grille)
      this.currentShippingUnit = (this.$refs as any).listShippingUnit.getFirstShippingUnit();
    }

    if (this.currentShippingUnit) {
      await this.getCurrentComponantToPrepare();
    } else {
      //TODO : peut etre afficher une notif
      alert("pas d'UE correspondante");
    }
  }

  /**
   * Creation de l'UE
   */
  createShippingUnit() {
    //TODO : appeler l'API pour creer l'UE
    // this.currentShippingUnit prendra la valeur de l'UE cree
    this.currentShippingUnit = null;
    Vue.$log.debug("createShippingUnit");
    Vue.$log.debug(this.eanCodeUE);
    this.current_step = 3;
  }

  /**
   * recuperation de l'UE courante
   */
  async getShippingUnit(param: any) {
    this.currentShippingUnit = null;
    if (param) {
      this.currentShippingUnit = await this.getShippingUnitFromApi(param.number);
      if (this.currentShippingUnit) {
        await this.getCurrentComponantToPrepare();
      }
    }
  }

  /**
   * Appel Api pour obtenir un UE via code bar
   */
  async getShippingUnitFromApi(barcode: string) {
    this.controller.Log.logDebug(`getShippingUnitFromApi`, `Object query : ${JSON.stringify(barcode)}`);
    let sendResult: any = { data: [], itemsCount: 0 };
    let shippingUnit: ShippingUnit | null = null;

    try {
      sendResult = await this.controller.StockStore.getShippingUnitFromBarCode(barcode);

      shippingUnit = sendResult.data;
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getShippingUnitFromApi`, "", error, moduleName);
      sendResult = null;
    }

    return shippingUnit;
  }

  /**
   * recuperation des composant a preparer
   */
  async getCurrentComponantToPrepare() {
    // Verification du start preparation de l'UE
    if (await this.startShippingUnitPrepareFromApi()) {
      this.currentShippingUnitToPrepare = await this.getCurrentComponantToPrepareFromApi(this.withMissing);
      if (this.currentShippingUnitToPrepare) {
        await this.setNextCurrentShippingUnitToPrepare();
      } else {
        //TODO ajouter notification d'erreur
      }
    }
  }

  /**
   * Lancement de la preparation de l'UE
   */
  async startShippingUnitPrepareFromApi() {
    this.controller.Log.logDebug(
      `startShippingUnitPrepareFromApi`,
      `Object query : ${JSON.stringify(this.currentShippingUnit)} ${JSON.stringify(this.isResumptionMode)}`
    );
    let sendResult: any = { data: [], itemsCount: 0 };

    try {
      if (this.currentShippingUnit) {
        sendResult = await this.controller.StockStore.startShippingUnitPrepareFromApi(this.currentShippingUnit, this.isResumptionMode);
        if (sendResult.status === 200) {
          return true;
        } else {
          // traitement de l'erreur et affichage du message d'erreur
          if (sendResult.data) {
            let listError = sendResult.data;
            if (sendResult.data.errors) {
              listError = sendResult.data.errors;
            }
            let errorMessage = "";
            Object.entries(listError).forEach(function ([clé, valeur]) {
              errorMessage += `${clé}: ${valeur}\n`;
            });
            //TODO : peut etre afficher une notif
            alert(`Erreur :\n ${errorMessage}`);
          }
        }
      } else {
        // TODO : voir peut etre a jouter des infos de debug pour elements null
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`startShippingUnitPrepareFromApi`, "", error, moduleName);
      sendResult = null;
    }

    return false;
  }

  /**
   * Appel Api pour la recuperation des composant apreparer
   */
  async getCurrentComponantToPrepareFromApi(withMissing: boolean) {
    this.controller.Log.logDebug(
      `getCurrentComponantToPrepare`,
      `Object query : ${JSON.stringify(this.currentOrderPreparation)}${JSON.stringify(this.currentShippingUnit)}`
    );
    let sendResult: any = { data: [], itemsCount: 0 };
    let currentShippingUnitToPrepare: ShippingUnitToPrepare | null = null;
    try {
      if (this.currentShippingUnit) {
        sendResult = await this.controller.StockStore.getComponantToPrepare(this.currentShippingUnit, withMissing);
        currentShippingUnitToPrepare = sendResult.data;
      } else {
        //TODO : peut etre ajouter des info de debug pour elements null
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getCurrentComponantToPrepare`, "", error, moduleName);
      sendResult = null;
    }

    return currentShippingUnitToPrepare;
  }

  async enterResumptionMode() {
    this.isResumptionMode = true;
    this.withMissing = true;
    this.current_step = 2;
  }

  /**
   * Appel Api pour la recuperation des mode de preparation
   */
  async getPreparationModeItems() {
    this.controller.Log.logDebug(`getPreparationModeItems`, ``);
    let sendResult: any = { data: [], itemsCount: 0 };
    this.preparationModeItems = [];

    try {
      sendResult = await this.controller.StockStore.getPreparationModeItems();

      this.preparationModeItems = sendResult.data;
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getPreparationModeItems`, "", error, moduleName);
      sendResult = null;
    }

    this.getUserPreferences();
  }

  /**
   * Declartion d'une rupture de composant
   */
  async ruptureClick() {
    this.controller.Log.logDebug(`ruptureClick`, ``);
    let sendResult: any = { data: null, itemsCount: 0 };

    try {
      if (this.currentShippingUnitToPrepare && this.currentComponantToPrepare) {
        sendResult = await this.controller.StockStore.setMissingComponent(
          this.currentShippingUnitToPrepare,
          this.currentComponantToPrepare,
          this.withMissing
        );
        if (sendResult.data) {
          this.currentShippingUnitToPrepare = sendResult.data;
          await this.setNextCurrentShippingUnitToPrepare();
        } else {
          //TODO ajouter notification d'erreur
        }
      } else {
        //TODO: voir peut etre a ajouter des info de debug pour elements null
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`ruptureClick`, "", error, moduleName);
      sendResult = null;
    }
  }

  /**
   * Set l'UE suivante
   */
  async setNextCurrentShippingUnitToPrepare() {
    if (this.currentShippingUnitToPrepare) {
      if (
        Array.isArray(this.currentShippingUnitToPrepare.componentsToPrepares) &&
        this.currentShippingUnitToPrepare.componentsToPrepares.length >= 1
      ) {
        // si on a un composant suivant, on le prends et on continue le traitement
        this.currentComponantToPrepare = this.currentShippingUnitToPrepare.componentsToPrepares[0];
        this.current_step = 3;
        Vue.nextTick().then(() => {
          // Prise de focus sur le CAB de preparation
          (this.$refs as any).refEanCodePreparation.focus();
        });
      } else {
        // appel au set du premier element de la liste des composant a preparer de l'UE avec les manquant
        if (await this.setInitShippingUnitToPrepare(true)) {
          if (
            // si on a des elements a preparer c'est qu'il y a des manquants
            Array.isArray(this.currentShippingUnitToPrepare.componentsToPrepares) &&
            this.currentShippingUnitToPrepare.componentsToPrepares.length >= 1
          ) {
            if (!this.withMissing) {
              // si on est pas deja dans la reprise des manquant on demande de traitement des manquants
              this.withMissing = window.confirm("Traiter les manquants ?");
            }

            if (this.withMissing) {
              // on prends le manquant qui se presente
              this.currentComponantToPrepare = this.currentShippingUnitToPrepare.componentsToPrepares[0];
              this.current_step = 3;
              Vue.nextTick().then(() => {
                // Prise de focus sur le CAB de preparation
                (this.$refs as any).refEanCodePreparation.focus();
              });
            } else {
              // demande de cloture de l'UE
              let forceClose = window.confirm("Plus d'element non manquant a preparer. Cloturer de l'UE ?");
              if (this.currentShippingUnit) {
                this.controller.StockStore.stopShippingUnit(this.currentShippingUnit, this.isResumptionMode, forceClose);
              } else {
                // TODO : peut etre ajouter des message de debug pour les elements null
              }
              if (this.currentOrderPreparation) {
                await this.getAllOrderPreparationDetail(this.currentOrderPreparation.id);
              } else {
                // TODO : peut etre ajouter des message de debug pour les elements null
              }
              await (this.$refs as any).listShippingUnit.refreshList();
              this.current_step = 2;
            }
          } else {
            // demande de cloture de l'UE
            let forceClose = window.confirm("Plus d'element a preparer. Cloturer de l'UE ?");
            if (this.currentShippingUnit) {
              this.controller.StockStore.stopShippingUnit(this.currentShippingUnit, this.isResumptionMode, forceClose);
            } else {
              // TODO : peut etre ajouter des message de debug pour les elements null
            }
            if (this.currentOrderPreparation) {
              await this.getAllOrderPreparationDetail(this.currentOrderPreparation.id);
            } else {
              // TODO : peut etre ajouter des message de debug pour les elements null
            }
            await (this.$refs as any).listShippingUnit.refreshList();
            this.current_step = 2;
          }
        } else {
          //TODO ajouter notification d'erreur propre
          alert("pas d'UE remonté par API");
        }
      }
    } else {
      //TODO ajouter notification d'erreur propre
      alert("pas d'UE remonté par API");
    }
  }

  /**
   * Set l'UE initial (avec le premier composatn a preparer de l'UE)
   */
  async setInitShippingUnitToPrepare(withMissing: boolean) {
    this.currentShippingUnitToPrepare = await this.getCurrentComponantToPrepareFromApi(withMissing);
    return this.currentShippingUnitToPrepare ? true : false;
  }

  /**
   * Flashage du code bar de l'emplacement
   */
  async changeBarCodePreparation() {
    if (this.eanCodePreparation) {
      this.controller.Log.logDebug(`changeBarCodePreparation`, ``);
      let sendResult: any = { data: [], itemsCount: 0 };

      try {
        if (this.currentShippingUnitToPrepare && this.currentComponantToPrepare && this.eanCodePreparation) {
          sendResult = await this.controller.StockStore.prepareUEWithBarCode(
            this.currentShippingUnitToPrepare,
            this.currentComponantToPrepare,
            this.eanCodePreparation,
            this.withMissing
          );
          if (sendResult.data) {
            if (sendResult.status === 200) {
              this.currentShippingUnitToPrepare = sendResult.data;
              await this.setNextCurrentShippingUnitToPrepare();
            } else {
              // traitement de l'erreur et affichage du message d'erreur
              if (sendResult.data) {
                let listError = sendResult.data;
                if (sendResult.data.errors) {
                  listError = sendResult.data.errors;
                }

                this.errorMessage = "";
                Object.entries(listError).forEach(([clé, valeur]) => {
                  this.errorMessage += `${clé}: ${valeur}\n`;
                });
                //TODO : peut etre afficher une notif
                //alert(`Erreur :\n ${errorMessage}`);
                this.errorNotif = true;
              }
            }
          } else {
            //TODO ajouter notification d'erreur
          }
        } else {
          // TODO : peut etre ajouter des message de debug pour les elements null
        }
      } catch (error) {
        //Log
        await this.controller.Log.logError(`changeBarCodePreparation`, "", error, moduleName);
        sendResult = null;
      }

      Vue.nextTick().then(() => {
        // Prise de focus sur l'element de saisie des nombre de composant
        (this.$refs as any).refEanCodePreparation.focus();
        this.eanCodePreparation = null;
      });
    }
  }

  /**
   * changement de mode de preparation
   */
  preparationModeChange() {
    this.savePreferences();
  }

  /**
   * Save user prefenreces when they changes
   */
  async savePreferences() {
    let labelDataType = "preparationMode";
    //Format data
    let data = { preparationMode: this.preparationMode };

    let query = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: JSON.stringify(data),
    };

    this.controller.Log.logDebug(`saveUserPreferences`, `Object query : ${JSON.stringify(query)}`);

    try {
      await this.controller.UserStore.saveUserPreferences(query);
    } catch (error) {
      //Log
      await this.controller.Log.logError(`saveUserPreferences`, "", error, moduleName);
    }
  }

  /**
   * Set user preference sur la grille
   */
  async getUserPreferences() {
    //refresh ag-grid with preference in database

    let labelDataType = "preparationMode";

    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: null,
    };

    this.controller.Log.logDebug(`getUserPreferences`, `Object query : ${JSON.stringify(query)}`);
    let sendResult = null;
    try {
      sendResult = await this.controller.UserStore.getUserPreferences(query);

      let data = JSON.parse(sendResult.data);

      if (data) {
        let preparationMode = data.preparationMode;

        if (
          this.preparationModeItems.some(function (e) {
            // comparaison des deux objets
            return e.id === preparationMode.id && e.code === preparationMode.code && e.label === preparationMode.label;
          })
        ) {
          // set la valeur par defaut de la selectbox
          this.preparationMode = preparationMode;
        }
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getUserPreferences`, "", error, moduleName);
      sendResult = null;
    }
  }

  /**
   * Fermeture de la notification et reprise de focus sur le CAB de preparation
   */
  closeErrorNotif() {
    this.errorNotif = false;
    // Prise de focus sur le CAB de preparation
    (this.$refs as any).refEanCodePreparation.focus();
  }

  async beforeMount() {
    this.controller.EventBus.$on("showShippingUnitDetail", await this.showShippingUnitDetail);
    this.controller.EventBus.$on("getShippingUnit", await this.getShippingUnit);
  }

  created() {
    this.retrieveController();
  }

  async beforeDestroy() {
    this.controller.EventBus.$off("showShippingUnitDetail", await this.showShippingUnitDetail);
    this.controller.EventBus.$off("getShippingUnit", await this.getShippingUnit);
  }
}
