
import { Component, Vue } from "vue-property-decorator";

import ButtonFavoris from "@/app/shared/components/buttonFavoris/ButtonFavoris.vue";

@Component({
  components: {
    ButtonFavoris,
  },
})
export default class AppHeader extends Vue {
  get year(): number {
    return new Date().getFullYear();
  }

  get leftDrawer(): boolean {
    return this.$store.state.drawerContext.leftDrawer;
  }
  set leftDrawer(state: boolean) {
    this.$store.commit("drawerContext/CHANGE_LEFT_DRAWER", state);
  }

  get rightDrawer(): boolean {
    return this.$store.state.drawerContext.rightDrawer;
  }
  set rightDrawer(state) {
    this.$store.commit("drawerContext/CHANGE_RIGHT_DRAWER", state);
  }

  get items(): any[] {
    return this.$route.matched.map((x) => x.meta.breadcrumb[0]);
  }

  /** Methode pour passer l'application en full screen */
  toogleFullScreen() {
    // interface de type pour le passage en plein ecran
    interface DocumentWithFullscreen extends HTMLDocument {
      mozFullScreenElement?: Element;
      msFullscreenElement?: Element;
      webkitFullscreenElement?: Element;
      msExitFullscreen?: () => void;
      mozCancelFullScreen?: () => void;
      webkitExitFullscreen?: () => void;
    }

    interface DocumentElementWithFullscreen extends HTMLElement {
      msRequestFullscreen?: () => void;
      mozRequestFullScreen?: () => void;
      webkitRequestFullscreen?: () => void;
    }

    const doc = document as DocumentWithFullscreen;

    if (
      !doc.fullscreenElement &&
      // alternative standard method
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      const elem = document.documentElement as DocumentElementWithFullscreen;

      // current working methods
      if (doc.documentElement.requestFullscreen) {
        doc.documentElement.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    } else {
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      }
    }
  }
}
