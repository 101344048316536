import onScan from "onscan.js";

const ScanHelper = class {
  /**
   * Listener de code bar sur le document
   * @param onBarCode
   */
  public listenForBarcode(onBarCode: any) {
    // Enable scan events for the entire document
    onScan.attachTo(document, {
      reactToKeydown: true,
      reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
      timeBeforeScanTest: 100,
      avgTimeByChar: 30,
      suffixKeyCodes: [9, 13], // tab and enter-key expected at the end of a scan
      onScan: function (barCode: any) {
        onBarCode(barCode);
      },
      //le scan remplace les "-" par un code 189, on le re-remplace donc
      keyCodeMapper: function (oEvent: any) {
        if (oEvent.which == "189") {
          return "-";
        }
        return onScan.decodeKeyEvent(oEvent);
      },
    });
  }

  /**
   * Arret du listener
   */
  public stopListening() {
    onScan.detachFrom(document);
  }
};

export default new ScanHelper();
