
import { Component, Prop } from "vue-property-decorator";
import ListComponent from "@/app/shared/components/generic/list/ListComponentV2.vue";
import moment from "moment";
import "moment/locale/fr";

import BadgeRenderer from "./renderer/BadgeRenderer.vue";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { ColDef } from "node_modules/ag-grid-community/main";
// eslint-disable-next-line no-unused-vars
import { AffectedPreparedNote, QueryUserPref } from "@/app/stock/shared/store/Types";

moment.locale("fr");

const moduleName = "Stock";
const subModuleName = "OrderPreparation";
const labelDataType = "ListOrderPreparation";

@Component({
  components: {
    BadgeRenderer,
  },
})
export default class ListOrderPreparation extends ListComponent {
  @Prop() declare controller: StockController;
  @Prop() icon!: string;

  onRowClicked = "";
  onRowDoubleClicked = "";

  initialColumnDefs: ColDef[] = [];

  listInittialize = true;

  currentCompany: string = this.$store.state.userContext.currentCompany;
  currentWarehouse: string = this.$store.state.userContext.currentWarehouse;

  get hideDefaultColumns() {
    return screen.width < 768;
  }

  async onGridReady(params: any) {
    let gridApi = params.api;

    this.dataSource = {
      rowCount: null,
      getRows: async (params: any) => {
        let query = this.fillListWithFilterAndSort(params);

        this.controller.Log.logDebug(`listOrderPreparation`, `Object query : ${JSON.stringify(query)}`);
        //let sendResult = { data: [], itemsCount: 0 };
        let sendResult: { data: AffectedPreparedNote[]; itemsCount: number } | null = { data: [], itemsCount: 0 };

        try {
          sendResult = await this.controller.StockStore.getAllOrderPreparation(query);

          params.successCallback(sendResult?.data, sendResult?.itemsCount);
        } catch (error) {
          //Log
          await this.controller.Log.logError(`listOrderPreparation onGridReady`, "", error, moduleName);
          sendResult = null;
        }
      },
    };
    gridApi.setDatasource(this.dataSource);
  }

  /** Event when column state change */
  onColumnEvent() {
    if (!this.listInittialize) {
      this.savePrefrences();
    }
  }

  /** Event when filter or sort change */
  onModelUpdated() {
    if (!this.listInittialize) {
      this.savePrefrences();
    }
  }

  /** Save user prefenreces when they changes */
  async savePrefrences() {
    if (this.gridColumnApi) {
      //Format data
      let data = this.gridColumnApi.getColumnState();

      let query = {
        moduleName: moduleName,
        subModuleName: subModuleName,
        labelDataType: labelDataType,
        companyCode: this.currentCompany,
        warehouseCode: this.currentWarehouse,
        data: JSON.stringify(data),
      };

      this.controller.Log.logDebug(`saveUserPreferences`, `Object query : ${JSON.stringify(query)}`);

      try {
        await this.controller.UserStore.saveUserPreferences(query);
      } catch (error) {
        //Log
        await this.controller.Log.logError(`saveUserPreferences`, "", error, moduleName);
      }
    }
  }

  /** Set user preference sur la grille */
  async setUserPrefrences() {
    //refresh ag-grid with preference in database
    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: null,
    };

    this.controller.Log.logDebug(`getUserPreferences`, `Object query : ${JSON.stringify(query)}`);
    let sendResult = null;
    try {
      sendResult = await this.controller.UserStore.getUserPreferences(query);

      let data = JSON.parse(sendResult.data);

      //set grid columns, sort, filter
      if (data) {
        let data = JSON.parse(sendResult.data);
        if (data.columnState) {
          this.gridColumnApi.setColumnState(data.columnState);
        }
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getUserPreferences`, "", error, moduleName);
      sendResult = null;
    }
    //}
  }

  onRowSelected(row: any) {
    if (row.node.isSelected()) {
      this.controller.EventBus.$emit("showShippingUnitDetail", row.data.id);
    }
  }

  isFirstColumn(params: any) {
    let displayedColumns = params.columnApi.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onFirstDataRendered(params: any) {
    //resize des colonnes pour remplir la grille
    params.api.sizeColumnsToFit();
  }

  /** Affichage de la modale pour le detail */
  showDetails(params: any) {
    this.controller.EventBus.$emit("showModalDetailPrera", params);
  }

  async beforeMount() {
    this.columnDefs = [
      this.numericColumn("id", {
        headerName: "Id",
        hide: true,
        cellRenderer: this.gridOptions.components.loadingRenderer,
      }),
      this.textColumn("contractor.code", {
        headerName: "Code D.O.",
        field: "contractor.code",
      }),
      this.dateColumn("bpcDate", {
        headerName: "Date de bon",
      }),
      this.numericColumn("bpcNumber", {
        headerName: "N° B.P.C.",
      }),
      this.textColumn("recipient.name", {
        headerName: "Nom du destinataire",
        field: "recipient.name",
        hide: this.hideDefaultColumns,
      }),
      this.numericColumn("quantityPrepared", {
        headerName: "Nb de colis préparés",
        hide: this.hideDefaultColumns,
      }),
      this.numericColumn("quantityToPrepare", {
        headerName: "Nb de colis à préparer",
        hide: this.hideDefaultColumns,
      }),
      this.textColumn("status", {
        headerName: "Statut",
        maxWidth: 45,
        editable: false,
        cellClass: "d-flex justify-content-center align-items-center",
        pinned: "right",
        field: "status",
        resizable: false,
        suppressSizeToFit: true,
        cellRendererFramework: BadgeRenderer,
        cellRendererParams: {
          status: "status", // Complementing the Cell Renderer parameters
        },
        cellStyle: { "text-align": "center" },
      }),
      this.buttonColumn(
        "detail",
        { icon: "fa-light fa-circle-info", action: this.showDetails, title: "detail", btnClass: "btn" },
        {
          headerName: "",
          maxWidth: 45,
          editable: false,
          cellClass: "d-flex justify-content-center align-items-center prepaDetail",
          pinned: "right",
          resizable: false,
          suppressSizeToFit: true,
          cellStyle: { "text-align": "center" },
        }
      ),
    ];

    this.defaultColDef.suppressMenu = true;
    this.defaultColDef.filter = false;
    this.defaultColDef.sortable = false;
    this.defaultColDef.resizable = true;
    this.defaultColDef.floatingFilterComponentParams = { suppressFilterButton: true };

    this.gridOptions.rowMultiSelectWithClick = false;

    this.gridOptions.paginationPageSize = 10;
    this.gridOptions.cacheBlockSize = 10;

    this.gridOptions.pagination = false;
    this.gridOptions.suppressPaginationPanel = true;
    this.gridOptions.suppressScrollOnNewData = true;

    this.gridOptions.suppressRowClickSelection = true;
    this.gridOptions.onCellClicked = (e: any) => {
      if (e.column.colId !== "detail") {
        // cell is from non-select column
        e.node.setSelected(true);
      }
    };

    this.setUserPrefrences();
  }
}
