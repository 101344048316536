
// import Vue from "vue";
import { Component, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { Route } from "vue-router";

interface WithRoute /* can also extend Vue to make sure nothing is colliding */ {
  $route: Route;
}

@Component
export default class RightDrawer extends Vue implements WithRoute {
  get code() {
    return this.$store.state.userContext.code;
  }

  get currentCompany() {
    return this.$store.state.userContext.currentCompany;
  }

  get currentWarehouse() {
    return this.$store.state.userContext.currentWarehouse;
  }

  get drawer() {
    return this.$store.state.drawerContext.rightDrawer;
  }

  set drawer(state) {
    this.$store.commit("drawerContext/CHANGE_RIGHT_DRAWER", state);
  }

  get companies() {
    return this.$store.state.userContext.companies;
  }

  /**
   * Action suu bouton de deconnexion
   */
  logout(): void {
    const redirectUri = this.$route.fullPath;
    this.$store
      .dispatch("userContext/logout")
      .then(() => this.$router.push({ name: "login", query: { redirectUri: redirectUri } }))
      .catch((error) => {
        //TODO : ajouter un message d'erreur sur la page
        Vue.$log.error(error);
      });
  }

  /**
   * Update lors d'un changement de site
   * @param companyCode
   * @param warehouseCode
   */
  updateCurrentCompanyWarehouse(companyCode: string, warehouseCode: string): void {
    this.$store
      .dispatch("userContext/updateCurrentCompanyWarehouse", { companyCode: companyCode, warehouseCode: warehouseCode })
      .then(() => {
        if (this.$router.currentRoute.path != "/Home") {
          this.$router.push({ path: "/Home" });
        }
      })
      .catch((error) => {
        //TODO : ajouter un message d'erreur sur la page
        Vue.$log.error(error);
      });
  }
}
