
import { Component, Vue, Watch } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { DataTableHeader } from "node_modules/vuetify";

import moment from "moment";
import "moment/locale/fr";
// eslint-disable-next-line no-unused-vars
import { BadgeRenderer, ButtonRenderer, FieldType, Filter, Operator } from "@/app/shared/components/generic/list/V3/Type";
moment.locale("fr");

// import des components
import FilterComponent from "./components/Filter.vue";
import ItemCreate from "./components/ItemCreate.vue";
import ItemUpdate from "./components/ItemUpdate.vue";
import ItemDelete from "./components/ItemDelete.vue";
import NotificationComponent, { Notification, NotificationType } from "./components/Notification.vue";

// import des renderer
import ButtonRendererComponent from "./renderer/ButtonRenderer.vue";
import BadgeRendererComponent from "./renderer/BadgeRenderer.vue";
import CheckboxRenderer from "./renderer/CheckboxRenderer.vue";

// eslint-disable-next-line no-unused-vars
import { Query } from "@/app/stock/shared/store/Types";

import resizableColumns from "./directives/resizable-columns";
import sortableColumns from "./directives/sortable-columns";
import sortableRow from "./directives/sortable-row";

export interface HeadersMap extends DataTableHeader {
  fieldType: FieldType;
  decimalPrecision?: number;
  hide?: boolean;
  hideable?: boolean;
  renderer?: {
    badge?: BadgeRenderer;
    button?: ButtonRenderer;
    checkbox?: unknown;
    custom?: any;
  };
}

export interface UserPreference {
  fields: string[];
  selectedFields: string[];
  Sorts: { sortBy: string[]; sortDesc: boolean[] };
  filters: Filter[];
}

Vue.directive("resizableColumns", resizableColumns as any);
Vue.directive("sortableColumns", sortableColumns as any);
Vue.directive("sortableRow", sortableRow as any);

@Component({
  name: "listComponentV3",
  components: {
    FilterComponent,
    ItemCreate,
    ItemUpdate,
    ItemDelete,
    NotificationComponent,
    ButtonRendererComponent,
    BadgeRendererComponent,
    CheckboxRenderer,
  },
  /* directives: {
    resizableColumns,
    sortableColumns,
    sortableRow,
  }, */
})
export default class ListComponentV3 extends Vue {
  items: any[] = [];
  itemKey = "id";
  totalItems = 0;
  loading = false;
  options: any = {};
  selectedItems: [] = [];

  // Gestion des entetes de colonnes
  headers: DataTableHeader[] = [];
  headersMap: HeadersMap[] = [];
  selectedHeaders: DataTableHeader[] = []; // liste des header selectionné pour l'affichage

  //préférences utilisateur
  listInittialize = true; // permet de savoir si la liste est en cours d'initialisation (pour eviter d'enregistrer avant d'avoir tous setter a partir des preference utilisateur)
  savedUserPreference: UserPreference = {
    fields: [], // liste de valeur des champs de colonne
    selectedFields: [], // liste des valeur des champs selectionnée
    Sorts: { sortBy: [], sortDesc: [] }, // elements sort et sort desc pris a partir de variable option de la grille
    filters: [], // liste des filtres
  };

  // Gestion de la pagination
  paginate = true;
  currentPage = 1;
  pageCount = 0;
  itemsPerPage = 10;
  totalVisiblePage = 7;

  filters: Filter[] = []; // List des filtres

  // Global Option
  rowCreatable = false; // Possibilite de creer un item
  rowEditable = false; // Possibilite de modifier un item
  rowDeletable = false; // Possibilite de supprimer un item
  deleteNoConfirm = false; // Possibilite de supprimersans conformation
  dialogCreate = false;
  singleSelect = true;
  showSelect = false;
  dragableRow = false;

  // Gestion des ligne expandable
  expandable = false;
  defaultExpandable = true;
  singleExpand = true;
  expanded: [] = [];
  expandDetail: any | null = null; //detail affiché dans l'expander

  // Pour les notifications
  notification: Notification | null = null;

  /**
   * Liste des headers affiché
   */
  get showHeaders(): DataTableHeader[] {
    return this.headers.filter((s: DataTableHeader) => this.selectedHeaders.includes(s));
  }
  /**
   * Pouvoir masque la premiere ligne si pas de filtre dans la grille
   */
  get hasFiltersColumns() {
    return this.showHeaders.findIndex((e) => e.filterable) > 0;
  }

  /**
   * Liste des headers pouvant etre masqué
   */
  get switchableHeaders(): DataTableHeader[] {
    return this.headersMap.filter(
      (s: HeadersMap) =>
        s.value != "actions" &&
        s.value != "data-table-expand" &&
        s.value != "dragHandle" &&
        (s.hideable === undefined || s.hideable === true)
    ) as DataTableHeader[];
  }

  get customRendere(): HeadersMap[] {
    return this.headersMap.filter((s: HeadersMap) => s.renderer);
  }

  /**
   * Format de la chaine en fonction du type
   */
  formatValue(value: string, header: HeadersMap): string {
    switch (header.fieldType) {
      case FieldType.date:
        if (value) {
          value = moment(value).format("L");
        }
        break;
      case FieldType.decimal:
        value = Number(value).toFixed(header.decimalPrecision ? header.decimalPrecision : 3);
        break;
      default:
        break;
    }
    return value;
  }

  @Watch("options")
  onChange(): void {
    if (this.isColMoved) {
      this.isColMoved = !this.isColMoved;
    } else {
      this.getDataFromApi();
    }
  }

  /**
   * Action sur le changement de filtre
   */
  @Watch("filters", { deep: true })
  onfiltersChange(): void {
    this.getDataFromApi();
    this.updateFilters();
  }

  getDataFromApi() {
    Vue.$log.debug("getDataFromApi not implemented");
  }

  clearFilter() {
    this.filters = [];
  }

  //#region CRUD
  /**
   * Appel a la methode de creation
   */
  async onCreateItem() {
    this.createItem()
      .then() // Possibilité d'ajouter des actions apres appel a la methode du parent
      .catch((error) => {
        this.notification = new Notification(`Une erreur est survenu : ${error}`, NotificationType.error);
        this.notification.show();
      })
      .finally();
  }

  /**
   * Methode de creation d'item (a override dans le parent)
   */
  async createItem() {
    Vue.$log.debug("call createItem not implemented");
  }

  /**
   * Appel a la methode de mise a jour
   */
  async onUpdateItem(item: any): Promise<any> {
    this.updateItem(item)
      .then() // Possibilité d'ajouter des actions apres appel a la methode du parent
      .catch((error) => {
        this.notification = new Notification(`Une erreur est survenu : ${error}`, NotificationType.error);
        this.notification.show();
      })
      .finally();
  }

  /**
   * Methode de mise a jour d'item (a override dans le parent)
   */
  async updateItem(item: any): Promise<any> {
    // a implementer dans le parent
    Vue.$log.debug("call updateItem not implemented", item);
  }

  /**
   * Appel a la methode de suppression
   */
  async onDeleteItem(item: any): Promise<any> {
    this.deleteItem(item)
      .then() // Possibilité d'ajouter des actions apres appel a la methode du parent
      .catch((error) => {
        this.notification = new Notification(`Une erreur est survenu : ${error}`, NotificationType.error);
        this.notification.show();
      })
      .finally();
  }

  /**
   * Methode de suppression d'item (a override dans le parent)
   */
  async deleteItem(item: any): Promise<any> {
    // a implementer dans le parent
    Vue.$log.debug("call deleteItem not implemented", item);
  }
  //#endregion CRUD

  //#region enregistrement des preference utilisateur
  /**
   * Save header sur un deplacement de colonne
   */
  saveHeader() {
    this.setPreferenceSetting();
  }

  /**
   * Save selectedHeader sur une selection de colonne a afficher
   */
  updateSelectedHeaders() {
    this.setPreferenceSetting();
  }
  /**
   * Save sorts sur un ordonnancement de colonne
   */
  updateSorts() {
    this.setPreferenceSetting();
  }

  /**
   * Save filters sur une modification des filtres de la grille
   */
  updateFilters() {
    this.setPreferenceSetting();
  }

  /**
   * Set les valeurs des preference utilisateur a sauvergarder
   */
  setPreferenceSetting() {
    this.savedUserPreference.fields = this.headers.map((e: DataTableHeader) => e.value);
    this.savedUserPreference.selectedFields = this.selectedHeaders.map((e: DataTableHeader) => e.value);
    this.savedUserPreference.filters = Object.assign([], this.filters);
    this.savedUserPreference.Sorts = Object.assign({}, { sortBy: this.options.sortBy, sortDesc: this.options.sortDesc });
    // si on est pas en cours d'initialisation
    if (!this.listInittialize) {
      this.saveUserPreference();
    }
  }

  /**
   * Renvoi la liste headers en fonction des preference utilisateur enregistré
   */
  get headersFromUserPreference(): HeadersMap[] {
    return this.headersMap.sort((a: HeadersMap, b: HeadersMap) => {
      let indexA: number | 0 = this.savedUserPreference.fields.findIndex((e: string) => e == a.value);
      let indexB: number | 0 = this.savedUserPreference.fields.findIndex((e: string) => e == b.value);
      return indexA - indexB;
    });
  }

  /**
   * Renvoi la liste selectedHeaders en fonction des preference utilisateur enregistré
   */
  get selectedHeadersFromUserPreference(): HeadersMap[] {
    return this.headersMap
      .map((e: HeadersMap) => {
        let selectedFields: string[] = this.savedUserPreference.selectedFields;
        e.hide = !selectedFields.includes(e.value);
        return e;
      })
      .filter((s: HeadersMap) => !s.hide);
  }

  /**
   * Set les variables a partir des preferences utiliateur
   */
  async setSettingFromPreference() {
    await this.setUserPreferences().then((data: UserPreference | null) => {
      if (data) {
        this.savedUserPreference = data;
        // On replace les colonnes dans l'ordre enregistrée
        if (this.savedUserPreference.fields && this.savedUserPreference.fields.length > 0) {
          this.headers = this.headersFromUserPreference;
        }
        // On replace les colonnes selectionnées
        if (this.savedUserPreference.selectedFields && this.savedUserPreference.selectedFields.length > 0) {
          this.selectedHeaders = this.selectedHeadersFromUserPreference;
        }
        // On replace les filtre des preference utilisateur
        if (this.savedUserPreference.filters) {
          this.filters = this.savedUserPreference.filters as Filter[];
        }

        // On replace les sorts des preference utilisateur
        if (this.savedUserPreference.Sorts && this.savedUserPreference.Sorts.sortBy?.length > 0) {
          this.options.sortBy = this.savedUserPreference.Sorts.sortBy;
          this.options.sortDesc = this.savedUserPreference.Sorts.sortDesc;
        }
      }
    });

    this.listInittialize = false;
  }

  /**
   * Methode a override pour sauvegarder les preference utilisateur
   */
  saveUserPreference() {
    // a implementer dans le parent
  }

  async setUserPreferences(): Promise<UserPreference | null> {
    // a implementer dans le parent
    return null;
  }
  //#endregion enregistrement des preference utilisateur

  anIncreasingNumber = 1;
  isColMoved = false; // determine si uns colonne a bouge de place
  /**
   * Reorganisation des colonnes apres dragandrop
   */
  sortableColumnsEnd(evt: any) {
    const headersTmp = this.headers;
    const oldIndex = this.headers.indexOf(this.showHeaders[evt.oldIndex]);
    const newIndex = this.headers.indexOf(this.showHeaders[evt.newIndex]);

    if (newIndex >= headersTmp.length) {
      let k = newIndex - headersTmp.length + 1;
      while (k--) {
        headersTmp.push();
      }
    }
    headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
    this.anIncreasingNumber += 1;
    this.isColMoved = true;

    this.saveHeader();
  }

  getClassRow() {
    return this.dragableRow ? "sortableRow" : ""; // Ajout de la class pour le sortHandleRow
  }

  //#region Gestion du drag end drop row

  // eslint-disable-next-line no-unused-vars
  dragRowOnChoose(event: any) {
    this.expanded = [];
  }

  /**
   * Debut du deplacement de ligne
   */
  // eslint-disable-next-line no-unused-vars
  dragRowStart(event: any) {
    // Methode a override dans le parent
  }

  // eslint-disable-next-line no-unused-vars
  dragRowOnMove(event: any) {
    // Methode a override dans le parent
  }

  // eslint-disable-next-line no-unused-vars
  dragRowOnSort(event: any) {
    // Methode a override dans le parent
  }

  /**
   * Fin du deplacement de ligne
   */
  dragRowEnd(event: any) {
    const movedItem = this.items.splice(event.oldDraggableIndex, 1)[0];
    this.items.splice(event.newDraggableIndex, 0, movedItem);
  }

  //#endregion Gestion du drag end drop row

  /**
   * Permet de placer le bouton ou non sur un item
   */
  itemCanExpand(item: any) {
    return item ? true : false;
  }

  /**
   * Methode pour setter le detail a afficher dans l'expander
   */
  async loadDetails(item: any) {
    //var firstKey = Object.keys(item.item)[0];
    this.expandDetail = item.item;
  }

  //#region getFilterAndSort
  /**
   * Methode de construction des filtres par methode post
   */
  getFilterAndSort() {
    let query: Query = {
      name: null,
      user: null,
      offset: 0,
      limit: 0,
      filters: [],
      hasFilters: null,
      sorts: [],
      hasSorts: null,
      results: {},
      isValid: null,
    };

    var re = /\./gi;
    this.filters.forEach((element) => {
      Vue.$log.debug("element", element);
      let field = element.field;

      let queryFilterType = "";
      let queryFilterValue = element.value; // TODO : voir peut etre a ajouter un range pour les ope in

      if (element.fieldType == FieldType.date) {
        queryFilterValue = moment(element.value).format("Y-MM-DD") + "T00:00:00";
      }

      switch (element.operator) {
        case Operator.equals:
          queryFilterType = "EQ";
          break;
        case Operator.notEqual:
          queryFilterType = "NEQ";
          break;
        case Operator.lessThan:
          queryFilterType = "LT";
          break;
        case Operator.greaterThan:
          queryFilterType = "GT";
          break;
        case Operator.lessThanOrEqual:
          queryFilterType = "LTE";
          break;
        case Operator.greaterThanOrEqual:
          queryFilterType = "GTE";
          break;
        case Operator.contains:
          queryFilterValue = "%" + queryFilterValue + "%";
          queryFilterType = "LIKE";
          break;
        case Operator.notContains:
          queryFilterValue = "%" + queryFilterValue + "%";
          queryFilterType = "LIKE";
          break;
        case Operator.endsWith:
          queryFilterValue = "%" + queryFilterValue;
          queryFilterType = "LIKE";
          break;
        case Operator.startsWith:
          queryFilterValue = queryFilterValue + "%";
          queryFilterType = "LIKE";
          break;
        case Operator.inRange:
          queryFilterType = "IN";
          break;
        default:
          break;
      }

      if (element.fieldType == FieldType.date && queryFilterType == "IN") {
        //TODO : revoir le range date
        query.filters?.push({ type: queryFilterType, field: field, value: queryFilterValue });
      } else if (element.fieldType == FieldType.date && queryFilterType == "EQ") {
        let startDate = moment(element.value).format("Y-MM-DD") + "T00:00:00";
        let endDate = moment(element.value).add(1, "days").format("Y-MM-DD") + "T00:00:00";

        query.filters?.push({ type: "GTE", field: field, value: startDate });
        query.filters?.push({ type: "LT", field: field, value: endDate });
      } else {
        query.filters?.push({ type: queryFilterType, field: field, value: queryFilterValue });
      }
    });

    // Ajout de la partie ordonancement
    if (this.options.sortBy.length > 0) {
      query.sorts = [];
      for (let key in this.options.sortBy) {
        let sortDesc: boolean = this.options.sortDesc[key];
        //Enleve les points ex: variant.product.ean -> variantproductean
        let sortKey: string = this.options.sortBy[key].replace(re, "");
        let sort = sortDesc ? "dsc" : "asc";
        query.sorts.push({ field: sortKey, direction: sort });
      }
    }

    query.offset = (this.currentPage - 1) * this.itemsPerPage;
    query.limit = this.itemsPerPage;

    return query;
  }

  /**
   * Methode de construction des filtres par methode Get filtre dans querystring
   * TODO : le formatage des la querystring est a revoir
   */
  getQueryStringFilterAndSort() {
    // TODO :
    // verifier les syntaxe :
    // les valeurs doivent être au format JSON, ex : int 0, bool true/false, string "toto", date "yyyy-MM-ddTHH:mm:ss" et les in sont avec entre [...] ex : [0,1,2]
    let query = [];

    var re = /\./gi;
    if (this.filters.length > 0) {
      let filterTmp: string[] = [];
      this.filters.forEach((element) => {
        let field = element.field;
        let value = element.value; // TODO : voir peut etre a ajouter un range pour les ope in

        // traitement en fontion des type
        switch (element.fieldType) {
          case FieldType.string:
            value = `"${element.value}"`;
            break;
          case FieldType.date:
            value = `"${moment(element.value).format("Y-MM-DD")}T00:00:00"`;
            break;
        }

        switch (element.operator) {
          case Operator.equals:
          case Operator.notEqual:
          case Operator.lessThan:
          case Operator.greaterThan:
          case Operator.lessThanOrEqual:
          case Operator.greaterThanOrEqual:
          case Operator.contains:
          case Operator.notContains:
            filterTmp.push(`${field}:${element.operator}:${value}`);
            break;
          case Operator.endsWith:
            filterTmp.push(`${field}:${element.operator}:^${value}`);
            break;
          case Operator.startsWith:
            filterTmp.push(`${field}:${element.operator}:${value}^`);
            break;
          case Operator.inRange:
            // TODO : revoir le formatagge pour le in
            filterTmp.push(`${field}:${Operator.inRange}:[${value}]`);
            break;

          default:
            break;
        }
      });
      query.push(`filters=${filterTmp.join(";")}`);
    }
    // Ajout de la partie ordonancement
    let sorts: string[] = [];
    if (this.options.sortBy.length > 0) {
      for (let key in this.options.sortBy) {
        let sortDesc: boolean = this.options.sortDesc[key];
        //Enleve les points ex: variant.product.ean -> variantproductean
        let sortKey: string = this.options.sortBy[key].replace(re, "");
        let sort = sortDesc ? "-" : "+";
        sorts.push(`${sort}${sortKey}`);
      }
      query.push(`sorts=${sorts.join(";")}`);
    }

    // Ajout offest
    let offsetQueryString = `offset=${(this.currentPage - 1) * this.itemsPerPage}`;
    query.push(offsetQueryString);

    // Ajout limit
    let limitQueryString = `limit=${this.itemsPerPage}`;
    query.push(limitQueryString);

    return query.join("&");
  }
  //#endregion getFilterAndSort

  /**
   * Action sur le click de ligne
   */
  rowClick(item: never, row: any) {
    Vue.$log.debug(item, row);
    if (this.showSelect) {
      // selectionne une ligne au click de la ligne
      if (this.singleSelect) {
        // vide la selection si selection unique
        this.selectedItems = [];
      }
      this.selectedItems.includes(item) ? this.selectedItems.splice(this.selectedItems.indexOf(item), 1) : this.selectedItems.push(item);
    }
  }

  /**
   * Action sur le double click de ligne
   */
  rowDoublClick(event: any, row: any) {
    Vue.$log.debug(event, row);
  }

  async beforeMount() {
    // TODO get savedHeaders : this.savedHeaders = [...]
    if (this.rowEditable || this.rowDeletable) {
      this.headersMap.push({ text: "", value: "actions", fieldType: FieldType.null, sortable: false, groupable: false });
    }
    if (this.expandable && this.defaultExpandable) {
      this.headersMap.push({ text: "", value: "data-table-expand", fieldType: FieldType.null, sortable: false, groupable: false });
    }
    if (this.dragableRow) {
      this.headersMap.unshift({
        text: "",
        value: "dragHandle",
        fieldType: FieldType.null,
        sortable: false,
        groupable: false,
        class: "fixed-column",
      });
    }

    this.headers = this.headersMap;
    this.selectedHeaders = this.headersMap.filter((s: HeadersMap) => !s.hide);
    await this.setSettingFromPreference();
  }
}
