
import { Component, Prop, Vue } from "vue-property-decorator";
import ListComponent from "@/app/shared/components/generic/list/ListComponentV2.vue";
import moment from "moment";
import "moment/locale/fr";
//import { mapState } from "vuex";

// eslint-disable-next-line no-unused-vars
import { ColDef } from "ag-grid-community";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";
// eslint-disable-next-line no-unused-vars
import { QueryUserPref, StreamTypeMvtCode, StreamTypeMvtCodeLight } from "@/app/stock/shared/store/Types";
// eslint-disable-next-line no-unused-vars
import streamTypeMovementCode from "../../StreamTypeMovementCode.vue";

// import { alpha_num } from "vee-validate/dist/rules";

moment.locale("fr");

const moduleName = "Stock";
const subModuleName = "StreamTypeMovementCode";
const labelDataType = "listStreamTypeMovementCode";

@Component
export default class ListStreamTypeMovementCode extends ListComponent {
  @Prop() declare controller: StockController;
  @Prop() icon!: string;

  onRowClicked = "";
  onRowDoubleClicked = "";

  listInittialize = true;

  initialColumnDefs: ColDef[] = [];

  currentCompany: string = this.$store.state.userContext.currentCompany;
  currentWarehouse: string = this.$store.state.userContext.currentWarehouse;

  // computed: {
  //   ...mapState("userContext", ["currentCompany", "currentWarehouse"]),
  //   hideDefaultColumns() {
  //     return screen.width < 768;
  //   }
  // },

  async onGridReady(params: any) {
    let gridApi = params.api;

    await this.createColumns();
    // on relance le retrieveColumns pour reranplir les elemements dans le columnsState
    this.retrieveColumns();

    this.dataSource = {
      rowCount: null,
      getRows: async (params: any) => {
        let query = this.fillListWithFilterAndSort(params);
        this.controller.Log.logDebug(`listStreamTypeMovementCode`, `Object query : ${JSON.stringify(query)}`);
        let sendResult: { data: StreamTypeMvtCode[]; itemsCount: number } | null = { data: [], itemsCount: 0 };

        try {
          sendResult = await this.controller.StockStore.getStreamTypeMvtCode(query);

          params.successCallback(sendResult?.data, sendResult?.itemsCount);
        } catch (error) {
          //Log
          await this.controller.Log.logError(`listStreamTypeMovementCode onGridReady`, "", error, moduleName);
          sendResult = null;
        }
      },
    };

    gridApi.setDatasource(this.dataSource);
  }

  /** Event when column state change */
  onColumnEvent() {
    if (!this.listInittialize) {
      //this.savePrefrences();
    }
  }

  /** Event when filter or sort change */
  onModelUpdated() {
    if (!this.listInittialize) {
      //this.savePrefrences();
    }
  }

  /** Save user prefenreces when they changes */
  async savePrefrences() {
    //Format data
    let data = this.gridColumnApi.getColumnState();

    let query = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: JSON.stringify(data),
    };

    this.controller.Log.logDebug(`saveUserPreferences`, `Object query : ${JSON.stringify(query)}`);

    try {
      await this.controller.UserStore.saveUserPreferences(query);
    } catch (error) {
      //Log
      await this.controller.Log.logError(`saveUserPreferences`, "", error, moduleName);
    }
  }

  /** Set user preference sur la grille */
  async setUserPrefrences() {
    //refresh ag-grid with preference in database

    let query: QueryUserPref = {
      moduleName: moduleName,
      subModuleName: subModuleName,
      labelDataType: labelDataType,
      companyCode: this.currentCompany,
      warehouseCode: this.currentWarehouse,
      data: null,
    };

    this.controller.Log.logDebug(`getUserPreferences`, `Object query : ${JSON.stringify(query)}`);
    let sendResult = null;
    try {
      sendResult = await this.controller.UserStore.getUserPreferences(query);

      let data = JSON.parse(sendResult.data);

      //set grid columns, sort, filter
      if (data) {
        let data = JSON.parse(sendResult.data);
        if (data.columnState) {
          if (data.sortModel.length > 0) {
            data.columnState.sortIndex = data.sortModel;
          }
          this.gridColumnApi.applyColumnState({ state: data.columnState });
        }
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getUserPreferences`, "", error, moduleName);
      sendResult = null;
    }
  }

  onRowSelected(row: any) {
    if (row.node.isSelected()) {
      Vue.$log.debug("row.node.isSelected");
    }
    if (this.gridOptions.api.getSelectedRows().length > 0) this.isDeleteButtonDisabled = false;
    else this.isDeleteButtonDisabled = true;
  }

  async onRemoveSelected() {
    let selectedData = this.gridOptions.api.getSelectedRows();

    for (var i = 0; i < selectedData.length; i++) {
      let data = selectedData[i];
      await this.deleteStreamTypeMvtCode(data);
    }
    return;
  }

  onAddSelected() {
    // ouveture de la dialog box du parent
    (this.$parent as streamTypeMovementCode).openCreate();
  }

  isFirstColumn(params: any) {
    let displayedColumns = params.columnApi.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onFirstDataRendered(params: any) {
    //resize des colonnes pour remplir la grille
    params.api.sizeColumnsToFit();
  }

  onRowValueChanged(row: any) {
    //Verification des modification de valeur avant update
    if (this.editingOldValues !== row.data) {
      let StreamTypeMvtCode = row.data;
      // validation des champ non vide
      if (!this.checkParameters(StreamTypeMvtCode)) {
        return; // si un champ vide, on n'update pas
      }
      // si on est sur une creation, on ajoute
      if (!StreamTypeMvtCode.id) {
        this.createStreamTypeMvtCode(StreamTypeMvtCode);
      }
      // sinon, on update
      else {
        this.updateStreamTypeMvtCode(StreamTypeMvtCode);
      }
    }
  }

  /**
   * Validation des données avant insertion
   * renvoie vrai si tous les parametres sont bien renseigné
   */
  checkParameters(data: any) {
    if (!data.streamType || !data.storageType || !data.goodsMvtCode) {
      //TODO : revoir si notif pour notifier l'erreur de saisi
      alert("il manque des donées");
      //this.controller.Notification.notifyInfo("Action impossible, parametre manquant", "");
      return false;
    } else {
      return true;
    }
  }

  /**
   * Recuperation de la liste des type de stockage pour la combo
   */
  async getStorageTypeCombobox() {
    let result: { id: string | null; code: string | null; valueRenderer: string; obj: any }[] = [];
    this.controller.Log.logDebug(`getStorageTypeCombobox`, ``);
    try {
      let sendResult = await this.controller.StockStore.getStorageTypeSuggests();

      sendResult.data.forEach(function (element) {
        result.push({ id: element.code, code: element.code, valueRenderer: `${element.code}/${element.label} `, obj: element });
      });
    } catch (error) {
      //Log
      await this.controller.Log.logError(`getStorageTypeCombobox`, "", error, moduleName);
    }

    return result;
  }

  /**
   * Recuperation de la liste des type de flux pour la combo
   */
  async getStreamTypeCombobox() {
    let result: { id: number; valueRenderer: string; obj: any }[] = [];
    this.controller.Log.logDebug(`getListeComboboxValue`, ``);
    try {
      let sendResult = await this.controller.StockStore.getStreamSuggests("");

      sendResult.data.forEach(function (element) {
        result.push({ id: element.id, valueRenderer: `${element.code}/${element.label} (${element.direction})`, obj: element });
      });
    } catch (error) {
      //Log
      this.controller.Log.logError(`getListeComboboxValue`, "", error, moduleName);
    }

    return result;
  }

  /**
   * Recuperation de la liste des code mouvement pour la combo
   */
  async getGoodsMvtCodeCombobox() {
    let result: { id: number; valueRenderer: string; obj: any }[] = [];
    this.controller.Log.logDebug(`getListeComboboxValue`, ``);
    try {
      let sendResult = await this.controller.StockStore.getGoodsMvtCodeSuggests(null, null, null);

      //result = sendResult.data;
      sendResult.data.forEach(function (element) {
        result.push({ id: element.id, valueRenderer: `${element.code}/${element.label} (${element.direction})`, obj: element });
      });
    } catch (error) {
      //Log
      this.controller.Log.logError(`getListeComboboxValue`, "", error, moduleName);
    }

    return result;
  }

  /**
   * Creation d'un type de flux
   */
  async createStreamTypeMvtCode(StreamTypeMvtCode: StreamTypeMvtCodeLight) {
    Vue.$log.debug("creation");
    Vue.$log.debug(StreamTypeMvtCode);

    this.controller.Log.logDebug(`createStreamTypeMvtCode`, ``);
    try {
      let sendResult = await this.controller.StockStore.createStreamTypeMvtCode(StreamTypeMvtCode);
      Vue.$log.debug(sendResult);
      //TODO : mettre en place une notification pour valider la creation
      await this.refreshData();
    } catch (error) {
      //Log
      this.controller.Log.logError(`createStreamTypeMvtCode`, "", error, moduleName);
    }
  }

  /**
   * Mise a jour d'un type de flux
   */
  async updateStreamTypeMvtCode(StreamTypeMvtCode: StreamTypeMvtCode) {
    this.controller.Log.logDebug(`updateStreamTypeMvtCode`, ``);
    try {
      let sendResult = await this.controller.StockStore.updateStreamTypeMvtCode(StreamTypeMvtCode);
      //TODO : mettre en place une notification pour valider la mise a jour
      Vue.$log.debug("update");
      Vue.$log.debug(sendResult);
      this.refreshData();
    } catch (error) {
      //Log
      this.controller.Log.logError(`updateStreamTypeMvtCode`, "", error, moduleName);
    }
  }

  /**
   * Suppression d'un type de flux
   */
  async deleteStreamTypeMvtCode(StreamTypeMvtCode: StreamTypeMvtCode) {
    this.controller.Log.logDebug(`deleteStreamTypeMvtCode`, ``);
    try {
      let sendResult = await this.controller.StockStore.deleteStreamTypeMvtCode(StreamTypeMvtCode);
      Vue.$log.debug("delete");
      Vue.$log.debug(StreamTypeMvtCode);
      Vue.$log.debug(sendResult);
      //TODO : mettre en place une notification pour valider la creation
      await this.refreshData();
    } catch (error) {
      //Log
      this.controller.Log.logError(`deleteStreamTypeMvtCode`, "", error, moduleName);
    }
  }

  /** Affichage de la modale pour le detail */
  async deleteConfiguration(params: StreamTypeMvtCode) {
    Vue.$log.debug(params);
    await this.deleteStreamTypeMvtCode(params);
  }

  /**
   * Creation des colums de la gille
   */
  async createColumns() {
    // on place dans une methode pour appeler dans le gridReady a cause des methode async des combo
    this.columnDefs = [
      this.numericColumn("id", {
        headerName: "Id",
        hide: true,
        autoHeight: true,
        cellRenderer: this.gridOptions.components.loadingRenderer,
      }),

      this.comboboxColumn(
        "storageType",
        { selectedId: "code", options: await this.getStorageTypeCombobox(), label: "Type stockage" },
        {
          headerName: "Type stockage",
          editable: true,
          cellRenderer: (params: any) => {
            if (!params || !params.value) {
              return "";
            }
            return `${params.value.code}/${params.value.label}`;
          },
        }
      ),

      this.comboboxColumn(
        "streamType",
        { selectedId: "id", options: await this.getStreamTypeCombobox(), label: "Type flux" },
        {
          headerName: "Type flux",
          editable: true,
          cellRenderer: (params: any) => {
            if (!params || !params.value) {
              return "";
            }
            return `${params.value.code}/${params.value.label} (<b>${params.value.direction}</b>)`;
          },
        }
      ),

      this.comboboxColumn(
        "goodsMvtCode",
        { selectedId: "id", options: await this.getGoodsMvtCodeCombobox(), label: "Code mouvement" },
        {
          headerName: "Code mouvement",
          editable: true,
          autoHeight: true,
          cellRenderer: (params: any) => {
            if (!params || !params.value) {
              return "";
            }
            return `${params.value.code}/${params.value.label} (<b>${params.value.direction}</b>)`;
          },
        }
      ),
      this.buttonColumn(
        "detail",
        { icon: "fa-light fa-trash-can", action: this.deleteConfiguration, title: "", btnClass: "btn" },
        {
          headerName: "",
          maxWidth: 45,
          editable: false,
          cellClass: "d-flex justify-content-center align-items-center prepaDetail",
          pinned: "right",
          resizable: false,
          suppressSizeToFit: true,
          cellStyle: { "text-align": "center" },
        }
      ),
    ];

    this.gridOptions.rowMultiSelectWithClick = false;

    this.gridOptions.editType = "fullRow";
    this.addButtonVisibility = true;
    this.isAddButtonDisabled = false;
  }
}
