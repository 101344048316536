
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AdvancementRederer extends Vue {
  @Prop() props!: any;

  progessColor(value: number): string {
    let color = "";
    switch (value) {
      case 0:
        color = "error";
        break;
      case 100:
        color = "success";
        break;
      default:
        color = "primary";
        break;
    }
    return color;
  }
}
