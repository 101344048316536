import Vue from "vue";
import RestClient from "@/app/shared/services/restClient";
import { apiUrls, clientId, licenseExpirationTime } from "@/environment/environment";
import authHelper from "@/app/shared/services/authHelper";
import urlApiHelper from "@/app/shared/services/urlApiHelper";
import { module, functionality } from "@/app/settings/shared/ModuleInterface";

import { Module, ActionContext } from "vuex";
import { RootState } from "@/app/app-state";

import { AxiosResponse } from "axios";
import ModuleStore from "@/app/settings/shared/store/moduleStore";

export interface Userinfo {
  companyCode: string;
  warehouseCode: string;
  login: string;
  password: string;
  key: string;
  applicationName: string;
}
export interface SystemInfo {
  environmentCode: string;
  serverName: string;
  instanceName: string;
  dataBaseName: string;
}

interface Company {
  id: number;
  code: string;
  label: string;
  warehouses: null | Warehouse;
}
export interface Warehouse {
  id: number;
  code: string;
  label: string;
}

interface Right {
  id: number;
  code: string;
  label: string;
}

export interface StateType {
  code: null | string;
  name: null | string;
  surname: null | string;
  initials: null | string;
  fonction: null | string;
  authToken: null | string;
  isAdministrator: boolean;
  currentCompany: null | string;
  currentWarehouse: null | string;
  companies: Array<Company>;
  rights: Array<string>;
  functionalities: Array<string>;
  systemInfo: null | SystemInfo;
  isLicenceAuthorize: boolean;
  favoris: string[];
}

const userContext: Module<StateType, RootState> = {
  namespaced: true,
  state: {
    code: null,
    name: null,
    surname: null,
    initials: null,
    fonction: null,
    authToken: null,
    isAdministrator: false,
    currentCompany: null,
    currentWarehouse: null,
    companies: [],
    rights: [],
    functionalities: [],
    systemInfo: null,
    isLicenceAuthorize: false,
    favoris: [],
  } as StateType,
  getters: {
    isAuthenticated: (state: StateType) => {
      return state.authToken != null;
    },
    getToken: (state: StateType) => {
      return state.authToken;
    },
    isAuthorized: (state: StateType) => {
      return state.rights && state.rights.length > 0;
    },
  },
  mutations: {
    clearState(state: StateType) {
      state.code = null;
      state.name = null;
      state.surname = null;
      state.initials = null;
      state.fonction = null;
      state.authToken = null;
      state.isAdministrator = false;
      state.currentCompany = null;
      state.currentWarehouse = null;
      state.companies = [];
      state.rights = [];
      state.functionalities = [];
      state.systemInfo = null;
      state.isLicenceAuthorize = false;
      state.favoris = [];
    },
    currentCompany(state: StateType, newState: string) {
      state.currentCompany = newState;
      sessionStorage.setItem("currentCompany", newState);
      if (localStorage.getItem("defaultCurrentCompany") == null) {
        localStorage.setItem("defaultCurrentCompany", newState);
      }
    },
    companies(state: StateType, newState: Array<Company>) {
      state.companies = newState;
    },
    currentWarehouse(state: StateType, newState: string) {
      state.currentWarehouse = newState;
      sessionStorage.setItem("currentWarehouse", newState);
      if (localStorage.getItem("defaultCurrentWarehouse") == null) {
        localStorage.setItem("defaultCurrentWarehouse", newState);
      }
    },
    rights(state: StateType, newState: Array<string>) {
      state.rights = newState;
    },
    functionalities(state: StateType, newState: Array<string>) {
      state.functionalities = newState;
    },
    code(state: StateType, newValue: string) {
      state.code = newValue;
    },
    name(state: StateType, newValue: string) {
      state.name = newValue;
    },
    surname(state: StateType, newValue: string) {
      state.surname = newValue;
    },
    fonction(state: StateType, newValue: string) {
      state.fonction = newValue;
    },
    initials(state: StateType, newValue: string) {
      state.initials = newValue;
    },
    authToken(state: StateType, newState: string) {
      state.authToken = newState;
    },
    isAdministrator(state: StateType, newState: boolean) {
      state.isAdministrator = newState;
    },
    systemInfo(state: StateType, newValue: SystemInfo) {
      state.systemInfo = newValue;
    },
    isLicenceAuthorize(state: StateType, newValue: boolean) {
      state.isLicenceAuthorize = newValue;
    },
    favoris(state: StateType, newValue: string[]) {
      // on s'assure de setter un tableau
      if (Array.isArray(newValue)) state.favoris = newValue;
    },
  },
  actions: {
    /**
     * Mise a jours de la sosiete courante du context
     * @param context
     * @param newCompany
     */
    updateCurrentCompany(context: ActionContext<StateType, RootState>, newCompany: string) {
      return new Promise((resolve, reject) => {
        //Apple a l'api user
        const userAPI = apiUrls.user;
        const restClient = new RestClient(userAPI).Instance;
        restClient
          .post("/Users/CurrentCompany?currentCompanyCode=" + newCompany)
          .then((response: AxiosResponse) => {
            if (response.status == 204) {
              context.commit("currentCompany", "");
              context.dispatch("refreshUserWarehouses");
              resolve(context.state.currentCompany);
            } else {
              reject(response.statusText);
            }
          })
          .catch((error) => {
            Vue.$log.error(error);
            reject("Error updateCurrentCompany from User API: " + error);
          });
      });
    },

    /**
     * Mise a jours du site courante du context
     * @param context
     * @param newWarehouse
     */
    updateCurrentWarehouse(context: ActionContext<StateType, RootState>, newWarehouse: string) {
      return new Promise((resolve, reject) => {
        //Apple a l'api us
        const userAPI = apiUrls.user;
        const restClient = new RestClient(userAPI).Instance;
        restClient
          .post("/Users/CurrentWarehouse?currentWarehouseCode=" + newWarehouse)
          .then((response: AxiosResponse) => {
            if (response.status == 204) {
              context.commit("currentWarehouse", "");
              resolve(context.state.currentWarehouse);
            } else {
              reject(response.statusText);
            }
          })
          .catch((error) => {
            Vue.$log.error(error);
            reject("Error updateCurrentWarehouse from User API: " + error);
          });
      });
    },

    /**
     * Mise a jour de societe et site courant du le context
     * @param context
     * @param payload
     */
    updateCurrentCompanyWarehouse(context: ActionContext<StateType, RootState>, payload: Userinfo) {
      return new Promise((resolve) => {
        context.commit("currentCompany", payload.companyCode);
        context.commit("currentWarehouse", payload.warehouseCode);
        context.dispatch("refreshUserLicenceAccess");
        context.dispatch("refreshUserRights").then(() => resolve(context.state));
      });
    },

    /**
     * Mise a jour des droits utilisateur du le context
     * @param context
     * @param newRights
     */
    updateRights(context: ActionContext<StateType, RootState>, newRights: Array<string>) {
      return new Promise((resolve, reject) => {
        if (Array.isArray(newRights)) {
          context.commit("rights", newRights);
          resolve(context.state.rights);
        } else {
          reject("Object must be an Array.");
        }
      });
    },

    /**
     * Mise a jour des fonctionalitées site du context
     * @param context
     * @param newFunctionalities
     */
    updateFunctionalities(context: ActionContext<StateType, RootState>, newFunctionalities: Array<string>) {
      return new Promise((resolve, reject) => {
        if (Array.isArray(newFunctionalities)) {
          context.commit("functionalities", newFunctionalities);
          resolve(context.state.functionalities);
        } else {
          reject("Object must be an Array.");
        }
      });
    },

    /**
     * Mise a jour de la liste des site du le context
     * @param context
     * @param newWarehouses
     */
    updateCompanies(context: ActionContext<StateType, RootState>, newCompanies: Array<Company>) {
      return new Promise((resolve, reject) => {
        if (Array.isArray(newCompanies)) {
          context.commit("companies", newCompanies);
          resolve(context.state.companies);
        } else {
          reject("Object must be an Array.");
        }
      });
    },

    /**
     * Methode de deconnexion
     * @param context
     */
    logout(context: ActionContext<StateType, RootState>) {
      return new Promise((resolve, reject) => {
        try {
          sessionStorage.clear();
          localStorage.clear();
          Vue.$cookies.remove("authToken");
          context.commit("clearState");
          resolve(null);
        } catch (error) {
          //Log
          Vue.$log.error(error);
          reject("Error logout from tesfriconfigurationAPI : " + error);
        }
      });
    },

    /**
     * Methode de connexion
     * @param context
     * @param userinfo
     */
    // eslint-disable-next-line no-unused-vars
    async login(context: ActionContext<StateType, RootState>, userinfo: Userinfo) {
      await urlApiHelper.setUrlAuthFromApi(userinfo);

      await authHelper.getAuthTokenFromApi(userinfo).then(null, (reject) => {
        return Promise.reject(reject);
      });

      await context.dispatch("refreshUserLicenceAccess");

      await context.dispatch("refreshUserRights");

      await context.dispatch("refreshUserFunctionalities");

      await context.dispatch("setUserInfosFromApi");

      await context.dispatch("refreshUserWarehouses");

      await authHelper.getSystemInfo();

      await authHelper.getFavoris(userinfo.companyCode, userinfo.warehouseCode);
    },

    /**
     * Auto login with key
     */
    // eslint-disable-next-line no-unused-vars
    async autoLogin(context: ActionContext<StateType, RootState>, userinfo: Userinfo) {
      await urlApiHelper.setUrlAuthFromApi(userinfo);

      await authHelper.getAuthTokenFromApiWithKey(userinfo);

      await context.dispatch("refreshUserLicenceAccess");

      await context.dispatch("refreshUserRights");

      await context.dispatch("refreshUserFunctionalities");

      await context.dispatch("setUserInfosFromApi");

      await context.dispatch("refreshUserWarehouses");

      await authHelper.getSystemInfo();

      await authHelper.getFavoris(userinfo.companyCode, userinfo.warehouseCode);
    },

    /**
     * initialisation du contexte a partir d'un cookies existant
     * @param context
     */
    async initContextStateWithCookies(context: ActionContext<StateType, RootState>) {
      const authTokenCookies = Vue.$cookies.get("authToken");
      let currentCompany = null;
      // Si on ouvre un nouvel onglet/fenetre le sessionStorage est vide donc on prends dans le localstorage
      if (sessionStorage.getItem("currentCompany")) {
        currentCompany = sessionStorage.getItem("currentCompany");
      } else {
        currentCompany = localStorage.getItem("defaultCurrentCompany");
      }

      // Si on ouvre un nouvel onglet/fenetre le sessionStorage est vide donc on prends dans le localstorage
      let currentWarehouse = null;
      if (sessionStorage.getItem("currentWarehouse")) {
        currentWarehouse = sessionStorage.getItem("currentWarehouse");
      } else {
        currentWarehouse = localStorage.getItem("defaultCurrentWarehouse");
      }

      // Si on a le token, le currentCompany  at currentWarehouse on peut s'auto-logger
      if (
        authTokenCookies != null &&
        currentCompany != null &&
        currentWarehouse != null &&
        // les valeur null sont passé dans les cookies ??
        authTokenCookies != "null" &&
        currentCompany != "null" &&
        currentWarehouse != "null"
      ) {
        context.commit("authToken", authTokenCookies);
        context.commit("currentCompany", currentCompany);
        context.commit("currentWarehouse", currentWarehouse);

        await context.dispatch("refreshUserLicenceAccess");

        await context.dispatch("refreshUserRights");

        await context.dispatch("refreshUserFunctionalities");

        await context.dispatch("setUserInfosFromApi");

        await context.dispatch("refreshUserWarehouses");

        await authHelper.getSystemInfo();

        await authHelper.getFavoris(currentCompany, currentWarehouse);
      }
    },

    async refreshUserWarehouses(context: ActionContext<StateType, RootState>) {
      if (context.getters.isAuthenticated) {
        const currentCompany = context.state.currentCompany;
        const currentWarehouse = context.state.currentWarehouse;
        try {
          const userAPI = apiUrls.user;
          const restClient = new RestClient(userAPI).Instance;
          const response = await restClient.get(`Warehouses?companyCode=${currentCompany}&warehouseCode=${currentWarehouse}`);

          // TODO : a modifier lorsque la bonne methode sera implementer cote API.
          // L'API devrait retourner une liste de societes qui ont une liste de site
          const result: Array<Company> = [];
          const warehousesList = response.data;

          const map = new Map();
          let index = 0;
          warehousesList.forEach((item: any) => {
            if (!map.has(item.companyCode)) {
              map.set(item.companyCode, true); // set any value to Map
              result.push({
                id: index,
                code: item.companyCode,
                label: item.companyName,
                warehouses: warehousesList
                  .filter((x: any) => x.companyCode == item.companyCode)
                  .map((x: any, i: number) => {
                    return { id: i, code: x.warehouseCode, label: x.warehouseName };
                  }),
              });
              index++;
            }
          });

          context
            .dispatch("updateCompanies", result)
            .then((d: any) => Vue.$log.debug(d))
            .catch((err: string) => Vue.$log.error(err));
        } catch (error) {
          Vue.$log.error(error);
        }
      }
    },

    /**
     * Recuperation des droits utilisateurs
     * @param context
     */
    async refreshUserRights(context: ActionContext<StateType, RootState>) {
      if (context.getters.isAuthenticated) {
        const restClient = new RestClient(apiUrls.user).Instance;
        await restClient
          .get(`UserRights?companyCode=${context.state.currentCompany}&warehouseCode=${context.state.currentWarehouse}`)
          .then((response: AxiosResponse) => {
            if (response.status == 200 || response.status == 204) {
              const rights: Array<string> = [];

              const rightsApi = response.data;

              rightsApi.forEach((right: Right) => {
                rights.push(right.code);
              });

              if (Array.isArray(rights)) {
                context.commit("rights", rights);
              } else {
                Vue.$log.error("Object must be an Array.");
              }
            }
          });
      }
    },

    /**
     * check l'autorisation de licences
     * @param context
     */
    async refreshUserLicenceAccess(context: ActionContext<StateType, RootState>) {
      if (context.getters.isAuthenticated) {
        const restClient = new RestClient(apiUrls.module).Instance;
        const expirationSeconde = licenseExpirationTime / 1000; // licenseExpirationTime est en ms, on transforme en sd
        const applicationName = clientId;
        await restClient
          .post(
            `/Licences/Check?companyCode=${context.state.currentCompany}&warehouseCode=${context.state.currentWarehouse}&applicationName=${applicationName}&expirationSeconde=${expirationSeconde}`
          )
          .then((response: AxiosResponse) => {
            context.commit("isLicenceAuthorize", response.status == 200);
          })
          .catch((error) => {
            Vue.$log.error(error);
            context.commit("isLicenceAuthorize", false);
          });
      }
    },

    /**
     * Recuperation des droits utilisateurs
     * @param context
     */
    async refreshUserFunctionalities(context: ActionContext<StateType, RootState>) {
      if (context.getters.isAuthenticated) {
        const store = new ModuleStore(apiUrls.module);
        await store.getCompanyWarehousePackagApi(true).then((functionalitiesApi: module[]) => {
          const functionalities: Array<string> = [];

          functionalitiesApi.forEach((module: module) => {
            module.functionalities.forEach((functionality: functionality) => {
              functionalities.push(functionality.code);
            });
          });

          if (Array.isArray(functionalities)) {
            context.commit("functionalities", functionalities);
          } else {
            Vue.$log.error("Object must be an Array.");
          }
        });
      }
    },

    async setUserInfosFromApi(context: ActionContext<StateType, RootState>) {
      if (context.getters.isAuthenticated) {
        const restClient = new RestClient(apiUrls.user).Instance;
        await restClient
          .get(`UserInfos?companyCode=${context.state.currentCompany}&warehouseCode=${context.state.currentWarehouse}`)
          .then((response: AxiosResponse) => {
            if (response.status == 200 || response.status == 204) {
              const datas = response.data;
              context.commit("name", datas.name);
              context.commit("surname", datas.surname);
              context.commit("code", datas.code);
              context.commit("initials", datas.initials);
              context.commit("isAdministrator", datas.isAdministrator);
              context.commit("fonction", datas.fonction);
            }
          });
      }
    },

    /**
     * Mise a jour des favoris
     * @param context
     * @param payload
     */
    toggleFavoris(context: ActionContext<StateType, RootState>, routeName: string) {
      return new Promise(() => {
        let favoris = context.state.favoris;

        // si on a une route, on modifie les favoris
        if (routeName) {
          // modificaion dans le contexte
          const index = favoris.indexOf(routeName);
          index === -1 ? favoris.push(routeName) : favoris.splice(index, 1);
          favoris = [...new Set(favoris)];
          context.commit("favoris", favoris);

          // modification en base
          if (context.state.currentCompany && context.state.currentWarehouse) {
            authHelper.setFavoris(context.state.currentCompany, context.state.currentWarehouse, favoris);
          }
        }
      });
    },

    /**
     * Mise a jour des favoris
     * @param context
     * @param payload
     */
    updateFavoris(context: ActionContext<StateType, RootState>, favoris: string[]) {
      return new Promise(() => {
        // on supprime les elements eventuellement null ou undefined
        const cleanFavoris = favoris.filter(function (el) {
          return el !== null && el !== undefined;
        });

        context.commit("favoris", cleanFavoris);
        // modification en base
        if (context.state.currentCompany && context.state.currentWarehouse) {
          authHelper.setFavoris(context.state.currentCompany, context.state.currentWarehouse, cleanFavoris);
        }
      });
    },
  },
};

export default userContext;
