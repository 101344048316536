
import Component from "vue-class-component";
import { Prop, VModel, Vue, Watch } from "vue-property-decorator";
import OperatorMissionPriorityPalletsDetailDataTableZoneSelectRenderer from "./renderer/OperatorMissionPriorityPalletsDetailDataTableZoneSelectRenderer.vue";
import OperatorMissionPriorityPalletsDetailDataTableOperatorSelectRenderer from "./renderer/OperatorMissionPriorityPalletsDetailDataTableOperatorSelectRenderer.vue";
import OperatorMissionPriorityPalletsDetailDataTableCheckRenderer from "./renderer/OperatorMissionPriorityPalletsDetailDataTableCheckRenderer.vue";

// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";

// eslint-disable-next-line no-unused-vars
import { Localization, PalletPrioritization, PlanOrNote } from "../../shared/store/Types";
import moment from "moment";

import { isEnabled } from "vue-feature-flipping";

@Component({
  components: {
    OperatorMissionPriorityPalletsDetailDataTableZoneSelectRenderer,
    OperatorMissionPriorityPalletsDetailDataTableOperatorSelectRenderer,
    OperatorMissionPriorityPalletsDetailDataTableCheckRenderer,
  },
})
export default class OperatorMissionPriorityPalletsDetailIterator extends Vue {
  @Prop() controller!: StockController;
  @Prop() note!: PlanOrNote;

  @VModel({ default: false }) isDetailsOpened!: boolean;

  loading = false;
  items: PalletPrioritization[] = [];
  totalItems = 0;
  pageCount = 0;
  itemsPerPage = -1;

  errorMessage = "";
  errorNotif = false;

  /**
   * feature flag pour afficher la selection de cariste
   */
  get isFeatureOperatorSelect(): boolean {
    return isEnabled("operatorMissionPriorityOperatorSelect");
  }

  @Watch("note")
  async onNoteChanged(newVal: PlanOrNote) {
    if (newVal) {
      this.items = [];
      await this.getDataFromApi();
    } else {
      // si le bon est null, on ferme systematiquement le detail
      this.isDetailsOpened = false;
    }
  }

  async getDataFromApi() {
    try {
      this.loading = true;
      if (this.note?.noteId) {
        let result = await this.controller.StockStore.getOperatorMissionPriorityPalletsList(this.note.noteId);
        if (result && result.itemsCount > 0) {
          this.items = result.data;
          this.totalItems = result.itemsCount;
        } else {
          this.items = [];
        }
      } else {
        this.items = [];
      }
    } catch (error) {
      //Log
      await this.controller.Log.logError(`OperatorMissionPriorityPalletsDetailDataTable onGridReady`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }

  formatLocalization(localization: Localization) {
    if (localization) {
      return `${localization.roomCode} ${localization.areaCode} - ${localization.aisleCode} ${localization.locationCode} ${localization.levelCode}`;
    }
  }

  formatDate(date: string) {
    if (moment(date).isValid()) {
      return moment(date).format("L");
    } else {
      return date;
    }
  }

  formatDecimal(value: string) {
    return Number(value).toFixed(3);
  }

  /**
   * Mise a jours de zone sur une palette
   */
  async operatorMissionPriorityUpdateLocalizationPallet(itemUpdate: PalletPrioritization, newLocalization: Localization | null) {
    this.loading = true;
    try {
      this.controller.Log.logDebug(`operatorMissionPriorityUpdateLocalizationPallet`, `Object query : ${JSON.stringify(itemUpdate)}`);

      await this.controller.StockStore.operatorMissionPriorityUpdateLocalizationPallet(itemUpdate, newLocalization, this.note).then(
        (r: { status: number; errors: any }) => {
          if (r.status === 200) {
            // rafraichissement de la grille
            this.getDataFromApi();
            this.controller.EventBus.$emit("refreshDataGrid");
          } else {
            let msgError = "";
            Object.entries(r.errors).forEach(([key, value]) => {
              msgError += `${key}: ${value}\n`;
            });
            Vue.$log.error(msgError);
            this.errorMessage = msgError;
            this.errorNotif = true;

            // refarishissement des informations dans la grille
            this.items = [];
            this.getDataFromApi();
          }
        }
      );
    } catch (error) {
      //Log
      await this.controller.Log.logError(`operatorMissionPriorityUpdateLocalizationPallet`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }

  /**
   * Mise a jours de caristes sur une palette
   */
  async operatorMissionPriorityUpdateOperatorPallet(itemUpdate: PalletPrioritization) {
    this.loading = true;
    try {
      this.controller.Log.logDebug(`operatorMissionPriorityUpdateOperatorPallet`, `Object query : ${JSON.stringify(itemUpdate)}`);

      await this.controller.StockStore.operatorMissionPriorityUpdateOperatorPallet(itemUpdate).then(
        (r: { status: number; errors: any }) => {
          if (r.status === 200) {
            // rafraichissement de la grille
            this.getDataFromApi();
            this.controller.EventBus.$emit("refreshDataGrid");
          } else {
            let msgError = "";
            Object.entries(r.errors).forEach(([key, value]) => {
              msgError += `${key}: ${value}\n`;
            });
            Vue.$log.error(msgError);
          }
        }
      );
    } catch (error) {
      //Log
      await this.controller.Log.logError(`operatorMissionPriorityUpdateOperatorPallet`, "", error, "Stock");
    } finally {
      this.loading = false;
    }
  }

  /**
   * rafraichissement des données de la grille
   */
  async refreshDataGridPaletDetail() {
    this.items = [];
    await this.getDataFromApi();
  }

  async beforeMount() {
    this.controller.EventBus.$on("operatorMissionPriorityUpdateLocalizationPallet", this.operatorMissionPriorityUpdateLocalizationPallet);
    this.controller.EventBus.$on("operatorMissionPriorityUpdateOperatorPallet", this.operatorMissionPriorityUpdateOperatorPallet);
    this.controller.EventBus.$on("refreshDataGridPaletDetail", this.refreshDataGridPaletDetail);
  }
  async beforeDestroy() {
    this.controller.EventBus.$off("operatorMissionPriorityUpdateLocalizationPallet", this.operatorMissionPriorityUpdateLocalizationPallet);
    this.controller.EventBus.$off("operatorMissionPriorityUpdateOperatorPallet", this.operatorMissionPriorityUpdateOperatorPallet);
    this.controller.EventBus.$off("refreshDataGridPaletDetail", this.refreshDataGridPaletDetail);
  }
}
