
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ButtonFavoris extends Vue {
  @Prop() routeName!: string;
  @Prop({ default: true }) visible!: boolean;

  toggleFavoris(e: any) {
    e.preventDefault();

    const routeName = this.routeName ? this.routeName : this.$route.name;

    if (routeName) {
      this.$store
        .dispatch("userContext/toggleFavoris", routeName)
        .then()
        .catch((error) => {
          Vue.$log.error(error);
        });
    }
  }

  check() {
    const routeName = this.routeName ? this.routeName : this.$route.name;
    return routeName && this.$store.state.userContext.favoris.indexOf(routeName) != -1;
  }
}
