export default class Url {
  public url: string;

  constructor() {
    this.url = window.location.href;
  }

  getAll() {
    const params: any = {};
    const search = decodeURIComponent(this.url.slice(this.url.indexOf("?") + 1));
    const definitions = search.split("&");

    definitions.forEach(function (val) {
      const parts = val.split("=", 2);
      params[parts[0]] = parts[1];
    });
    return params;
  }

  get(property: string) {
    const params = this.getAll();
    return property && property in params ? params[property] : null;
  }

  exists(property: string) {
    const params = this.getAll();
    return property && property in params;
  }
}
