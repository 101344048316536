
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import "ag-grid-community/dist/styles/ag-grid.css";
import "@/assets/ag-theme-material-before.scss";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "@/assets/ag-theme-material-after.scss";
// eslint-disable-next-line no-unused-vars
import ControllerBase from "@/app/shared/services/controllers/controllerBase";

//import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";

import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

import ComboboxRenderer from "../combobox/ComboboxComponent.vue";
import CheckboxRenderer from "./renderer/CheckboxRenderer.vue";
import ButtonColumnRenderer from "./renderer/ButtonColumnRenderer.vue";
import ButtonDisablableColumnRenderer from "./renderer/ButtonDisablableColumnRenderer.vue";
import ButtonHideColumnRenderer from "./renderer/ButtonHideColumnRenderer.vue";
import BadgeColumnRenderer from "./renderer/BadgeColumnRenderer.vue";
import IconeColumnRenderer from "./renderer/IconeColumnRenderer.vue";

// eslint-disable-next-line no-unused-vars
import { ColDef, Column, GridApi, ColumnApi } from "ag-grid-community";
// eslint-disable-next-line no-unused-vars
import { Query } from "@/app/stock/shared/store/Types";

@Component({
  name: "listComponentV2",
  components: {
    AgGridVue,
    ComboboxRenderer,
    CheckboxRenderer,
    ButtonColumnRenderer,
    ButtonDisablableColumnRenderer,
    ButtonHideColumnRenderer,
    BadgeColumnRenderer,
    IconeColumnRenderer,
  },
})
export default class ListComponentV2 extends Vue {
  @Prop() controller!: ControllerBase;
  @Prop() id!: string;

  columnDefs: ColDef[] = [];
  gridApi!: GridApi;
  gridColumnApi!: ColumnApi;
  gridOptions: any = null;
  defaultColDef: any = {};
  rowData: Array<object> = [];
  editType = "cell";
  columns: { id: string; name: string; visible: boolean }[] = [];
  editingOldValues: any = null;
  editingNewValues: any = null;
  frameworkComponents: any = null;
  isReady = false;

  addButtonVisibility = false;
  deleteButtonVisibility = false;
  exportButtonVisibility = false;
  clearFilterButtonVisibility = true;
  toggleCheckboxIsVisibility = true;
  hasRows = false;
  dataSource!: any;
  title = "";
  isAddButtonDisabled = true;
  isDeleteButtonDisabled = true;
  isExportButtonDisabled = true;
  overlayLoadingTemplate: string | null = null;
  overlayNoRowsTemplate: string | null = null;
  //Device width
  screenW: number = screen.width;
  tabletW = 768;
  pinnedBottomRowData: any = null;
  pagination = true;
  confCardheight = "25%";
  height = "100%";
  width = "100%";
  suppressPaginationPanel = false;

  localeText = {
    page: "page",
    to: "-",
    of: "/",
    loadingOoo: "Chargement...",
    searchOoo: "Rechercher...",
    equals: "Égal",
    notEqual: "Différent",
    lessThan: "Infernieur à",
    greaterThan: "Superieur à",
    lessThanOrEqual: "Inferieur ou égal à",
    greaterThanOrEqual: "Superieur ou égal à",
    inRange: "Compris entre",
    contains: "Contient",
    notContains: "Ne contient pas",
    startsWith: "Commence par",
    endsWith: "Fini par",
    andCondition: "Et",
    orCondition: "Ou",
    filterOoo: "Filtrer...",
  };

  get headerVisibility(): any {
    return (
      this.deleteButtonVisibility ||
      this.addButtonVisibility ||
      this.exportButtonVisibility ||
      this.clearFilterButtonVisibility ||
      this.toggleCheckboxIsVisibility
    );
  }

  @Watch("gridOptions.columnApi")
  // eslint-disable-next-line no-unused-vars
  onPropertyChanged(value: number, oldValue: number) {
    this.retrieveColumns();
  }

  onColumnEvent() {
    this.retrieveColumns();
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-empty-function
  onGridSizeChanged(params: any) {
    return;
  }

  retrieveColumns() {
    let tmpColumns: { id: string; name: string; visible: boolean }[] = [];
    for (let col of this.gridOptions.columnApi.getColumnState()) {
      let columnDefs = this.columnDefs.find((c) => c.field === col.colId);
      if (columnDefs && columnDefs.headerName) {
        let name = columnDefs.headerName;
        if (name.trim()) {
          tmpColumns.push({ id: col.colId, name: name, visible: !col.hide });
        }
      }
    }
    this.columns = tmpColumns;
  }
  toggleColumnVisibility(data: any) {
    if (
      this.gridOptions.columnApi.getColumnState().find((col: any) => {
        return col.colId == data;
      }).hide
    ) {
      this.gridOptions.columnApi.setColumnVisible(data, true);
    } else {
      this.gridOptions.columnApi.setColumnVisible(data, false);
    }
  }

  //Permet créer une colonne numérique
  numericColumn(name: string, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      filter: "agNumberColumnFilter",
      filterParams: { suppressAndOrCondition: true },
      cellStyle: { "text-align": "right" },
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne text
  textColumn(name: string, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
      cellStyle: { "text-align": "left" },
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne checkbox
  chekboxColumn(name: string, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      cellRendererFramework: CheckboxRenderer,
      cellEditorFramework: CheckboxRenderer,
      cellEditorParams: { rowStartEditing: true },
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne combobox
  comboboxColumn(name: string, datas: any, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      cellEditorFramework: ComboboxRenderer,
      cellEditorParams: datas,
      filter: false,
      sortable: false,
    };
    //Permet de remplasser des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne button
  buttonColumn(name: string, datas: any, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      cellRendererFramework: ButtonColumnRenderer,
      cellRendererParams: datas,
      filter: false,
      sortable: false,
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne buttonDisablable
  buttonDisablableColumn(name: string, datas: any, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      cellRendererFramework: ButtonDisablableColumnRenderer,
      cellRendererParams: datas,
      filter: false,
      sortable: false,
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne buttonHide
  buttonHideColumn(name: string, datas: any, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      cellRendererFramework: ButtonHideColumnRenderer,
      cellRendererParams: datas,
      filter: false,
      sortable: false,
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne badge
  badgeColumn(name: string, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      cellRendererFramework: BadgeColumnRenderer,
      filter: false,
      sortable: false,
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne date (columnParams = objet a rajouter ou remplacer sur le défaut ex: { headerName: 'myName' })
  dateColumn(name: string, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      filter: "agDateColumnFilter",
      valueFormatter: (data: any) => (data.value ? moment(data.value).format("L") : ""),
      filterParams: {
        filterOptions: ["equals", "inRange"],
        suppressAndOrCondition: true,
      },
      cellStyle: { "text-align": "center" },
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne date + heure (columnParams = objet a rajouter ou remplacer sur le défaut ex: { headerName: 'myName' })
  dateFullColumn(name: string, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      filter: "agDateColumnFilter",
      valueFormatter: (data: any) => (data.value ? moment(data.value).format("DD/MM/YY - HH:mm") : ""),
      filterParams: {
        filterOptions: ["equals", "inRange"],
        suppressAndOrCondition: true,
      },
      cellStyle: { "text-align": "center" },
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  //Permet de créer une colonne icône
  iconeColumn(name: string, columnParams: any) {
    let defaultColumnParams = {
      headerName: name,
      field: name,
      cellRendererFramework: IconeColumnRenderer,
      filter: false,
      sortable: false,
    };
    //Permet de remplacer des params de "defaultColumnParams" avec ceux passés en param (columnParams)
    return Object.assign({}, defaultColumnParams, columnParams);
  }

  // Resize toute les colonnes non pinned
  autoSizeAllColumns() {
    var allColumnIds: any[] = [];
    this.gridOptions.columnApi.getAllColumns().forEach(function (column: any) {
      if (column.pinned == null) {
        allColumnIds.push(column.colId);
      }
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  }

  fillListWithFilterAndSort(params: any) {
    let query: Query = {
      name: null,
      user: null,
      offset: 0,
      limit: 0,
      filters: [],
      hasFilters: null,
      sorts: [],
      hasSorts: null,
      results: {},
      isValid: null,
    };

    query.offset = params.startRow;
    query.limit = params.endRow - params.startRow;
    var re = /\./gi;
    let filterModelKey = Object.keys(params.filterModel);
    if (filterModelKey.length != 0) {
      query.filters = [];
      for (let key in filterModelKey) {
        let col = filterModelKey[key];
        let queryFilter = params.filterModel[col];
        let queryFilterType = "";
        let queryFilterValue = queryFilter.filter;
        if (col) {
          switch (queryFilter.type) {
            case "equals":
              queryFilterType = "EQ";
              break;
            case "notEqual":
              queryFilterType = "NEQ";
              break;
            case "lessThan":
              queryFilterType = "LT";
              break;
            case "greaterThan":
              queryFilterType = "GT";
              break;
            case "lessThanOrEqual":
              queryFilterType = "LTE";
              break;
            case "greaterThanOrEqual":
              queryFilterType = "GTE";
              break;
            case "contains":
              queryFilterValue = "%" + queryFilterValue + "%";
              queryFilterType = "LIKE";
              break;
            case "endsWith":
              queryFilterValue = "%" + queryFilterValue;
              queryFilterType = "LIKE";
              break;
            case "startsWith":
              queryFilterValue = queryFilterValue + "%";
              queryFilterType = "LIKE";
              break;
            case "notContains":
              queryFilterValue = "%" + queryFilterValue + "%";
              queryFilterType = "LIKE";
              break;
            case "inRange":
              queryFilterType = "IN";
              break;
            default:
          }
          var field = col.replace(re, "");
          //Pour les dates il faut les formater avant de les envoyer
          if (col == "date" || col.toLowerCase().includes("date")) {
            //Je considère que pour une date si on fait un "inRange" ça veut dire de telle date à telle date
            if (queryFilterType == "IN") {
              query.filters.push({ type: "GTE", field: field, value: moment(queryFilter.dateFrom).format("Y-MM-DD") + "T00:00:00" });
              query.filters.push({ type: "LT", field: field, value: moment(queryFilter.dateTo).format("Y-MM-DD") + "T00:00:00" });
            } else if (queryFilterType == "EQ") {
              query.filters.push({ type: "GTE", field: field, value: moment(queryFilter.dateFrom).format("Y-MM-DD") + "T00:00:00" });
              query.filters.push({
                type: "LT",
                field: field,
                value: moment(queryFilter.dateFrom).add(1, "days").format("Y-MM-DD") + "T00:00:00",
              });
            } else {
              query.filters.push({ type: queryFilterType, field: field, value: moment(queryFilter.dateFrom).format() });
            }
          } else {
            query.filters.push({ type: queryFilterType, field: field, value: queryFilterValue });
          }
        }
      }
    }
    if (params.sortModel.length > 0) {
      query.sorts = [];
      for (let key in Object.keys(params.sortModel)) {
        let sort = params.sortModel[key].sort === "asc" ? "asc" : "dsc";
        //Enleve les points ex: variant.product.ean -> variantproductean
        params.sortModel[key].colId = params.sortModel[key].colId.replace(re, "");
        query.sorts.push({ field: params.sortModel[key].colId, direction: sort });
      }
    }
    return query;
  }

  // eslint-disable-next-line no-unused-vars
  onRemoveSelected(row: any) {
    return;
  }

  // eslint-disable-next-line no-unused-vars
  onAddSelected() {
    var cols = this.gridOptions.columnApi.getAllColumns();
    let firstColNameEditable;
    // Get the first column editable
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].colDef.editable) {
        firstColNameEditable = cols[i].colDef.headerName;
        break;
      }
    }
    var rows = [{}];
    this.gridOptions.api.setPinnedTopRowData(rows);
    this.gridOptions.api.setFocusedCell(0, firstColNameEditable, "top");
    this.gridOptions.api.startEditingCell({
      rowIndex: 0,
      colKey: firstColNameEditable,
      rowPinned: "top",
      keyPress: null,
      charPress: null,
    });
    return;
  }

  onRowDataChanged() {
    Vue.nextTick(() => {
      this.gridOptions.api.sizeColumnsToFit();
    });
  }

  onExport() {
    return;
  }

  // eslint-disable-next-line no-unused-vars
  create_csv_link_based_on_column(cell: any) {
    return;
  }

  // eslint-disable-next-line no-unused-vars
  onRowValueChanged(row: any) {
    return;
  }

  onRowEditingStarted(row: any) {
    // Récuperation de l'ancienne valeur pour la mettre à dispo
    this.editingOldValues = Object.assign({}, row.data);
  }

  onRowEditingStopped(row: any) {
    this.editingNewValues = Object.assign({}, row.data);
    this.gridOptions.api.setPinnedTopRowData([]);
  }

  // eslint-disable-next-line no-unused-vars
  onCellEditingStarted(row: any) {
    return;
  }

  // eslint-disable-next-line no-unused-vars
  onCellEditingStopped(row: any) {
    return;
  }

  // TODO: créer une interface
  // eslint-disable-next-line no-unused-vars
  onCellClicked(cel: any) {
    return;
  }

  // eslint-disable-next-line no-unused-vars
  onRowSelected(row: any) {
    // vérification d'une ligne selectionnée pour afficher le bouton corbeille
    if (this.gridOptions.api.getSelectedRows().length > 0) this.isDeleteButtonDisabled = false;
    else this.isDeleteButtonDisabled = true;
  }

  // eslint-disable-next-line no-unused-vars
  onSelectionChanged(row: any) {
    return;
  }

  // Refresh des elements de la grille
  async refreshData() {
    let gridApi = this.gridOptions.api;
    let pageNumber = gridApi.paginationGetCurrentPage();
    gridApi.setDatasource(this.dataSource);
    gridApi.paginationGoToPage(pageNumber);
  }

  // eslint-disable-next-line no-unused-vars
  onModelUpdated(model: any) {
    let gridApi = this.gridOptions.api;
    if (gridApi.getDisplayedRowCount() < 1) {
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }

  clearFilters() {
    let gridApi = this.gridOptions.api;
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged();
  }

  // TODO: créer une interface
  // eslint-disable-next-line no-unused-vars
  onFirstDataRendered(params: any) {
    return;
  }

  beforeMount() {
    this.gridOptions = {
      components: {
        loadingRenderer: (params: any) => {
          if (params.value !== undefined) {
            return params.value;
          } else {
            return '<i class="fa-light fa-spinner fa-pulse"></i>';
          }
        },
      },
      rowModelType: "infinite",
      rowSelection: "single",
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 1,
      colResizeDefault: "shift",
      paginationPageSize: 20,
      cacheBlockSize: 20,
      rowHeight: 32,
      headerHeight: 40,
      getRowId: (item: any) => {
        return item.data.id;
      },
    };
    this.defaultColDef = {
      resizable: true,
      filter: true,
      sortable: true,
    };

    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center"><i class="fa-light fa-spinner fa-pulse"></i> Patientez svp...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">Pas de résultat</span>';
  }
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }
}
