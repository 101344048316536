import { ObjectDirective } from "vue";
import Sortable from "sortablejs";
import { Vue } from "vue-property-decorator";

const directive: ObjectDirective = {
  inserted: (el, binding) => {
    Vue.nextTick().then(() => {
      // Charge la liste des fonctionnalité selectionées

      el.querySelectorAll("tbody > tr").forEach((draggableEl: { classList: { add: (arg0: string) => void } }) => {
        // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
        watchClass(draggableEl, "sortHandleRow");
        draggableEl.classList.add("sortHandleRow");
      });
      Sortable.create(
        el.querySelector("tbody") as HTMLElement,
        binding.value ? { ...binding.value, draggable: ".sortableRow", handle: ".sortHandleRow" } : {}
      );
    });
  },
};

function watchClass(targetNode: any, classToWatch: any) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === "attributes" && mutation.attributeName === "class") {
        const currentClassState = (mutation.target as any).classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            (mutation.target as any).classList.add("sortHandleRow");
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}
export default directive;
