
import { Component, Prop, Vue } from "vue-property-decorator";

import moment from "moment";
import "moment/locale/fr";
// eslint-disable-next-line no-unused-vars
import ControllerBase from "@/app/shared/services/controllers/controllerBase";

moment.locale("fr");

@Component
export default class FormOrderPreparationIstructions extends Vue {
  @Prop() controller!: ControllerBase;

  preparationsInstructions: any[] = [];

  // /**
  //  * initialisation du composant
  //  * @param params
  //  */
  async loadComponent(params: any) {
    Vue.$log.info("params");
    Vue.$log.info(params);
    this.preparationsInstructions = params.preparationsInstructions;
  }

  async beforeMount() {
    this.controller.EventBus.$on("loadUpdate", await this.loadComponent);
  }

  async beforeDestroy() {
    this.controller.EventBus.$off("loadUpdate", await this.loadComponent);
  }
}
