
import { Component, Vue } from "vue-property-decorator";
import { AppHeader, AppMenu, AppFooter, RightDrawer } from "@/app/shared/components";

@Component({
  components: {
    AppHeader,
    AppFooter,
    AppMenu,
    RightDrawer,
  },
})
export default class PrivateLayout extends Vue {}
