
import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import moment from "moment";
import "moment/locale/fr";
// eslint-disable-next-line no-unused-vars
import { FilterItem, Query, ThirdWarehouse, TypeControle } from "../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import preparationControlController from "../../shared/services/controllers/preparationControl/preparationControlController";

moment.locale("fr");

const moduleName = "preparationControl";
// const subModuleName = "preparationControl";
// const labelDataType = "filterAutoComplete";
@Component
export default class FilterAutoComplete extends Vue {
  @Prop() controller!: preparationControlController;
  @Prop() typeControle!: TypeControle;
  @Prop() dateControle!: string;
  @Prop() filterItem!: FilterItem;

  loading = false;
  search: Query | null = null;

  /**
   * watch to autocomplete for search element
   * @param query
   */
  @Watch("search")
  onChange(query: Query): void {
    // rafraichissement de la list de palette
    if (query && !this.filterItem.value) {
      this.updateListItems(query);
    }
  }

  /**
   * Methode pour le changement de valeur du composant
   */
  changeCustomer() {
    return;
  }

  /**
   * Mise a jours de la liste des elements de recherche
   */
  async updateListItems(query: Query) {
    this.loading = true;
    this.controller.Log.logDebug(`filterChange`, `Object query : ${JSON.stringify(this.filterItem)}`);
    let sendResult: { data: ThirdWarehouse[]; itemsCount: number; status?: number } | null = { data: [], itemsCount: 0 };

    let filterResult: ThirdWarehouse[] = [];
    if (this.dateControle) {
      try {
        switch (this.filterItem.searchCode) {
          case "carrier": // filtre de recherche de transporteurs
            sendResult = await this.controller.preparationControlStore.getCarrierSuggests(
              this.dateControle,
              this.typeControle,
              this.filterItem,
              query
            );
            if (sendResult.status === 200 || sendResult.status === 206) {
              filterResult = sendResult.data;
              // on concaten le code et le nom du transporteur pour afficher dans la liste
              for (let c of filterResult) {
                let code = c.code != "" ? c.code + " - " : c.code;
                c.codeName = `${code}${c.name}`;
              }
            }
            break;
          case "contractor": // filtre de recherche de D.O
            sendResult = await this.controller.preparationControlStore.getContractorSuggests(
              this.dateControle,
              this.typeControle,
              this.filterItem,
              query
            );
            if (sendResult.status === 200 || sendResult.status === 206) {
              filterResult = sendResult.data;
              // on concaten le code et le nom du do pour afficher dans la liste
              for (let c of filterResult) {
                let code = c.code != "" ? c.code + " - " : c.code;
                c.codeName = `${code}${c.name}`;
              }
            }
            break;
          default:
            break;
        }
      } catch (error) {
        //Log
        await this.controller.Log.logError(`filterChange`, "", error, moduleName);
        sendResult = null;
      }
    }
    this.loading = false;
    this.filterItem.items = filterResult;
  }
}
