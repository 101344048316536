import AppModuleSettings from "./moduleSettings/ModuleSettings.vue";
import store from "@/app/app-state";
import PrivateLayout from "@/app/layouts/Private.vue";
import { Route } from "vue-router";

const settingsRoutes = [
  {
    path: "/settings",
    meta: {
      type: "module",
      breadcrumb: [
        {
          text: "Paramètres",
        },
      ],
      iconClass: "mdi-cog",
    },
    component: PrivateLayout,

    children: [
      {
        path: "moduleSettings",
        name: "moduleSettings",
        component: AppModuleSettings,
        meta: {
          breadcrumb: [
            {
              text: "Gestion modules",
              to: "/Settings/moduleSettings",
            },
          ],
          rightName: "_SETTINGS_MODULESETTINGS", // Ce droit ne doit pas etre créé dans le rightManager
          type: "subModule",
          iconClass: "mdi-view-module ",
        },
        beforeEnter: (to: Route, _from: Route, next: any) => {
          // page autorisé seulement si administrateur
          if (store.state.userContext.isAdministrator) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        },
      },
    ],
  },
];

export default settingsRoutes;
