import ControllerBase from "@/app/shared/services/controllers/controllerBase";
import StockStore from "../../../store/stockStore";
import UserStore from "../../../store/userStore";

export default class StockController extends ControllerBase {
  public StockStore: StockStore;
  public UserStore: UserStore;

  constructor(baseUrl: string, userBaseUrl: string) {
    super();
    this.StockStore = new StockStore(baseUrl);
    this.UserStore = new UserStore(userBaseUrl);
  }
}
