
// import Vue from "vue";
import { Component, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { Route } from "vue-router";
// eslint-disable-next-line no-unused-vars
import { Userinfo } from "@/app/shared/state/userContextState";
import { clientId } from "@/environment/environment";

interface WithRoute /* can also extend Vue to make sure nothing is colliding */ {
  $route: Route;
}

@Component
// Le décorateur @Component indique que la classe est un composant Vue
export default class Login extends Vue implements WithRoute {
  // Les données initiales peuvent être déclarées comme des propriétés de l'instance
  loading = false;
  form: Userinfo = { companyCode: "", warehouseCode: "", login: "", password: "", key: "", applicationName: clientId };
  show1 = false;
  errorMessage = "error login.....";
  snackbar = false;

  getValidationState = ({ dirty, validated, valid = null }: any) => {
    return dirty || validated ? valid : null;
  };

  // Les méthodes peuvent être déclarées comme des méthodes d'instance
  submit() {
    this.loading = true;

    //TOOD : customizer la validation
    //evt.preventDefault();
    // redirection par default sur la page d'accueil
    let urlredirect = "/";

    // recuperation de l'url de destination dans la query
    if (this.$route.query.redirectUri != undefined) {
      urlredirect = this.$route.query.redirectUri.toString();
    }

    this.$store
      .dispatch("userContext/login", this.form)
      .then(
        () => {
          this.$router.replace(urlredirect);
        },
        (reject) => {
          this.errorMessage = reject;
          this.snackbar = true;
        }
      )
      .catch((error: string) => {
        Vue.$log.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  dessiner() {
    // fonction pour la partie dessin de gauche
    var canvas: any = document.getElementById("canvas");
    if (canvas) {
      var ctx = canvas.getContext("2d");

      ctx.canvas.width = window.innerWidth;
      ctx.canvas.height = window.innerHeight;

      var largeurCtx = ctx.canvas.width / 2;
      var hauteurCtx = ctx.canvas.height / 2;

      ctx.strokeStyle = "#C13660";
      ctx.lineCap = "round";
      ctx.lineWidth = 75;
      ctx.beginPath();
      ctx.arc(-10, hauteurCtx + 150, hauteurCtx / 2, 0, 100, false);
      ctx.stroke();

      ctx.strokeStyle = "#ec406f";
      ctx.lineCap = "round";
      ctx.lineWidth = 75;
      ctx.beginPath();
      ctx.arc(largeurCtx, hauteurCtx, hauteurCtx, 100, 0, true);
      ctx.stroke();
    }
  }

  mounted() {
    this.dessiner();
  }
}
