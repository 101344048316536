
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ComboboxComponent extends Vue {
  selectedElement: any = null;
  params: any;

  public get selectedId() {
    if (this.params.value) {
      let selectedId = eval("this.params.value." + this.params.selectedId);
      return selectedId;
    } else return null;
  }

  public set selectedId(newValue) {
    let selectedElement;
    this.params.options.forEach(function (element: any) {
      if (element === newValue) {
        selectedElement = element.obj;
      }
    });
    this.selectedElement = selectedElement;
  }

  getValue() {
    // on renvoie le params.value modifié dans le setter
    return this.selectedElement;
  }

  mounted() {
    // initialisation de l'objet selectioné
    this.selectedElement = this.params.value;
  }
}
