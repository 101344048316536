
// eslint-disable-next-line no-unused-vars
import { isEnabled } from "vue-feature-flipping";
import { Component, Vue, Prop } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { ShippingUnitForStorage, SUStorageArea } from "../../shared/types/ShippingUnit";
import CardExpander from "./CardExpander.vue";

@Component({
  components: { CardExpander },
})
export default class StorageShippingUnitsDetail extends Vue {
  @Prop() shippingUnitForStorage!: ShippingUnitForStorage;
  @Prop() loading!: boolean;
  @Prop() storageAreaToHighlight!: SUStorageArea;
  get isFeatureNewCard() {
    return isEnabled("tempCardExpanderListRecipient");
  }

  getColor(recipient: any) {
    // si 0/X Ue faite alors en rouge
    // si 1à X-1 /X UE fait alors c'est en cour en bleu
    // si X/X UE fait alors en vert
    let color = "blue--text";

    if (recipient.stockedShippingUnitCount === 0) {
      color = "red--text";
    } else if (recipient.stockedShippingUnitCount === recipient.totalShippingUnit) {
      color = "green--text";
    }

    return color;
  }
}
