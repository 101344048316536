
import { Component, Prop, Vue } from "vue-property-decorator";
import ModalComponent from "@/app/shared/components/generic/modal/ModalComponent.vue";
import formUpdate from "../form/FormOrderPreparationIstructions.vue";
// eslint-disable-next-line no-unused-vars
import StockController from "@/app/stock/shared/services/controllers/stock/stockController";

@Component({
  name: "ModalPreparationInstructions",
  components: {},
})
export default class ModalPreparationInstructions extends ModalComponent {
  @Prop() declare controller: StockController;

  get dynamicModal() {
    return formUpdate;
  }

  async showModalPreparationInstructions(params: any) {
    await Vue.nextTick();
    this.controller.EventBus.$emit("loadUpdate", params);
    this.showModal();
  }

  async closeModalPreparationInstructions() {
    this.closeModal();
  }

  async beforeMount() {
    this.controller.EventBus.$on("closeModalPreparationInstructions", await this.closeModalPreparationInstructions);
    this.controller.EventBus.$on("showModalPreparationInstructions", await this.showModalPreparationInstructions);
  }

  async beforeDestroy() {
    this.controller.EventBus.$off("closeModalPreparationInstructions", await this.closeModalPreparationInstructions);
    this.controller.EventBus.$off("showModalPreparationInstructions", await this.showModalPreparationInstructions);
  }
}
