
import { Component, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";

import ButtonFavoris from "@/app/shared/components/buttonFavoris/ButtonFavoris.vue";

import { RouteConfig } from "vue-router";
interface FavorisConfig {
  moduleText: string;
  moduleIconClass: string;
  subModuleName?: string;
  subModuletext: string;
  subModuleIconClass: string;
  path: string;
}

@Component({
  components: {
    ButtonFavoris,
    draggable,
  },
})
export default class HomeFavoris extends Vue {
  drag = false;

  get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost",
    };
  }

  /**
   * Recuperation des modules sous modules
   */
  get modulesSousModules(): Array<FavorisConfig> {
    //Liste des modules et sous modules disponible
    let routeList = this.$router.options.routes;

    let favorisConfig: FavorisConfig[] = [];
    if (routeList) {
      routeList
        .filter(
          (element: RouteConfig) =>
            "meta" in element && element.meta != undefined && "type" in element.meta && element.meta.type === "module"
        )
        .forEach((mod) => {
          mod.children?.forEach((sub) => {
            favorisConfig.push({
              moduleText: mod.meta?.breadcrumb[0].text as string,
              moduleIconClass: mod.meta?.iconClass,
              subModuleName: sub.name,
              subModuletext: sub.meta?.breadcrumb[0].text as string,
              subModuleIconClass: sub.meta?.iconClass,
              path: sub.path,
            });
          });
        });
    }

    // retourne la liste des menu en favoris dans l'ordre des favoris
    return this.$store.state.userContext.favoris.map((f: string) => {
      let menu = favorisConfig?.find((r: FavorisConfig) => {
        return r.subModuleName === f;
      });
      return menu;
    });
  }

  change(e: any) {
    const favoris = this.modulesSousModules.map((f: FavorisConfig) => f.subModuleName);
    console.log("change", e, favoris);
    this.$store
      .dispatch("userContext/updateFavoris", favoris)
      .then()
      .catch((error) => {
        Vue.$log.error(error);
      });
  }
}
