
import { Component, Vue } from "vue-property-decorator";
import CheckboxComponent from "../../checkbox/CheckboxComponent.vue";

@Component({
  components: {
    CheckboxComponent,
  },
})
export default class CheckboxRenderer extends Vue {
  private params: any;

  // getter
  get checked() {
    if (this.params.value) {
      return this.params.value;
    } else return false;
  }
  // setter
  set checked(newValue) {
    this.params.value = newValue;
  }

  // getter
  get disabled() {
    if (this.params.rowStartEditing) {
      return false;
    } else return true;
  }
}
