
// eslint-disable-next-line no-unused-vars
import { Localization } from "@/app/stock/shared/store/Types";
// eslint-disable-next-line no-unused-vars
import OptionalFilter from "@/app/stock/shared/types/OptionalFilter";
import { Component, Vue, Prop, VModel } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FilterChipsRenderer extends Vue {
  @VModel() filter!: OptionalFilter;
  @Prop({ default: false }) close!: boolean;

  /**
   * Formatage de l'affichage d'une localisation
   */
  formatLocalization(localization: Localization): string {
    const localizationCode = [
      localization.roomCode,
      localization.areaCode,
      localization.aisleCode,
      localization.levelCode,
      localization.locationCode,
    ]
      .filter((el) => {
        return el != null && el != "";
      })
      .join("-");
    return `${localizationCode} : ${localization.areaLabel}`;
  }
}
