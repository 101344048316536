
import { Component, Prop } from "vue-property-decorator";
import ListComponent from "@/app/shared/components/generic/list/ListComponentV2.vue";
import moment from "moment";
import "moment/locale/fr";
// eslint-disable-next-line no-unused-vars
import preparationControl from "../../../PreparationControl.vue";
// eslint-disable-next-line no-unused-vars
import { TypeControle } from "../../../shared/store/Types";
// eslint-disable-next-line no-unused-vars
import preparationControlController from "../../../shared/services/controllers/preparationControl/preparationControlController";

moment.locale("fr");

const moduleName = "preparationControl";

@Component
export default class ByNote extends ListComponent {
  @Prop() declare controller: preparationControlController;
  @Prop() dateControle!: string;
  @Prop() typeControle!: TypeControle;

  onRowClicked = "";
  onRowDoubleClicked = "";

  async onGridReady(params: any) {
    let gridApi = params.api;

    this.dataSource = {
      rowCount: null,
      getRows: async (params: any) => {
        let query = this.fillListWithFilterAndSort(params);
        this.controller.Log.logDebug(`listPreparationControlByNote`, `Object query : ${JSON.stringify(query)}`);
        let sendResult: { data: any[]; itemsCount: number } | null = { data: [], itemsCount: 0 };
        try {
          if (this.dateControle && this.typeControle) {
            sendResult = await this.controller.preparationControlStore.getLst(this.dateControle, this.typeControle, query);

            // Affichage du DO et du destinataire = Code + Nom dans la grille
            sendResult?.data.forEach(function (element) {
              var code = element.contractorCode != null ? element.contractorCode + " - " : "";
              var name = element.contractorName != null ? element.contractorName : "";
              element.contractor = `${code}${name}`;

              code = element.recipientCode != null ? element.recipientCode + " - " : "";
              name = element.recipientName != null ? element.recipientName : "";
              element.recipient = `${code}${name}`;
            });
          }
          params.successCallback(sendResult?.data, sendResult?.itemsCount);
        } catch (error) {
          //Log
          await this.controller.Log.logError(`listPreparationControlByNote onGridReady`, "", error, moduleName);
          sendResult = null;
        }
      },
    };
    gridApi.setDatasource(this.dataSource);
  }

  /**
   * rafraichissement de la liste
   */
  refreshList() {
    this.refreshData();
  }

  onRowSelected(row: any) {
    if (row.node.isSelected()) {
      (this.$parent as preparationControl).current_step = 2;
      (this.$parent as preparationControl).plan = row.data;
    }
  }

  async beforeMount() {
    this.columnDefs = [
      this.numericColumn("id", {
        headerName: "Id",
        hide: true,
        cellRenderer: this.gridOptions.components.loadingRenderer,
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
      }),
      this.textColumn("number", {
        headerName: "N° de bon",
        field: "number",
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
        floatingFilterComponentParams: { suppressFilterButton: false },
        width: 80,
      }),
      this.textColumn("contractor", {
        headerName: "D.O",
        field: "contractor",
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
      this.textColumn("recipient", {
        headerName: "Destinataire",
        field: "recipient",
        suppressMenu: true,
        floatingFilter: false,
        sortable: false,
        floatingFilterComponentParams: { suppressFilterButton: false },
      }),
    ];
    this.gridOptions.rowMultiSelectWithClick = true;
    //modification pour permettre l'infiniteScrolling
    this.pagination = true;
    this.gridOptions.infiniteInitialRowCount = 20;
    this.gridOptions.maxBlocksInCache = 20;
    this.gridOptions.cacheOverflowSize = 20;
    this.gridOptions.maxConcurrentDatasourceRequests = 5;

    // on conditionne le nombre de ligne affiché en fonction du device
    if (this.$vuetify.breakpoint.smAndDown) {
      this.gridOptions.paginationPageSize = 5;
      this.pagination = true;
    } else {
      this.gridOptions.paginationPageSize = 20;
    }

    this.gridOptions.cacheBlockSize = 20;
    // Suppression des boutons clear filtre
    this.clearFilterButtonVisibility = false;
  }
  mounted() {
    (this.$parent as preparationControl).clearCabAndPlan();
    (this.$parent as preparationControl).refreshListPallets();
  }
}
